import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import 'rxjs/add/operator/map';
import { globalValues } from '@app/globals';
import { CustomHttp } from '@service/customhttp.service';
import { RequestLibraryService } from '@service/core/request-library.service';
import { HttpClient } from '@angular/common/http';
@Injectable()
export class FormReportService {
    private customHttp: CustomHttp;
    constructor(
        private http: HttpClient,
        private router: Router,
        private requestLibrary: RequestLibraryService
    ) {
        this.customHttp = new CustomHttp(http, router);
    }

    getFormSubmissionCount(criteriaArray) {
        let returnArr = [];
        if (criteriaArray != null) {
            returnArr = JSON.parse(JSON.stringify(criteriaArray));
        }

        return this.requestLibrary.GetAll('/forms/reports/forms', {
            'options': { criteria: returnArr }
        });
    }
    // getFormSubmissionCount(date_interval) {
    //     return this.requestLibrary.GetAll('/forms/reports/contacts-submissions', {
    //         'options': { date_interval: date_interval }
    //     })
    //         .map((res: any) => res.data)
    //         .map(arr => arr.reduce((a, b) => a + Number(b.forms_submitted), 0));
    // }

}
