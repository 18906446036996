import { Injectable } from "@angular/core"
import { Router } from "@angular/router"
import { globalValues, themesArray } from "@app/globals"
import * as auth0js from "auth0-js"
import * as _ from "lodash"
import { Logger } from "../global.funcs"
import {
  HelpersService,
  AccountService,
  AuthService,
  PrivateLabelService,
  SetupAppService,
  UserService,
  UserPrivilegeContextService,
  FieldService,
  AddictionTreatmentSuiteService,
} from "../index"
import { auth0Config } from "./auth0.config"
import { NgxPermissionsService } from "ngx-permissions"
import { AppConfig } from "@service/app.config"
import { ThemesService } from "@app/core/themes/themes.service"

@Injectable()
export class AuthLoginService {
  private _clientId = auth0Config.clientID
  private _domain = auth0Config.domain
  private afterLoginUrl = "/leads"
  private privateLables: any
  private isAuthCallback: Boolean = false

  _auth0WebAuth = new auth0js.WebAuth({
    domain: this._domain,
    clientID: this._clientId,
    responseType: "token id_token",
    audience: `https://${this._domain}/api/v2/`,
    scope:
      "openid profile email read:current_user update:users update:current_user_identities",
    redirectUri: auth0Config.redirectUrl,
  })

  constructor(
    private addictionSuite: AddictionTreatmentSuiteService,
    public router: Router,
    public privateLabelService: PrivateLabelService,
    public accountService: AccountService,
    private authService: AuthService,
    private fieldService: FieldService,
    private userService: UserService,
    private setupAppService: SetupAppService,
    private helperService: HelpersService,
    private permissionsService: NgxPermissionsService,
    private theme: ThemesService,
    private userPrivilegeContext: UserPrivilegeContextService
  ) {}

  private redirectAfterLoginUrl() {
    /* if (
       this.privateLables &&
       this.privateLables.interface_url &&
       this.privateLables.interface_url != window.location.origin
     ) {
       window.location.href = this.privateLables.interface_url;
       return;
     }*/
    this.router.navigate([this.afterLoginUrl])
    // window.location.href = this.afterLoginUrl;
    return
  }

  public login(_as = null): void {
    if (this.authService.isAuthenticated()) {
      this.redirectAfterLoginUrl()
      return
    }
    if (!(_as === null)) {
      this._auth0WebAuth.authorize({
        redirectUri: this.helperService.appendQueryString(
          auth0Config.redirectUrl,
          { _as: _as }
        ),
      })
    } else {
      this._auth0WebAuth.authorize()
    }
  }

  public signup(): void {
    if (this.authService.isAuthenticated()) {
      this.redirectAfterLoginUrl()
      return
    } else {
      this._auth0WebAuth.authorize({
        action: "signup",
      })
    }
  }

  public handleAuthentication(_as = null): void {
    this._auth0WebAuth.parseHash((err, authResult) => {
      if (authResult && authResult.accessToken && authResult.idToken) {
        window.location.hash = ""
        this.setSession(authResult, _as)
          .then(this.setupAppService.setupApp())
          .then(() => {
            this.redirectAfterLoginUrl()
          })
      } else if (err) {
        this.redirectAfterLoginUrl()
        Logger().info(err)
      } else {
        this.redirectAfterLoginUrl()
      }
    })
  }

  private setSession(authResult, _as = null) {
    const expiresAt = authResult.idTokenPayload
      ? JSON.stringify(
          authResult.idTokenPayload.exp * 1000 + new Date().getTime()
        )
      : JSON.stringify(authResult.expiresIn * 1000 + new Date().getTime())
    localStorage.setItem("access_token", authResult.accessToken)
    localStorage.setItem("id_token", authResult.idToken)
    localStorage.setItem("expires_at", expiresAt)
    localStorage.setItem("loggedIn", "true")

    const mo_user_id =
      authResult.idTokenPayload && authResult.idTokenPayload.mo_user_id
        ? authResult.idTokenPayload.mo_user_id
        : null

    if (!(_as === null)) {
      localStorage.setItem("User", _as)
    }
    if (mo_user_id) {
      localStorage.setItem("User", mo_user_id)
    }

    return this.afterSetup()
  }

  public afterSetup() {
    return new Promise((resolve, reject) => {
      return this.getAccount()
        .then(this.updatePrivateLabels.bind(this))
        .then(this.getUser.bind(this))
        .then(this.getStripePublishKey.bind(this))
        .then(this.handleUserPrivileges.bind(this))
        .then(this.handleAddiction.bind(this))
        .then(this.handleClientPortal.bind(this))
        .then(this.setupAppService.handleSmallLogo.bind(this))
        .then(() => {
          this.redirectAfterLoginUrl()
        })
    })
  }

  public getAccount() {
    return new Promise((resolve, reject) => {
      this.accountService.getMyAccount().subscribe(
        (res: any) => {
          Logger().info("getAccount", "getMyAccount", res)
          if (res.success === "true") {
            localStorage.setItem("account", JSON.stringify(res.data))
            
            localStorage.setItem("account_id", res.data.id)
            localStorage.setItem("account_name", res.data.name)
            localStorage.setItem("creation_date", res.data.created_at_unixtime)
            localStorage.setItem("group_name", res.data.group.name)
            localStorage.setItem("phone", res.data.telephone)
            localStorage.setItem("zip_code_field", res.data.zip_code_field_id)
            localStorage.setItem("account_timezone", res.data.timezone)
          } else {
            return reject("Something went wrong.")
          }
        },
        err => {
          return reject(err)
        }
      )
      return resolve(true)
    })
  }

  // todo: use get my user information api instead user detail api.
  public getUser() {
    const appVersion = localStorage.getItem("appVersion");
    return new Promise((resolve, reject) => {
      return this.userService.getMyUser().subscribe(
        (res: any) => {
          if (res.success == "true") {
            localStorage.setItem("name", res.data.name)
            localStorage.setItem("email", res.data.email)
            localStorage.setItem("User", res.data.id)
            if (res.data.available_suites) {
              localStorage.setItem(
                "appAvailablesVersions",
                JSON.stringify(res.data.available_suites)
              )
            }
            if (res.data.default_suite && !appVersion) {
              localStorage.setItem(
                "appVersion",
                this.getActualAppVersion(
                  res.data.default_suite,
                  res.data.available_suites
                )
              )
            }

            return resolve(true)
          } else {
            return reject("Something went wrong.")
          }
        },
        err => {
          return reject(err)
        }
      )
    })
  }

  public getActualAppVersion(appVersion = "portal", availableAppVersions) {
    if (
      this.checkIsAllowedAppVersion(appVersion, availableAppVersions) !== true
    ) {
      appVersion = AppConfig.availableAppVersions.PORTAL
      if (
        this.checkIsAllowedAppVersion(
          AppConfig.availableAppVersions.ADDICTION,
          availableAppVersions
        ) !== false
      ) {
        appVersion = AppConfig.availableAppVersions.ADDICTION
      } else if (
        this.checkIsAllowedAppVersion(
          AppConfig.availableAppVersions.AGGREGATION,
          availableAppVersions
        ) !== false
      ) {
        appVersion = AppConfig.availableAppVersions.AGGREGATION
      } else if (
        this.checkIsAllowedAppVersion(
          AppConfig.availableAppVersions.AUTOMATION,
          availableAppVersions
        ) !== false
      ) {
        appVersion = AppConfig.availableAppVersions.AUTOMATION
      }
    }
    return appVersion
  }

  private checkIsAllowedAppVersion(appVersion, availableAppVersions) {
    if (
      availableAppVersions &&
      Array.isArray(availableAppVersions) &&
      availableAppVersions.includes(appVersion)
    ) {
      return true
    } else {
      return false
    }
  }

  public handleUserPrivileges() {
    return this.userPrivilegeContext
      .getPrivilegesServer()
      .then(this.userPrivilegeContext.storePrivilegesStorage.bind(this))
      .then(this.loadUserPrivileges.bind(this))
  }

  private getUserPrivileges() {
    return new Promise((resolve, reject) => {
      const user: number = +localStorage.getItem("User")
      return this.userService.getMyPrivilege().subscribe(
        (res: any) => {
          if (res.success == "true") {
            const objPrivileges = res.data.privileges
            const modelPrivilege = objPrivileges.map(privilege => privilege.key)
            // Logger().info(modelPrivilege, objPrivileges);
            localStorage.setItem(
              "userPrivileges",
              JSON.stringify(modelPrivilege)
            )
            return resolve(modelPrivilege)
          } else {
            return reject("Something went wrong.")
          }
        },
        err => {
          return reject(err)
        }
      )
    })
  }
  private loadUserPrivileges(privileges) {
    return new Promise((resolve, reject) => {
      // Logger().info('in:loadUserPrivileges:', privileges);
      this.permissionsService.loadPermissions(privileges)
      resolve(true)
    })
  }

  private updatePrivateLabels() {
    return new Promise((resolve, reject) => {
      return this.privateLabelService.getMyPrivateLabels().subscribe(
        (res: any) => {
          this.privateLables = res.data
          if (res.success == "true") {
            // console.log('updatePrivateLabels', res.data);
            if (res.data.logo_url && res.data.small_logo_url) {
              localStorage.setItem("logo", res.data.logo_url)
              localStorage.setItem("small_logo", res.data.small_logo_url)
            } else if (res.data.logo_url && !res.data.small_logo_url) {
              localStorage.setItem("logo", res.data.logo_url)
              localStorage.setItem("small_logo", globalValues.small_logo)
            } else if (!res.data.logo_url && res.data.small_logo_url) {
              localStorage.setItem("logo", globalValues.logo)
              localStorage.setItem("small_logo", res.data.small_logo_url)
            }
            if (res.data.theme_name) {
              localStorage.setItem("theme_name", String(res.data.theme_name))
              this.theme.setTheme(String(res.data.theme_name))
            }
            return resolve(true)
          } else {
            return reject("Something went wrong.")
          }
        },
        err => {
          return reject(err)
        }
      )
    })
  }

  public getStripePublishKey() {
    return new Promise((resolve, reject) => {
      this.accountService.getStripePublishableKey().subscribe((res: any) => {
        if (res.success === "true") {
          localStorage.setItem(
            "stripe_publishable_key",
            res.data.stripe_publishable_key
          )
          return resolve(true)
        } else {
          return reject(
            "Something went wrong getting your credit card settings!"
          )
        }
      })
    })
  }
  public handleAddiction() {
    const appVersion = localStorage.getItem("appVersion")
    Logger().info("in:" + appVersion)
    if (appVersion == AppConfig.availableAppVersions.ADDICTION) {
      return this.getMyAccountAddiction().then(
        this.getInsuranceFieldAddiction.bind(this)
      )
    } else {
      return Promise.resolve()
    }
  }

  private getMyAccountAddiction() {
    return new Promise((resolve, reject) => {
      this.accountService
        .getMyAccountSettings("addictiontreatment")
        .subscribe((res: any) => {
          if (res.success === "true") {
            localStorage.setItem("addictiontreatment", JSON.stringify(res.data))
            return resolve(true)
          } else {
            return reject("Something went wrong getting your account settings!")
          }
        })
    })
  }

  private getInsuranceFieldAddiction() {
    return new Promise((resolve, reject) => {
      this.fieldService.getAllFields().subscribe((res: any) => {
        if (res.success === "true") {
          const fieldsArray = res.data
          const insuranceFieldDetail = _.find(fieldsArray, {
            type: globalValues.insurance_field_type,
          })
          const referredToFieldDetail = _.find(fieldsArray, {
            type: globalValues.referred_to_field_type,
          })
          if (insuranceFieldDetail) {
            localStorage.setItem(
              "addictiontreatment_insurance_field",
              JSON.stringify(insuranceFieldDetail)
            )
          }
          if (referredToFieldDetail) {
            localStorage.setItem(
              "addictiontreatment_referred_to_field",
              JSON.stringify(referredToFieldDetail)
            )
          }
          return resolve(true)
        } else {
          return reject("Something went wrong getting your account settings!")
        }
      })
    })
  }

  private getParentCreditCardPublishableKey() {
    return new Promise((resolve, reject) => {
      this.accountService
        .getParentStripePublishableKey()
        .subscribe((res: any) => {
          if (res.success === "true") {
            localStorage.setItem(
              "stripe_publishable_key",
              res.data.stripe_publishable_key
            )
            return resolve(true)
          } else {
            return reject(
              "Something went wrong getting your credit card settings!"
            )
          }
        })
    })
  }
  public handleClientPortal() {
    const appVersion = localStorage.getItem("appVersion")
    Logger().info("in:" + appVersion)
    if (appVersion == AppConfig.availableAppVersions.PORTAL) {
      return this.getParentCreditCardPublishableKey()
    } else {
      return Promise.resolve()
    }
  }
}
