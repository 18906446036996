import {
  Component,
  OnInit,
  ViewChild,
  NgZone,
  ChangeDetectorRef,
  AfterViewChecked,
} from "@angular/core"
import { Router } from "@angular/router"
import { AuthService } from "@service/index"
import { SettingsService } from "../../core/settings/settings.service"
const browser = require("jquery.browser")
declare var $: any
import { BootController } from "@app/../boot-control"

import { AppConfig } from "@service/index"
import { Logger } from "@service/global.funcs"

declare var window: any

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit, AfterViewChecked {
  public logo = localStorage.getItem("logo")
  public smallLogo = localStorage.getItem("small_logo")
  public account_name = localStorage.getItem("account_name")
  public user_id = localStorage.getItem("User")
  public appVersions = AppConfig.availableAppVersions
  public parent_account_id: string
  isNavSearchVisible: boolean
  @ViewChild("fsbutton") fsbutton

  constructor(
    public settings: SettingsService,
    private authService: AuthService,
    private router: Router,
    private ngZone: NgZone,
    private cdRef: ChangeDetectorRef
  ) {
    if (this.smallLogo == "") {
      this.smallLogo = this.logo
    }
  }

  ngAfterViewChecked() {}

  ngOnInit() {
    const account = JSON.parse(localStorage.getItem("account"))
    if (account) {
      this.parent_account_id = account.parent_account_id
    }

    this.isNavSearchVisible = false
    if (browser.msie) {
      this.fsbutton.nativeElement.style.display = "none"
    }
  }

  toggleCollapsedSideabar() {
    this.settings.layout.isCollapsed = !this.settings.layout.isCollapsed
    localStorage.setItem("layout.isCollapsed", this.settings.layout.isCollapsed)
    return
  }

  isCollapsedText() {
    return this.settings.layout.isCollapsedText
  }

  restartApp() {
    this.ngZone.runOutsideAngular(() =>
      BootController.getbootControl().restart()
    )
  }
}
