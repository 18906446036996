import { Component, Input,  OnInit, ViewContainerRef, Output, EventEmitter } from '@angular/core';
import * as _ from 'lodash';

@Component({
    selector: 'show-local-filters',
    templateUrl: './show-filters.components.html',
})

export class ShowFiltersComponent implements OnInit {

    @Input() showFiltersObj: any;   
    @Output() afterClearFilters: EventEmitter<any> = new EventEmitter();
    public roleName: string = '';
    public currentUser: string;

    constructor(
        public vcRef: ViewContainerRef
    ) {
        // overlay.defaultViewContainer = vcRef;
    }

    ngOnInit() {        
        this.currentUser = localStorage.getItem('User');
        /** Initialize filters with default values */
        if ('status' in this.showFiltersObj) {
            this.showFiltersObj.status = '2';
        }
        if ('call_tracking_status' in this.showFiltersObj) {
            this.showFiltersObj.call_tracking_status = '';
        }
        if ('call_tracking_fallback' in this.showFiltersObj) {
            this.showFiltersObj.call_tracking_fallback = '';
        }
        if ('call_tracking_schedule' in this.showFiltersObj) {
            this.showFiltersObj.call_tracking_schedule.id = '';
            this.showFiltersObj.call_tracking_schedule.name = '';
        }

        if ('search' in this.showFiltersObj) {
            this.showFiltersObj.search = '';
        }
        if ('is_archived' in this.showFiltersObj) {
            this.showFiltersObj.is_archived = '';
        }
        if ('lead_type' in this.showFiltersObj) {
            this.showFiltersObj.lead_type = '';
        }
        if ('role' in this.showFiltersObj) {
            this.showFiltersObj.role.id = '';
            this.showFiltersObj.role.name = '';
        }

        if ('plan' in this.showFiltersObj) {
            this.showFiltersObj.plan.id = '';
            this.showFiltersObj.plan.name = '';
        }
        if ('group' in this.showFiltersObj) {
            this.showFiltersObj.group.id = '';
            this.showFiltersObj.group.name = '';
        }
        if ('channel' in this.showFiltersObj) {
            this.showFiltersObj.channel.id = '';
            this.showFiltersObj.channel.name = '';
        }
        this.getFilters();
    }

    /** Get Filters from Local localStorage */
    getFilters() {
        if (localStorage.getItem('local_filters_' + this.currentUser) && localStorage.getItem('local_filters_' + this.currentUser) != '') {
            const filterData = JSON.parse(localStorage.getItem('local_filters_' + this.currentUser));
            const key = this.showFiltersObj.key;
            if (!_.isEmpty(filterData) && filterData[key]) {
                if ('status' in this.showFiltersObj) {
                    this.showFiltersObj.status = filterData[key].status;
                }
                if ('call_tracking_status' in this.showFiltersObj) {
                    this.showFiltersObj.call_tracking_status = filterData[key].call_tracking_status;
                }

                if ('call_tracking_fallback' in this.showFiltersObj) {
                    this.showFiltersObj.call_tracking_fallback = filterData[key].call_tracking_fallback;
                }

                if ('call_tracking_schedule' in this.showFiltersObj) {
                    this.showFiltersObj.call_tracking_schedule.id = filterData[key].call_tracking_schedule.id;
                    this.showFiltersObj.call_tracking_schedule.name = filterData[key].call_tracking_schedule.name;
                }

                if ('search' in this.showFiltersObj) {
                    this.showFiltersObj.search = filterData[key].search;
                }
                if ('is_archived' in this.showFiltersObj) {
                    this.showFiltersObj.is_archived = filterData[key].is_archived;
                }
                if ('lead_type' in this.showFiltersObj) {
                    this.showFiltersObj.lead_type = filterData[key].lead_type;
                }
                if ('role' in this.showFiltersObj) {
                    this.showFiltersObj.role.id = filterData[key].role.id;
                    this.showFiltersObj.role.name = filterData[key].role.name;
                }

                if ('plan' in this.showFiltersObj) {
                    this.showFiltersObj.plan.id = filterData[key].plan.id;
                    this.showFiltersObj.plan.name = filterData[key].plan.name;
                }
                if ('group' in this.showFiltersObj) {
                    this.showFiltersObj.group.id = filterData[key].group.id;
                    this.showFiltersObj.group.name = filterData[key].group.name;
                }

                if ('channel' in this.showFiltersObj) {
                    this.showFiltersObj.channel.id = filterData[key].channel.id;
                    this.showFiltersObj.channel.name = filterData[key].channel.name;
                }
            }
        }
    }

    /** delete applied filters */
    deleteAppliedFilters(id: string, confirm_msg: string) {
        let filterData: any;
        filterData = JSON.parse(localStorage.getItem('local_filters_' + this.currentUser));
        
        delete filterData[this.showFiltersObj.key];
        localStorage.setItem('local_filters_' + this.currentUser, JSON.stringify(filterData));
        this.afterClearFilters.emit(); // Emit event after clear filters//
    }
}
