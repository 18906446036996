import { Component, OnDestroy, OnInit } from '@angular/core';
import { environment } from '@env/environment';
import { confirmationAlertSwalSettings } from '@service/app.config';
import { Logger } from '@service/global.funcs';
import { ToasterLibraryService } from '@service/index';
import * as _ from 'lodash';
import { TimerObservable } from 'rxjs/observable/TimerObservable';
import Swal from 'sweetalert2';
import { LatestVersionService } from './lastest-version.service';
import { VersionModel } from './version-model';

@Component({
  selector: 'app-latest-version',
  templateUrl: './latest-version.component.html',
  styleUrls: []
})
export class LatestVersionComponent implements OnInit, OnDestroy {
  private versionData: VersionModel;
  private alive: boolean;
  private interval: number;
  private display: boolean;
  constructor(
    private latestVersionService: LatestVersionService,
  ) {
    this.display = false;
    this.alive = true;
    this.interval = 600000;
  }

  ngOnInit() {
    TimerObservable.create(0, this.interval)
      .takeWhile(() => (environment.production) && this.alive)
      .subscribe(() => {
        this.latestVersionService.get()
          .subscribe((data) => {
            this.versionData = data;
            const oldVersionData = localStorage.getItem('version');
            if (oldVersionData && !_.isEqual(this.versionData, JSON.parse(oldVersionData))) {
              if (!this.display) {
                  this.display = true;
                  let swal_opts = Object.assign({},confirmationAlertSwalSettings);
                  swal_opts.confirmButtonText = "Reload";
                  swal_opts.cancelButtonText = "Remind me later";
                  swal_opts.title = "<span class='confirm-alert-title'>New Version Available</span>";
                  swal_opts['html'] = `<span class='confirm-alert-body'>A new version of this application is available. Would you like to load it?</span>`;
                  Swal.fire(swal_opts).then((result) => {
                  if (result.isConfirmed) {
                    localStorage.setItem('version', JSON.stringify(this.versionData));
                    window.location.reload();
                  } else if (result.dismiss === Swal.DismissReason.cancel) {
                    this.display = false;
                    this.interval = 360000;
                  }
                });
              }
            } else if (!oldVersionData) {
              localStorage.setItem('version', JSON.stringify(this.versionData));
            } else if (oldVersionData && _.isEqual(this.versionData, JSON.parse(oldVersionData))) {
              Logger().info(oldVersionData, this.versionData);
              Logger().info('you already on latest version.');
            }
          });
      });
  }

  ngOnDestroy() {
    this.alive = false;
  }
}
