import { Component, Input, OnChanges, OnInit, SimpleChange } from '@angular/core';
import { ContactReportService, ToasterLibraryService, AppConfig } from '@service/index';
import { NgxPermissionsService } from 'ngx-permissions';

@Component({
  selector: 'app-contact-unsold-count-widget',
  templateUrl: './contact-unsold-count-widget.component.html',
  styleUrls: ['./contact-unsold-count-widget.component.scss']
})

export class ContactUnsoldCountWidgetComponent implements OnInit, OnChanges {
  @Input() relativeDate: any = '';
  @Input() criteriaArray: any;
  @Input() campaignData: any;
  @Input() formData: any;
  @Input() priceData: any;
  public isCountLoaded = false;
  public contactsSoldCount: any = 0;
  @Input() widgetTitle = 'Unsold Leads';
  public appContactSoldOptions = AppConfig.contactSoldOptions;
  constructor(
    private contactReportService: ContactReportService,
    private toasterLibrary: ToasterLibraryService,
    private permissionService: NgxPermissionsService
  ) { }
  ngOnInit() {
    if (this.permissionService.hasPermission('contacts:can_get_contacts_reports_sold_times')) {
      this.criteriaArray = JSON.parse(localStorage.getItem('leadsCriteria'));
      // console.log(this.criteriaArray);
      this.requestContactsUnsoldCount();
    }
  }

  ngOnChanges(changes: { [key: string]: SimpleChange }) {
    if (this.permissionService.hasPermission('contacts:can_get_contacts_reports_sold_times')) {
      if (
        (changes['relativeDate'] && !changes['relativeDate'].isFirstChange()) ||
        (changes['campaignData'] && !changes['campaignData'].isFirstChange()) ||
        (changes['criteriaArray'] && !changes['criteriaArray'].isFirstChange()) ||
        (changes['priceData'] && !changes['priceData'].isFirstChange()) ||
        (changes['formData'] && !changes['formData'].isFirstChange())
      ) {
        this.requestContactsUnsoldCount();
      }
    }
  }

  requestContactsUnsoldCount() {
    const criteriaArray: any = this.getCommonCriteria() || [];
    criteriaArray.push({
      id: '',
      is_required: true,
      criterion: 'soldOption',
      value: {
        sold_option: this.appContactSoldOptions.unsold
      }
    });

    this.isCountLoaded = false;
    this.contactReportService.getContactsCount(criteriaArray, 'ContactUnsoldCountWidgetComponent')
      .subscribe((res: any) => {
        this.contactsSoldCount = Number(res.data.count);
        this.isCountLoaded = true;
      });
  }

  private getCommonCriteria() {
    const criteriaArray: any = [
      {
        is_required: true,
        criterion: 'dateRange',
        value: {
          relative_date: this.relativeDate
        }
      }
    ];

    if (this.campaignData && this.campaignData != "") {
      criteriaArray.push({
        'id': '',
        'value': { c_id: this.campaignData },
        'criterion': 'campaign',
        'is_required': true
      });
    }

    if (this.formData && this.formData != "") {
      criteriaArray.push({
        value: [this.formData],
        criterion: 'form',
        is_required: true,
        id: ''
      });
    }

    if (this.priceData && this.priceData != "") {
      criteriaArray.push({
        value: { rprice_id: this.priceData },
        criterion: 'leadPricing',
        is_required: true,
        id: 0
      });
    }
    return criteriaArray;
  }

}
