export const globalValues = {
  id: localStorage.getItem('id_token'),
  logo: 'assets/img/mo-logo.jpg',
  small_logo: 'assets/img/mo_small_logo.png',
  insurance_field_type: '3001',
  referred_to_field_type: '3002',
  pie_chart_slices: 7,
  pie_chart_label_length: 35,
  chart_colors: [ //#e88b1b
    '#e88b1c',
    '#435363',
    '#50bdd6',
    '#ecca2a',
    '#263746',
    '#2f80e7',
    '#fcb116',
    '#dde6e9',
    '#49e8cd',
    '#1fd5ff',
    '#50d6cf',
    '#1fafff'
  ]
};

export const pageTitles = {
  dashboard: 'Dashboard',
  leads: 'Leads',
  calls: 'Calls',
  tasks: 'tasks',
  clients: 'Clients',
  email: 'Inbox',
  automations: 'Automations',
  email_templates: 'Email Templates',
  general_settings: 'General Settings',
  fields: 'Fields',
  forms: 'Forms',
  lead_prices: 'Lead Prices',
  call_tracking: 'Call Tracking',
  webhooks: 'Webhooks',
  private_labels: 'Private Labels',
  domains: 'Domains',
  campaigns: 'Campaigns',
  refund_reasons: 'Refund Reasons',
  manage_schedules: 'Manage Schedules',
  manage_call_schedules: 'Manage Call Schedules',
  manage_automation_schedules: 'Manage Automation Schedules',
  tracking_numbers: 'Manage Tracking Numbers',
  my_account: 'My Account',
  manage_user: 'Manage Users',
  credit_card: 'Credit Card Settings',
  billing_history: 'Billing History',
  my_profile: 'My Profile',
  my_email_settings: 'My Email Settings',
  lead_buying: 'Lead Buying Settings',
  web_form_settings: 'Web Form Settings',
  call_buying_settings: 'Call Buying Settings',
  manage_user_groups: 'Manage User Groups',
  manage_plans: 'Manage Plans',
  add_tracking_number: 'Add Tracking Number',
  export_leads: 'Export Leads',
  import_leads: 'Import Leads',
  statuses: 'Statuses',
  events: 'Events',
  sms_templates: 'SMS Templates',
  post_inbound: 'Inbound HTTP POST instructions',
  tests: 'A/B and Multivariate Experiments'
};

export const colors = [
  ['Black', 'Black'],
  ['darkblue', 'Dark Blue'],
  ['Blue', 'Blue'],
  ['Red', 'Red'],
  ['darkred', 'Dark Red'],
  ['Green', 'Green'],
  ['darkgreen', 'Dark Green'],
  ['Brown', 'Brown'],
  ['Purple', 'Purple'],
  ['Orange', 'Orange'],
  ['darkorange', 'Dark Orange'],
  ['Yellow', 'Yellow']
];

export const channelsArray = [
  ['BUSDEV', 'Business Development'],
  ['DIRECT', 'Direct Traffic'],
  ['DISPLAY', 'Display'],
  ['EMAIL', 'Email'],
  ['EVENTS', 'Events'],
  ['MAIL', 'Direct Mail'],
  ['ORGANIC', 'Organic Search'],
  ['PAIDSEARCH', 'Paid Search'],
  ['PRINT', 'Print Advertising'],
  ['PR', 'Public Relations'],
  ['RADIO', 'Radio'],
  ['REFERRAL', 'Referral Traffic'],
  ['SOCIAL', 'Social'],
  ['TELEPHONE', 'Telemarketing'],
  ['TELEVISION', 'Television'],
  ['WORD', 'Word-of-Mouth'],
  ['DIGITALOTHER', 'Other Digital'],
  ['OTHER', 'Everything Else']
];
export const themesArray = [
  ['', '2021 Theme'],
  // ['B', 'Direct Traffic'],
  // ['C', 'Display'],
  // ['D', 'Email'],
  // ['E', 'Events'],
  // ['F', 'Direct Mail'],
  // ['G', 'Organic Search'],
  // ['H', 'Paid Search'],
  ['ACTIVE', 'Active'],
  ['COBRA', 'Cobra'],
  ['GROWTH', 'Growth']
];
