import { Component, Input, OnChanges, OnInit, SimpleChange } from '@angular/core';
import { Privilege } from '@decorator/index';
import { ContactReportService, ToasterLibraryService } from '@service/index';
import { NgxPermissionsService } from 'ngx-permissions';

@Component({
  selector: 'app-new-leads-widget',
  templateUrl: './new-leads-widget.component.html',
  styleUrls: ['./new-leads-widget.component.scss']
})

// @Privilege({
//   privilege: 'contacts:can_get_contacts_reports_count'
// })
export class NewLeadsWidgetComponent implements OnInit, OnChanges {
  @Input() relativeDate: any;
  @Input() campaignData: any;
  public isCountLoaded = false;
  public newLeadsCount = 0;
  @Input() widgetTitle = 'New Leads';
  constructor(
    private contactReportService: ContactReportService,
    private toasterLibrary: ToasterLibraryService,
    private permissionService: NgxPermissionsService
  ) { }

  ngOnInit() {
    if (this.permissionService.hasPermission('contacts:can_get_contacts_reports_count')) {
      this.getNewLeadsCount();
    }
  }

  ngOnChanges(changes: { [key: string]: SimpleChange }) {
    if (this.permissionService.hasPermission('contacts:can_get_contacts_reports_count')) {
      if (
        (changes['relativeDate'] && !changes['relativeDate'].isFirstChange()) ||
        (changes['campaignData'] && !changes['campaignData'].isFirstChange()) ||
        (changes['criteriaArray'] && !changes['criteriaArray'].isFirstChange())
      ) {
        this.getNewLeadsCount();
      }
    }

  }

  getNewLeadsCount() {
    const criteriaArr: any = [
      {
        is_required: true,
        criterion: 'dateRange',
        value: {
          relative_date: this.relativeDate
        }
      }
    ];

    if (this.campaignData && this.campaignData != "") {
      criteriaArr.push({
        'id': '',
        'value': { c_id: this.campaignData },
        'criterion': 'campaign',
        'is_required': true
      });
    }

    this.isCountLoaded = false;
    this.contactReportService.getContactsCount(criteriaArr, 'NewLeadsWidgetComponent').subscribe((res: any) => {
      this.newLeadsCount = Number(res.data.count);
      this.isCountLoaded = true;
    });
  }
}
