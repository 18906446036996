import { Component, OnInit, NgZone } from '@angular/core';
import { SettingsService } from '../../core/settings/settings.service';
import { VersionModel } from '../../latest-version/version-model';
import * as moment from 'moment';
import {
  AppConfig,
  FieldService,
  AccountService
} from '@service/index';
import { BootController } from '@app/../boot-control';
import { Logger } from '@service/global.funcs';
import { AuthLoginService } from '@service/auth/auth-login.service';
import { AddictionTreatmentSuiteService } from '@service/index';
import * as _ from 'lodash';
import { globalValues, pageTitles } from '@app/globals';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  public version: VersionModel;
  public buildDate: any;
  public currentAppVersion = 'default';
  public appVersions = AppConfig.availableAppVersions;
  public account_name = localStorage.getItem('account_name');
  constructor(
    private authLoginService: AuthLoginService,
    private addictionSuite: AddictionTreatmentSuiteService,
    public settings: SettingsService,
    private accountService: AccountService,
    private fieldService: FieldService,
    private ngZone: NgZone) { }

  ngOnInit() {
    if (localStorage.getItem('version') !== null) {
      const oldVersionData = localStorage.getItem('version');
      this.version = JSON.parse(oldVersionData);
      this.version.buildDate = moment(this.version.buildDate).format('YYYYMMDDHHmmss');
    }
    if (localStorage.getItem('appVersion') !== null) {
      const availableAppVersions = {
        'default': 'Default',
        'aggregation': 'Lead Aggregation',
        'automation': 'Marketing Automation',
        'addiction': 'Addiction Treatment',
        'portal': 'Client Portal',
      };
      const appVersion = localStorage.getItem('appVersion');
      if (appVersion != 'null') {
        this.currentAppVersion = availableAppVersions[appVersion];
      } else {
        this.currentAppVersion = availableAppVersions.automation;
      }
    }
  }

  setAppVersion(version = 'default') {
    localStorage.setItem('appVersion', version);

    if (version == 'addiction') {
      this.handleAddiction()
        .then(() => {
          this.restartApp();
        });
    } else {
      this.restartApp();
    }
    // location.reload();
  }

  isAppVersion(version = 'default') {
    return localStorage.getItem('appVersion') !== null && localStorage.getItem('appVersion') === version;
  }

  isAllowVersion(version) {
    if (localStorage.getItem('appAvailablesVersions') !== null) {
      const versionsData = localStorage.getItem('appAvailablesVersions');

      const availableVersions = JSON.parse(versionsData);
      if (availableVersions && Array.isArray(availableVersions)) {
        //Logger().info('availableVersions', availableVersions, 'versionData', versionsData, 'availableVersions.includes(version);', availableVersions.includes(version));
        return availableVersions.includes(version) || localStorage.getItem('account_id') === '1';
      } else {
        return false;
      }
    }
    else {
      return false;
    }
  }

  isNotEmptyVersion() {
    const versionsData = localStorage.getItem('appAvailablesVersions');
    const availableVersions = JSON.parse(versionsData);
    //Logger().info('Array.isArray(versionsData) ',versionsData, typeof versionsData);
    if (availableVersions && Array.isArray(availableVersions)) {
      return true;
    } else {
      return false;
    }
  }
  restartApp() {
    this.ngZone.runOutsideAngular(() => BootController.getbootControl().restart());
    // this.ngZone.run(() => BootController.getbootControl().restart());
  }

  private handleAddiction(): Promise<{} | string> {
    const appVersion = localStorage.getItem('appVersion');
    Logger().info("in:" + appVersion);
    if (appVersion == AppConfig.availableAppVersions.ADDICTION) {
      return this
        .getMyAccountAddiction()
        .then(this.getInsuranceFieldAddiction.bind(this));

    } else {
      return Promise.resolve(true);
    }
  }

  private getMyAccountAddiction() {
    return new Promise((resolve, reject) => {
      this.accountService
        .getMyAccountSettings('addictiontreatment')
        .subscribe((res: any) => {
          if (res.success === 'true') {
            localStorage.setItem('addictiontreatment', JSON.stringify(res.data));
            return resolve(true);
          }
          else {
            return reject('Something went wrong getting your account settings!');
          }
        });
    });
  }

  private getInsuranceFieldAddiction() {
    return new Promise((resolve, reject) => {
      this.fieldService.getAllFields().subscribe((res: any) => {
        if (res.success === 'true') {
          const fieldsArray = res.data;
          const insuranceFieldDetail = _.find(fieldsArray, { type: globalValues.insurance_field_type });
          const referredToFieldDetail = _.find(fieldsArray, { type: globalValues.referred_to_field_type });
          if (insuranceFieldDetail) {
            localStorage.setItem('addictiontreatment_insurance_field', JSON.stringify(insuranceFieldDetail));
          }
          if (referredToFieldDetail) {
            localStorage.setItem('addictiontreatment_referred_to_field', JSON.stringify(referredToFieldDetail));
          }
          return resolve(true);
        } else {
          return reject('Something went wrong getting your account settings!');
        }
      });
    });
  }

  public _getAvailableVersionsCount() {
    const versionsData = localStorage.getItem('appAvailablesVersions');
    const availableVersions = JSON.parse(versionsData);
    if(availableVersions){
      return availableVersions.length;
    } else {
      return 0;
    }
    
  }
}
