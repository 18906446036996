<ng-template [ngxPermissionsOnly]="['tests:can_get_tests_reports_visits']">
  <div class="box-row panel panel-default">
    <div class="panel-body text-center">
      <div *ngIf="!isTestVisitsChartLoaded">
        <div class="whirl standard spinnermargin"></div>
      </div>
      <ng-template [ngIf]="isTestVisitsChartLoaded">
        <stacked-bar-chart [stackedBarData]="testVisitsChartData" [stackedBarHeight]="220"></stacked-bar-chart>
      </ng-template>
    </div>
  </div>
</ng-template>