<!--<div class="wrapper">-->
<div class="abs-center">
  <div class="text-center mb-xl">
    <div class="mb-lg">
      <em class="fa fa-frown-o fa-5x text-muted"></em>
    </div>
    <div class="text-lg mb-lg">Oops!</div>
    <p class="lead m0">Something went wrong :(</p>
    <p>Try <a (click)="navigate('')">Dashboard</a> OR <a (click)="navigate('login')">Login</a></p>
  </div>
  <div class="p-lg text-center">
    <span>&copy;</span>
    <span>{{current_year}}</span>
    <span></span>
    <span></span>
  </div>
</div>
<!--</div>-->
