import { ReflectiveInjector } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { ConsoleLoggerService } from './core/logger/console-logger.service';
import { LoggerService } from './core/logger/logger.service';
export function redirectToPrivilegeRouteCallback(
    rejectedPermissionName: string,
    activateRouteSnapshot: ActivatedRouteSnapshot,
    routerStateSnapshot: RouterStateSnapshot
) {
    Logger().info(
        'Unauthorize Route Privilege Access:',
        rejectedPermissionName,
        activateRouteSnapshot.url,
        routerStateSnapshot.url
    );
    // return `/leads?from=users`;
    alert(`Unauthorized Access: You do not have the required privileges to access '${routerStateSnapshot.url}'.`);
    return `/leads`;
}

export function Logger() {
    const loggerFactory = ReflectiveInjector.resolveAndCreate([
        { provide: LoggerService, useClass: ConsoleLoggerService }
    ]);
    return loggerFactory.get(LoggerService);
}
