import { OnInit, Component } from '@angular/core';
import { Privilege } from '@decorator/index';
import { Logger } from '@service/global.funcs';
import { AbTestReportService, LoggerService, ToasterLibraryService } from '@service/index';
import { NgxPermissionsService } from 'ngx-permissions';
@Component({
  selector: 'app-tests-visits-widget',
  templateUrl: './tests-visits-widget.component.html',
  styleUrls: ['./tests-visits-widget.component.scss']
})

// @Privilege({
//   privilege: 'tests:can_get_tests_reports_visits'
// })
export class TestsVisitsWidgetComponent implements OnInit {
  public testVisitsChartData = [
    {
      data: [],
      label: 'Visits',
      color: '#e88b1b'
    }
  ];
  public isTestVisitsChartLoaded: boolean = false;
  constructor(
    private toasterLibrary: ToasterLibraryService,
    private testReportService: AbTestReportService,
    private permissionService: NgxPermissionsService
  ) {}

  ngOnInit() {
    if (this.permissionService.hasPermission('tests:can_get_tests_reports_visits')) {
      this.getTestStatVisit();
    }
  }
  getTestStatVisit() {
    this.isTestVisitsChartLoaded = false;
    this.testReportService.getStatVisit('all').subscribe(
      (res: any) => {
        Logger().info('getTestStatVisit', 'getStatVisit', res);
        if (res.success == 'true') {
          const byTotalVisists = res.data.slice(0);
          byTotalVisists.sort(function(a, b) {
            return b.total_visits - a.total_visits;
          });
          
        Logger().info('getTestStatVisit', 'byTotalVisists', byTotalVisists);
          byTotalVisists.forEach(record => {
            let cat = record.category;
            // if (typeof cat === 'string' && cat.length >= 10) {
            //   cat = cat.substr(0, 10) + '...';
            // }
            this.testVisitsChartData[0].data.push([cat, parseInt(record.total_visits, 10)]);
          });
        }
        this.isTestVisitsChartLoaded = true;
        Logger().info(this.testVisitsChartData);
      },
      err => {
        this.toasterLibrary.error(null, err.error.message);
      }
    );
  }
}
