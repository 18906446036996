import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';
import { Router } from '@angular/router';
import 'rxjs/Rx';
import { globalValues } from '@app/globals';
import { environment } from '@env/environment';
import { HttpClient, HttpResponse } from '@angular/common/http';

@Injectable()
export class CustomHttp {
    private baseUrl: string = '';
    private headers;
    constructor(private http: HttpClient, private router: Router) {
        this.baseUrl = environment.api_base_url;
        this.headers = new Headers();
        // this.headers.append('Content-Type', 'application/json');
        this.headers.append('Access-Control-Allow-Headers', 'Content-Type');
        this.headers.append('Access-Control-Allow-Methods', '*');
        this.headers.append('Access-Control-Allow-Headers', 'Origin, Content-Type, X-Auth-Token');
    }

    getAll(url, body: any) {
        if (!this.headers.get('User')) {
            this.headers.append('User', localStorage.getItem('User'));
            if (!this.headers.get('Authorization')) {
                this.headers.append('Authorization', 'Bearer ' + localStorage.getItem('id_token'));
                return this.http.get(this.baseUrl + url + this.jsonToURI(body), { headers: this.headers,observe:'response' })
                    .map(this.extractData);
            }

            else {
                return this.http.get(this.baseUrl + url + this.jsonToURI(body), { headers: this.headers,observe:'response' })
                    .map(this.extractData);
            }
        } else {
            if (!this.headers.get('Authorization')) {
                this.headers.append('Authorization', 'Bearer ' + localStorage.getItem('id_token'));
                return this.http.get(this.baseUrl + url + this.jsonToURI(body), { headers: this.headers,observe:'response' })
                    .map(this.extractData);
            }

            else {
                return this.http.get(this.baseUrl + url + this.jsonToURI(body), { headers: this.headers,observe:'response' })
                    .map(this.extractData);
            }
        }
    }

    getDownloadableCsv(url, body: any) {
        if (!this.headers.get('User')) {
            this.headers.append('User', localStorage.getItem('User'));
            if (!this.headers.get('Authorization')) {
                this.headers.append('Authorization', 'Bearer ' + localStorage.getItem('id_token'));
                return this.http.get(this.baseUrl + url + this.jsonToURI(body), { headers: this.headers,observe:'response',responseType:'text' })
                    .map(this.extractCsvData);
            }

            else {
                return this.http.get(this.baseUrl + url + this.jsonToURI(body), { headers: this.headers,observe:'response',responseType:'text' })
                    .map(this.extractCsvData);
            }
        } else {
            if (!this.headers.get('Authorization')) {
                this.headers.append('Authorization', 'Bearer ' + localStorage.getItem('id_token'));
                return this.http.get(this.baseUrl + url + this.jsonToURI(body), { headers: this.headers,observe:'response',responseType:'text'})
                    .map(this.extractCsvData);
            }

            else {
                return this.http.get(this.baseUrl + url + this.jsonToURI(body), { headers: this.headers,observe:'response',responseType:'text' })
                .map(this.extractCsvData);
            }
        }
    }

    get(url) {
        if (!this.headers.get('User')) {
            this.headers.append('User', localStorage.getItem('User'));

            if (!this.headers.get('Authorization')) {
                //  this.headers.append('Content-Type', 'application/x-www-form-urlencoded');
                this.headers.append('Authorization', 'Bearer ' + localStorage.getItem('id_token'));
                return this.http.get(this.baseUrl + url, { headers: this.headers,observe:'response' })
                    .map(this.extractData);
            }

            else {
                return this.http.get(this.baseUrl + url, { headers: this.headers,observe:'response' })
                    .map(this.extractData);
            }
        } else {
            if (!this.headers.get('Authorization')) {
                //  this.headers.append('Content-Type', 'application/x-www-form-urlencoded');
                this.headers.append('Authorization', 'Bearer ' + localStorage.getItem('id_token'));
                return this.http.get(this.baseUrl + url, { headers: this.headers,observe:'response' })
                    .map(this.extractData);
            }

            else {
                return this.http.get(this.baseUrl + url, { headers: this.headers,observe:'response' })
                    .map(this.extractData);
            }
        }
    }

    post(url: string, body: string) {
        if (!this.headers.get('User')) {
            this.headers.append('User', localStorage.getItem('User'));
            if (!this.headers.get('Authorization')) {
                //    this.headers.append('enctype', 'multipart/form-data');
                this.headers.append('Authorization', 'Bearer ' + globalValues.id);
                return this.http.post(this.baseUrl + url, body, { headers: this.headers,observe:'response' })
                    .map(this.extractData);
            }
            else {
                return this.http.post(this.baseUrl + url, body, { headers: this.headers,observe:'response' })
                    .map(this.extractData);
            }
        } else {
            if (!this.headers.get('Authorization')) {
                //   this.headers.append('enctype', 'multipart/form-data');
                this.headers.append('Authorization', 'Bearer ' + globalValues.id);
                return this.http.post(this.baseUrl + url, body, { headers: this.headers,observe:'response' })
                    .map(this.extractData);
            }
            else {
                return this.http.post(this.baseUrl + url, body, { headers: this.headers ,observe:'response'})
                    .map(this.extractData);
            }
        }
    }

    loginClient(url: string, body: string) {
        if (!this.headers.get('Authorization')) {
            //   this.headers.append('enctype', 'multipart/form-data');
            this.headers.append('Authorization', 'Bearer ' + globalValues.id);
            return this.http.post(this.baseUrl + url, body, { headers: this.headers,observe:'response' })
                .map(this.extractData);
        }
        else {
            return this.http.post(this.baseUrl + url, body, { headers: this.headers,observe:'response' })
                .map(this.extractData);
        }
    }

    appendUserId(id, account_id, account_name) {
        this.headers.delete('User');
        this.headers.append('User', id);
        localStorage.setItem('User', id);
        localStorage.setItem('account_id', account_id);
        localStorage.setItem('account_name', account_name);
        return true;
    }

    put(url: string, body: string) {
        if (!this.headers.get('User')) {
            this.headers.append('User', localStorage.getItem('User'));
            if (!this.headers.get('Authorization')) {
                this.headers.append('Authorization', 'Bearer ' + globalValues.id);
                return this.http.put(this.baseUrl + url, body, { headers: this.headers,observe:'response' })
                    .map(this.extractData);
            }
            else {
                return this.http.put(this.baseUrl + url, body, { headers: this.headers,observe:'response' })
                    .map(this.extractData);

            }
        } else {
            if (!this.headers.get('Authorization')) {
                this.headers.append('Authorization', 'Bearer ' + globalValues.id);
                return this.http.put(this.baseUrl + url, body, { headers: this.headers,observe:'response' })
                    .map(this.extractData);
            }
            else {
                return this.http.put(this.baseUrl + url, body, { headers: this.headers,observe:'response' })
                    .map(this.extractData);

            }
        }
    }

    delete(url: string) {
        if (!this.headers.get('User')) {
            this.headers.append('User', localStorage.getItem('User'));
            const formData = new FormData();
            formData.append('_METHOD', 'delete');
            if (!this.headers.get('Authorization')) {
                this.headers.append('Authorization', 'Bearer ' + globalValues.id);
                return this.http.post<any>(this.baseUrl + url, formData, { headers: this.headers })
                    .map(this.extractDataforDelete);
            }
            else {
                return this.http.post<any>(this.baseUrl + url, formData, { headers: this.headers })
                    .map(this.extractDataforDelete);
            }
        } else {
            const formData = new FormData();
            formData.append('_METHOD', 'delete');
            if (!this.headers.get('Authorization')) {
                this.headers.append('Authorization', 'Bearer ' + globalValues.id);
                return this.http.post<any>(this.baseUrl + url, formData, { headers: this.headers })
                    .map(this.extractDataforDelete);
            }
            else {
                return this.http.post<any>(this.baseUrl + url, formData, { headers: this.headers })
                    .map(this.extractDataforDelete);
            }
        }
    }

    private extractData(res : HttpResponse<any>) {
        if (res.status == 200 || res.status == 201 || res.status == 202) {
            let body = res.body;
            return body;
        } else {
            return [];
        }
    }

    private extractCsvData(res) {
        let fileName = '';
        var headers = res.headers;
        var contentDisposition = headers.get('content-disposition');
        if (contentDisposition) {
            var result = contentDisposition.split(';')[1].trim().split('=')[1];
            fileName = result.replace(/"/g, '')
        }


        if (res.status == 200 || res.status == 201 || res.status == 202) {
            let body = res.body;
            return { 'data': body, 'filename': fileName };
        } else {
            return [];
        }
    }

    jsonToURI(json) { return encodeURIComponent(JSON.stringify(json)); }

    private extractDataforDelete(res: Response) {
        if (res.status == 204) {
            return true;
        } else {
            return false;
        }
    }
}
