import { Component } from '@angular/core';
import { AuthService } from '@service/auth/auth.service';
import { ToasterConfig, ToasterService } from 'angular2-toaster';

@Component({
    selector: 'app-reset-password',
    templateUrl: './reset-password.component.html',
    styleUrls: ['./reset-password.component.scss'],
    providers: [AuthService]
})
export class ResetPasswordComponent {

    public model = {
        username: ''
    };
    public isSaving: boolean = false;

    // TOASTER
    toasterConfig: any;
    toasterconfig: ToasterConfig = new ToasterConfig({
        positionClass: 'toast-bottom-right',
        showCloseButton: true
    });

    constructor(
        private auth: AuthService,
        private toasterService: ToasterService
    ) {

    }

    submitForm() {
        this.isSaving = true;
        this.auth.resetPassword(this.model).subscribe((res: any) => {
            if (res.success == 'true') {
                this.pop('success', '', res.message);
            } else {
                this.pop('error', '', res.message);
            }
        });
        this.isSaving = false;
    }

    pop(type, title, text) {
        this.toasterService.pop(type, title, text);
    };
}
