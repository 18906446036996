import { Component, Input, OnChanges, OnInit, SimpleChange } from '@angular/core';
import { Privilege } from '@decorator/index';
import { globalValues } from '@app/globals';
import { CampaignReportService, ToasterLibraryService } from '@service/index';
import { NgxPermissionsService } from 'ngx-permissions';
import { Logger } from '@service/global.funcs';

@Component({
  selector: 'app-cost-per-contact-by-campaign-widget',
  templateUrl: './cost-per-contact-by-campaign-widget.component.html',
  styleUrls: ['./cost-per-contact-by-campaign-widget.component.scss'],
})


export class CostPerContactByCampaignWidgetComponent implements OnInit, OnChanges {
  @Input() relativeDate: any;
  @Input() campaignData: any;
  @Input() widgetTitle = 'Cost Per Lead By Campaign';
  public costByCampaignData = {
    labels: [],
    datasets: [{
      data: []
    }],
    totalCount: 0,
  };
  public salesByCampaignLoaded = false;

  constructor(
    private campaignReportService: CampaignReportService,
    private toasterLibrary: ToasterLibraryService,
    private permissionService: NgxPermissionsService

  ) { }

  ngOnInit() {
    if (this.permissionService.hasPermission('visitors:can_get_visitors_reports_count')) {
      this.getSalesByCampaign();
    }

  }

  ngOnChanges(changes: { [key: string]: SimpleChange }) {

    if (this.permissionService.hasPermission('visitors:can_get_visitors_reports_count')) {
      if (
        (changes['relativeDate'] && !changes['relativeDate'].isFirstChange()) ||
        (changes['campaignData'] && !changes['campaignData'].isFirstChange()) ||
        (changes['criteriaArray'] && !changes['criteriaArray'].isFirstChange())
      ) {
        this.getSalesByCampaign();
      }
    }
  }

  getSalesByCampaign() {
    const criteriaArray: any = [
      // {
      //   id: '',
      //   is_required: true,
      //   criterion: 'dateRange',
      //   value: {
      //     start_date: '',
      //     end_date: '',
      //     relative_date: this.relativeDate
      //   }
      // },
      {
        id: '',
        is_required: true,
        criterion: 'statusSubstatus',
        value: {
          ostat_id: 2,
          ss_id: 0
        }
      }
    ];

    if (this.campaignData && this.campaignData != "") {
      criteriaArray.push({
        'id': '',
        'value': { c_id: this.campaignData },
        'criterion': 'campaign',
        'is_required': true
      });
    }

    this.salesByCampaignLoaded = false;
    let aggregateIndex = 0;
    this.reInit();
    this.campaignReportService.getCostPerContactByCampaign(this.relativeDate, criteriaArray).subscribe(
      (res: any) => {
        if (res.success === 'true') {
          let fifthOptionCount = 0;
          res.data.forEach((campaign, index) => {
            let cat = campaign.campaign;

            if (index < globalValues.pie_chart_slices) {
              if (!this.costByCampaignData.labels.includes(cat)) {
                this.costByCampaignData.labels.push(cat);
                this.costByCampaignData.datasets[0].data.push(campaign.total_cost);
              } else {
                aggregateIndex = this.costByCampaignData.labels.indexOf(cat);
                Logger().info('getSalesByCampaign', 'pie chart data', 'aggregate index', aggregateIndex);
                Logger().info('getSalesByCampaign', 'pie chart data', 'channel', campaign);

                this.costByCampaignData.datasets[0].data[aggregateIndex] =
                  parseInt(this.costByCampaignData.datasets[0].data[aggregateIndex], 10) +
                  parseInt(campaign.total_cost, 10);

                Logger().info(
                  'getSalesByCampaign',
                  'pie chart data',
                  'new channel',
                  this.costByCampaignData.datasets[0].data[aggregateIndex]
                );
              }
            } else {
              fifthOptionCount = fifthOptionCount + parseInt(campaign.total_cost, 10);
            }
            this.costByCampaignData.totalCount =
              this.costByCampaignData.totalCount + parseInt(campaign.total_cost, 10);
          });
          if (fifthOptionCount > 0) {
            this.costByCampaignData.labels.push('All Other');
            this.costByCampaignData.datasets[0].data.push(fifthOptionCount);
          }
        }
        this.salesByCampaignLoaded = true;
      },
      err => {
        this.toasterLibrary.error(null, err.error.message);
      }
    );
  }
  private reInit() {
    this.costByCampaignData = {
      labels: [],
      datasets: [{
        data: []
      }],
      totalCount: 0,
    };
  }
}
