import { Component, Input, OnChanges, OnInit, SimpleChange } from '@angular/core';
import { Privilege } from '@decorator/index';
import { AccountReportService, ToasterLibraryService } from '@service/index';
import { NgxPermissionsService } from 'ngx-permissions';

@Component({
  selector: 'app-account-registered-count-widget',
  templateUrl: './account-registered-count-widget.component.html',
  styleUrls: ['./account-registered-count-widget.component.scss']
})

// @Privilege({
//   privilege: 'emailtemplates:can_get_emailtemplates_reports_sent'
// })
export class AccountRegisteredCountWidgetComponent implements OnInit, OnChanges {
  @Input('relativeDate') relativeDate: any;
  @Input() campaignData: any;
  public isCountLoaded = false;
  public accountsCount = 0;

  constructor(
    private accountReportService: AccountReportService,
    private toasterLibrary: ToasterLibraryService,
    private permissionService: NgxPermissionsService
  ) { }
  ngOnInit() {
    if (this.permissionService.hasPermission('accounts:can_get_accounts_reports_new')) {
      this.requestAccountsRegisteredCount();
    }
  }

  ngOnChanges(changes: { [key: string]: SimpleChange }) {
    if (
      this.permissionService.hasPermission('accounts:can_get_accounts_reports_new') &&
      changes['relativeDate'] &&
      !changes['relativeDate'].isFirstChange()
    ) {
      this.requestAccountsRegisteredCount();
    }
  }

  requestAccountsRegisteredCount() {
    const criteriaArray: any = [
      {
        is_required: true,
        criterion: 'dateRange',
        value: {
          relative_date: this.relativeDate
        }
      }
    ];

    if (this.campaignData && this.campaignData != "") {
      criteriaArray.push({
        'id': '',
        'value': { c_id: this.campaignData },
        'criterion': 'campaign',
        'is_required': true
      });
    }

    this.isCountLoaded = false;
    this.accountReportService.getAccountsNewCount(criteriaArray).subscribe(res => {
      this.accountsCount = res;
      this.isCountLoaded = true;
    });
  }
}
