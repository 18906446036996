import { Injectable } from "@angular/core"
import { HttpClient } from "@angular/common/http"
import { environment } from "@env/environment"
import { JwtHelperService } from "@auth0/angular-jwt"

@Injectable()
export class AuthService {
  constructor(
    private httpClient: HttpClient,
    public jwtHelper: JwtHelperService
  ) {}

  tokenValid(): boolean {
    // Check if current time is past access token's expiration
    return Date.now() < JSON.parse(localStorage.getItem("expires_at"))
  }

  public isAuthenticated(): boolean {
    return !this.jwtHelper.isTokenExpired()
  }

  public resetPassword(model) {
    return this.httpClient.post(
      environment.api_base_url + "/users/forgotpassword",
      model
    )
  }
  public reqPassword(model) {
    return this.httpClient.post(
      environment.api_base_url + "/customer/onboard/setpassword",
      model
    )
  }

  public changePassword(model) {
    return this.httpClient.post(
      environment.api_base_url + "/users/changepassword",
      model
    )
  }
  public setPassword(model) {
    return this.httpClient.post(
      environment.api_base_url + "/users/change_password",
      model
    )
  }

  appendUserId(id, account_id, account_name) {
    localStorage.setItem("User", id)
    localStorage.setItem("account_id", account_id)
    localStorage.setItem("account_name", account_name)
  }
}
