import { Injectable } from '@angular/core';
import { VersionModel } from './version-model';
import { Observable, throwError } from 'rxjs';
// import 'rxjs/Rx';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class LatestVersionService {
    private url = '/version.json';

    constructor(private http: HttpClient) { }

    get(): Observable<any> {
        return this.http
            .get<any>(this.url)
            .map((res: Response) => {
                return res;
            });
    }

}