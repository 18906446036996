import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ChartsModule } from 'ng2-charts';
import { DndModule } from 'ng2-dnd';
import { FileUploadModule } from 'ng2-file-upload';
import { CustomFormsModule } from 'ng2-validation';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { SharedModule } from '../../shared/shared.module';
import {
  AccountChangeStatusComponent,
  AccountLoginComponent,
  AddDaysScheduleComponent,
  AddMultipleFiltersComponent,
  AddNoteModalComponent,
  ContactEmailModalComponent,
  AssignPrivilegeContainerComponent,
  BackButtonComponent,
  BarChartCompactComponent,
  BarChartComponent,
  DonutChartComponent,
  EditorComponent,
  ErrorComponent,
  FiltersComponent,
  LeadDfFieldComponent,
  LeadDynamicFormComponent,
  LineChartComponent,
  MapComponent,
  PieChartComponent,
  ReadMoreComponent,
  SetFiltersModalComponent,
  ShowCampaignListComponent,
  ShowPricingListComponent,
  ShowFiltersComponent,
  ShowPrivilegeListComponent,
  StackedBarChartComponent,
  TemplateLegendModalComponent,
  TimeLineEmailComponent,
  TimeLineNoteComponent
} from './index';

import {
  ActiveLeadsWidgetComponent,
  AutomationsActionRunChartWidgetComponent,
  CloseLeadsWidgetComponent,
  AddictionAdmissionsWidgetComponent,
  ContactCapturedLineChartWidgetComponent,
  EmailOpenedWidgetComponent,
  EmailSentWidgetComponent,
  FormSubmissionWidgetComponent,
  InboundCallWidgetComponent,
  LeadByCampaignWidgetComponent,
  LeadByChannelWidgetComponent,
  NewCustomerMapWidgetComponent,
  NewLeadsWidgetComponent,
  NewSalesByRepWidgetComponent,
  ChannelNewContactsBarChartWidgetComponent,
  ChannelSalesBarChartWidgetComponent,
  SalesByCampaignWidgetComponent,
  SalesByChannelWidgetComponent,
  StatusSalesPipelineWidgetComponent,
  TestsConversionRateWidgetComponent,
  TestsVisitsWidgetComponent,
  WebsiteConversionRateWidgetComponent,
  WebsiteVisitorWidgetComponent,
  AccountRegisteredCountWidgetComponent,
  ContactSoldCountWidgetComponent,
  ContactAverageIncomeWidgetComponent,
  AccountReceivablesWidgetComponent,
  ContactLatestWidgetComponent,
  CallRevenueWidgetComponent,
  AutomationsActionRunCountWidgetComponent,
  ContactSoldCapturedLineChartWidgetComponent,
  LeadToEventRateWidgetComponent,
  VobRateWidgetComponent,
  VobCountWidgetComponent,
  AdmissionsWidgetComponent,
  CpaWidgetComponent,
  AddictionMostReferredToChartWidgetComponent,
  AddictionTopAdmissionsByPayorsChartWidgetComponent,
  AddictionTopLeadsByPayorsChartWidgetComponent,
  AccountsNewBarChartWidgetComponent,
  ContactByCampaignLineChartWidgetComponent,
  CostPerContactByCampaignWidgetComponent,
  CostPerContactByChannelWidgetComponent,
  ConversionRateByCampaignBarChartWidgetComponent,
  ConversionRateByCampaignLineChartWidgetComponent,
  ConversionRateByChannelBarChartWidgetComponent,
  ContactCapturedCountWidgetComponent,
  ContactUnsoldCountWidgetComponent,
  ContactSoldRefundLineChartWidgetComponent,
  ContactRefundCountWidgetComponent,
  ContactUnsoldByChannelPieWidgetComponent,
  ContactSoldByChannelPieWidgetComponent,
  ContactRefundByChannelPieWidgetComponent,
  ContactSoldStatusByChannelPieWidgetComponent,
  ContactGrossProfitCountWidgetComponent,
  ContactValueRefundCountWidgetComponent,
  ContactSoldNetProfitCountWidgetComponent,
  ContactSoldNotRefundCountWidgetComponent,
  ContactSoldCountWidgetLeadsComponent
} from './widgets/index';
@NgModule({
  providers: [],
  imports: [
    CommonModule,
    FormsModule,
    DndModule.forRoot(),
    CustomFormsModule,
    FileUploadModule,
    AccordionModule.forRoot(),

    ChartsModule,

    SharedModule
  ],
  declarations: [
    AccountChangeStatusComponent,
    AccountLoginComponent,
    AddDaysScheduleComponent,
    AddMultipleFiltersComponent,
    AddNoteModalComponent,
    ContactEmailModalComponent,
    BarChartComponent,
    BarChartCompactComponent,
    DonutChartComponent,
    EditorComponent,
    ErrorComponent,
    LineChartComponent,
    PieChartComponent,
    ReadMoreComponent,
    SetFiltersModalComponent,
    ShowCampaignListComponent,
    ShowPricingListComponent,
    ShowFiltersComponent,
    StackedBarChartComponent,
    TemplateLegendModalComponent,
    FiltersComponent,
    TimeLineEmailComponent,
    TimeLineNoteComponent,
    LeadDynamicFormComponent,
    LeadDfFieldComponent,
    MapComponent,
    ShowPrivilegeListComponent,
    AssignPrivilegeContainerComponent,
    BackButtonComponent,

    // Widgets
    SalesByCampaignWidgetComponent,
    SalesByChannelWidgetComponent,
    NewSalesByRepWidgetComponent,
    ChannelNewContactsBarChartWidgetComponent,
    ChannelSalesBarChartWidgetComponent,
    LeadByCampaignWidgetComponent,
    LeadByChannelWidgetComponent,
    NewCustomerMapWidgetComponent,
    TestsVisitsWidgetComponent,
    TestsConversionRateWidgetComponent,
    WebsiteVisitorWidgetComponent,
    WebsiteConversionRateWidgetComponent,
    InboundCallWidgetComponent,
    FormSubmissionWidgetComponent,
    EmailSentWidgetComponent,
    EmailOpenedWidgetComponent,
    NewLeadsWidgetComponent,
    ActiveLeadsWidgetComponent,
    StatusSalesPipelineWidgetComponent,
    CloseLeadsWidgetComponent,
    AddictionAdmissionsWidgetComponent,
    AutomationsActionRunChartWidgetComponent,
    AutomationsActionRunCountWidgetComponent,
    ContactCapturedLineChartWidgetComponent,
    AccountRegisteredCountWidgetComponent,
    ContactSoldCountWidgetComponent,
    ContactAverageIncomeWidgetComponent,
    AccountReceivablesWidgetComponent,
    ContactLatestWidgetComponent,
    CallRevenueWidgetComponent,
    ContactSoldCapturedLineChartWidgetComponent,
    LeadToEventRateWidgetComponent,
    VobRateWidgetComponent,
    VobCountWidgetComponent,
    AdmissionsWidgetComponent,
    CpaWidgetComponent,
    AddictionMostReferredToChartWidgetComponent,
    AddictionTopAdmissionsByPayorsChartWidgetComponent,
    AddictionTopLeadsByPayorsChartWidgetComponent,
    AccountsNewBarChartWidgetComponent,

    ContactByCampaignLineChartWidgetComponent,
    CostPerContactByCampaignWidgetComponent,
    CostPerContactByChannelWidgetComponent,
    ConversionRateByCampaignBarChartWidgetComponent,
    ConversionRateByCampaignLineChartWidgetComponent,
    ConversionRateByChannelBarChartWidgetComponent,
    ContactCapturedCountWidgetComponent,
    ContactUnsoldCountWidgetComponent,
    ContactSoldRefundLineChartWidgetComponent,
    ContactRefundCountWidgetComponent,
    ContactUnsoldByChannelPieWidgetComponent,
    ContactSoldByChannelPieWidgetComponent,
    ContactRefundByChannelPieWidgetComponent,
    ContactSoldStatusByChannelPieWidgetComponent,
    ContactGrossProfitCountWidgetComponent,
    ContactValueRefundCountWidgetComponent,
    ContactSoldNetProfitCountWidgetComponent,
    ContactSoldNotRefundCountWidgetComponent,
    ContactSoldCountWidgetLeadsComponent
  ],
  exports: [
    CommonModule,
    FormsModule,
    DndModule,
    CustomFormsModule,
    FileUploadModule,
    ChartsModule,

    AccountChangeStatusComponent,
    AccountLoginComponent,
    AddDaysScheduleComponent,
    AddMultipleFiltersComponent,
    AddNoteModalComponent,
    ContactEmailModalComponent,
    BarChartComponent,
    BarChartCompactComponent,
    DonutChartComponent,
    EditorComponent,
    ErrorComponent,
    LineChartComponent,
    PieChartComponent,
    ReadMoreComponent,
    SetFiltersModalComponent,
    ShowCampaignListComponent,
    ShowPricingListComponent,
    ShowFiltersComponent,
    StackedBarChartComponent,
    TemplateLegendModalComponent,
    FiltersComponent,
    TimeLineEmailComponent,
    TimeLineNoteComponent,
    LeadDynamicFormComponent,
    LeadDfFieldComponent,
    MapComponent,
    ShowPrivilegeListComponent,
    AssignPrivilegeContainerComponent,
    BackButtonComponent,

    // Widgets
    SalesByCampaignWidgetComponent,
    SalesByChannelWidgetComponent,
    NewSalesByRepWidgetComponent,
    ChannelNewContactsBarChartWidgetComponent,
    ChannelSalesBarChartWidgetComponent,
    LeadByCampaignWidgetComponent,
    LeadByChannelWidgetComponent,
    NewCustomerMapWidgetComponent,
    TestsVisitsWidgetComponent,
    TestsConversionRateWidgetComponent,
    WebsiteVisitorWidgetComponent,
    WebsiteConversionRateWidgetComponent,
    InboundCallWidgetComponent,
    FormSubmissionWidgetComponent,
    EmailSentWidgetComponent,
    EmailOpenedWidgetComponent,
    NewLeadsWidgetComponent,
    ActiveLeadsWidgetComponent,
    StatusSalesPipelineWidgetComponent,
    CloseLeadsWidgetComponent,
    AddictionAdmissionsWidgetComponent,
    AutomationsActionRunChartWidgetComponent,
    AutomationsActionRunCountWidgetComponent,
    ContactCapturedLineChartWidgetComponent,
    AccountRegisteredCountWidgetComponent,
    ContactSoldCountWidgetComponent,
    ContactAverageIncomeWidgetComponent,
    AccountReceivablesWidgetComponent,
    ContactLatestWidgetComponent,
    CallRevenueWidgetComponent,
    ContactSoldCapturedLineChartWidgetComponent,
    LeadToEventRateWidgetComponent,
    VobRateWidgetComponent,
    VobCountWidgetComponent,
    AdmissionsWidgetComponent,
    CpaWidgetComponent,
    AddictionMostReferredToChartWidgetComponent,
    AddictionTopAdmissionsByPayorsChartWidgetComponent,
    AddictionTopLeadsByPayorsChartWidgetComponent,
    SharedModule,
    AccountsNewBarChartWidgetComponent,
    ContactByCampaignLineChartWidgetComponent,
    CostPerContactByCampaignWidgetComponent,
    CostPerContactByChannelWidgetComponent,
    ConversionRateByCampaignBarChartWidgetComponent,
    ConversionRateByCampaignLineChartWidgetComponent,
    ConversionRateByChannelBarChartWidgetComponent,
    ContactCapturedCountWidgetComponent,
    ContactUnsoldCountWidgetComponent,
    ContactSoldRefundLineChartWidgetComponent,
    ContactRefundCountWidgetComponent,
    ContactUnsoldByChannelPieWidgetComponent,
    ContactSoldByChannelPieWidgetComponent,
    ContactRefundByChannelPieWidgetComponent,
    ContactSoldStatusByChannelPieWidgetComponent,
    ContactGrossProfitCountWidgetComponent,
    ContactValueRefundCountWidgetComponent,
    ContactSoldNetProfitCountWidgetComponent,
    ContactSoldNotRefundCountWidgetComponent,
    ContactSoldCountWidgetLeadsComponent
  ]
})
export class SharedComponentModule {}
