import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'line-chart',
  templateUrl: './line-chart.component.html'
})
export class LineChartComponent implements OnInit {
  @Input() chartData: any;
  @Input() totalCount: any;

  // Line chart
  // -----------------------------------

  lineData = {
    labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
    datasets: [
      {
        label: 'My First dataset',
        data: [
          this.rFactor(),
          this.rFactor(),
          this.rFactor(),
          this.rFactor(),
          this.rFactor(),
          this.rFactor(),
          this.rFactor()
        ]
      },
      {
        label: 'My Second dataset',
        data: [
          this.rFactor(),
          this.rFactor(),
          this.rFactor(),
          this.rFactor(),
          this.rFactor(),
          this.rFactor(),
          this.rFactor()
        ]
      }
    ],
    legend: true
  };

  lineColors = [
    {
      // backgroundColor: 'rgba(114,102,186,0.2)',
      // borderColor: 'rgba(114,102,186,1)',
      // pointBackgroundColor: 'rgba(114,102,186,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(114,102,186,1)'
    }
  ];

  lineOptionsForEmpty = {
    animation: false,
    responsive: true,
    // elements: {
    //     point: {
    //         radius: 0
    //     }
    // },
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
            max: 10
          }
        }
      ],
      xAxes: [
        {
          ticks: {
            beginAtZero: true,
            min:0
          }
        }
      ]
    }
  };

  lineOptions = {
    animation: false,
    responsive: true,
    borderWidth: 0
    // elements: {
    //     point: {
    //         radius: 0
    //     }
    // }
  };

  constructor() { }

  ngOnInit() { }

  // random values for demo
  rFactor() {
    return Math.round(Math.random() * 100);
  }
}
