import { Component, Input, OnChanges, OnInit, SimpleChange } from '@angular/core';
import { channelsArray } from '@app/globals';
import { Privilege } from '@decorator/index';
import { globalValues } from '@app/globals';
import { CampaignReportService, ToasterLibraryService } from '@service/index';
import { NgxPermissionsService } from 'ngx-permissions';
import { Logger } from '@service/global.funcs';

@Component({
  selector: 'app-lead-by-channel-widget',
  templateUrl: './lead-by-channel-widget.component.html',
  styleUrls: ['./lead-by-channel-widget.component.scss']
})

// @Privilege({
//   privilege: 'campaigns:can_get_campaigns_reports_contact_per_channel'
// })
export class LeadByChannelWidgetComponent implements OnInit, OnChanges {
  @Input() relativeDate = 'Last 90 Days';
  @Input() widgetTitle = 'Leads by Channel';
  @Input() channel = '';
  @Input() campaignData: any;
  @Input('chartType') chartType = 'pieChart';
  public leadsByChannelData = {
    labels: [],
    datasets: [
      {
        data: []
      }
    ],
    totalCount: 0,
    legend : false
  };

  public leadsByChannelLoaded = false;
  testData = {
    labels : ['2021-01-01','2021-01-10','2021-01-22','2021-02-08','2021-02-22','2021-02-27'],
    data : ['2','3','0','1',2,0]
  }
  @Input('useTestData') useTestData = false;
  capturedCount = 0;
  constructor(
    private campaignReportService: CampaignReportService,
    private toasterLibrary: ToasterLibraryService,
    private permissionService: NgxPermissionsService
  ) { }

  ngOnInit() {
    if (this.permissionService.hasPermission('campaigns:can_get_campaigns_reports_contact_per_channel')) {
      if(this.useTestData){
        this.leadsByChannelData.labels = this.testData.labels;
        this.leadsByChannelData.datasets[0]['backgroundColor'] = 'rgba(192,135,66,0.2)';
        this.leadsByChannelData.datasets[0]['borderColor'] = '#e88b1b';
        this.leadsByChannelData.datasets[0]['pointBorderColor'] = '#fff';
        this.leadsByChannelData.datasets[0].data = this.testData.data;
        this.capturedCount = 6;
        this.leadsByChannelLoaded = true;
      }else{
        this.getLeadsByChannel();
      }
    }
  }
  ngOnChanges(changes: { [key: string]: SimpleChange }) {
    if (this.permissionService.hasPermission('campaigns:can_get_campaigns_reports_contact_per_channel')) {
      if (
        (changes['relativeDate'] && !changes['relativeDate'].isFirstChange()) ||
        (changes['campaignData'] && !changes['campaignData'].isFirstChange()) ||
        (changes['criteriaArray'] && !changes['criteriaArray'].isFirstChange())
      ) {
        if(this.useTestData){
          this.leadsByChannelData.labels = this.testData.labels;
          this.leadsByChannelData.datasets[0].data = this.testData.data;
          this.capturedCount = 6;
          this.leadsByChannelLoaded = true;
        }else{
          this.getLeadsByChannel();
        }
      }
    }
  }
  getLeadsByChannel() {
    const criteriaArray: any = [
      {
        id: '',
        is_required: true,
        criterion: 'dateRange',
        value: {
          start_date: '',
          end_date: '',
          relative_date: this.relativeDate
        }
      }
    ];
    if (this.campaignData && this.campaignData != "") {
      criteriaArray.push({
        'id': '',
        'value': { c_id: this.campaignData },
        'criterion': 'campaign',
        'is_required': true
      });
    }

    this.leadsByChannelLoaded = false;
    let aggregateIndex = 0;
    this.reInit();
    this.campaignReportService.getLeadsByChannel(criteriaArray).subscribe(
      (res: any) => {
        if (res.success === 'true') {
          let fifthOptionCount = 0;
          res.data.forEach((channel, index) => {
            let cat = channel.category;

            // if (typeof cat === 'string' && cat.length >= 35) {
            //   cat = cat.substr(0, 35) + '...';
            // }
            if (index < globalValues.pie_chart_slices) {
              if (!this.leadsByChannelData.labels.includes(cat)) {
                this.leadsByChannelData.labels.push(cat);
                this.leadsByChannelData.datasets[0].data.push(channel.number_of_occurrences);
              } else {
                aggregateIndex = this.leadsByChannelData.labels.indexOf(cat);
                // Logger().info('getSalesByChannel', 'pie chart data', 'aggregate index', aggregateIndex);
                // Logger().info('getSalesByChannel', 'pie chart data', 'channel', channel);

                this.leadsByChannelData.datasets[0].data[aggregateIndex] =
                  parseInt(this.leadsByChannelData.datasets[0].data[aggregateIndex], 10) +
                  parseInt(channel.number_of_occurrences, 10);

                // Logger().info(
                //   'getSalesByChannel',
                //   'pie chart data',
                //   'new channel',
                //   this.leadsByChannelData.datasets[0].data[aggregateIndex]
                // );
              }
            } else {
              fifthOptionCount = fifthOptionCount + parseInt(channel.number_of_occurrences, 10);
            }
            this.leadsByChannelData.totalCount =
              this.leadsByChannelData.totalCount + parseInt(channel.number_of_occurrences, 10);
          });
          if (fifthOptionCount > 0) {
            this.leadsByChannelData.labels.push('All Other');
            this.leadsByChannelData.datasets[0].data.push(fifthOptionCount);
          }
        }
        this.leadsByChannelLoaded = true;
      },
      err => {
        this.toasterLibrary.error(null, err.error.message);
      }
    );
  }

  private reInit() {
    this.leadsByChannelData = {
      labels: [],
      datasets: [
        {
          data: []
        }
      ],
      totalCount: 0,
      legend : false
    };
  }
}
