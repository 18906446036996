import {
  Component,
  HostBinding,
  OnInit,
  ViewEncapsulation,
  AfterViewChecked,
} from "@angular/core"
import { AuthLoginService } from "@service/auth/auth-login.service"
import { ActAsLoginService } from "@service/auth/act-as-login.service"
import { SettingsService } from "./core/settings/settings.service"
import { ThemesService } from "./core/themes/themes.service"
import { PageTitleService } from "@service/core/page-title.service"
import { Intercom } from "ng-intercom"
import { environment } from "./../environments/environment"
import { GoogleAnalyticsService } from "ngx-google-analytics"
import { ConsoleToggleService } from "@service/core/logs/console-toggle.service"

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
  providers: [AuthLoginService, ActAsLoginService],
  encapsulation: ViewEncapsulation.None,
})
export class AppComponent implements OnInit, AfterViewChecked {
  @HostBinding("class.layout-fixed") get isFixed() {
    return this.settings.layout.isFixed
  }
  @HostBinding("class.aside-collapsed") get isCollapsed() {
    return this.settings.layout.isCollapsed
  }
  @HostBinding("class.layout-boxed") get isBoxed() {
    return this.settings.layout.isBoxed
  }
  @HostBinding("class.layout-fs") get useFullLayout() {
    return this.settings.layout.useFullLayout
  }
  @HostBinding("class.hidden-footer") get hiddenFooter() {
    return this.settings.layout.hiddenFooter
  }
  @HostBinding("class.layout-h") get horizontal() {
    return this.settings.layout.horizontal
  }
  @HostBinding("class.aside-float") get isFloat() {
    return this.settings.layout.isFloat
  }
  @HostBinding("class.offsidebar-open") get offsidebarOpen() {
    return this.settings.layout.offsidebarOpen
  }
  @HostBinding("class.aside-toggled") get asideToggled() {
    return this.settings.layout.asideToggled
  }
  @HostBinding("class.aside-collapsed-text") get isCollapsedText() {
    return this.settings.layout.isCollapsedText
  }

  constructor(
    public settings: SettingsService,
    private theme: ThemesService,
    public intercom: Intercom,
    private $gaService: GoogleAnalyticsService,
    private pageTitle: PageTitleService,
    private consoleToggleService: ConsoleToggleService
  ) {
    this.consoleToggleService.disableConsoleInProduction();
  }

  async ngOnInit() {
    document.addEventListener("click", e => {
      const target = e.target as HTMLElement
      if (
        target.tagName === "A" &&
        ["", "#"].indexOf(target.getAttribute("href") || "") > -1
      )
        e.preventDefault()
    })

    if (localStorage.getItem("theme_name")) {
      this.theme.setTheme(localStorage.getItem("theme_name"))
    }
  }
  intercomLoad() {
    this.intercom.boot({
      user_id: localStorage.getItem("User"),
      name: localStorage.getItem("name"), // Full name
      email: localStorage.getItem("email"), // Email address
      companies: [
        {
          company_id: localStorage.getItem("account_id"),
          name: localStorage.getItem("account_name"),
          remote_created_at: localStorage.getItem("creation_date"),
          plan: localStorage.getItem("group_name"),
          phone: localStorage.getItem("phone"),
        },
      ],
    })
  }

  ngAfterViewChecked() {
    const account = JSON.parse(localStorage.getItem("account"));
    
    if (
      account &&
      account.parent_account_id == "1" &&
      localStorage.getItem("User") &&
      localStorage.getItem("email")
    ) {
      this.intercomLoad()
    }
    if (account && localStorage.getItem("User")) {
      this.gaLoad()
    }
    if (account && localStorage.getItem('account_name')) {      
      this.pageTitle.boot()
    }
  }

  gaLoad() {
    this.$gaService.gtag("config", environment.ga_measurement_id, {
      user_id: localStorage.getItem("User"),
    })
    this.$gaService.gtag("set", "user_properties", {
      user_account_id: localStorage.getItem("account_id"),
      user_suite: localStorage.getItem("appVersion"),
    })
  }
}
