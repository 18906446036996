import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import 'rxjs/add/operator/map';
import { globalValues } from '@app/globals';
import { CustomHttp } from '@service/customhttp.service';
import { RequestLibraryService } from '@service/core/request-library.service';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class AccountReportService {
  private customHttp: CustomHttp;
  constructor(private http: HttpClient, private router: Router, private requestLibrary: RequestLibraryService) {
    this.customHttp = new CustomHttp(http, router);
  }

  getAccountsRegisteredCount(filter) {
    return this.requestLibrary
      .GetAll('/accounts/reports/new?options=', {
        options: {
          date_interval: filter
        }
      })
      .map((res: any) => res.data)
      .map(arr => arr.reduce((a, b) => a + Number(b.client_registered), 0));
  }

  getAccountsNewCount(criteriaArray) {
    return this.requestLibrary
      .GetAll('/accounts/reports/new', { options: { criteria: criteriaArray } })
      .map((res: any) => res.data)
      .map(arr => arr.reduce((a, b) => a + Number(b.number_of_occurrences), 0));
  }

  getAccountReceiveable() {
    return this.requestLibrary.Get('/accounts/reports/receivables').map((res: any) => res.data);
  }

  getClientChart(criteriaArray) {
    let returnArr = [];
    if (criteriaArray != null) {
      returnArr = JSON.parse(JSON.stringify(criteriaArray));
    }
    return this.requestLibrary.GetAll('/accounts/reports/new', { options: { criteria: criteriaArray } });
  }
}
