<div class="mb-editor">
  <!-- START action buttons-->
  <div class="btn-group btn-group-sm mb pull-right">
    <button class="btn btn-default btn-sm" type="button" [ngClass]="{'active':showCC}" (click)="showCC = !showCC" href="#">CC</button>
    <button class="btn btn-default btn-sm" type="button" [ngClass]="{'active':showBCC}" (click)="showBCC = !showBCC" href="#">BCC</button>
  </div>
  <!-- END action buttons  -->
  <div [id]="editorID"></div>
  <br/>
</div>
