<nav class="navbar topnavbar" role="navigation">
  <div class="navbar-header">
    <a class="navbar-brand" [routerLink]="['/leads']">
      <div class="brand-logo hidden-xs">
        <img class="img-responsive" [src]="logo" alt="" />
      </div>
      <div class="brand-logo-collapsed hidden-xs">
        <img class="img-responsive" [src]="smallLogo" alt="" />
      </div>
      <div class="brand-logo-collapsed visible-xs">
        <img class="img-responsive" [src]="smallLogo" alt="" />
      </div>
    </a>
  </div>
  <div class="nav-wrapper nav-fonts">
    <ul class="nav navbar-nav">
      <li>
        <a
          class="hidden-xs"
          trigger-resize=""
          (click)="toggleCollapsedSideabar()"
          *ngIf="!isCollapsedText()"
        >
          <em class="fa fa-navicon"></em> {{ account_name }}
        </a>
      </li>
    </ul>
    <ul class="nav navbar-nav navbar-right">
      <li class="nav-icon">
        <a
          class="visible-xs"
          (click)="settings.layout.asideToggled = !settings.layout.asideToggled"
        >
          <em class="fa fa-navicon fa-lg"></em>
        </a>
      </li>

      <ng-template
        [ngxPermissionsOnly]="[
          'emailtemplates:can_post_emailtemplates',
          'tests:can_post_tests',
          'smstemplates:can_post_smstemplates',
          'domains:can_post_domains',
          'privatelabels:can_post_privatelabels',
          'webhooks:can_post_webhooks',
          'leadprices:can_post_leadprices',
          'phonenumbers:can_post_phonenumbers',
          'statuses:can_post_statuses',
          'events:can_post_events',
          'campaigns:can_post_campaigns',
          'forms:can_post_forms',
          'fields:can_post_fields',
          'accounts:can_post_accounts_my_settings'
        ]"
      >
        <li class="nav-icon dropdown dropdown-list" dropdown>
          <a dropdownToggle (click)="settings.layout.asideToggled = false">
            <em class="fa fa-cog fa-lg"></em>
          </a>
          <ul *dropdownMenu class="dropdown-menu">
            <ng-template
              [ngxPermissionsOnly]="['accounts:can_post_accounts_my_settings']"
            >
              <li>
                <div class="list-group">
                  <a
                    [routerLink]="['/settings/general']"
                    class="list-group-item"
                  >
                    <div class="media-box">
                      <div class="media-box-body clearfix">
                        <p class="m0">General</p>
                      </div>
                    </div>
                  </a>
                </div>
              </li>
            </ng-template>
            <ng-template
              [ngxPermissionsOnly]="['emailtemplates:can_post_emailtemplates']"
            >
              <li>
                <div class="list-group">
                  <a
                    [routerLink]="['/email-templates']"
                    class="list-group-item"
                  >
                    <div class="media-box">
                      <div class="media-box-body clearfix">
                        <p class="m0">Email Templates</p>
                      </div>
                    </div>
                  </a>
                </div>
              </li>
            </ng-template>
            <ng-template [ngxPermissionsOnly]="['fields:can_post_fields']">
              <li>
                <div class="list-group">
                  <a [routerLink]="['/fields']" class="list-group-item">
                    <div class="media-box">
                      <div class="media-box-body clearfix">
                        <p class="m0">Fields</p>
                      </div>
                    </div>
                  </a>
                </div>
              </li>
            </ng-template>
            <ng-template [ngxPermissionsOnly]="['forms:can_post_forms']">
              <li>
                <div class="list-group">
                  <a [routerLink]="['/forms']" class="list-group-item">
                    <div class="media-box">
                      <div class="media-box-body clearfix">
                        <p class="m0">Forms</p>
                      </div>
                    </div>
                  </a>
                </div>
              </li>
            </ng-template>
            <ng-template
              [ngxPermissionsOnly]="['campaigns:can_post_campaigns']"
            >
              <li>
                <div class="list-group">
                  <a [routerLink]="['/campaigns']" class="list-group-item">
                    <div class="media-box">
                      <div class="media-box-body clearfix">
                        <p class="m0">Campaigns</p>
                      </div>
                    </div>
                  </a>
                </div>
              </li>
            </ng-template>
            <ng-template [ngxPermissionsOnly]="['events:can_post_events']">
              <li>
                <div class="list-group">
                  <a [routerLink]="['/events']" class="list-group-item">
                    <div class="media-box">
                      <div class="media-box-body clearfix">
                        <p class="m0">Events</p>
                      </div>
                    </div>
                  </a>
                </div>
              </li>
            </ng-template>
            <ng-template [ngxPermissionsOnly]="['statuses:can_post_statuses']">
              <li>
                <div class="list-group">
                  <a [routerLink]="['/statuses']" class="list-group-item">
                    <div class="media-box">
                      <div class="media-box-body clearfix">
                        <p class="m0">Statuses</p>
                      </div>
                    </div>
                  </a>
                </div>
              </li>
            </ng-template>
            <ng-template
              [ngxPermissionsOnly]="['phonenumbers:can_post_phonenumbers']"
            >
              <li>
                <div class="list-group">
                  <a
                    [routerLink]="['/settings/call-tracking']"
                    class="list-group-item"
                  >
                    <div class="media-box">
                      <div class="media-box-body clearfix">
                        <p class="m0">Call Tracking</p>
                      </div>
                    </div>
                  </a>
                </div>
              </li>
            </ng-template>
            <ng-template
              [ngxPermissionsOnly]="['leadprices:can_post_leadprices']"
            >
              <li>
                <div class="list-group">
                  <a
                    [routerLink]="['/leads/leadsprices']"
                    class="list-group-item"
                  >
                    <div class="media-box">
                      <div class="media-box-body clearfix">
                        <p class="m0">Lead Prices</p>
                      </div>
                    </div>
                  </a>
                </div>
              </li>
            </ng-template>
            <ng-template [ngxPermissionsOnly]="['webhooks:can_post_webhooks']">
              <li>
                <div class="list-group">
                  <a [routerLink]="['/web-hooks']" class="list-group-item">
                    <div class="media-box">
                      <div class="media-box-body clearfix">
                        <p class="m0">Webhooks</p>
                      </div>
                    </div>
                  </a>
                </div>
              </li>
            </ng-template>
            <ng-template
              [ngxPermissionsOnly]="['privatelabels:can_post_privatelabels']"
            >
              <li>
                <div class="list-group">
                  <a [routerLink]="['/private-labels']" class="list-group-item">
                    <div class="media-box">
                      <div class="media-box-body clearfix">
                        <p class="m0">Private Labels</p>
                      </div>
                    </div>
                  </a>
                </div>
              </li>
            </ng-template>
            <ng-template [ngxPermissionsOnly]="['domains:can_post_domains']">
              <li>
                <div class="list-group">
                  <a [routerLink]="['/domains']" class="list-group-item">
                    <div class="media-box">
                      <div class="media-box-body clearfix">
                        <p class="m0">Domains</p>
                      </div>
                    </div>
                  </a>
                </div>
              </li>
            </ng-template>
            <ng-template
              [ngxPermissionsOnly]="['smstemplates:can_post_smstemplates']"
            >
              <li>
                <div class="list-group">
                  <a [routerLink]="['/sms-templates']" class="list-group-item">
                    <div class="media-box">
                      <div class="media-box-body clearfix">
                        <p class="m0">Text Messages</p>
                      </div>
                    </div>
                  </a>
                </div>
              </li>
            </ng-template>
            <ng-template [ngxPermissionsOnly]="['tests:can_post_tests']">
              <li>
                <div class="list-group">
                  <a [routerLink]="['/tests']" class="list-group-item">
                    <div class="media-box">
                      <div class="media-box-body clearfix">
                        <p class="m0">A/B Testing</p>
                      </div>
                    </div>
                  </a>
                </div>
              </li>
            </ng-template>
          </ul>
        </li>
      </ng-template>
      <ng-template
        [ngxPermissionsOnly]="[
          'users:can_get_users',
          'users:can_get_users_my',
          'accounts:can_get_accounts_my'
        ]"
      >
        <li class="nav-icon dropdown dropdown-list" dropdown>
          <a dropdownToggle (click)="settings.layout.asideToggled = false">
            <em class="fa fa-lg fa-user fa-lg"></em>
          </a>
          <ul *dropdownMenu class="dropdown-menu">
            <ng-template
              [ngxPermissionsOnly]="['accounts:can_get_accounts_my']"
            >
              <li class="nav-item">
                <div class="list-group">
                  <a [routerLink]="['/account']" class="list-group-item">
                    <div class="media-box">
                      <div class="media-box-body clearfix">
                        <em class="icon-organization"></em>&nbsp;
                        <span>Manage Account</span>
                      </div>
                    </div>
                  </a>
                </div>
              </li>
            </ng-template>
            <ng-template [ngxPermissionsOnly]="['users:can_post_users']">
              <li>
                <div class="list-group">
                  <a [routerLink]="['/users']" class="list-group-item">
                    <div class="media-box">
                      <div class="media-box-body clearfix">
                        <em class="icon-people"></em>&nbsp;
                        <span>Manage Users</span>
                      </div>
                    </div>
                  </a>
                </div>
              </li>
            </ng-template>
            <li role="separator" class="divider"></li>
            <ng-template [ngxPermissionsOnly]="['users:can_get_users_my']">
              <li>
                <div class="list-group">
                  <a [routerLink]="['/users/my']" class="list-group-item">
                    <div class="media-box">
                      <div class="media-box-body clearfix">
                        <em class="icon-user"></em>&nbsp;
                        <span>My User Profile</span>
                      </div>
                    </div>
                  </a>
                </div>
              </li>
            </ng-template>
            <ng-template
              [ngxPermissionsOnly]="['email:can_post_email_credentials']"
            >
              <li>
                <div class="list-group">
                  <a [routerLink]="['/users/my/email']" class="list-group-item">
                    <div class="media-box">
                      <div class="media-box-body clearfix">
                        <em class="icon-envelope-letter"></em>&nbsp;
                        <span>My Email Settings</span>
                      </div>
                    </div>
                  </a>
                </div>
              </li>
            </ng-template>
            <li role="separator" class="divider"></li>
            <li>
              <div class="list-group">
                <a [routerLink]="['/logout']" class="list-group-item">
                  <div class="media-box">
                    <div class="media-box-body clearfix">
                      <em class="icon-logout"></em>&nbsp;
                      <span>Log Out</span>
                    </div>
                  </div>
                </a>
              </div>
            </li>
          </ul>
        </li>
      </ng-template>
    </ul>
  </div>
</nav>
