import { Component, Injector, OnInit } from "@angular/core"
import { Router } from "@angular/router"
import { MenuService } from "@app/core/menu/menu.service"
import { SettingsService } from "@app/core/settings/settings.service"
import { Intercom } from "ng-intercom"
declare var $: any
import * as _ from "lodash"
declare var window: any
@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
})
export class SidebarComponent implements OnInit {
  menuItems: Array<any>
  router: Router
  appCurrentVersion: any
  public parent_account_id: string

  constructor(
    private menu: MenuService,
    public settings: SettingsService,
    private injector: Injector,
    public intercom: Intercom
  ) {
    this.menuItems = menu.getMenu()
    if (localStorage.getItem("appVersion") == "portal") {
      _.remove(this.menuItems, {
        link: "/dashboard",
      })
    }
  }

  ngOnInit() {
    const account = JSON.parse(localStorage.getItem("account"))
    if (account) {
      this.parent_account_id = account.parent_account_id
    }

    this.router = this.injector.get(Router)
    this.appCurrentVersion = localStorage.getItem("appVersion")
    this.router.events.subscribe(val => {
      // scroll view to top
      window.scrollTo(0, 0)
    })
  }

  removeFloatingNav() {
    $(".nav-floating").remove()
  }

  isSidebarCollapsed() {
    return this.settings.layout.isCollapsed
  }
  isSidebarCollapsedText() {
    return this.settings.layout.isCollapsedText
  }
  isEnabledHover() {
    return this.settings.layout.asideHover
  }

  showIntercom() {
    this.intercom.show()
  }
}
