import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { APPLICATION_PROVIDERS } from './application-providers';
import { APPLICATION_MODULES } from './application-modules';
import { ENTRY_COMPONENTS } from './moRoutes/entry-components';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
@NgModule({
    declarations: [
        AppComponent,
    ],
    imports: [
        APPLICATION_MODULES,
        BrowserAnimationsModule
    ],
    providers: [
        APPLICATION_PROVIDERS,
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
    constructor() {
    }
}
