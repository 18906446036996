import { Component, Input, OnChanges, OnInit, SimpleChange } from '@angular/core';
import { Privilege } from '@decorator/index';
import { AbTestReportService, ToasterLibraryService, VisitorService, ContactReportService } from '@service/index';
import { NgxPermissionsService } from 'ngx-permissions';
import { Logger } from '@service/global.funcs';
@Component({
  selector: 'app-vob-count-widget',
  templateUrl: './vob-count-widget.component.html',
  styleUrls: ['./vob-count-widget.component.scss']
})

// @Privilege({
//   privilege: 'visitors:can_get_visitors_reports_average_conversion_rate'
// })
export class VobCountWidgetComponent implements OnInit, OnChanges {
  @Input() relativeDate: any;
  @Input() campaignData: any;
  public isVobLoaded: boolean = false;
  public vobCount: number = 0;
  public criteriaArray: Array<any> = [];
  public myAccountSettings: {
    settings_name: 'addictiontreatment';
    settings_value: {
      admissions_role_id: '';
      business_dev_rep_role_id: '';
      ref_out_event_id: '';
      ref_out_status_id: '';
      vob_event_id: '';
    };
  };

  constructor(
    private contactReportService: ContactReportService,
    private toasterLibrary: ToasterLibraryService,
    private permissionService: NgxPermissionsService
  ) { }

  ngOnInit() {
    if (this.permissionService.hasPermission('visitors:can_get_visitors_reports_average_conversion_rate')) {
      this.getAddictionTreatmentSettings();
      this.getVobCount();
    }
  }

  ngOnChanges(changes: { [key: string]: SimpleChange }) {
    if (this.permissionService.hasPermission('visitors:can_get_visitors_reports_average_conversion_rate')) {
      if (
        (changes['relativeDate'] && !changes['relativeDate'].isFirstChange()) ||
        (changes['campaignData'] && !changes['campaignData'].isFirstChange()) ||
        (changes['criteriaArray'] && !changes['criteriaArray'].isFirstChange())
      ) {
        this.getVobCount();
      }
    }

  }
  getAddictionTreatmentSettings() {
    this.myAccountSettings = JSON.parse(localStorage.getItem('addictiontreatment'));
  }

  getVobCount() {
    this.isVobLoaded = false;
    const criteriaArray: any = [
      {
        id: '',
        is_required: true,
        criterion: 'dateRange',
        value: {
          start_date: '',
          end_date: '',
          relative_date: this.relativeDate
        }
      }
    ];

    if (this.campaignData && this.campaignData != "") {
      criteriaArray.push({
        'id': '',
        'value': { c_id: this.campaignData },
        'criterion': 'campaign',
        'is_required': true
      });
    }

    this.contactReportService
      .getVobCount(criteriaArray, this.myAccountSettings.settings_value.vob_event_id)
      .subscribe((res: any) => {
        this.vobCount = parseInt(res.data.count, 10);
        this.isVobLoaded = true;
      });
  }
}
