import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import {
    SettingsService,
    LocalFilterStorageService,
    LeadPriceService,
    CallScheduleService,
    GroupService,
    PlanService
} from '@service/index';
import { Router } from '@angular/router';
import * as _ from 'lodash';
import { channelsArray } from '@app/globals';

@Component({
    selector: 'app-set-filters',
    templateUrl: './set-filters-modal.component.html',
})

export class SetFiltersModalComponent implements OnInit {
    public isSaving: boolean = false;
    public context: any;
    public model: any;
    public leadPricings: Array<any> = [];
    public leadPricingsLoaded: boolean = true;
    public isPlanExist: boolean = false;
    public isGroupExist: boolean = false;
    public isStatusFilterExist: boolean = false;
    public isCallTrackingStatusFilterExist: boolean = false;
    public isCallTrackingFallbackFilterExist: boolean = false;
    public isCallTrackingScheduleFilterExist: boolean = false;
    public isSearchFilterExist: boolean = false;
    public isPriceFilterExist: boolean = false;
    public isArchiveFilterExist: boolean = false;
    public isLeadTypeExist: boolean = false;
    public isLeadPriceStatusExist: boolean = false;
    public isRoleExist: boolean = false;
    public isChannelExist: boolean = false;
    public rolesArray: Array<any> = [];
    public rolesLoaded: boolean = true;

    public plansArray: Array<any> = [];
    public plansLoaded: boolean = false;

    public groupsArray: Array<any> = [];
    public groupsLoaded: boolean = false;

    public channels: Array<any> = channelsArray;
    public currentUser: string;

    public callTrackingScheduleArray: Array<any> = [];
    public callTrackingScheduleLoaded: boolean = true;
    @Input('filters') filters = null;
    @Input('isInlineComponent') isInlineComponent = false;
    @Output() filterApplied: EventEmitter<any> = new EventEmitter();
    constructor(
        private settingsService: SettingsService,
        private _lfsService: LocalFilterStorageService,
        private router: Router,
        private leadPriceService: LeadPriceService,
        private callScheduleService: CallScheduleService,
        private groupService: GroupService,
        private planService: PlanService
    ) {
    }

    ngOnInit() {
        this.currentUser = localStorage.getItem('User');
        this.model = this.filters;
        if ('status' in this.model) {
            this.isStatusFilterExist = true;
        }
        if ('call_tracking_status' in this.model) {
            this.isCallTrackingStatusFilterExist = true;
        }
        if ('search' in this.model) {
            this.isSearchFilterExist = true;
        }
        if ('call_tracking_fallback' in this.model) {
            this.isCallTrackingFallbackFilterExist = true;
        }

        if ('call_tracking_schedule' in this.model) {
            this.isCallTrackingScheduleFilterExist = true;
        }

        if ('is_archived' in this.model) {
            this.isArchiveFilterExist = true;
            if (!this.model.is_archived) {
                this.model.is_archived = '0';
            }
        }
        if ('lead_type' in this.model) {
            this.isLeadTypeExist = true;
        }
        if ('is_active' in this.model) {
            this.isLeadPriceStatusExist = true;
        }
        if ('price' in this.model) {
            this.isPriceFilterExist = true;
        }
        if ('role' in this.model) {
            this.isRoleExist = true;
        }
        if ('channel' in this.model) {
            this.isChannelExist = true;
        }

        if ('plan' in this.model) {
            this.isPlanExist = true;
            this.getClientPlans();
        }

        if ('group' in this.model) {
            this.isGroupExist = true;
            this.getUserGroups();
        }

        if (!this._lfsService.getItem()) {
            this._lfsService.setItem('');
        }
        this.getFilters();
        if (this.isPriceFilterExist) {
            this.leadPricingsLoaded = false;
            this.getLeadPricings();
        }
        if (this.isRoleExist) {
            this.rolesLoaded = false;
            this.getUserRoles();
        }

        if (this.isCallTrackingScheduleFilterExist) {
            this.callTrackingScheduleLoaded = false;
            this.getCallTrackingSchedule();
        }
    }
    getClientPlans() {
        this.planService
            .getPlans()
            .subscribe((res: any) => {
                if (res.success == 'true') {
                    this.plansArray = res.data;
                }
                this.plansLoaded = true;

            }, err => {
                this.router.navigate(['error']);
            });
    }

    getUserGroups() {
        this.groupService
            .getGroups()
            .subscribe((res: any) => {
                if (res.success == 'true') {
                    this.groupsArray = res.data;
                }
                this.groupsLoaded = true;

            }, err => {
                this.router.navigate(['error']);
            });
    }

    setFilters() {
        this.isSaving = true;
        const key: any = this.model.key;
        
        // If Price Object found
        if (this.model.price && this.model.price.id != '') {
            const selectedPrice = this.leadPricings.filter((data) => data.id == this.model.price.id);
            this.model.price.name = selectedPrice[0].name;
        }
        // If Role Object found
        if (this.model.role && this.model.role.id != '') {
            const selectedRole = this.rolesArray.filter((data) => data.id == this.model.role.id);
            this.model.role.name = selectedRole[0].name;
        }

        // If Role Object found
        if (this.model.plan && this.model.plan.id != '') {
            const selectedPlan = this.plansArray.filter((data) => data.id == this.model.plan.id);
            this.model.plan.name = selectedPlan[0].name;
            this.model.plan.id = selectedPlan[0].id;
        }

        // If Group Object found
        if (this.model.group && this.model.group.id != '') {
            const selectedGroup = this.groupsArray.filter((data) => data.id == this.model.group.id);
            this.model.group.name = selectedGroup[0].name;
            this.model.group.id = selectedGroup[0].id;
        }
        // If Channel Object found
        if (this.model.channel && this.model.channel.id != '') {
            const selectedChannel = this.channels.filter((data) => data[0] == this.model.channel.id);
            this.model.channel.name = selectedChannel[0][1];
        }

        // If Call Tracking Schedule Object found
        if (this.model.call_tracking_schedule && this.model.call_tracking_schedule.id != '') {
            const selectedCallTrackingSchedule = this.callTrackingScheduleArray.filter((data) => data.id == this.model.call_tracking_schedule.id);
            this.model.call_tracking_schedule.name = selectedCallTrackingSchedule[0].name;
        }

        const obj = this.model;
        let filterData: any;

        if (this._lfsService.getItem() == '') {
            filterData = {};
            filterData[key] = obj;
        } else {
            filterData = JSON.parse(this._lfsService.getItem());
            delete filterData[key];
            filterData[key] = obj;
        }
        // delete filterData[key].key;
        this._lfsService.setItem(JSON.stringify(filterData))
        this.isSaving = false;
        this.closeDialog();

    }

    getFilters() {// get filters list
        if (this._lfsService.getItem() != '') {
            const filterData = JSON.parse(this._lfsService.getItem());
            const key = this.model.key;
            
            if (!_.isEmpty(filterData) && filterData[key]) {
                this.model = filterData[key];
                this.model.key = key;
            }
        }
    }

    getLeadPricings() {
        this.leadPriceService
            .getAllLeadsPrices()
            .subscribe((res: any) => {
                if (res.success == 'true') {
                    this.leadPricings = res.data;
                }
                this.leadPricingsLoaded = true;
            }, err => {
                this.router.navigate(['error']);
            });
    }

    getUserRoles() {
        this.settingsService.getAllRoles().subscribe((res:any) => {
            if (res.success == 'true') {
                this.rolesArray = res.data;
            }
            this.rolesLoaded = true;
        }, err => {
            this.router.navigate(['error']);
        });
    }

    getCallTrackingSchedule() {
        this.callScheduleService
            .getAllCallTrackingSchedule()
            .subscribe((res: any) => {
                if (res.success == 'true') {
                    this.callTrackingScheduleArray = res.data;
                }
                this.callTrackingScheduleLoaded = true;
            }, err => {
                this.router.navigate(['error']);
            });
    }

    closeDialog() {
        this.filterApplied.emit();
    }

    cancelDialog() {
        // this.filterApplied.emit(false);
    }

    resetFilters() {
        if (this.isStatusFilterExist) {
            this.model.status = 2;
        }

        if (this.isCallTrackingStatusFilterExist) {
            this.model.call_tracking_status = '';
        }

        if (this.isCallTrackingFallbackFilterExist) {
            this.model.call_tracking_fallback = '';
        }

        if (this.isCallTrackingScheduleFilterExist) {
            this.model.call_tracking_fallback = '';
            this.model.call_tracking_schedule.id = '';
        }

        if (this.isSearchFilterExist) {
            this.model.search = '';
        }
        if (this.isArchiveFilterExist) {
            this.model.is_archived = 0;
        }
        if (this.isLeadTypeExist) {
            this.model.lead_type = '';
        }
        if (this.isLeadPriceStatusExist) {
            this.model.is_active = 2;
        }
        if (this.isPriceFilterExist) {
            this.model.price.id = '';
            this.model.price.name = '';
        }
        if (this.isRoleExist) {
            this.model.role.id = '';
            this.model.role.name = '';
        }
        if (this.isChannelExist) {
            this.model.channel.id = '';
        }
        if (this.isPlanExist) {
            this.model.plan.id = '';
        }
        if (this.isGroupExist) {
            this.model.group.id = '';
            this.model.group.name = '';
        }
    }

    trim_search_input() {
        this.model.search = this.model.search.trim();
    }

    onSelectChange() {
        if (this.model.price && this.model.price.id != '') {
            const selectedPrice = this.leadPricings.find(data => data.id == this.model.price.id);
            this.model.price.name = selectedPrice.name;
        }

        if (this.model.role && this.model.role.id != '') {
            const selectedRole = this.rolesArray.find(data => data.id == this.model.role.id);
            this.model.role.name = selectedRole.name;
        }

        if (this.model.group && this.model.group.id != '') {
            const selectedGroup = this.groupsArray.find(data => data.id == this.model.group.id);
            this.model.group.name = selectedGroup.name;
        }

        if (this.model.plan && this.model.plan.id != '') {
            const selectedGroup = this.plansArray.find(data => data.id == this.model.plan.id);
            this.model.plan.name = selectedGroup.name;
        }

        if (this.model.call_tracking_schedule && this.model.call_tracking_schedule.id != '') {
            const selectedGroup = this.callTrackingScheduleArray.find(data => data.id == this.model.call_tracking_schedule.id);
            this.model.call_tracking_schedule.name = selectedGroup.name;
        }

        if (this.model.channel && this.model.channel.id != '') {
            const selectedGroup = this.channels.find(data => data[0] == this.model.channel.id);
            if (selectedGroup) {
                this.model.channel.name = selectedGroup[1];
            }
        }
    }
}
