<div *ngIf="!campaignsLoaded" class="whirl standard settingsspinnermargin"></div>
<div *ngIf="campaignsLoaded">
    <div class="row">
        <div class="col-sm-12">
            <a [routerLink]="[ '/campaigns' ]" class="hyperlink-color pull-right">Manage Campaigns</a>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12">
            <select class="form-control" #campaign name="campaign" [(ngModel)]="model.is_required">
                <option value=true>Require Any Checked Campaign</option>
                <option value=false>Exclude all Checked Campaigns</option>
            </select>
        </div>
    </div>
    <div class="row mt-sm">
        <div class="col-md-12">
            <a class="hyperlink-color pull-left" (click)="toggleAllCampaigns();">{{toggleCampaignLabel}}</a>
        </div>
    </div>
    <div class="campaigns-checkboxes-list">
        <ng-template ngFor let-chunkCampaigns [ngForOf]="chunkCampaignsArray['chunkData']" let-i="chunkIndex">
            <div class="row">
                <ng-template ngFor let-iCampaign [ngForOf]="chunkCampaigns" let-i="index">
                    <div class="col-{{view}}-{{chunkCampaignsArray['chunkSize']}}">
                        <label class="checkbox-inline c-checkbox">
                            <input name="campaign_name" (click)="addRemoveCampaign(iCampaign.id);"
                                [checked]="checkCampaign(iCampaign.id)" type="checkbox" />
                            <span class="fa fa-check"></span>
                            [{{iCampaign.id}}] {{iCampaign.channel}} - {{iCampaign.name}}
                        </label>
                    </div>
                </ng-template>
            </div>
        </ng-template>

    </div>
</div>