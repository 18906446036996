import { Component, Input, OnChanges, OnInit, SimpleChange } from '@angular/core';
import { ContactReportService, ToasterLibraryService } from '@service/index';
import { NgxPermissionsService } from 'ngx-permissions';

@Component({
  selector: 'app-contact-latest-widget',
  templateUrl: './contact-latest-widget.component.html',
  styleUrls: ['./contact-latest-widget.component.scss']
})

export class ContactLatestWidgetComponent implements OnInit, OnChanges {
  public isContactLoaded = false;
  @Input() campaignData: any;
  @Input() relativeDate: any;
  public contactsArray: Array<any> = [];
  constructor(
    private contactReportService: ContactReportService,
    private toasterLibrary: ToasterLibraryService,
    private permissionService: NgxPermissionsService

  ) { }
  ngOnInit() {
    if (this.permissionService.hasPermission('contacts:can_get_contacts_reports_new')) {
      this.requestContactLatest();
    }
  }

  ngOnChanges(changes: { [key: string]: SimpleChange }) {

    if (this.permissionService.hasPermission('contacts:can_get_contacts_reports_new')) {
      if (
        (changes['relativeDate'] && !changes['relativeDate'].isFirstChange()) ||
        (changes['campaignData'] && !changes['campaignData'].isFirstChange()) ||
        (changes['criteriaArray'] && !changes['criteriaArray'].isFirstChange())
      ) {
        this.requestContactLatest();
      }
    }
  }

  requestContactLatest() {
    this.isContactLoaded = false;
    this.contactReportService
      .getContactLatest(this.relativeDate)
      .subscribe((res: any) => {
        if (res.success == 'true') {
          this.contactsArray = res.data;
          this.isContactLoaded = true;
        }
      });
  }

  _trackByContactId(index: number, contact: any): string { return contact.id; }

}
