import { Component } from "@angular/core"
import { ActivatedRoute, Router } from "@angular/router"
import * as auth0js from "auth0-js"
import { auth0Config } from "@service/auth/auth0.config"
import { timezones } from "@asset/timezones/timezones"
import { currencies } from "@asset/currency/currency"
import * as jwt from "jsonwebtoken"
import * as _ from "lodash"
import { AuthService } from "@service/auth/auth.service"
import { AuthLogoutService } from "@service/auth/auth-logout.service"

import {
  CustomerOnboardService,
  CustomerOnboardHelperService,
  CustomerOnboardRedirectService,
  ToasterLibraryService,
} from "@service/index"
@Component({
  selector: "app-password-page",
  templateUrl: "./password-page.component.html",
  styleUrls: ["./password-page.component.scss"],
})
export class PasswordPageComponent {
  public accountLoaded: Boolean = false
  public isSaving: Boolean = false
  public wrongSubmission: Boolean = false
  public passwordValidation: boolean = true
  public emailValidation: boolean = true

  public timezoneArray = timezones
  public currencyArray = currencies
  public model: any = {
    timezone_offset: 1,
    timezone: "America/New_York",
  }

  public modelPass = {
    token: "",
    password: "",
    confirm_password: "",
  }

  public auth0_session_token: string
  public auth0_state: string

  private _auth0_clientId = auth0Config.clientID
  private _auth0_domain = auth0Config.domain
  public _auth0_rollias_secret = "x2f1" // collect this from environment file
  public auth0IncomingData

  constructor(
    private _route: ActivatedRoute,
    private auth: AuthService,
    private router: Router,
    private authLogout: AuthLogoutService,
    private customerOnboardRedirectService: CustomerOnboardRedirectService,
    private customerOnboardHelperService: CustomerOnboardHelperService
  ) {
    this._route.queryParams.subscribe(params => {
      this.auth0_session_token = params["mo_session_token"]
      this.auth0_state = params["state"]
    })
    this.accountLoaded = true
  }

  async ngOnInit() {
    this.auth0IncomingData =
      await this.customerOnboardHelperService.validateIncomingToken(
        this.auth0_session_token
      )

    if (
      this.auth0IncomingData.mo_req_payload &&
      _.has(this.auth0IncomingData.mo_req_payload, "email")
    ) {
      this.model.email = this.auth0IncomingData.mo_req_payload.email
    }

    if (this.auth0IncomingData && _.has(this.auth0IncomingData, "exp")) {
      this.auth0IncomingData.exp = this.auth0IncomingData.exp + 60
    }
  }

  onSubmit() {
    this.redirectToAuth0()
    // this.modelPass.confirm_password = this.modelPass.password
    // this.isSaving = true
    // if (this.modelPass.password == this.modelPass.confirm_password) {
    //   this.auth.setPassword(this.modelPass).subscribe((res: any) => {
    //     if (res.success == "true") {
    //       // this.customerOnboardRedirectService.redirectToAuth0(
    //       //   this.auth0IncomingData,
    //       //   this.auth0_state
    //       // )
    //       this.authLogout.logout()
    //       this.redirectToAuth0()
    //     } else {
    //       console.log("password change response in error" + JSON.stringify(res))
    //     }
    //   })
    // } else {
    //   console.log("password missmatched!!!")
    // }
    // this.isSaving = false
  }

  restReq() {
    this.isSaving = true
    let model = {
      username: this.auth0IncomingData.mo_data.mo_user_name,
    }
    this.auth.reqPassword(model).subscribe((res: any) => {
      if (res.success == "true") {
        this.modelPass.token = res.data.token
      } else {
        console.log("error on req password API........:" + JSON.stringify(res))
      }
    })
    this.isSaving = false
  }

  confirmEmailValidation(email) {
    this.emailValidation = this.validateEmail(email)
    return this.validateEmail
  }

  validateEmail(email) {
    var re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(email)
  }

  confirmPasswordValidation(password, confirmPassword) {
    if (password && password !== confirmPassword) {
      this.passwordValidation = false
    } else {
      this.passwordValidation = true
    }
  }

  async redirectToAuth0() {
    const newToken = await this.customerOnboardHelperService.generateNewToken(
      this.auth0_state,
      this.auth0IncomingData,
      {}
    )

    this.auth0IncomingData =
      await this.customerOnboardHelperService.validateIncomingToken(newToken)

    const url = `https://${this._auth0_domain}/continue?state=${this.auth0_state}&mo_token=${newToken}`
    window.location.href = url
  }
}
