import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import 'rxjs/add/operator/map';
import { globalValues } from '@app/globals';
import { CustomHttp } from '@service/customhttp.service';
import { RequestLibraryService } from '@service/core/request-library.service';
import { Observable } from 'rxjs/Observable'
import { HttpClient } from '@angular/common/http';
@Injectable()
export class FieldService {
    private customHttp: CustomHttp;
    constructor(
        private http: HttpClient,
        private router: Router,
        private requestLibrary: RequestLibraryService
    ) {
        this.customHttp = new CustomHttp(http, router);
    }

    getPaginatedFields(body) {
        return this.requestLibrary.GetAll('/fields', {
            'options': body
        });
    }

    getExportFields(body) {
        return this.requestLibrary.GetCSV('/fields', {
            'options': body
        });
    }

    getAllFields() {
        return this.requestLibrary.GetAll('/fields', {
            'options': { page_size: 'all' }
        });
    }
    addEditField(model) {
        return this.requestLibrary.Post('/fields', model);
    }

    deleteField(id) {
        return this.requestLibrary.Delete('/fields/' + id);
    }

    getField(id) {
        return this.requestLibrary.Get('/fields/' + id);
    }

    getFieldTypes() {
        return this.requestLibrary.Get('/fields/types');
    }
}
