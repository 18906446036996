<toaster-container [toasterconfig]="toasterconfig"></toaster-container>
<div class="container" *ngIf="!loggedIn">
  <div class="page-header">
    <h1>Welcome <small>Company Information</small></h1>
  </div>
  <div class="row" *ngIf="loaded">
    <div class="col-md-12">
      <form name="signupForm" class="form-validate" #signupForm="ngForm" (ngSubmit)="onSubmit()" novalidate>
        <div class="panel panel-default">
          <div [ngClass]="isSaving ? 'panel-body whirl shadow oval right' : ''">
            <div class="panel-heading">
              <!-- <div class="panel-heading">Company Details</div> -->
            </div>
            <div class="panel-body">
              <div class="form-group">
                <label class="control-label">Email</label>
                <div>
                  <p class="form-control-static">{{auth0IncomingData.mo_req_payload.email}}</p>
                </div>
              </div>
              <ng-template [ngIf]="auth0IncomingData.mo_req_payload.username">
                <div class="form-group">
                  <label class="control-label">User Name</label>
                  <div>
                    <p class="form-control-static">{{auth0IncomingData.mo_req_payload.username}}</p>
                  </div>
                </div>
              </ng-template>
              <ng-template [ngIf]="auth0IncomingData.mo_req_payload.firstname">
                <div class="form-group">
                  <label class="control-label">First Name</label>
                  <div>
                    <p class="form-control-static">{{auth0IncomingData.mo_req_payload.firstname}}</p>
                  </div>
                </div>
              </ng-template>
              <ng-template [ngIf]="auth0IncomingData.mo_req_payload.lastname">
                <div class="form-group">
                  <label class="control-label">Last Name</label>
                  <div>
                    <p class="form-control-static">{{auth0IncomingData.mo_req_payload.lastname}}</p>
                  </div>
                </div>
              </ng-template>
              <ng-template [ngIf]="!auth0IncomingData.mo_req_payload.firstname">
                <div class="form-group">
                  <label for="name">First Name</label>
                  <input class="form-control" type="input" name="name" [(ngModel)]="model.first_name" placeholder="First Name"
                    required />
                  <span class="text-danger" *ngIf="wrongSubmission && model.first_name==''">first name is required</span>
                </div>
              </ng-template>
              <ng-template [ngIf]="!auth0IncomingData.mo_req_payload.lastname">
                <div class="form-group">
                  <label for="name">Last Name</label>
                  <input class="form-control" type="input" name="name" [(ngModel)]="model.last_name" placeholder="Last Name"
                    required />
                  <span class="text-danger" *ngIf="wrongSubmission && model.last_name==''">last name is required</span>
                </div>
              </ng-template>

              <div class="form-group">
                <label for="name">Company Name</label>
                <input class="form-control" type="input" name="name" [(ngModel)]="model.name" placeholder="Company Name"
                  required />
                <span class="text-danger" *ngIf="wrongSubmission && model.name==''">company name is required</span>
              </div>

              <div class="form-group">
                <label for="name">Company Suite</label>
                <select class="form-control" [(ngModel)]="selectedAppVersion" (ngModelChange)="setAppVersion($event)">
                  <option value="" disabled>Select a version suite</option>
                  <option value="automation">Marketing Automation Suite</option>
                  <option value="aggregation">Lead Aggregation Suite</option>
                  <option value="addiction">Addiction Treatment Suite</option>
                </select>
              </div>
            </div>
            
            <div class="panel-footer">
              <button class="btn btn-primary btn-lg" type="submit" [disabled]="!signupForm.form.valid">
                Submit
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>