import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import 'rxjs/add/operator/map';
import { globalValues } from '@app/globals';
import { CustomHttp } from '@service/customhttp.service';
import { RequestLibraryService } from '@service/core/request-library.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AutomationScheduleService {
     
  private customHttp: CustomHttp;
        constructor(
          private http: HttpClient,
          private router: Router,
          private requestLibrary: RequestLibraryService
        ) {
          this.customHttp = new CustomHttp(http, router);
        }

        getPaginatedSchedules(page, page_size, sort, sort_dir) {
          return this.requestLibrary.GetAll('/automation/schedules', {
            'options': { page: page, page_size: page_size, sort: sort, sort_dir: sort_dir }
          });
        }

        getAllSchedules() {
          return this.requestLibrary.GetAll('/automation/schedules', {
            'options': { page_size: 'all' }
          });
        }

        /** SCHEDULES SERVICES */
        addSchedule(model) {
          console.log("Model before schedule saving :"+ JSON.stringify(model));
          
          return this.requestLibrary.Post('/automation/schedules', model);
        }

        getScheduleDetail(id) {
          return this.requestLibrary.Get('/automation/schedules/' + id);
        }

        deleteSchedule(id) {
          return this.requestLibrary.Delete('/automation/schedules/' + id);
        }
}
