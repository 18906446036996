import { Component, OnInit } from '@angular/core';
import { SettingsService } from '../../../core/settings/settings.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: []
})
export class ErrorComponent implements OnInit {
  public current_year: number

  constructor(public settings: SettingsService, private router: Router) { }

  ngOnInit() {
    this.getCurrentYear;
  }

  navigate(url) {
    this.router.navigate([url]);
  }

  get getCurrentYear() {
    const d = new Date();
    return this.current_year = d.getFullYear();
  }
  
}
