import { Injectable } from '@angular/core';
import 'rxjs/add/operator/map';
import { RequestLibraryService } from '@service/core/request-library.service';

@Injectable()
export class FilterService {
    constructor(private requestLibrary: RequestLibraryService) {
    }

    getAll(page: number) {
        return this.requestLibrary.GetAll('/filters', {
            options: {
                page: page,
                page_size: 5
            },
        });
    }

    get(id: number) {
        return this.requestLibrary.GetAll('/filters/' + id);
    }

    save(criteriaArray, isShared = false, accountId: string, userId: string, name: string = 'No Name') {
        return this.requestLibrary.Post('/filters', {
            is_shared: isShared,
            account_id: accountId,
            user_id: userId,
            name: name,
            criteria: criteriaArray
        });
    }

    delete(id: number) {
        return this.requestLibrary.Delete('/filters/' + id);
    }



}
