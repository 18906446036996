import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { SettingsService } from '@app/core/settings/settings.service';
import { AuthLogoutService } from '@service/auth/auth-logout.service';
import {
  AppConfig,
  AuthService,
  ToasterLibraryService
} from '@service/index';
import { Subscription } from 'rxjs';
import { interval } from 'rxjs/observable/interval';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  encapsulation: ViewEncapsulation.None
})
export class LayoutComponent implements OnInit,OnDestroy {
  toasterConfig: any;
  constructor(
    private toasterService: ToasterLibraryService,
    private authService : AuthService,
    private authLogoutService : AuthLogoutService,
    public settings: SettingsService
  ) {
    this.toasterConfig = this.toasterService.getToasterConfig();
  }

  ngOnInit() {
    this.subscribeAuthCheckService();
  }
  ngOnDestroy(){
    this.unsubscribeAuthCheckService();
  }

  //Background Job to check auth expiration
  auth_check_subscription : Subscription;
  subscribeAuthCheckService(){
    this.auth_check_subscription = interval(AppConfig.authCheckTimeInterval).subscribe(t=>{
      if(!this.authService.tokenValid()){
        this.unsubscribeAuthCheckService();
        this.authLogoutService.logout();
      }
    });

  }
  unsubscribeAuthCheckService(){
    if(this.auth_check_subscription){
      this.auth_check_subscription.unsubscribe();
    }
  }

  collapseSidebarOnOutsideClick(event: MouseEvent) {
    const target = event.target as HTMLElement;
    const is_inside_form = target.closest('form') !== null;
    
    if (!is_inside_form) {
        this.settings.layout.asideToggled = false;
    }
  }

}
