<ng-template [ngxPermissionsOnly]="['accounts:can_get_accounts_reports_new']">
  <div class="box-row panel panel-default">
    <div class="panel-heading">
      <h4>{{widgetTitle}}</h4>
    </div>
    <div class="panel-body text-center">
      <div *ngIf="!stackedBarChartLoaded" class="whirl standard spinnermargin"></div>
      <ng-template [ngIf]="stackedBarChartLoaded">
        <stacked-bar-chart [stackedBarData]="stackedBarChartData"></stacked-bar-chart>
      </ng-template>
    </div>
  </div>
</ng-template>
