import { Routes } from "@angular/router"
import { LayoutComponent } from "../layout/layout.component"
import { AuthenticationGuard } from "./authentication-guard/authentication-guard"
import { CallbackComponent } from "./callback"
import {
  ChangePasswordComponent,
  ResetPasswordComponent,
} from "./forgot-password"
import { LogoutComponent } from "./logout"
import { ErrorComponent } from "./shared-components"
import { ActAsCallbackComponent } from "./act-as-callback"
import { PasswordPageComponent } from "./customer-onboard/password-page/password-page.component"
import { CompanyInfoPageComponent } from "./customer-onboard/company-info-page/company-info-page.component"
import { SignupPageComponent } from "./login/signup-page/signup-page.component"

export const apRroutes: Routes = [
  { path: "onboard/customer/pwd", component: PasswordPageComponent },
  {
    path: "onboard/customer/company/info",
    component: CompanyInfoPageComponent,
  },
  {
    path: "login",
    loadChildren: () => import("./login/login.module").then(m => m.LoginModule),
  },
  { path: "forgot-password", component: ResetPasswordComponent },
  { path: "change-password/:token", component: ChangePasswordComponent },
  {
    path: "",
    component: LayoutComponent,
    canActivate: [AuthenticationGuard],
    children: [
      { path: "", redirectTo: "/leads", pathMatch: "full" },
      {
        path: "get-started",
        loadChildren: () =>
          import("./onboard-report/onboard-report.module").then(
            m => m.OnboardReportModule
          ),
      },
      {
        path: "tests",
        loadChildren: () =>
          import("./ab-tests/ab-tests.module").then(m => m.AbTestsModule),
      },
      {
        path: "email",
        loadChildren: () =>
          import("./email/email.module").then(m => m.EmailModule),
      },
      {
        path: "clients",
        loadChildren: () =>
          import("./accounts/accounts.module").then(m => m.AccountsModule),
      },
      {
        path: "dashboard",
        loadChildren: () =>
          import("./dashboard/dashboard.module").then(m => m.DashboardModule),
      },
      {
        path: "leads",
        loadChildren: () =>
          import("./contacts/contacts.module").then(m => m.ContactsModule),
      },
      {
        path: "calls",
        loadChildren: () =>
          import("./calls/calls.module").then(m => m.CallsModule),
      },
      {
        path: "tasks",
        loadChildren: () =>
          import("./tasks/tasks.module").then(m => m.TasksModule),
      },
      {
        path: "tasktypes",
        loadChildren: () =>
          import("./task-types/task-types.module").then(m => m.TaskTypesModule),
      },
      {
        path: "task-templates",
        loadChildren: () =>
          import("./task-templates/task-templates.module").then(
            m => m.TaskTemplatesModule
          ),
      },
      {
        path: "email-templates",
        loadChildren: () =>
          import("./email-templates/email-templates.module").then(
            m => m.EmailTemplatesModule
          ),
      },
      {
        path: "web-hooks",
        loadChildren: () =>
          import("./webhooks/webhooks.module").then(m => m.WebhooksModule),
      },
      {
        path: "statuses",
        loadChildren: () =>
          import("./statuses/statuses.module").then(m => m.StatusesModule),
      },
      {
        path: "refund-reasons",
        loadChildren: () =>
          import("./refund-reasons/refund-reasons.module").then(
            m => m.RefundReasonsModule
          ),
      },
      {
        path: "fields",
        loadChildren: () =>
          import("./fields/fields.module").then(m => m.FieldsModule),
      },
      {
        path: "forms",
        loadChildren: () =>
          import("./forms/forms.module").then(m => m.FormsModule),
      },
      {
        path: "events",
        loadChildren: () =>
          import("./events/events.module").then(m => m.EventsModule),
      },
      {
        path: "domains",
        loadChildren: () =>
          import("./domains/domains.module").then(m => m.DomainsModule),
      },
      {
        path: "sms-templates",
        loadChildren: () =>
          import("./sms-templates/sms-templates.module").then(
            m => m.SmsTemplatesModule
          ),
      },
      {
        path: "private-labels",
        loadChildren: () =>
          import("./private-labels/private-labels.module").then(
            m => m.PrivateLabelsModule
          ),
      },
      {
        path: "campaigns",
        loadChildren: () =>
          import("./campaigns/campaigns.module").then(m => m.CampaignsModule),
      },
      {
        path: "call-schedules",
        loadChildren: () =>
          import("./call-schedules/call-schedules.module").then(
            m => m.CallSchedulesModule
          ),
      },
      {
        path: "settings",
        loadChildren: () =>
          import("./settings/settings.module").then(m => m.SettingsModule),
      },
      {
        path: "tracking-numbers",
        loadChildren: () =>
          import("./phone-numbers/phone-numbers.module").then(
            m => m.PhoneNumbersModule
          ),
      },
      {
        path: "visitor-tracking-numbers",
        loadChildren: () =>
          import("./visitor-phone-numbers/visitor-phone-numbers.module").then(
            m => m.VisitorPhoneNumbersModule
          ),
      },
      {
        path: "automations",
        loadChildren: () =>
          import("./automations/automations.module").then(
            m => m.AutomationsModule
          ),
      },
      {
        path: "account",
        loadChildren: () =>
          import("./organization/organization.module").then(
            m => m.OrganizationModule
          ),
      },
      {
        path: "users",
        loadChildren: () =>
          import("./users/users.module").then(m => m.UsersModule),
      },
      { path: "logout", component: LogoutComponent },
    ],
  },
  { path: "error", component: ErrorComponent },
  { path: "callback", component: CallbackComponent },
  { path: "act-as", component: ActAsCallbackComponent },
  { path: "signup", component: SignupPageComponent, data: { title: "signup" } },

  /*{ path: 'login', component: pages.LoginComponent },
    { path: 'register', component: pages.RegisterComponent },
    { path: 'recover', component: pages.RecoverComponent },
    { path: 'lock', component: pages.LockComponent },
    { path: 'maintenance', component: pages.MaintenanceComponent },
    { path: '404', component: pages.Error404Component },
    { path: '500', component: pages.Error500Component },*/

  // Not found
  // { path: '/', redirectTo: '/leads' },
  { path: "**", redirectTo: "/leads" },
]
