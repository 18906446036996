import { Injectable } from '@angular/core';

@Injectable()
export class LocalFilterStorageService {
    private filterData: any;
    public currentUser: string;
    private key = 'local_filters_';
    private contactKey = 'leadsCriteria';
    private clearContactData = [];
    constructor() {
        this.currentUser = localStorage.getItem('User');
        this.filterData = this.getOrSetItemParse();
    }

    public isEmptyItem() {
        return this.getItem() === null || this.getItem() === '';
    }

    public isEmptyContact() {
        const filter = this.getItemParse();
        return !(this.contactKey in filter);
    }

    public getItem() {
        return localStorage.getItem(this.key + this.currentUser);
    }

    private getOrSetItemParse() {
        if (this.isEmptyItem()) {
            this.setItem(JSON.stringify({}));
        }
        // check local filter exist
        // else set with default local filter and leads criteria and return
        // check local filter has leads criteria key
        if (this.isEmptyContact()) {
            const filter = this.getItemParse();
            filter[this.contactKey] = this.clearContactData
            this.setItem(JSON.stringify(filter));
        }

        return this.getItemParse();
    }

    public getItemParse() {
        return JSON.parse(this.getItem());
    }

    public setItem(obj = '') {
        localStorage.setItem(this.key + this.currentUser, obj)
    }

    public haveContact() {
        // console.log(this.filterData);
        // const isFilter = this.filterData[this.contactKey] &&
        //     this.filterData[this.contactKey] !== '' &&
        //     this.filterData[this.contactKey].length;

        // return this.isEmptyItem() && isFilter;
        return !(this.isEmptyItem() && this.isEmptyContact());

    }

    public getContact() {
        const filter = this.getItemParse();
        if (this.haveContact()) { return filter[this.contactKey]; }

    }

    public clearContact() {
        const that = this;
        return Promise.resolve().then(function () {
            const filter = that.getItemParse();
            filter[that.contactKey] = that.clearContactData;
            that.setItem(JSON.stringify(filter));
        });
    }
}
