import { Component, OnInit } from "@angular/core"
import { ActivatedRoute, Router } from "@angular/router"
import "rxjs/add/operator/filter"
import { ActAsLoginService } from "@service/auth/act-as-login.service"
import * as _ from "lodash"
import {
  AccountService,
  PrivateLabelService,
  AuthService,
  UserService,
  FieldService,
  UserPrivilegeContextService,
  AddictionTreatmentSuiteService,
  SetupAppService,
  ToasterLibraryService,
} from "@service/index"
import { globalValues, pageTitles, themesArray } from "@app/globals"
import { Observable } from "rxjs/Rx"
import { Logger } from "@service/global.funcs"
import { NgxPermissionsService } from "ngx-permissions"
import { AppConfig } from "@service/app.config"

@Component({
  selector: "app-act-as-callback",
  templateUrl: "./act-as-callback.component.html",
  styleUrls: [],
})
export class ActAsCallbackComponent implements OnInit {
  _as = null
  _c = null
  private privateLables: any
  constructor(
    private actAsLoginService: ActAsLoginService,
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    private toasterLibrary: ToasterLibraryService
  ) {
    this.route.queryParams
      // .filter(params => params._as)
      .subscribe(params => {
        this._as = params._as
        this._c = params._c
      })
  }

  ngOnInit() {
    Observable.of(
      this.authService.appendUserId(this._as, this._c, null)
    ).subscribe(
      res => {
        this.actAsLoginService.afterSetup().then(() => {
          this.redirectAfterLoginUrl()
        })
      },
      err => {
        this.toasterLibrary.error(null, err.error.message)
      }
    )
  }

  private redirectAfterLoginUrl() {
    this.router.navigate([""])
    return
  }
}
