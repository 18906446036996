import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import 'rxjs/add/operator/map';
import { globalValues } from '@app/globals';
import { CustomHttp } from '@service/customhttp.service';
import { RequestLibraryService } from '@service/core/request-library.service';
import { HttpClient } from '@angular/common/http';
@Injectable()
export class EventService {
    private customHttp: CustomHttp;
    constructor(
        private http: HttpClient,
        private router: Router,
        private requestLibrary: RequestLibraryService
    ) {
        this.customHttp = new CustomHttp(http, router);
    }

    getPaginatedEvents(page, page_size, sort, sort_dir) {
        return this.requestLibrary.GetAll('/events', {
            'options': { page: page, page_size: page_size, sort: sort, sort_dir: sort_dir }
        });
    }

    getEventDetail(id) {
        return this.requestLibrary.Get('/events/' + id);
    }

    addEditEvent(model) {
        return this.requestLibrary.Post('/events', model);
    }

    deleteEvent(id) {
        return this.requestLibrary.Delete('/events/' + id);
    }

    getAllEvents() {
        return this.requestLibrary.GetAll('/events', {
            'options': { page_size: 'all' }
        });
    }
    getEvents(options: {
        page_size: number,
        sort: string,
        sort_dir: string,
        is_export: boolean
    }) {
        return this.requestLibrary.GetCSV('/events', {
            'options': options
        });
    }

    getEventCommunicationSettings() {
        return this.requestLibrary.Get('/events/communicationsettings');
    }

    postEventCommunicationSettings(model) {
        return this.requestLibrary.Post('/events/communicationsettings', model);
    }
}
