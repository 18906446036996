import { Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from '@service/auth/auth.service';
import { ToasterConfig, ToasterService } from 'angular2-toaster';
@Component({
    selector: 'app-change-password',
    templateUrl: './change-password.component.html',
    styleUrls: ['./change-password.component.scss'],
    providers: [AuthService]
})
export class ChangePasswordComponent {

    public model = {
        token: '',
        password: '',
        confirm_password: ''
    };
    public isSaving: boolean = false;

    // TOASTER
    toasterConfig: any;
    toasterconfig: ToasterConfig = new ToasterConfig({
        positionClass: 'toast-bottom-right',
        showCloseButton: true
    });

    constructor(
        private auth: AuthService,
        private toasterService: ToasterService,
        private router: Router,
        private route: ActivatedRoute
    ) {
        this.route.params.subscribe(params => {
            this.model.token = params['token'];
        });
    }

    submitForm() {
        this.isSaving = true;
        if (this.model.password == this.model.confirm_password) {
            this.auth.changePassword(this.model).subscribe((res: any) => {
                if (res.success == 'true') {
                    this.pop('success', '', res.message);
                    setTimeout(function () { }, 3000);
                    this.router.navigate(['login']);
                } else {
                    this.pop('error', '', res.message);
                }
            });
        } else {
            this.pop('error', '', 'Password Mismatched!');
        }
        this.isSaving = false;
    }

    pop(type, title, text) {
        this.toasterService.pop(type, title, text);
    };
}
