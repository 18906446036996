import { FormFieldBase } from './form-field-base';

export class TextboxField extends FormFieldBase<string> {
  override controlType = 'textbox';
  type: string;

  constructor(options: {} = {}) {
    super(options);
    this.type = options['type'] || '';
  }
}
