<ng-template [ngIf]="_getAvailableVersionsCount() > 1">
  <div class="row">
    <div class="col-md-12">
      <div class="dropup" *ngIf="isNotEmptyVersion()">
        <button class="btn btn-link btn-sm dropdown-toggle" id="dropdownMenu2" data-toggle="dropdown"
          aria-haspopup="true" aria-expanded="false">
          {{currentAppVersion}}
          <span class="caret"></span>
        </button>
        <ul class="dropdown-menu" aria-labelledby="dropdownMenu2">
          <li *ngIf="isAllowVersion(appVersions.AUTOMATION)"
            [ngClass]="{'active': isAppVersion(appVersions.AUTOMATION) }">
            <a (click)="setAppVersion(appVersions.AUTOMATION)">
              Marketing Automation Suite
            </a>
          </li>
          <li *ngIf="isAllowVersion(appVersions.AGGREGATION)"
            [ngClass]="{'active': isAppVersion(appVersions.AGGREGATION) }">
            <a (click)="setAppVersion(appVersions.AGGREGATION)">
              Lead Aggregation Suite
            </a>
          </li>
          <li *ngIf="isAllowVersion(appVersions.ADDICTION)"
            [ngClass]="{'active': isAppVersion(appVersions.ADDICTION) }">
            <a (click)="setAppVersion(appVersions.ADDICTION)">
              Addiction Treatment Suite
            </a>
          </li>
          <li *ngIf="isAllowVersion(appVersions.PORTAL)" [ngClass]="{'active': isAppVersion(appVersions.PORTAL) }">
            <a (click)="setAppVersion(appVersions.PORTAL)">
              Client Portal
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</ng-template>
<div class="row">
  <div class="col-md-10">
    &copy; {{settings.app.year}} - {{ account_name }}
    <span *ngIf="version && version.buildDate">&nbsp;-&nbsp;Version:&nbsp;{{version.version}}</span>
  </div>
</div>
<app-auth-failed-redirect></app-auth-failed-redirect>
