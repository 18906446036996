import { Component, Input, OnChanges, OnInit, SimpleChange } from '@angular/core';
import { Privilege } from '@decorator/index';
import { EmailTemplateReportService, ToasterLibraryService } from '@service/index';
import { NgxPermissionsService } from 'ngx-permissions';

@Component({
  selector: 'app-email-sent-widget',
  templateUrl: './email-sent-widget.component.html',
  styleUrls: ['./email-sent-widget.component.scss']
})

// @Privilege({
//   privilege: 'emailtemplates:can_get_emailtemplates_reports_sent'
// })
export class EmailSentWidgetComponent implements OnInit, OnChanges {
  @Input() relativeDate: any;
  @Input() campaignData: any;
  public isCountLoaded = false;
  public emailSentCount = 0;

  constructor(
    private emailTemplateReportService: EmailTemplateReportService,
    private toasterLibrary: ToasterLibraryService,
    private permissionService: NgxPermissionsService
  ) { }
  ngOnInit() {
    if (this.permissionService.hasPermission('emailtemplates:can_get_emailtemplates_reports_sent')) {
      this.getEmailSentCount();
    }
  }

  ngOnChanges(changes: { [key: string]: SimpleChange }) {

    if (this.permissionService.hasPermission('emailtemplates:can_get_emailtemplates_reports_sent')) {
      if (
        (changes['relativeDate'] && !changes['relativeDate'].isFirstChange()) ||
        (changes['campaignData'] && !changes['campaignData'].isFirstChange()) ||
        (changes['criteriaArray'] && !changes['criteriaArray'].isFirstChange())
      ) {
        this.getEmailSentCount();
      }
    }
  }

  getEmailSentCount() {
    const criteriaArray: any = [
      {
        is_required: true,
        criterion: 'dateRange',
        value: {
          relative_date: this.relativeDate
        }
      }
    ];
    if (this.campaignData && this.campaignData != "") {
      criteriaArray.push({
        'id': '',
        'value': { c_id: this.campaignData },
        'criterion': 'campaign',
        'is_required': true
      });
    }
    this.isCountLoaded = false;
    this.emailTemplateReportService.getSent(criteriaArray).subscribe((res: any) => {
      this.emailSentCount = res.data.reduce((total, item) => total + Number(item.number_of_occurrences), 0);
      this.isCountLoaded = true;
    });
  }
}
