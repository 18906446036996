import { Injectable } from '@angular/core';
import * as _ from 'lodash';
@Injectable()
export class MenuService {

    menuItems: Array<any>;

    constructor() {
        this.menuItems = [];
    }

    /**
     * This is basically only used to load the sidebar, not any other menus
     * 
     * @param items elements of the sidebar, such as all the data needed to make a pretty button for leads
     */
    addMenu(items: Array<{
        text: string,
        heading?: boolean,
        link?: string,     // internal route links
        elink?: string,    // used only for external links
        target?: string,   // anchor target="_blank|_self|_parent|_top|framename"
        icon?: string,
        alert?: string,
        submenu?: Array<any>
    }>) {
        items.forEach((item) => {
            this.menuItems.push(item);
        });
    }

    getMenu() {
        return _.sortBy(this.menuItems, [(o) => o.order]);
    }

}
