import { Injectable } from '@angular/core';
import 'rxjs/add/operator/map';
import { Router } from '@angular/router';
import { globalValues } from '@app/globals';
import { CustomHttp } from '@service/customhttp.service';
import { Logger } from '@service/global.funcs'
import { HttpClient } from '@angular/common/http';

@Injectable()
export class SettingsService {
    private customHttp: CustomHttp;
    constructor(private http: HttpClient, private router: Router) {
        this.customHttp = new CustomHttp(http, router);
    }

    updateContact(model) {
        return this.customHttp.post('/filters', model);
    }

    getAllRoles() {
        return this.customHttp.getAll('/roles?options=', { page_size: 'all' });
    }

    getAllCompactAttachments() {
        return this.customHttp.getAll('/files?options=', { page_size: 'all', is_compact: true });
    }

    private extractData(res: Response) {
        let body = res.json();
        return body;
    }


}
