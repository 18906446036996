import { Component, OnInit, Input } from '@angular/core';


@Component({
    selector: 'app-bar-chart-compact',
    templateUrl: './bar-chart-compact.component.html'
})
export class BarChartCompactComponent implements OnInit {
    @Input() barData: any;
    @Input() totalCount: any;
    @Input() chartFor: string = 'normalSize';
    @Input() chartType: string = 'bar';
    @Input() chartDisableAspectRatio: any = 'true';
    barColors = [
        {
            backgroundColor: 'rgb(232, 139, 28)',
            borderColor: '#e88b1b',
            pointHoverBackgroundColor: '#e88b1b',
            pointHoverBorderColor: '#e88b1b'
        }
    ];

    optionsForLessThanTen: any = {
        // scaleShowVerticalLines: false,
        responsive: true,
        maintainAspectRatio: false,
        elements: {
            point: {
                radius: 0
            }
        },
        scales: {
            yAxes: [{
                ticks: {
                    beginAtZero: true,
                    max: 10
                }
            }],
            xAxes: [{
                ticks: {
                    // Create scientific notation labels
                    callback: function (value, index, values) {
                        if (typeof value === 'string') {
                            return (value);
                        } else {
                            return parseInt(value, 10);
                        }
                    }
                }
            }]
        },
        tooltips: {
            callbacks: {
                title: function (tooltipItem, data) {
                    return data.labels[tooltipItem[0].index];
                }
            }
        }
    };

    optionsForGreaterThanTen: any = {
        // scaleShowVerticalLines: false,
        responsive: true,
        maintainAspectRatio: false,
        elements: {
            point: {
                radius: 0
            }
        },
        scales: {
            yAxes: [{
                ticks: {
                    beginAtZero: true,
                }
            }],
            xAxes: [{
                ticks: {
                    // Create scientific notation labels
                    callback: function (value, index, values) {
                        if (typeof value === 'string') {
                            return (value);
                        } else {
                            return parseInt(value, 10);
                        }
                    }
                }
            }]
        },
        tooltips: {
            callbacks: {
                title: function (tooltipItem, data) {
                    return data.labels[tooltipItem[0].index];
                }
            }
        }
    };


    constructor() {
    }

    ngOnInit() {
        if (this.chartDisableAspectRatio == 'false') {
            delete this.optionsForGreaterThanTen['maintainAspectRatio'];
            delete this.optionsForLessThanTen['maintainAspectRatio'];
        }
    }

}
