import { Privilege } from '@model/privilege/privilege.model';
export const PrivilegesTemplates: {
  name: string,
  id: string,
  privileges: string[] | string
}[] = [
    {
      'name': 'Admin - [EVERYTHING]', 'id': '1', privileges: '*'
    },
    {
      name: 'Super User', 'id': '2', privileges: [
        'contacts:can_get_contacts',
        'followups:can_get_followups',
        'contacts:can_get_contacts_history',
        'contacts:can_get_contacts_notes',
        'contacts:can_post_contacts',
        'contacts:can_post_contacts_event',
        'contacts:can_post_contacts_refund',
        'contacts:can_post_contacts_actions',
        'contacts:can_delete_contacts',
        'contacts:can_get_contacts_purchase',
        'contacts:can_post_contacts_purchase_refund',
        'contacts:can_get_contacts_exports',
        'contacts:can_get_contacts_exports_progress',
        'contacts:can_get_contacts_exports_download',
        'contacts:can_post_contacts_exports',
        'contacts:can_post_contacts_exports_start',
        'contacts:can_post_contacts_exports_stope',
        'contacts:can_post_contacts_exports_reset',
        'contacts:can_delete_contacts_exports',
        'contacts:can_get_contacts_filters',
        'contacts:can_post_contacts_filters',
        'contacts:can_delete_contacts_filters',
        'contacts:can_get_contacts_imports_queue',
        'contacts:can_post_contacts_imports',
        'contacts:can_get_contacts_inbound_webhook',
        'contacts:can_get_contacts_imports_template',
        'contacts:can_get_contacts_reports_count',
        'contacts:can_get_contacts_reports_sold',
        'contacts:can_get_contacts_reports_captured',
        'contacts:can_get_contacts_reports_income_per_contact',
        'contacts:can_get_contacts_reports_contact_value',
        'contacts:can_get_contacts_reports_new',
        'contacts:can_get_contacts_reports_sold_times',
        'contacts:can_get_contacts_reports_sales',
        'contacts:can_get_contacts_reports_count_per_field_type',
        'contacts:can_get_contacts_reports_total_conversion_rate',
        'contacts:can_get_contacts_reports_count_per_city',
        'files:can_get_files',
        'files:can_get_files_download',
        'files:can_post_files',
        'files:can_delete_files',
        'accounts:can_get_accounts_my',
        'accounts:can_get_accounts_my_billdetails',

        'accounts:can_delete_accounts_my_notes',
        'accounts:can_get_accounts_my_settings',
        'accounts:can_get_accounts_creditcard',
        'accounts:can_get_accounts_notes',
        'accounts:can_get_accounts_stripe',
        'accounts:can_post_accounts_stripe_customer',
        'accounts:can_get_accounts_billdetails',
        'accounts:can_get_accounts',
        'accounts:can_get_accounts_settings',
        'accounts:can_post_accounts_clientlogin',
        'accounts:can_post_accounts_settings',

        'accounts:can_post_accounts_my_settings',
        'accounts:can_post_accounts_cancel',
        'accounts:can_post_accounts_restore',
        'accounts:can_post_accounts',
        'accounts:can_post_accounts_creditcard',
        'accounts:can_post_accounts_stripe',
        'accounts:can_delete_accounts',
        'accounts:can_delete_accounts_notes',
        'accounts:can_get_accounts_invoices',

        'accounts:can_post_accounts_invoices',
        'accounts:can_delete_accounts_invoices',
        'accounts:can_get_accounts_reports_receivables',
        'accounts:can_get_accounts_reports_deposited_amount',
        'accounts:can_get_accounts_reports_sales_value',
        'accounts:can_get_accounts_reports_new',
        'accounts:can_get_accounts_privileges',
        'accounts:can_post_accounts_privileges',
        'actions:can_run_actions_reseller_pricing',
        'actions:can_run_actions_reseller_specific',
        'actions:can_run_actions_reseller_org_all',
        'actions:can_run_actions_reseller_group_specific_all',
        'actions:can_run_actions_reseller_group_queue',
        'actions:can_run_actions_reseller_org_queue',
        'actions:can_run_actions_email_contact',

        'actions:can_run_actions_email_address',
        'actions:can_run_actions_email_assigned_user',
        'actions:can_run_actions_email_specific_user',
        'actions:can_run_actions_sms_action_type_number',
        'actions:can_run_actions_post',
        'actions:can_run_actions_field_value',
        'actions:can_run_actions_event',
        'actions:can_run_actions_assign_user_specific_user',
        'actions:can_run_actions_assign_user_org_queue',
        'actions:can_run_actions_assign_user_org_queue_in',
        'automations:can_get_automations',
        'automations:can_post_automations',
        'automations:can_delete_automations',
        'automations:can_get_automations_actions',
        'automations:can_post_automations_actions',
        'automations:can_delete_automations_actions',
        'automations:can_get_automations_criterion',
        'automations:can_post_automations_criterion',
        'automations:can_delete_automations_criterion',
        'automations:can_get_automations_reports_actions',
        'campaigns:can_get_campaigns',
        'campaigns:can_post_campaigns',
        'campaigns:can_delete_campaigns',
        'campaigns:can_get_campaigns_history',
        'campaigns:can_post_campaigns_history',
        'campaigns:can_delete_campaigns_history',
        'campaigns:can_get_campaigns_reports_contact_per_campaign',
        'campaigns:can_get_campaigns_reports_contact_per_channel',
        'campaigns:can_get_campaigns_reports_sales_per_channel',
        'campaigns:can_get_campaigns_reports_sales_per_campaign',
        'campaigns:can_get_campaigns_reports_average_cpa',
        'domains:can_get_domains',
        'domains:can_post_domains',
        'domains:can_delete_domains',
        'emailtemplates:can_get_emailtemplates',
        'emailtemplates:can_post_emailtemplates',
        'emailtemplates:can_delete_emailtemplates',
        'emailtemplates:can_get_emailtemplates_reports_sent',
        'emailtemplates:can_get_emailtemplates_reports_open',
        'fields:can_get_fields_types',
        'fields:can_get_fields',
        'fields:can_post_fields',
        'fields:can_delete_fields',
        'filters:can_get_filters',
        'filters:can_post_filters',
        'filters:can_delete_filters',
        'filters:can_get_filters_criteria',
        'filters:can_post_filters_criteria',
        'filters:can_delete_filters_criteria',
        'forms:can_get_forms',
        'forms:can_post_forms',
        'forms:can_delete_forms',
        'forms:can_get_forms_history',
        'forms:can_post_forms_history',
        'forms:can_delete_forms_history',
        'forms:can_get_forms_reports_contacts_submissions',
        'groups:can_get_groups',
        'groups:can_post_groups',
        'groups:can_delete_groups',
        'groups:can_get_groups_privileges',
        'groups:can_post_groups_privileges',
        'plans:can_get_plans',
        'plans:can_post_plans',
        'plans:can_delete_plans',
        'plans:can_get_plans_privileges',
        'plans:can_post_plans_privileges',
        'leadprices:can_get_leadprices',
        'leadprices:can_post_leadprices',
        'leadprices:can_delete_leadprices',
        'notes:can_get_notes',
        'notes:can_post_notes',
        'notes:can_delete_notes',
        'privatelabels:can_get_privatelabels',
        'privatelabels:can_get_privatelabels_my',
        'privatelabels:can_get_privatelabels_user',
        'privatelabels:can_post_privatelabels',
        'privatelabels:can_delete_privatelabels',
        'privileges:can_get_privileges',
        'refundmoderate:can_get_refundmoderate',
        'refundmoderate:can_post_refundmoderate_approve',
        'refundmoderate:can_post_refundmoderate_deny',
        'refundreasons:can_get_refundreasons',
        'refundreasons:can_get_refundreasons_parent',
        'refundreasons:can_post_refundreasons',
        'refundreasons:can_delete_refundreasons',
        'roles:can_get_roles',
        'roles:can_post_roles',
        'roles:can_delete_roles',
        'statuses:can_get_statuses',
        'statuses:can_post_statuses',
        'statuses:can_post_statuses_pipeline',
        'statuses:can_delete_statuses',
        'statuses:can_get_statuses_reports_status',
        'statuses:can_get_statuses_reports_substatus',
        'statuses:can_get_statuses_reports_sales_pipeline',
        'statuses:can_get_statuses_history',
        'statuses:can_post_statuses_history',
        'statuses:can_delete_statuses_history',
        'templatelegends:can_get_templatelegends',
        'users:can_get_users',
        'users:can_get_users_my',
        'users:can_get_users_settings',
        'users:can_get_users_my_settings',
        'users:can_post_users',
        'users:can_post_users_settings',
        'users:can_post_users_my_settings',
        'users:can_delete_users',
        'users:can_get_users_reports_sales',
        'users:can_get_users_privileges',
        'users:can_post_users_privileges',
        'users:can_get_users_privileges_my',
        'visitors:can_get_visitors',
        'visitors:can_post_visitors_pageviews',
        'visitors:can_post_visitors',
        'visitors:can_delete_visitors',
        'visitors:can_delete_visitors_campaigns',
        'visitors:can_get_visitors_reports_count',
        'visitors:can_get_visitors_reports_average_conversion_rate',
        'webhooks:can_get_webhooks',
        'webhooks:can_post_webhooks',
        'webhooks:can_delete_webhooks',
        'events:can_get_events',
        'events:can_get_events_communication_settings',
        'events:can_post_events',
        'events:can_post_events_communication_settings',
        'events:can_delete_events',
        'events:can_get_events_history',
        'events:can_post_events_history',
        'events:can_delete_events_history',
        'events:can_get_events_reports_count_per_id',
        'tests:can_get_tests_experiments',
        'tests:can_get_tests',
        'tests:can_post_tests',
        'tests:can_post_tests_reset',
        'tests:can_get_tests_reports_total_conversion_rate',
        'tests:can_get_tests_reports_visits',
        'tests:can_get_tests_reports_conversion_rate',
        'tests:can_get_tests_reports_visits_to_conversion_rate',
        'phonenumbers:can_get_phonenumbers',
        'phonenumbers:can_get_phonenumbers_global_settings',
        'phonenumbers:can_get_phonenumbers_my_global_settings',
        'phonenumbers:can_post_phonenumbers',
        'phonenumbers:can_post_phonenumbers_deactivate',
        'phonenumbers:can_post_phonenumbers_reactivate',
        'phonenumbers:can_post_phonenumbers_release',
        'phonenumbers:can_post_phonenumbers_global_settings',
        'phonenumbers:can_delete_phonenumbers',
        'callschedules:can_get_callschedules',
        'callschedules:can_post_callschedules',
        'callschedules:can_delete_callschedules',
        'smstemplates:can_get_smstemplates',
        'smstemplates:can_get_smstemplates_sender_phone_number',
        'smstemplates:can_post_smstemplates',
        'smstemplates:can_post_smstemplates_sender_phone_number',
        'smstemplates:can_delete_smstemplates',
        'smstemplates:can_delete_smstemplates_release_phone_number',
        'calls:can_get_calls',
        'calls:can_get_calls_lead_buying_settings',
        'calls:can_post_calls',
        'calls:can_post_calls_lead_buying_settings',
        'calls:can_delete_calls',
        'calls:can_get_calls_filters',
        'calls:can_post_calls_filters',
        'calls:can_delete_calls_filters',
        'calls:can_get_calls_reports_count',
        'calls:can_get_calls_reports_inbound',
        'calls:can_get_calls_reports_revenue',
        'calls:can_get_calls_reports_average_duration',
        'email:can_get_email_credentials',
        'email:can_delete_email_credentials',
        'email:can_post_email_credentials',
        'email:can_post_email_credentials_verify',
        'users:can_post_users_my',
      ]
    },
    {
      'name': 'Lead Buyer', 'id': '3', privileges: [
        'contacts:can_get_contacts',
        'followups:can_get_followups',
        'contacts:can_get_contacts_purchase',
        'contacts:can_post_contacts_purchase_refund',
        'accounts:can_get_accounts_my',
        'accounts:can_get_accounts_notes',
        'groups:can_get_groups',
        'leadprices:can_get_leadprices',
        'privatelabels:can_get_privatelabels_my',
        'refundmoderate:can_get_refundmoderate',
        'refundreasons:can_get_refundreasons',
        'refundreasons:can_get_refundreasons_parent',
        'refundreasons:can_post_refundreasons',
        'smstemplates:can_get_smstemplates_sender_phone_number',
        'users:can_get_users',
        'users:can_get_users_my',
        'users:can_post_users_my',
        'users:can_get_users_my_settings',
        'users:can_post_users_my_settings',
        'users:can_get_users_privileges',
        'users:can_get_users_privileges_my',
        'events:can_get_events',
        // Billing
        'accounts:can_get_accounts_invoices',
        'accounts:can_get_accounts_my_billdetails',
        'accounts:can_get_accounts_creditcard',
        'accounts:can_get_accounts_my_creditcard',
        'accounts:can_get_accounts_stripe',
        'accounts:can_get_accounts_billdetails',
        'accounts:can_post_accounts_stripe',
        'accounts:can_post_accounts_stripe_customer',
        'accounts:can_get_accounts_my_invoices',
        'accounts:can_post_accounts_invoices'
      ]
    },
    {
      'name': 'Sales User', 'id': '4', privileges: [
        'contacts:can_get_contacts',
        'followups:can_get_followups',
        'contacts:can_get_contacts_history',
        'contacts:can_get_contacts_notes',
        'contacts:can_post_contacts',
        'contacts:can_post_contacts_event',
        'contacts:can_post_contacts_actions',
        'contacts:can_get_contacts_filters',
        'contacts:can_post_contacts_filters',
        'contacts:can_delete_contacts_filters',
        'contacts:can_get_contacts_imports_queue',
        'contacts:can_post_contacts_imports',
        'contacts:can_get_contacts_imports_template',
        'contacts:can_get_contacts_reports_count',
        'contacts:can_get_contacts_reports_sold',
        'contacts:can_get_contacts_reports_captured',
        'contacts:can_get_contacts_reports_income_per_contact',
        'contacts:can_get_contacts_reports_contact_value',
        'contacts:can_get_contacts_reports_new',
        'contacts:can_get_contacts_reports_sold_times',
        'contacts:can_get_contacts_reports_sales',
        'contacts:can_get_contacts_reports_count_per_field_type',
        'contacts:can_get_contacts_reports_total_conversion_rate',
        'contacts:can_get_contacts_reports_count_per_city',
        'files:can_get_files',
        'files:can_get_files_download',
        'files:can_post_files',
        'files:can_delete_files',
        'accounts:can_get_accounts_my',
        'actions:can_run_actions_email_contact',
        'actions:can_run_actions_email_address',
        'actions:can_run_actions_email_assigned_user',
        'actions:can_run_actions_email_specific_user',
        'actions:can_run_actions_sms_action_type_number',
        'actions:can_run_actions_post',
        'actions:can_run_actions_field_value',
        'actions:can_run_actions_event',
        'actions:can_run_actions_assign_user_specific_user',
        'actions:can_run_actions_assign_user_org_queue',
        'actions:can_run_actions_assign_user_org_queue_in',
        'campaigns:can_get_campaigns',
        'campaigns:can_get_campaigns_history',
        'campaigns:can_post_campaigns_history',
        'campaigns:can_delete_campaigns_history',
        'campaigns:can_get_campaigns_reports_contact_per_campaign',
        'campaigns:can_get_campaigns_reports_contact_per_channel',
        'campaigns:can_get_campaigns_reports_sales_per_channel',
        'campaigns:can_get_campaigns_reports_sales_per_campaign',
        'campaigns:can_get_campaigns_reports_average_cpa',
        'domains:can_get_domains',
        'emailtemplates:can_get_emailtemplates',
        'emailtemplates:can_get_emailtemplates_reports_sent',
        'emailtemplates:can_get_emailtemplates_reports_open',
        'fields:can_get_fields_types',
        'fields:can_get_fields',
        'filters:can_get_filters',
        'filters:can_post_filters',
        'filters:can_delete_filters',
        'filters:can_get_filters_criteria',
        'filters:can_post_filters_criteria',
        'filters:can_delete_filters_criteria',
        'forms:can_get_forms',
        'forms:can_get_forms_history',
        'forms:can_post_forms_history',
        'forms:can_delete_forms_history',
        'forms:can_get_forms_reports_contacts_submissions',
        'notes:can_get_notes',
        'notes:can_post_notes',
        'notes:can_delete_notes',
        'privatelabels:can_get_privatelabels_my',
        'privileges:can_get_privileges',
        'statuses:can_get_statuses',
        'statuses:can_post_statuses_pipeline',
        'statuses:can_get_statuses_reports_status',
        'statuses:can_get_statuses_reports_substatus',
        'statuses:can_get_statuses_reports_sales_pipeline',
        'statuses:can_get_statuses_history',
        'statuses:can_post_statuses_history',
        'statuses:can_delete_statuses_history',
        'templatelegends:can_get_templatelegends',
        'users:can_get_users',
        'users:can_post_users_my',
        'users:can_get_users_my',
        'users:can_get_users_my_settings',
        'users:can_post_users_my_settings',
        'users:can_get_users_privileges_my',
        'visitors:can_get_visitors',
        'visitors:can_post_visitors_pageviews',
        'visitors:can_post_visitors',
        'visitors:can_delete_visitors',
        'visitors:can_delete_visitors_campaigns',
        'visitors:can_get_visitors_reports_count',
        'visitors:can_get_visitors_reports_average_conversion_rate',
        'webhooks:can_get_webhooks',
        'events:can_get_events',
        'events:can_get_events_communication_settings',
        'events:can_get_events_history',
        'events:can_post_events_history',
        'events:can_delete_events_history',
        'events:can_get_events_reports_count_per_id',
        'tests:can_get_tests_experiments',
        'tests:can_get_tests',
        'tests:can_get_tests_reports_total_conversion_rate',
        'tests:can_get_tests_reports_visits',
        'tests:can_get_tests_reports_conversion_rate',
        'tests:can_get_tests_reports_visits_to_conversion_rate',
        'phonenumbers:can_get_phonenumbers',
        'phonenumbers:can_get_phonenumbers_global_settings',
        'phonenumbers:can_get_phonenumbers_my_global_settings',
        'phonenumbers:can_delete_phonenumbers',
        'smstemplates:can_get_smstemplates',
        'smstemplates:can_get_smstemplates_sender_phone_number',
        'calls:can_get_calls',
        'calls:can_get_calls_filters',
        'calls:can_post_calls_filters',
        'calls:can_delete_calls_filters',
        'calls:can_get_calls_reports_count',
        'calls:can_get_calls_reports_inbound',
        'calls:can_get_calls_reports_revenue',
        'calls:can_get_calls_reports_average_duration',
        'email:can_get_email_credentials',
        'email:can_delete_email_credentials',
        'email:can_post_email_credentials',
        'email:can_post_email_credentials_verify'
      ]
    }, {
      'name': 'Marketing Manager', 'id': '5', privileges: [
        'contacts:can_get_contacts',
        'followups:can_get_followups',
        'contacts:can_get_contacts_history',
        'contacts:can_get_contacts_notes',
        'contacts:can_post_contacts',
        'contacts:can_post_contacts_event',
        'contacts:can_post_contacts_actions',
        'contacts:can_get_contacts_filters',
        'contacts:can_post_contacts_filters',
        'contacts:can_delete_contacts_filters',
        'contacts:can_get_contacts_imports_queue',
        'contacts:can_post_contacts_imports',
        'contacts:can_get_contacts_imports_template',
        'contacts:can_get_contacts_reports_count',
        'contacts:can_get_contacts_reports_sold',
        'contacts:can_get_contacts_reports_captured',
        'contacts:can_get_contacts_reports_income_per_contact',
        'contacts:can_get_contacts_reports_contact_value',
        'contacts:can_get_contacts_reports_new',
        'contacts:can_get_contacts_reports_sold_times',
        'contacts:can_get_contacts_reports_sales',
        'contacts:can_get_contacts_reports_count_per_field_type',
        'contacts:can_get_contacts_reports_total_conversion_rate',
        'contacts:can_get_contacts_reports_count_per_city',
        'files:can_get_files',
        'files:can_get_files_download',
        'files:can_post_files',
        'files:can_delete_files',
        'accounts:can_get_accounts_my',
        'accounts:can_get_accounts_my_billdetails',
        'accounts:can_delete_accounts_my_notes',
        'accounts:can_get_accounts_my_settings',
        'accounts:can_get_accounts_notes',
        'accounts:can_get_accounts',
        'accounts:can_post_accounts_clientlogin',
        'accounts:can_post_accounts_settings',
        'accounts:can_post_accounts_cancel',
        'accounts:can_post_accounts_restore',
        'accounts:can_post_accounts',
        'accounts:can_post_accounts_creditcard',
        'accounts:can_post_accounts_stripe',
        'accounts:can_delete_accounts',
        'accounts:can_delete_accounts_notes',
        'accounts:can_get_accounts_invoices',
        'accounts:can_post_accounts_invoices',
        'accounts:can_delete_accounts_invoices',
        'accounts:can_get_accounts_reports_receivables',
        'accounts:can_get_accounts_reports_deposited_amount',
        'accounts:can_get_accounts_reports_sales_value',
        'accounts:can_get_accounts_reports_new',
        'accounts:can_get_accounts_privileges',
        'accounts:can_post_accounts_privileges',
        'actions:can_run_actions_reseller_pricing',
        'actions:can_run_actions_reseller_specific',
        'actions:can_run_actions_reseller_org_all',
        'actions:can_run_actions_reseller_group_specific_all',
        'actions:can_run_actions_reseller_group_queue',
        'actions:can_run_actions_reseller_org_queue',
        'actions:can_run_actions_email_contact',
        'actions:can_run_actions_email_address',
        'actions:can_run_actions_email_assigned_user',
        'actions:can_run_actions_email_specific_user',
        'actions:can_run_actions_sms_action_type_number',
        'actions:can_run_actions_post',
        'actions:can_run_actions_field_value',
        'actions:can_run_actions_event',
        'actions:can_run_actions_assign_user_specific_user',
        'actions:can_run_actions_assign_user_org_queue',
        'actions:can_run_actions_assign_user_org_queue_in',
        'automations:can_get_automations',
        'automations:can_post_automations',
        'automations:can_delete_automations',
        'automations:can_get_automations_actions',
        'automations:can_post_automations_actions',
        'automations:can_delete_automations_actions',
        'automations:can_get_automations_criterion',
        'automations:can_post_automations_criterion',
        'automations:can_delete_automations_criterion',
        'automations:can_get_automations_reports_actions',
        'campaigns:can_get_campaigns',
        'campaigns:can_post_campaigns',
        'campaigns:can_delete_campaigns',
        'campaigns:can_get_campaigns_history',
        'campaigns:can_post_campaigns_history',
        'campaigns:can_delete_campaigns_history',
        'campaigns:can_get_campaigns_reports_contact_per_campaign',
        'campaigns:can_get_campaigns_reports_contact_per_channel',
        'campaigns:can_get_campaigns_reports_sales_per_channel',
        'campaigns:can_get_campaigns_reports_sales_per_campaign',
        'campaigns:can_get_campaigns_reports_average_cpa',
        'domains:can_get_domains',
        'domains:can_post_domains',
        'domains:can_delete_domains',
        'emailtemplates:can_get_emailtemplates',
        'emailtemplates:can_post_emailtemplates',
        'emailtemplates:can_delete_emailtemplates',
        'emailtemplates:can_get_emailtemplates_reports_sent',
        'emailtemplates:can_get_emailtemplates_reports_open',
        'fields:can_get_fields_types',
        'fields:can_get_fields',
        'fields:can_post_fields',
        'fields:can_delete_fields',
        'filters:can_get_filters',
        'filters:can_post_filters',
        'filters:can_delete_filters',
        'filters:can_get_filters_criteria',
        'filters:can_post_filters_criteria',
        'filters:can_delete_filters_criteria',
        'forms:can_get_forms',
        'forms:can_post_forms',
        'forms:can_delete_forms',
        'forms:can_get_forms_history',
        'forms:can_post_forms_history',
        'forms:can_delete_forms_history',
        'forms:can_get_forms_reports_contacts_submissions',
        'plans:can_get_plans',
        'plans:can_post_plans',
        'plans:can_delete_plans',
        'plans:can_get_plans_privileges',
        'leadprices:can_get_leadprices',
        'leadprices:can_post_leadprices',
        'leadprices:can_delete_leadprices',
        'notes:can_get_notes',
        'notes:can_post_notes',
        'notes:can_delete_notes',
        'privatelabels:can_get_privatelabels_my',
        'privileges:can_get_privileges',
        'refundmoderate:can_get_refundmoderate',
        'refundmoderate:can_post_refundmoderate_approve',
        'refundmoderate:can_post_refundmoderate_deny',
        'refundreasons:can_get_refundreasons',
        'refundreasons:can_get_refundreasons_parent',
        'refundreasons:can_post_refundreasons',
        'refundreasons:can_delete_refundreasons',
        'statuses:can_get_statuses',
        'statuses:can_post_statuses',
        'statuses:can_post_statuses_pipeline',
        'statuses:can_delete_statuses',
        'statuses:can_get_statuses_reports_status',
        'statuses:can_get_statuses_reports_substatus',
        'statuses:can_get_statuses_reports_sales_pipeline',
        'statuses:can_get_statuses_history',
        'statuses:can_post_statuses_history',
        'statuses:can_delete_statuses_history',
        'templatelegends:can_get_templatelegends',
        'users:can_get_users',
        'users:can_get_users_my',
        'users:can_get_users_my_settings',
        'users:can_post_users_my_settings',
        'users:can_get_users_privileges_my',
        'visitors:can_get_visitors',
        'visitors:can_post_visitors_pageviews',
        'visitors:can_post_visitors',
        'visitors:can_delete_visitors',
        'visitors:can_delete_visitors_campaigns',
        'visitors:can_get_visitors_reports_count',
        'visitors:can_get_visitors_reports_average_conversion_rate',
        'webhooks:can_get_webhooks',
        'webhooks:can_post_webhooks',
        'webhooks:can_delete_webhooks',
        'events:can_get_events',
        'events:can_get_events_communication_settings',
        'events:can_post_events',
        'events:can_post_events_communication_settings',
        'events:can_delete_events',
        'events:can_get_events_history',
        'events:can_post_events_history',
        'events:can_delete_events_history',
        'events:can_get_events_reports_count_per_id',
        'tests:can_get_tests_experiments',
        'tests:can_get_tests',
        'tests:can_post_tests',
        'tests:can_post_tests_reset',
        'tests:can_get_tests_reports_total_conversion_rate',
        'tests:can_get_tests_reports_visits',
        'tests:can_get_tests_reports_conversion_rate',
        'tests:can_get_tests_reports_visits_to_conversion_rate',
        'phonenumbers:can_get_phonenumbers',
        'phonenumbers:can_get_phonenumbers_global_settings',
        'phonenumbers:can_get_phonenumbers_my_global_settings',
        'phonenumbers:can_post_phonenumbers',
        'phonenumbers:can_post_phonenumbers_deactivate',
        'phonenumbers:can_post_phonenumbers_reactivate',
        'phonenumbers:can_post_phonenumbers_release',
        'phonenumbers:can_post_phonenumbers_global_settings',
        'phonenumbers:can_delete_phonenumbers',
        'callschedules:can_get_callschedules',
        'callschedules:can_post_callschedules',
        'callschedules:can_delete_callschedules',
        'smstemplates:can_get_smstemplates',
        'smstemplates:can_get_smstemplates_sender_phone_number',
        'smstemplates:can_post_smstemplates',
        'smstemplates:can_post_smstemplates_sender_phone_number',
        'smstemplates:can_delete_smstemplates',
        'smstemplates:can_delete_smstemplates_release_phone_number',
        'calls:can_get_calls',
        'calls:can_get_calls_lead_buying_settings',
        'calls:can_post_calls',
        'calls:can_post_calls_lead_buying_settings',
        'calls:can_get_calls_filters',
        'calls:can_post_calls_filters',
        'calls:can_delete_calls_filters',
        'calls:can_get_calls_reports_count',
        'calls:can_get_calls_reports_inbound',
        'calls:can_get_calls_reports_revenue',
        'calls:can_get_calls_reports_average_duration',
        'email:can_get_email_credentials',
        'email:can_delete_email_credentials',
        'email:can_post_email_credentials',
        'email:can_post_email_credentials_verify',
        'users:can_post_users_my',
      ]
    }
  ];
