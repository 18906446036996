import { Injectable } from '@angular/core';
import 'rxjs/add/operator/map';
import { Router } from '@angular/router';
import { CustomHttp } from '@service/customhttp.service';
import { RequestLibraryService } from '@service/core/request-library.service';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class RoleService {
    private customHttp: CustomHttp;
    constructor(
        private http: HttpClient,
        private router: Router,
        private requestLibrary: RequestLibraryService
    ) {
        this.customHttp = new CustomHttp(http, router);
    }

    storePrivilege(role: number, privileges: any) {
        return this.requestLibrary.Post(`/roles/${role}/privileges`, privileges);
    }

    getPrivilege(role: number) {
        return this.requestLibrary.Get(`/roles/${role}/privileges`);
    }

    getRoles() {
        return this.requestLibrary.GetAll('/roles', {
            'options': { page_size: 'all', is_compact: true }
        });
    }

    getFullRoles() {
        return this.requestLibrary.GetAll('/roles', {
            'options': { page_size: 'all' }
        });
    }


    getPaginatedRoles(page, page_size, sort, sort_dir) {
        return this.requestLibrary.GetAll('/roles', {
            'options': { page_size: 'all', sort: sort, sort_dir: sort_dir }
        });
    }

    getExportedRoles(args) {
        return this.requestLibrary.GetCSV('/roles', {
            'options': args
        });
    }

    getRoleDetail(id) {
        return this.requestLibrary.Get('/roles/' + id);
    }

    deleteUserRole(id) {
        return this.requestLibrary.Delete('/roles/' + id);
    }

    addEditRoles(model) {
        return this.requestLibrary.Post('/roles', model);
    }
}
