<!-- <div class="content-heading">
  <i class="fa fa-times pull-right modalcloseicon" aria-hidden="true" (click)="cancelDialog()"></i>
  <h4>Template Legend</h4>
</div> -->
<div class="container-fluid">
    <div class="content-data">
        <ul class="nav nav-pills mv-lg">
            <li [ngClass]="{active: activeTab==='contact'}"><a class="pv-sm" (click)="loadLegends('contact')">Contact</a></li>
            <li [ngClass]="{active: activeTab==='sender'}"><a class="pv-sm" (click)="loadLegends('sender')">Account/Sender</a></li>
            <li [ngClass]="{active: activeTab==='roles'}"><a class="pv-sm" (click)="loadLegends('roles')">Assigned User</a></li>
            <li [ngClass]="{active: activeTab==='fields'}"><a class="pv-sm" (click)="loadLegends('fields')">All Fields</a></li>
        </ul>
        <div *ngIf="selectedLegend.length < 1" class="whirl standard spinnermargin"></div>
        <div class="row legend-row">
            <ng-template ngFor let-legend [ngForOf]="selectedLegend" let-i="index">
                <div *ngIf="activeTab == 'contact' || activeTab == 'sender'" class="col-sm-3 legend-row-data">{{legend.name}}</div>
                <div *ngIf="activeTab == 'contact' || activeTab == 'sender'" class="col-sm-3 legend-row-data">{{legend.template}}</div>
                <div *ngIf="activeTab == 'roles' || activeTab == 'fields'" class="col-sm-3 legend-row-data">{{legend.legend.name}}</div>
                <div *ngIf="activeTab == 'roles' || activeTab == 'fields'" class="col-sm-3 legend-row-data">{{legend.legend.template}}</div>
            </ng-template>
        </div>
    </div>
</div>
   

