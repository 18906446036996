import { Injectable } from '@angular/core';
@Injectable()
export class UserContextService {
  constructor(
  ) {
  }
  user() {
    return +(localStorage.getItem('User') || '');
  }
  privileges() {
    const privileges = localStorage.getItem('userPrivileges') || '[]';
    return JSON.parse(privileges);
  }

}
