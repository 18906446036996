<div class="content-heading" *ngIf="!isInlineComponent">
    <div>
        <h4>Apply Filter</h4>
    </div>
    <div>
        <i class="fa fa-times modalcloseicon" aria-hidden="true" (click)="cancelDialog()"></i>
    </div>
</div>
<!--<div *ngIf="!isPricesLoaded && !isFormsLoaded" class="whirl standard spinnermargin"></div>-->
<div class="container-fluid">
  <div class="content-data">
         <form name="filtersForm" (ngSubmit)="setFilters()" #FiltersForm="ngForm" novalidate>
            <div class="row">
                <div *ngIf="isStatusFilterExist" class="col-xs-12 col-sm-6 form-group">
                    <label>Status</label>
                    <select class="form-control w-100" #status name="status" [(ngModel)]="model.status">
                        <option value="2">All</option>    
                        <option value="1">Active</option>
                        <option value="0">Deactivated</option>
                    </select>
                </div>
                
                <div *ngIf="isCallTrackingStatusFilterExist" class="col-xs-12 col-sm-6 form-group">
                    <label>Status</label>
                        <select class="form-control w-100"  name="call_tracking_status" [(ngModel)]="model.call_tracking_status">
                        <option value="">All</option>    
                        <option value="Active">Active</option>
                        <option value="Deactivated">Deactivated</option>
                        <option value="Released">Released</option> 
                    </select>
                </div>

                <div *ngIf="isArchiveFilterExist" class="col-xs-12 col-sm-6 form-group">
                    <label>Archive Status</label>
                        <select class="form-control w-100" #status name="status" [(ngModel)]="model.is_archived">
                        <option value="2">Both</option>    
                        <option value="1">Archived</option>
                        <option value="0">Not Archived</option>
                    </select>
                </div>
                <div *ngIf="isLeadTypeExist" class="col-xs-12 col-sm-6 form-group">
                    <label>Lead Type</label>
                        <select class="form-control w-100" #leadType name="lead_type" [(ngModel)]="model.lead_type">
                        <option value="">All</option>
                        <option value="contact">Web Form</option>
                        <option value="call">Call</option>
                    </select>
                </div>
                <div *ngIf="isLeadPriceStatusExist" class="col-xs-12 col-sm-6 form-group">
                    <label>Status</label>
                        <select class="form-control w-100" #leadPriceStatus name="is_active" [(ngModel)]="model.is_active">
                        <option value="2">All</option>    
                        <option value="1">Activate</option>
                        <option value="0">Deactivate</option>
                    </select>
                </div>
                 <div *ngIf="isSearchFilterExist" class="col-xs-12 col-sm-6 form-group">
                    <label>Search</label>
                    <input class="form-control w-100" #search name="search" type="text" placeholder="Search" [(ngModel)]="model.search" (ngModelChange)="trim_search_input()" />
                </div>
                
                <div *ngIf="isCallTrackingFallbackFilterExist" class="col-xs-12 col-sm-6 form-group">
                    <label>Fallback</label>
                    <input class="form-control w-100" name="call_tracking_fallback" type="text" placeholder="Fallback" [(ngModel)]="model.call_tracking_fallback"/>
                </div>

                <div *ngIf="!leadPricingsLoaded" class="whirl standard spinnermargin"></div>
                <div *ngIf="leadPricings.length > 0 && leadPricingsLoaded" class="col-xs-12 col-sm-6 form-group">
                    <label>Lead Price</label>
                    <select class="form-control w-100" #leadsPricings name="leadsPricings" [(ngModel)]="model.price.id" (change)="onSelectChange($event)">
                        <option value="">Any Price</option>
                        <option *ngFor="let price of leadPricings" [value]="price.id">{{price.price | currency:'usd'}} - {{price.name}}</option>
                    </select>
                </div>
                <div *ngIf="!rolesLoaded" class="whirl standard spinnermargin"></div>
                <div *ngIf="rolesArray.length > 0 && rolesLoaded" class="col-xs-12 col-sm-6 form-group">
                    <label>Roles</label>
                    <select class="form-control w-100" #roles name="roles" [(ngModel)]="model.role.id" (change)="onSelectChange($event)">
                        <option value="" [disabled]="true">Any Role</option>
                        <option *ngFor="let role of rolesArray" [value]="role.id">{{role.name}}</option>
                    </select>
                </div>
                <!-- <div *ngIf="plansLoaded" class="whirl standard spinnermargin"></div> -->
                <div *ngIf="plansArray.length > 0 && plansLoaded" class="col-xs-12 col-sm-6 form-group">
                    <label>Plans</label>
                    <select class="form-control w-100" #plans name="plans" [(ngModel)]="model.plan.id" (change)="onSelectChange($event)">
                        <option value="">Any Plan</option>
                        <option *ngFor="let plan of plansArray" [value]="plan.id">{{plan.name}}</option>
                    </select>
                </div>
                <div *ngIf="groupsArray.length > 0 && groupsLoaded" class="col-xs-12 col-sm-6 form-group">
                    <label>Groups</label>
                    <select class="form-control w-100" #plans name="groups" [(ngModel)]="model.group.id" (change)="onSelectChange($event)">
                        <option value="" [disabled]="true">Select Group</option>
                        <option *ngFor="let group of groupsArray" [value]="group.id">{{group.name}}</option>
                    </select>
                </div>
                <div *ngIf="isChannelExist" class="col-xs-12 col-sm-6 form-group">
                    <label>Channel</label>
                    <select class="form-control w-100" #roles name="roles" [(ngModel)]="model.channel.id" (change)="onSelectChange($event)">
                        <option value="">Select Any Channel</option>
                        <option *ngFor="let channel of channels" [value]="channel[0]">{{channel[1]}}</option>
                    </select>
                </div>
                <div *ngIf="!callTrackingScheduleLoaded" class="ball-clip-rotate spinnerinputmargin"></div>
                <div *ngIf="isCallTrackingScheduleFilterExist" class="col-xs-12 col-sm-6 form-group">
                    <label>Schedule </label>
                    <select class="form-control w-100" name="call_tracking_schedule" [(ngModel)]="model.call_tracking_schedule.id" (change)="onSelectChange($event)">
                        <option value="">Select Any Schedule</option>
                        <option *ngFor="let schedule of callTrackingScheduleArray"  [value]="schedule.id">{{schedule.name}}</option>
                    </select>
                </div>
            </div>
            <div *ngIf="!isSaving" class="row" style="margin-bottom: 20px;">
                <div class="col-xs-12 col-sm-6">
                    <button class="btn btn-default" type="button" (click)="resetFilters()">Reset</button>
                    <button class="btn btn-info" type="submit">Apply</button>
                </div>
                    <!-- <div class="col-sm-6">
                    <button class="btn btn-info" type="submit">Apply</button>
                </div> -->
            </div>
            <div *ngIf="isSaving" class="panel-footer text-center">
                <div class="whirl standard"></div>
            </div>       
        </form>
    </div>
</div>

