import { Injectable, Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'timelineTitle' })

export class FormatTimelineTitle implements PipeTransform {
    sep = '-';
    transform(title: string | number | Date, type: string) {
        let formattedTitle = title;
        switch (type) {
            case 'forms': {
                formattedTitle = 'Web Form Submitted' + ' ' + this.sep;
                break;
            }
            case 'campaigns': {
                formattedTitle = 'Campaign Touch' + ' ' + this.sep;
                break;
            }
            case 'notes': {
                formattedTitle = 'Note Added' + ' ' + this.sep;
                break;
            }
            case 'automations': {
                formattedTitle = 'Automation Executed' + ' ' + this.sep;
                break;
            }
            case 'logs': {
                formattedTitle = 'Log Entry' + ' ' + this.sep;
                break;
            }
            case 'statuses': {
                formattedTitle = 'Status Updated' + ' ' + this.sep;
                break;
            }
            case 'events': {
                formattedTitle = 'Event Occurred' + ' ' + this.sep;
                break;
            }
            case 'calls': {
                formattedTitle = 'Call Received' + ' ' + this.sep;
                break;
            }
            default: {
                formattedTitle = title;
                break;
            }
        }
        return formattedTitle;
    }
}
