<ng-template [ngxPermissionsOnly]="['contacts:can_get_contacts_reports_count_per_city']">
  <div class="box-row panel panel-default">
    <div class="panel-heading">
      <h4>New Customer Map</h4>
    </div>
    <div class="panel-body text-center">
      <div *ngIf="!patientsMapLoaded" class="whirl standard spinnermargin"></div>
      <ng-template [ngIf]="patientsMapLoaded">
        <app-map [seriesData]=seriesData [markersData]=markersData></app-map>
      </ng-template>
    </div>
  </div>
</ng-template>