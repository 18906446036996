import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import 'rxjs/add/operator/map';
import { globalValues } from '@app/globals';
import { CustomHttp } from '@service/customhttp.service';
import { RequestLibraryService } from '@service/core/request-library.service';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class PrivateLabelService {
    private customHttp: CustomHttp;
    constructor(
        private http: HttpClient,
        private router: Router,
        private requestLibrary: RequestLibraryService
    ) {
        this.customHttp = new CustomHttp(http, router);
    }

    getPaginatedPrivateLabels(page, page_size, sort, sort_dir) {
        return this.requestLibrary.GetAll('/privatelabels', {
            'options': { page: page, page_size: page_size, sort: sort, sort_dir: sort_dir }
        });
    }

    getAllPrivateLabels() {
        return this.requestLibrary.GetAll('/privatelabels', {
            'options': { page_size: 'all' }
        });
    }

    getExportedPrivateLabels(args) {
        return this.requestLibrary.GetCSV('/privatelabels', {
            'options': args
        });
    }

    deleteLabel(id) {
        return this.requestLibrary.Delete('/privatelabels/' + id);
    }

    getPrivateLabel(id) {
      // This can be an id or a host
        return this.requestLibrary.Get('/privatelabels/' + id);
    }

    addPrivateLabel(model) {
        return this.requestLibrary.Post('/privatelabels', model);
    }

    updatePrivateLabel(model) {
        return this.requestLibrary.Post('/privatelabels', model);
    }

    getMyPrivateLabels() {
        return this.requestLibrary.Get('/privatelabels/my');
    }

    getPrivateLabelUser(user_id) {
        return this.requestLibrary.Get(`/privatelabels/user/${user_id}`);
    }
}
