import { Component, OnInit, Input } from '@angular/core';
import { globalValues } from '@app/globals';

@Component({
  selector: 'donut-chart',
  templateUrl: './donut-chart.component.html'
})
export class DonutChartComponent implements OnInit {
  @Input() chartData: any;
  @Input() totalCount: any;
  @Input() chartLagend = false;

  // doughnutData = {
  //     labels: [
  //         'Open',
  //         'Closed - Won',
  //         'Non-Qual'
  //     ],
  //     datasets: [{
  //         data: [30, 50, 20]
  //     }]
  // };

  doughnutColors = [
    {
      borderColor: globalValues.chart_colors,
      backgroundColor: globalValues.chart_colors
    }
  ];

  doughnutOptions = {
    responsive: true
  };

  constructor() {}

  ngOnInit() {}
}
