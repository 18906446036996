<toaster-container [toasterconfig]="toasterconfig"></toaster-container>
<div class="col-sm-6 col-xs-10 col-lg-3 center-div">
    <div class="panel panel-default">
        <div class="panel-heading">Please enter your username. We will send you an email to reset your password.</div>
        <div class="panel-body">
            <form name="forgotPasswordForm" #ForgotPasswordForm="ngForm" class="form-validate" (submit)="submitForm()" novalidate="">
                <div class="form-group" style="margin-bottom: 50px;">
                    <div class="input-group">
                        <span class="input-group-addon"><i class="fa fa-user"></i></span>
                        <input class="form-control" type="text" name="username" #userName placeholder="Username" [(ngModel)]="model.username" required/>
                    </div>
                </div>
                <button *ngIf="!isSaving" class="btn btn-sm btn-default button-style" type="submit" [disabled]="!ForgotPasswordForm.form.valid">Send Email</button>
                <div *ngIf="isSaving" class="panel-footer text-center">
                    <div class="whirl standard"></div>
                </div>
            </form>
        </div>
    </div>
</div>
