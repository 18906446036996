import { Component, Input, OnChanges, OnInit, SimpleChange } from '@angular/core';
import { Privilege } from '@decorator/index';
import { AbTestReportService, ToasterLibraryService, CampaignReportService, ContactReportService } from '@service/index';
import { NgxPermissionsService } from 'ngx-permissions';
import { Logger } from '@service/global.funcs';
@Component({
  selector: 'app-cpa-widget',
  templateUrl: './cpa-widget.component.html',
  styleUrls: ['./cpa-widget.component.scss']
})

// @Privilege({
//   privilege: 'visitors:can_get_visitors_reports_average_conversion_rate'
// })
export class CpaWidgetComponent implements OnInit, OnChanges {
  @Input() relativeDate: any = '';
  @Input() campaignData: any;
  public isCpaLoaded = false;
  public costPerAcquisition = 0;
  public criteriaArray: Array<any> = [];

  constructor(
    private campaignReportService: CampaignReportService,
    private toasterLibrary: ToasterLibraryService,
    private permissionService: NgxPermissionsService
  ) { }

  ngOnInit() {
    if (this.permissionService.hasPermission('visitors:can_get_visitors_reports_average_conversion_rate')) {
      this.getCostPerAcquisition();
    }
  }

  ngOnChanges(changes: { [key: string]: SimpleChange }) {

    if (this.permissionService.hasPermission('visitors:can_get_visitors_reports_average_conversion_rate')) {
      if (
        (changes['relativeDate'] && !changes['relativeDate'].isFirstChange()) ||
        (changes['campaignData'] && !changes['campaignData'].isFirstChange()) ||
        (changes['criteriaArray'] && !changes['criteriaArray'].isFirstChange())
      ) {
        this.getCostPerAcquisition();
      }
    }

  }

  getCostPerAcquisition() {
    this.isCpaLoaded = false;
    const criteriaArray: any = [
      {
        id: '',
        is_required: true,
        criterion: 'dateRange',
        value: {
          start_date: '',
          end_date: '',
          relative_date: this.relativeDate
        }
      }
    ];
    
    if (this.campaignData && this.campaignData != "") {
      criteriaArray.push({
        'id': '',
        'value': { c_id: this.campaignData },
        'criterion': 'campaign',
        'is_required': true
      });
    }

    this.campaignReportService
      .getAvgCpa(criteriaArray)
      .subscribe((res: any) => {
        this.costPerAcquisition = parseInt(res.data, 10);
        this.isCpaLoaded = true;
      });
  }
}
