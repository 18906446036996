import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import "rxjs/add/operator/map";
import { globalValues } from "@app/globals";
import { CustomHttp } from "@service/customhttp.service";
import { RequestLibraryService } from "@service/core/request-library.service";
import { HttpClient } from "@angular/common/http";

@Injectable()
export class EmailService {
  private customHttp: CustomHttp;
  constructor(
    private http: HttpClient,
    private router: Router,
    private requestLibrary: RequestLibraryService
  ) {
    this.customHttp = new CustomHttp(http, router);
  }

  getCredentials(id) {
    return this.requestLibrary.Get("/email/credentials/" + id);
  }
  getVerifyCredentials(model) {
    return this.requestLibrary.Post("/email/credentials/verify", model);
  }

  updateCredentials(model) {
    return this.requestLibrary.Post("/email/credentials", model);
  }

  getPaginatedEmails(
    page,
    page_size,
    sort,
    sort_dir,
    direction,
    email_address,
    most_recent
  ) {
    return this.requestLibrary.GetAll("/email", {
      options: {
        page: page,
        page_size: page_size,
        sort: sort,
        sort_dir: sort_dir,
        direction: direction,
        recipient: email_address,
        most_recent: most_recent
      },
    });
  }

  getExportedEmails(args) {
    return this.requestLibrary.GetCSV("/email", {
      options: args,
    });
  }

  deleteEmail(model) {
    return this.requestLibrary.Post("/email/delete", model);
  }
  
  sendEmailToContact(model) {
    return this.requestLibrary.Post("/email/send-to-contact", model);
  }
  markRead(id, status) {
    return this.requestLibrary.Post("/email/read", {
      id: id,
      status: status,
    });
  }
  markReadBulk(model) {
    return this.requestLibrary.Post("/email/read", model);
  }
  
}
