import { Component, Input, OnChanges, OnInit, SimpleChange } from '@angular/core';
import { Privilege } from '@decorator/index';
import { ToasterLibraryService, CampaignReportService } from '@service/index';
import { NgxPermissionsService } from 'ngx-permissions';
import { Logger } from '@service/global.funcs';
import * as _ from 'lodash';

@Component({
  selector: 'app-conversion-rate-by-channel-bar-chart-widget',
  templateUrl: './conversion-rate-by-channel-bar-chart-widget.component.html',
  styleUrls: ['./conversion-rate-by-channel-bar-chart-widget.component.scss']
})

// @Privilege({
//   privilege: 'users:can_get_users_reports_sales'
// })
export class ConversionRateByChannelBarChartWidgetComponent implements OnInit, OnChanges {
  @Input() relativeDate: any;
  @Input() widgetTitle = 'Conversion Rate by Channel';
  @Input() chartAspectRatio: any;
  @Input() campaignData: any;

  public stackedBarChartData = [
    // {
    //   data: [],
    //   label: this.widgetTitle,
    //   color: '#1fafff'
    // }
  ];
  public barChartData = {
    labels: [],
    datasets: [
      {
        label: this.widgetTitle,
        data: []
      }
    ],
    name: this.widgetTitle,
    totalVisits: 0
  };
  public number_of_occurrences;
  public channelSalesLoaded = false;
  private total_number_occurrence = 5;
  constructor(
    private campaignReportService: CampaignReportService,
    private toasterLibrary: ToasterLibraryService,
    private permissionService: NgxPermissionsService
  ) { }

  ngOnInit() {
    if (this.permissionService.hasPermission('users:can_get_users_reports_sales')) {
      this.getByChannel();
    }
  }

  ngOnChanges(changes: { [key: string]: SimpleChange }) {
    if (this.permissionService.hasPermission('users:can_get_users_reports_sales')) {
      if (
        (changes['relativeDate'] && !changes['relativeDate'].isFirstChange()) ||
        (changes['campaignData'] && !changes['campaignData'].isFirstChange()) ||
        (changes['criteriaArray'] && !changes['criteriaArray'].isFirstChange())
      ) {
        this.getByChannel();
      }
    }
  }
  getByChannel() {
    const criteriaArray: any = [
      // {
      //   id: '',
      //   is_required: true,
      //   criterion: 'dateRange',
      //   value: {
      //     start_date: '',
      //     end_date: '',
      //     relative_date: this.relativeDate
      //   }
      // },
      {
        id: '',
        is_required: true,
        criterion: 'statusSubstatus',
        value: {
          ostat_id: 2,
          ss_id: 0
        }
      }
    ];

    if (this.campaignData && this.campaignData != "") {
      criteriaArray.push({
        'id': '',
        'value': { c_id: this.campaignData },
        'criterion': 'campaign',
        'is_required': true
      });
    }

    this.channelSalesLoaded = false;
    this.reInit();
    this.campaignReportService.getConversionRateByChannel(this.relativeDate, criteriaArray).subscribe(
      (res: any) => {
        Logger().info('getByChannel', 'res.data', res.data);
        if (res.success === 'true') {
          const byTotalVisitsTop10 = _.chain(res.data)
            .sortBy(function (item) { return item.total_visits * -1; })
            .slice(0, this.total_number_occurrence)
            .value();
          const forAggregation: any = _.chain(byTotalVisitsTop10)
            // Group the elements of Array based on `color` property
            .groupBy("channel")
            // `key` is group's name (color), `value` is the array of objects
            .map((value, key) => ({ channel: key, data: value }))
            .value();

          // console.log(forAggregation);
          // group by campaign

          forAggregation.forEach(record => {
            if (record.channel) {
              const stackData: any = {
                data: [],
                label: record.channel,
                // color: '#1fafff'
              }
              record.data.forEach(element => {
                const rate = parseInt(element.conversion_rate, 10);
                stackData.data.push([element.channel, rate]);
              });
              this.stackedBarChartData.push(stackData);
              // this.stackedBarChartData[0].data.push([record.campaign, parseInt(record.conversion_rate, 10)]);
            }
          });
          // this.stackedBarChartData.datasets =
          Logger().info('getByChannel', 'this.stackedBarChartData', this.stackedBarChartData);
        }
        this.channelSalesLoaded = true;
      },
      err => {
        this.toasterLibrary.error(null, err.error.message);
      }
    );
  }
  private reInit() {
    this.stackedBarChartData = [
      // {
      //   data: [],
      //   label: this.widgetTitle,
      //   color: '#1fafff'
      // }
    ];
  }
}
