import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { globalValues, themesArray } from '@app/globals';
import { Logger } from '@service/global.funcs';
import { AccountService, HelpersService, PrivateLabelService, ToasterLibraryService } from '@service/index';

@Component({
  selector: 'app-account-login',
  templateUrl: './account-login.component.html',
  styleUrls: ['./account-login.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class AccountLoginComponent implements OnInit {
  @Input() aConfig: any;
  private privateLables: any;
  constructor(
    private helperService: HelpersService,
    private privateLabelService: PrivateLabelService,
    private accountService: AccountService,
    private toasterLibrary: ToasterLibraryService
  ) { }

  ngOnInit() {
    Logger().info(this.aConfig);
  }

  _loginClientAs() {
    Logger().info(this.aConfig);
    this.accountService
      .loginClient({ id: this.aConfig.nAccountID, 'options': { 'expand': ['user'] } })
      .subscribe((res: any) => {
        if (res.success == 'true') {
          // this.pop('success', '', 'You are now logged in as ' + res.data.name);
          Logger().info(res);
          // this.accountService.appendUserId(res.data.id, res.data.account.id, res.data.account.name);
          // setTimeout(this.clientsService.appendUserId(res.data.id, res.data.account.id, res.data.account.name), 1500);
          // parse user id for login as feature.
          this.updatePrivateLabels(res.data.id, this.aConfig.nAccountID, res.data.account);
        } else {
          this.toasterLibrary.error(null, res.message);
        }
      }, err => {
        this.toasterLibrary.error(null, err.error.message);
      });
  }
  updatePrivateLabels(_as = null, client = null, account) {
    this.privateLabelService.getPrivateLabelUser(_as)
      .finally(() => {
        if (this.privateLables &&
          this.privateLables.interface_url &&
          this.privateLables.interface_url == window.location.origin
        ) {
          this.accountService.appendUserId(_as, account.id, account.name);
          if (this.privateLables.logo_url && this.privateLables.small_logo_url) {
            localStorage.setItem('logo', this.privateLables.logo_url);
            localStorage.setItem('small_logo', this.privateLables.small_logo_url);
          } else if (this.privateLables.logo_url && !this.privateLables.small_logo_url) {
            localStorage.setItem('logo', this.privateLables.logo_url);
            localStorage.setItem('small_logo', globalValues.small_logo);
          }
          else if (!this.privateLables.logo_url && this.privateLables.small_logo_url) {
            localStorage.setItem('logo', globalValues.logo);
            localStorage.setItem('small_logo', this.privateLables.small_logo_url);
          }

          if (this.privateLables.theme_name && this.privateLables.theme_name in themesArray) {
            localStorage.setItem('theme_name', this.privateLables.theme_name);
          }
          // else {
          //   console.log('updatePrivateLabels', 'Setting to Default theme', this.privateLables.theme_name, themesArray);
          //   localStorage.setItem('theme_name', 'A');
          // }
        }
        this.redirectAfterLoginUrl(_as, client);
      })
      .subscribe((res: any) => {
        if (res.success == 'true') {
          this.privateLables = res.data;
          // Logger().info('Private Label:', this.privateLables);
          // if (!this.privateLables.interface_url || this.privateLables.interface_url == '') {
          //     Logger().info('Interface url does not exist');
          //     this.pop('error', '', 'Please configure Interface URL for this Client.');
          // } else {
          //     Logger().info('Interface url does exist');
          //     this.pop('success', '', 'You are now logged in as ' + account.name);
          // }
        }

      }, err => {
        this.toasterLibrary.error(null, err.error.message);
      });
  }

  private redirectAfterLoginUrl(_as = null, client = null) {
    if (this.privateLables &&
      this.privateLables.interface_url &&
      this.privateLables.interface_url != window.location.origin
    ) {
      let _url = this.privateLables.interface_url;
      if (!(_as === null)) {
        _url = this.helperService.appendQueryString(_url, { '_as': _as, '_c': client });
      }
      window.location.href = _url;
      return;
    } else {
      let _url = window.location.origin;
      if (!(_as === null)) {
        _url = this.helperService.appendQueryString(_url, { '_as': _as, '_c': client });
      }
      Logger().info(_url);
      window.location.href = _url;
    }
    setTimeout(() => {
      location.reload();
    }, 3000);
    return;
  }
}

class AccountLoginAttr {
  accountID: number;
  class: string;
  redirectTo: string;
  constructor() { }
}
