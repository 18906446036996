import { DatePipe } from "@angular/common";
import { Component, Input, OnInit, SimpleChange } from "@angular/core";
import { Logger } from "@service/global.funcs";
import {
  ContactService,
  EmailService,
  ToasterLibraryService,
} from "@service/index";
import "rxjs/add/observable/of";

@Component({
  selector: "app-timeline-email",
  templateUrl: "./timeline-email.component.html",
  styleUrls: ['./timeline-email.component.scss'],
  providers: [DatePipe],
})
export class TimeLineEmailComponent implements OnInit {
  @Input() public contact_id: any;
  @Input() public timelineRefresh;
  timelineAlt = true;
  public timelineHistory: Array<any> = [];
  public isLoaded: Boolean = false;
  public tempDateContainer: string = "";
  constructor(
    private datePipe: DatePipe,
    private contactService: ContactService,
    private emailService: EmailService,
    private toasterLibrary: ToasterLibraryService
  ) {}

  ngOnInit() {
    this.getTimelineHistory();
  }
  ngOnChanges(changes: { [key: string]: SimpleChange }) {
    if (!changes["timelineRefresh"].isFirstChange()) {
      this.getTimelineHistory();
    }
  }
  getTimelineHistory() {
    this.contactService.getContactEmailTimeline(this.contact_id).subscribe(
      (res: any) => {
        if (res.success == "true") {
          this.isLoaded = true;
          this.timelineHistory = res.data.map((history) => {
            history.email.body = "<pre>" + history.email.body + "</pre>";
            return history;
          });
        }
      },
      (err) => {
        this.toasterLibrary.error(null, err.error.message);
      }
    );
  }

  checkDateGroup(date: string) {
    date = this.datePipe.transform(date, "yyyy-MM-dd");
    if (date != this.tempDateContainer) {
      this.tempDateContainer = date;
      return true;
    } else {
      return false;
    }
  }
}
