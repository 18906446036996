import { query } from '@angular/animations';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CustomHttp } from '@service/customhttp.service';
import { RequestLibraryService } from '@service/core/request-library.service';
import 'rxjs/add/operator/map';
import { Logger } from '@service/global.funcs';
import { HttpClient } from '@angular/common/http';
@Injectable()
export class ContactReportService {
  private customHttp: CustomHttp;
  constructor(private http: HttpClient, private router: Router, private requestLibrary: RequestLibraryService) {
    this.customHttp = new CustomHttp(http, router);
  }

  getEventCount(event_id: any) {
    return this.requestLibrary.GetAll('/events/reports/count-per-id', {
      options: { event_id: event_id },
      event_id: event_id
    });
  }

  getVobCount(criteriaArray, vob_event_id) {
    const vobEvent = {
      id: '',
      is_required: true,
      criterion: 'eventCountPerLead',
      value: {
        event: vob_event_id,
        hours: '999999',
        operator: 'GREATER',
        count: 0
      }
    };

    let returnArr = [];
    if (criteriaArray != null) {
      returnArr = JSON.parse(JSON.stringify(criteriaArray));
    }

    returnArr.push(vobEvent);
    return this.requestLibrary.GetAll('/contacts/reports/count', {
      options: {
        criteria: returnArr
      }
    });
  }

  getFilteredLeadsCount(criteriaArray) {
    return this.requestLibrary.GetAll('/contacts/reports/count', {
      options: {
        criteria: criteriaArray
      }
    });
  }

  // getContactSoldCount(criteriaArray) {
  //   Logger().info('getContactSoldCount', criteriaArray);

  // if (typeof criteriaArray === 'string') {
  //   criteriaArray = [
  //     {
  //       is_required: true,
  //       criterion: 'dateRange',
  //       value: {
  //         relative_date: criteriaArray
  //       }
  //     }
  //   ];
  // }

  // return this.requestLibrary.GetAll('/contacts/reports/sold', {
  //   options: {
  //     criteria: criteriaArray
  //   }
  // });

  // return this.requestLibrary
  //   .GetAll('/contacts/reports/sold?options=', { date_interval: filter })
  //   .map((res: any) => res.data)
  //   .map(arr => arr.reduce((a, b) => a + Number(b.sold_leads), 0));
  // }

  getContactLatest(since) {
    return this.requestLibrary.GetAll('/contacts/reports/new', {
      options: { date_interval: since, limit: 10, expand: ['contact'] }
    });
  }
  getRevenueGeneratedWidget() {
    return this.requestLibrary.Get('/contacts/reports/sales');
  }

  getRevenueFromContacts(criteriaArray) {
    // Replacement for getRevenueGeneratedWidget()

    return this.requestLibrary.GetAll('/contacts/reports/sales', {
      options: {
        criteria: criteriaArray
      }
    });
  }

  getCapturedContacts(criteriaArray) {
    return this.requestLibrary.GetAll('/contacts/reports/contacts', { options: { criteria: criteriaArray } });
  }

  getCapturedContactsCount(criteriaArray) {
    return this.requestLibrary.GetAll('/contacts/reports/captured', { options: { criteria: criteriaArray } });
  }
  getSoldContacts(criteriaArray, _query = "a") {
    if (!criteriaArray) {
      return this.requestLibrary.Get('/contacts/reports/sold');
    } else {
      return this.requestLibrary.GetAll('/contacts/reports/sold', { _: _query, options: { criteria: criteriaArray } });
    }
  }

  getLeadsSold(filter) {
    return this.requestLibrary.GetAll('/contacts/reports/soldcontacts', { options: { date_interval: filter } });
  }

  getContactAverageIncome(criteriaArray) {
    return this.requestLibrary.GetAll('/contacts/reports/income-per-contact', { options: { criteria: criteriaArray } });
  }

  getPatientsMap(criteriaArray) {

    const statusSubstatus = {
      is_required: true,
      criterion: 'statusSubstatus',
      value: {
        ostat_id: 2,
        ss_id: 0
      }
    };
    let returnArr = [];
    if (criteriaArray != null) {
      returnArr = JSON.parse(JSON.stringify(criteriaArray));
    }
    returnArr.push(statusSubstatus);

    return this.requestLibrary.GetAll('/contacts/reports/count-per-city', {
      options: { criteria: returnArr }
    });
  }

  getContacts(criteriaArray, _query = "a") {
    let returnArr = [];
    if (criteriaArray != null) {
      returnArr = JSON.parse(JSON.stringify(criteriaArray));
    }

    return this.requestLibrary.GetAll('/contacts/reports/contacts', {
      _: _query,
      options: { criteria: returnArr }
    });
  }

  getContactsbyChannel(criteriaArray, _query = "a") {
    let returnArr = [];
    if (criteriaArray != null) {
      returnArr = JSON.parse(JSON.stringify(criteriaArray));
    }

    return this.requestLibrary.GetAll('/contacts/reports/contacts-by-channel', {
      _: _query,
      options: { criteria: returnArr }
    });
  }

  getContactsRepartition(criteriaArray, _query = "a") {
    let returnArr = [];
    if (criteriaArray != null) {
      returnArr = JSON.parse(JSON.stringify(criteriaArray));
    }

    return this.requestLibrary.GetAll('/contacts/reports/lead-repartition', {
      _: _query,
      options: { criteria: returnArr }
    });
  }

  getContactsCount(criteriaArray, _query = "a") {
    let returnArr = [];
    if (criteriaArray != null) {
      returnArr = JSON.parse(JSON.stringify(criteriaArray));
    }

    return this.requestLibrary.GetAll('/contacts/reports/count', {
      _: _query,
      options: { criteria: returnArr }
    });
  }
  getContactsValueRefundCount(criteriaArray, _query = "a") {
    let returnArr = [];
    if (criteriaArray != null) {
      returnArr = JSON.parse(JSON.stringify(criteriaArray));
    }

    return this.requestLibrary.GetAll('/contacts/reports/value-lead-refund', {
      _: _query,
      options: { criteria: returnArr }
    });
  }

  getContactsByFieldValue(criteriaArray, field_type) {
    let returnArr = [];
    if (criteriaArray != null) {
      returnArr = JSON.parse(JSON.stringify(criteriaArray));
    }

    return this.requestLibrary.GetAll('/contacts/reports/field-value', {
      options: { criteria: returnArr, type: field_type }
    });
  }

  getAdmissionsPayerMix(date_interval) {
    return this.requestLibrary.GetAll('/contacts/reports/count-per-field-type', {
      options: {
        date_interval: date_interval,
        type: '3001',
        status: 2
      }
    });
  }
  getLeadsPayerMix(date_interval) {
    return this.requestLibrary.GetAll('/contacts/reports/count-per-field-type', {
      options: {
        date_interval: date_interval,
        type: '3001'
      }
    });
  }
  getLeadsReferredTo(date_interval) {
    return this.requestLibrary.GetAll('/contacts/reports/count-per-field-type', {
      options: {
        date_interval: date_interval,
        type: '3002'
      }
    });
  }

  getContactsGrossProfit(criteriaArray, _query = "a") {
    let returnArr = [];
    if (criteriaArray != null) {
      returnArr = JSON.parse(JSON.stringify(criteriaArray));
    }

    return this.requestLibrary.GetAll('/contacts/reports/gross-profit', {
      _: _query,
      options: { criteria: returnArr }
    });
  }
  getContactSoldNetProfit(criteriaArray, _query = "a") {
    let returnArr = [];
    if (criteriaArray != null) {
      returnArr = JSON.parse(JSON.stringify(criteriaArray));
    }

    return this.requestLibrary.GetAll('/contacts/reports/net-profit', {
      _: _query,
      options: { criteria: returnArr }
    });
  }
}
