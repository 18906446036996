<div class="content-heading">
  <div>
    Send an Email
    <ul class="breadcrumb">
      <li class="back-link" [routerLink]='["/email"]'>Back</li>
    </ul>
  </div>
</div>
<div class="container-fluid">
  <form name="contactEmailComposeForm" #contactEmailComposeForm="ngForm" [formGroup]="sendEmailToContactForm"
    class="form-validate form-horizontal" (submit)="submitForm()" novalidate="">
    <div class="panel panel-default">
      <div class="panel-body">
        <fieldset>
          <div class="form-group mb-sm">
            <label class="col-sm-2 control-label">Sender</label>
            <div class="col-sm-10">
              <div *ngIf="!isUsersLoaded" class="ball-clip-rotate spinnerinputmargin">
                <div></div>
              </div>
              <select *ngIf="isUsersLoaded" class="form-control" name="sender" formControlName="sender">
                <option value="0">Select Sender</option>
                <option *ngFor="let user of usersArr" [value]="user.id">{{
                  user.name
                  }}</option>
              </select>
              <span class="text-danger" *ngIf="
                    sendEmailToContactForm.get('sender').hasError('required')
                  ">This field is required</span>
            </div>
            <div class="col-sm-2">
              <small class="text-muted"></small>
            </div>
          </div>
          <div class="form-group mb-sm">
            <label class="col-sm-2 control-label">CC</label>
            <div class="col-sm-10">
              <input class="form-control" type="text" formControlName="cc" />
            </div>
            <div class="col-sm-2">
              <small class="text-muted"></small>
            </div>
          </div>
          <div class="form-group mb-sm">
            <label class="col-sm-2 control-label">Subject</label>
            <div class="col-sm-10">
              <input class="form-control" type="text" formControlName="subject" />
              <span class="text-danger" *ngIf="
                    sendEmailToContactForm.get('subject').hasError('required')
                  ">This field is required</span>
            </div>
            <div class="col-sm-2">
              <small class="text-muted"></small>
            </div>
          </div>
        </fieldset>
        <!-- <fieldset> -->
        <div class="row">
          <div class="form-group">
            <div class="col-sm-2">
              <small class="text-muted"></small>
            </div>
          </div>
          <label class="col-sm-2 control-label">
            <div *ngIf="!isEmailTemplatesLoaded" class="ball-clip-rotate spinnerinputmargin"></div>
            <select *ngIf="isEmailTemplatesLoaded" class="form-control" formControlName="email_template_id">
              <option value="0" selected>Template</option>
              <option *ngFor="let emailTemplate of emailTemplatesArray" [value]="emailTemplate.id">{{
                emailTemplate.title }}</option>
            </select><br /><a style="font-size: 14px; font-weight: normal;"
              (click)="addTemplateLegendAction()">Variables</a>
          </label>
          <div [ngClass]="{'col-sm-5': isSelected() === false, 'col-sm-10': isSelected() === true}" class="pd-l-sm">
            <app-editor [editorID]="editorID" [htmlTemplate]="emailText" (templateChanged)="onTemplateChange($event)">
            </app-editor>
          </div>
          <div [ngClass]="{'col-sm-5 pd-sm': isSelected() === false, 'hidden': isSelected() === true}">
            <div class="panel panel-default">
              <div class="panel-heading">
                <button type="button" (click)="destroyComponent()" class="close" aria-label="Close"><span
                    aria-hidden="true">&times;</span></button>
                <h4>{{getActionLabel(selectedAction)}}</h4>
              </div>
              <div class="panel-body">
                <ng-template #actionDetailContainter></ng-template>
              </div>
            </div>
          </div>
        </div>

      </div>
      <div class="panel-footer">
        <div *ngIf="!isSaving && isHTML" class="form-group">
          <div class="text-center action-button">
            <button class="btn btn-info" type="submit" [disabled]="contactEmailComposeForm.invalid">
              Send Email
            </button>
          </div>
        </div>
        <div *ngIf="isSaving" class="panel-footer text-center">
          <div class="whirl standard"></div>
        </div>
      </div>

    </div>
  </form>

</div>
<div class="container-fluid">
  <div class="panel">
    <div class="panel-body">
      <app-timeline-email [contact_id]="this.contactID" [timelineRefresh]="refreshTimeline"></app-timeline-email>
    </div>
  </div>
</div>