import { Injectable } from '@angular/core';
import 'rxjs/add/operator/map';
import { Router } from '@angular/router';
import { CustomHttp } from '@service/customhttp.service';
import { RequestLibraryService } from '@service/core/request-library.service';
import { IPlansGetResponse } from '@model/plan/plans-get.response'
import { Observable } from 'rxjs/Observable';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class PlanService {
    private customHttp: CustomHttp;
    constructor(
        private http: HttpClient,
        private router: Router,
        private requestLibrary: RequestLibraryService
    ) {
        this.customHttp = new CustomHttp(http, router);
    }
    storePrivilege(user: number, privileges: any) {
        return this.requestLibrary.Post(`/plans/${user}/privileges`, privileges);
    }

    getPrivilege(user: number) {
        return this.requestLibrary.Get(`/plans/${user}/privileges`);
    }

    getPlans(): Observable<IPlansGetResponse> {
        return this.requestLibrary.GetAll('/plans', {
            'options': { page_size: 'all', is_compact: true }
        });
    }

    getFullPlans() {
        return this.requestLibrary.GetAll('/plans', {
            'options': { page_size: 'all', 'expand': ['users'] }
        });
    }

    getPaginatedPlans(page, page_size, sort, sort_dir): Observable<IPlansGetResponse> {
        return this.requestLibrary.GetAll('/plans', {
            'options': { page_size: 'all', sort: sort, sort_dir: sort_dir }
        });
    }

    getExportedPlans(args) {
        return this.requestLibrary.GetCSV('/groups', {
            'options': args
        });
    }

    getPlanDetail(id) {
        return this.requestLibrary.Get(`/plans/${id}`);
    }

    deletePlan(id) {
        return this.requestLibrary.Delete(`/plans/${id}`);
    }

    addEditPlans(model) {
        return this.requestLibrary.Post('/plans', model);
    }
}
