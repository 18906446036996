import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import 'rxjs/add/operator/map';
import { globalValues } from '@app/globals';
import { CustomHttp } from '@service/customhttp.service';
import { RequestLibraryService } from '@service/core/request-library.service';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class ContactFilterService {
    private customHttp: CustomHttp;
    constructor(
        private http: HttpClient,
        private router: Router,
        private requestLibrary: RequestLibraryService
    ) {
        this.customHttp = new CustomHttp(http, router);
    }
    getFilters() {
        return this.requestLibrary.Get('/contactfilters');
    }

    postFilters(model) {
        return this.requestLibrary.Post('/contactfilters', model);
    }

    deleteFilters(id: string) {
        return this.requestLibrary.Delete('/contactfilters/' + id);
    }
}
