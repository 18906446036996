<ng-template [ngxPermissionsOnly]="['contacts:can_get_contacts_reports_captured']">
  <div class="box-row panel panel-default">
    <div class="panel-heading">
      <h4>Leads Sold / Captured</h4>
    </div>
    <div class="panel-body text-center ">
      <div *ngIf="!isContactCapturedLoaded" class="whirl standard spinnermargin"></div>
      <ng-template [ngIf]="isContactCapturedLoaded">
        <ng-container *ngIf="soldCount == 0 && capturedCount == 0; else displayChart">
          <p>No Leads Sold / Captured in the last {{relativeDate}}</p>
        </ng-container>
        <ng-template #displayChart>
          <div class="col-lg-12 col-sm-12 text-alpha"> 
            <line-chart [chartData]="leadsChartData" [totalCount]="(soldCount > capturedCount) ? soldCount : capturedCount"></line-chart>
          </div>
        </ng-template>
      </ng-template>
    </div>
  </div>
</ng-template>
