import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Input,
  AfterViewInit,
} from "@angular/core"
import { ToasterLibraryService } from "@service/index"
declare var $: any

@Component({
  selector: "app-editor",
  templateUrl: "./editor.component.html",
  styleUrls: ["./editor.component.scss"],
})
export class EditorComponent implements OnInit, AfterViewInit {
  public showCC = false
  public showBCC = false
  contents: string
  previewButtonState: boolean = false
  editorContentBeforePreview = ""

  @Output() templateChanged: EventEmitter<any> = new EventEmitter()
  @Input() htmlTemplate: string
  @Input() clearNote: boolean = false
  @Input("editorID") editorID = "summernote"

  constructor(private toasterLibrary: ToasterLibraryService) {}

  ngOnInit() {
    // Summernote is currently not ported as a native angular2 module
    // For a quick use it we use the component a wrapper
    // Plugin events can be used to keep component props
    // in sync with the editor content
    //  $('#summernote').summernote('pasteHTML', this.htmlTemplate);
    //$('#summernote').summernote('editor.insertText', this.emailTemplate);
  }
  ngAfterViewInit(): void {
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.
    let self = this
    let id = "#" + this.editorID
    let note = $(id).summernote({
      toolbar: [
        // [groupName, [list of button]]
        ["style", ["bold", "italic", "underline", "clear"]],
        ["font", ["strikethrough", "superscript", "subscript"]],
        ["fontsize", ["fontsize"]],
        ["fontname", ["fontname"]],
        ["color", ["color"]],
        ["para", ["ul", "ol", "paragraph"]],
        ["height", ["height"]],
        ["copyButton", ["copyButton"]],
        ["previewButton", ["previewButton"]],
      ],
      buttons: {
        copyButton: function (context) {
          var ui = $.summernote.ui
          // create button
          var button = ui.button({
            contents: '<i class="fa fa-copy"/>',
            tooltip: "Copy",
            click: function () {
              self.copyMessage(context.code())
            },
          })
          return button.render()
        },
        previewButton: function (context) {
          var ui = $.summernote.ui
          var button = ui.button({
            contents: '<i class="fa fa-eye"/>',
            tooltip: "Preview",
            className: "btn-preview",
            click: function () {
              self.previewClickHandler(context, self, ui)
            },
          })
          return button.render() // return button as jquery object
        },
      },

      height: 400,
      dialogsInBody: true,
      callbacks: {
        onChange: (contents, $editable) => {
          this.contents = contents
          this.templateChanged.emit(this.contents)
          // console.log("stoper 1")
        },
      },
    })
    $(id).summernote("code", this.htmlTemplate)
    $(id).summernote({
      disableDragAndDrop: true,
    })
  }
  ngOnChanges(changes) {
    if (changes && changes.clearNote && changes.clearNote.currentValue) {
      let id = "#" + this.editorID
      // console.log("stoper 2")
      $(id).summernote("code", "")
    }
    if (changes && changes.htmlTemplate && changes.htmlTemplate.currentValue) {
      let id = "#" + this.editorID
      console.log("stoper 3")
      // $(id).summernote("code", this.htmlTemplate)
    }
  }
  public copyMessage(val: string) {
    const selBox = document.createElement("textarea")
    selBox.style.position = "fixed"
    selBox.style.left = "0"
    selBox.style.top = "0"
    selBox.style.opacity = "0"
    selBox.value = val
    document.body.appendChild(selBox)
    selBox.focus()
    selBox.select()
    document.execCommand("copy")
    document.body.removeChild(selBox)
    this.toasterLibrary.success("Copy", "Copied to Clipboard!")
  }
  disableEditor() {
    let id = "#" + this.editorID
    $(id).summernote("disable")
  }
  enableEditor() {
    let id = "#" + this.editorID
    $(id).summernote("enable")
  }
  previewClickHandler(context, self, ui) {
    self.previewButtonState = !self.previewButtonState
    var $editor = context.layoutInfo.editor
    var $toolbar = context.layoutInfo.toolbar
    var $codable = context.layoutInfo.codable
    var $editable = context.layoutInfo.editable

    // console.log('sommernote preview btn state:',$editor.hasClass('previewButton'));
    var $btn = $toolbar.find("button")
    var $previewBtn = $toolbar.find(".btn-preview")
    $btn = $btn.not(".btn-preview")
    ui.toggleBtn($btn, !self.previewButtonState)
    ui.toggleBtnActive($previewBtn, self.previewButtonState)
    if (self.previewButtonState) {
      self.editorContentBeforePreview = context.code()
      let str: string = $editable[0]
        ? $editable[0]["innerHTML"]
          ? $editable[0]["innerHTML"]
          : ""
        : ""
      str = str.replace(/&lt;/gm, "<")
      str = str.replace(/&gt;/gm, ">")
      if (str) {
        context.code(str)
      }
    } else {
      context.code(self.editorContentBeforePreview)
    }
  }
}
