import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import 'rxjs/add/operator/map';
import { globalValues } from '@app/globals';
import { CustomHttp } from '@service/customhttp.service';
import { RequestLibraryService } from '@service/core/request-library.service';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class LeadPriceService {
    private customHttp: CustomHttp;
    constructor(
        private http: HttpClient,
        private router: Router,
        private requestLibrary: RequestLibraryService
    ) {
        this.customHttp = new CustomHttp(http, router);
    }

    getAllLeadsPrices() {
        return this.requestLibrary.GetAll('/leadprices', {
            'options': { page_size: 'all', is_active: true, sort: 'name', sort_dir: 'ASC' }
        });
    }

    getPaginatedLeadsPrices(body) {
        return this.requestLibrary.GetAll('/leadprices', {
            'options': body
        });
    }

    getExportLeadsPrices(body) {
        return this.requestLibrary.GetCSV('/leadprices', {
            'options': body
        });
    }

    getLeadsPrice(id) {
        return this.requestLibrary.Get('/leadprices/' + id);
    }

    addEditLeadsPrice(model) {
        return this.requestLibrary.Post('/leadprices', model);
    }

    deleteLeadPrice(id) {
        return this.requestLibrary.Delete('/leadprices/' + id);
    }
}
