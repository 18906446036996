import { OnInit, Component } from '@angular/core';
import { SortingPipe } from '@app/shared/pipes/sorting.pipe';
import { Privilege } from '@decorator/index';
import { Logger } from '@service/global.funcs';
import { AutomationReportService, ToasterLibraryService } from '@service/index';
import * as _ from 'lodash';
import { NgxPermissionsService } from 'ngx-permissions';



@Component({
  selector: 'app-automations-action-run-chart-widget',
  templateUrl: './automations-action-run-chart-widget.component.html',
  styleUrls: ['./automations-action-run-chart-widget.component.scss'],
})

// @Privilege({
//   privilege: 'automations:can_get_automations_reports_actions'
// })
export class AutomationsActionRunChartWidgetComponent implements OnInit {
  /*
  * Format
  *
  * [
	*  { label: record.name, data: [[record.[name].date_month, record.[name].conversion_rate]] },
  *  ]
  */
  public automationActionRunChartData = [{
    'label': '',
    'color': '#e88b1b',
    'data': []
  }];
  public isAutomationActionRunChartLoaded = false;
  public colors = [
    { 'category': 'POST', 'color': '#e88b1b' },
    { 'category': 'EMAIL_ADDRESS', 'color': '#263746' },
    { 'category': 'EMAIL_CONTACT', 'color': '#ECCA2A' },
  ];
  // #396AB1
  // #DA7C30
  // #3E9651
  // #CC2529
  // #535154
  // #6B4C9A
  // #922428
  // #948B3D
  constructor(
    private toasterLibrary: ToasterLibraryService,
    private automationReportService: AutomationReportService,
    private permissionService: NgxPermissionsService

  ) {
  }

  ngOnInit() {
    if (this.permissionService.hasPermission('automations:can_get_automations_reports_actions')) {
      this.getTestStatConversionRate();
    }
  }

  getTestStatConversionRate() {
    this.isAutomationActionRunChartLoaded = false;
    this.automationReportService
      .getStackedBarData()
      .subscribe((res: any) => {
        console.log(res);
        // debugger
        if (res.success == 'true') {
          if (res.data.length) {

            // Sorting the bars in ASC order w.r.t date_month
            res.data = new SortingPipe().transform(res.data,['date_month'],1);

            this.automationActionRunChartData = this.transform(res.data, 'category', 'date_month', 'actions_count');
          }
        }
        this.isAutomationActionRunChartLoaded = true;
      }, err => {
        this.toasterLibrary.error(null, err.error.message);
      });
  }

  private transform(records, group_by, xAtr, yAtr) {
    Logger().info(
      _.chain(records)
        .groupBy(group_by)
        .toPairs()
        .value()
    );
    return _.chain(records)
      .groupBy(group_by)
      .toPairs()
      .map(function (currentItem) {
        return _.zipObject(['label', 'data'], currentItem);
      })
      .map((currentItemT) => {
        const currentItemData = currentItemT['data'].map((item) => {
          return [item[xAtr], item[yAtr]];
        });
        const color = _.result(_.find(this.colors, function (obj) {
          return obj.category === currentItemT['label'];
        }), 'color') + '';
        return {
          'label': currentItemT['label'],
          'color': color,
          'data': currentItemData
        };
      })
      .value();
  }
}
