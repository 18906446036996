import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CustomHttp } from '@service/customhttp.service';
import { RequestLibraryService } from '@service/core/request-library.service';
import 'rxjs/add/operator/map';
import { HttpClient } from '@angular/common/http';
@Injectable()
export class VisitorService {
  private customHttp: CustomHttp;
  constructor(private http: HttpClient, private router: Router, private requestLibrary: RequestLibraryService) {
    this.customHttp = new CustomHttp(http, router);
  }

  getVisitors(criteriaArray) {
    return this.requestLibrary.GetAll('/visitors/reports/count', {
      options: { criteria: criteriaArray }
    });
  }

  getConversionRate(criteriaArray) {
    return this.requestLibrary.GetAll('/visitors/reports/average-conversion-rate', {
        options: { criteria: criteriaArray }
    });
  }
}
