import { Injectable } from "@angular/core"
import { environment } from "@env/environment"
import { HttpClient } from "@angular/common/http"

@Injectable()
export class CustomerOnboardService {
  constructor(private httpClient: HttpClient) {}

  createCustomer(model) {
    return this.httpClient.post(
      environment.api_base_url + "/customer/onboard",
      model
    )
  }

  checkCustomer(model) {
    return this.httpClient.post(
      environment.api_base_url + "/customer/onboard/check",
      model
    )
  }
}
