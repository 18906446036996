import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import 'rxjs/add/operator/map';
import { globalValues } from '@app/globals';
import { CustomHttp } from '@service/customhttp.service';
import { RequestLibraryService } from '@service/core/request-library.service';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class ContactPurchaseService {
    private customHttp: CustomHttp;
    constructor(
        private http: HttpClient,
        private router: Router,
        private requestLibrary: RequestLibraryService
    ) {
        this.customHttp = new CustomHttp(http, router);
    }

    getPaginatedLeads(page, page_size, sort, sort_dir, criteriaArray) {
        return this.requestLibrary.GetAll('/contactspurchase', {
            options: {
                page: page,
                page_size: page_size,
                sort: sort,
                sort_dir: sort_dir,
                criteria: criteriaArray
            }
        });
    }



    purchaseLead(contact_id, account_id) {
        return this.requestLibrary.Post('/contactspurchase/purchase', { 'contact_id': contact_id, 'account_id': account_id });
    }
    refundRequest(model) {
        return this.requestLibrary.Post('/contactspurchase/refundrequest', model);
    }
    bulkRefund(model) {
        return this.requestLibrary.Post('/contactspurchase/bulk-refund', model);
    }

    getExportContact(args) {
        return this.customHttp.getDownloadableCsv('/contactspurchase?options=', args);
    }
}
