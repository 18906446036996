import { Injectable } from '@angular/core';
import 'rxjs/add/operator/map';
import { CustomHttp } from '@service/customhttp.service';
import { RequestLibraryService } from '@service/core/request-library.service';

@Injectable()
export class CampaignReportService {
  constructor(private requestLibrary: RequestLibraryService) {

  }

  getAvgCpa(date_interval) {
    return this.requestLibrary.GetAll('/campaigns/reports/average-cpa', {
      options: {
        date_interval: date_interval
      }
    });
  }


  channelsGeneratedReportWithSale(since = 'Last 30 Days') {
    return this.requestLibrary.GetAll('/campaigns/reports/sales-per-channel', {
      options: { limit: '5', date_interval: since }
    });
  }

  getSalesByCampaign(criteriaArr) {
    const statusSubstatus = {
      id: '',
      is_required: true,
      criterion: 'statusSubstatus',
      value: {
        ostat_id: 2,
        ss_id: 0
      }
    };

    let returnArr = [];
    if (criteriaArr != null) {
      returnArr = JSON.parse(JSON.stringify(criteriaArr));
    }

    returnArr.push(statusSubstatus);
    return this.requestLibrary.GetAll('/campaigns/reports/campaigns', {
      options: { criteria: returnArr }
    });
  }
  getLeadsByCampaign(criteriaArr) {
    let returnArr = [];
    if (criteriaArr != null) {
      returnArr = JSON.parse(JSON.stringify(criteriaArr));
    }

    return this.requestLibrary.GetAll('/campaigns/reports/campaigns', {
      options: { criteria: returnArr }
    });
  }

  getCostPerContactByCampaign(since = 'Last 30 Days', criteriaArr) {
    let returnArr = [];
    if (criteriaArr != null) {
      returnArr = JSON.parse(JSON.stringify(criteriaArr));
    }

    return this.requestLibrary.GetAll('/campaigns/reports/cost-per-lead-by-campaign', {
      options: { criteria: returnArr, date_interval: since }
    });
  }

  getConversionRateByCampaign(since = 'Last 30 Days', criteriaArr) {
    let returnArr = [];
    if (criteriaArr != null) {
      returnArr = JSON.parse(JSON.stringify(criteriaArr));
    }

    return this.requestLibrary.GetAll('/campaigns/reports/campaign-conversion-rate', {
      options: { criteria: returnArr , date_interval: since}
    });
  }

  getConversionRateByChannel(since = 'Last 30 Days', criteriaArr) {
    let returnArr = [];
    if (criteriaArr != null) {
      returnArr = JSON.parse(JSON.stringify(criteriaArr));
    }

    return this.requestLibrary.GetAll('/campaigns/reports/channel-conversion-rate', {
      options: { criteria: returnArr, date_interval: since }
    });
  }



  getCostPerContactByChannel(since = 'Last 30 Days', criteriaArr) {
    let returnArr = [];
    if (criteriaArr != null) {
      returnArr = JSON.parse(JSON.stringify(criteriaArr));
    }

    return this.requestLibrary.GetAll('/campaigns/reports/cost-per-lead-by-channel', {
      options: { criteria: returnArr, date_interval: since }
    });
  }

  getLeadsByChannel(criteriaArr) {
    let returnArr = [];
    if (criteriaArr != null) {
      returnArr = JSON.parse(JSON.stringify(criteriaArr));
    }

    return this.requestLibrary.GetAll('/campaigns/reports/channels', {
      options: { criteria: returnArr }
    });
  }




  // getSalesByCampaignStat(relative_date) {
  //   return this.requestLibrary.GetAll('/campaigns/reports/sales-per-campaign', {
  //     options: { date_interval: relative_date }
  //   });
  // }


  // channelsGeneratedReportWithContacts() {
  //   return this.requestLibrary.GetAll('/campaigns/reports/contacts-per-channel', {
  //     options: { limit: '5' }
  //   });
  // }
  // getLeadsByCampaign(relativeDate, channel = '') {
  //   return this.requestLibrary.GetAll('/campaigns/reports/contacts-per-campaign', {
  //     options: { date_interval: relativeDate, channel: channel }
  //   });
  // }
  // getLeadCountPerCampaignBarChart(relativeDate, channel = '') {
  //   return this.requestLibrary.GetAll('/campaigns/reports/leads', {
  //     options: { date_interval: relativeDate, channel: channel }
  //   });
  // }
  // getSalesCountPerCampaignBarChart(relativeDate, channel = '') {
  //   return this.requestLibrary.GetAll('/campaigns/reports/sales', {
  //     options: { date_interval: relativeDate, channel: channel }
  //   });
  // }
  // getLeadsByChannel(filter) {
  //   return this.requestLibrary.GetAll('/campaigns/reports/contacts-per-channel', {
  //     options: { date_interval: filter }
  //   });
  // }


}
