import { Injectable } from '@angular/core';
import { RequestLibraryService } from '@service/core/request-library.service';

@Injectable()
export class PrivilegeService {
    constructor(
        private requestLibrary: RequestLibraryService
    ) {
    }

    getAllPrivileges() {
        return this.requestLibrary.GetAll('/privileges');
    }


}
