import { Injectable, Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'TimeDuration'})

export class TimeDurationFormat implements PipeTransform
{
    transform(time)
    {
        let mins = 0, secs = 0;
        if(time < 60 && time > 0) {
            mins = 0;
            secs = time;     
        }
        if(time >= 60) {
            mins = ~~(time / 60);
            secs = time % 60;   
        }        
        return (this.zeroPad(mins, 2) + ":" + this.zeroPad(secs,2).trim());
    }

    zeroPad(num, places) {
        var zero = places - num.toString().length + 1;
        return Array(+(zero > 0 && zero)).join("0") + num;
    }
}