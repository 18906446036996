import { CurrencyPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { currencies } from '@asset/currency/currency';
@Pipe({ name: 'currency' })

export class FormatPricePipe implements PipeTransform {
    public currencyArray = {
        'en_US': 'USD',
        'de_DE': 'EUR',
        'en_AU': 'AUD',
        'en_GB': 'GBP',
        'ja_JP': 'JPY'
    };
    transform(value: string, currencyCode: string = 'USD') {
        const sAccount = JSON.parse(localStorage.getItem('account'));
        const local = 'en';
        if (value) {
            if (sAccount && sAccount['currency_code'] !== '') {
                currencyCode = sAccount['currency_code'];
                // local = sAccount['currency_locale'].split('_')[0];
            }
            currencyCode = currencyCode.toUpperCase();
            const number = Number(value.toString().replace(/[^0-9\.-]+/g, ''));
            return new CurrencyPipe(local).transform(number, currencyCode, 'symbol');
        }
        return value;
    }
}
