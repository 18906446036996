import { Component, Input, OnChanges, OnInit, SimpleChange } from '@angular/core';
import { ContactReportService } from '@service/index';
import { NgxPermissionsService } from 'ngx-permissions';

import { Logger } from '@service/global.funcs';
@Component({
  selector: 'app-contact-average-income-widget',
  templateUrl: './contact-average-income-widget.component.html',
  styleUrls: ['./contact-average-income-widget.component.scss']
})

// @Privilege({
//   privilege: 'emailtemplates:can_get_emailtemplates_reports_sent'
// })
export class ContactAverageIncomeWidgetComponent implements OnInit, OnChanges {
  @Input() relativeDate: any;
  @Input() campaignData: any;
  public isCountLoaded = false;
  public contactsAverageIncome: any;
  constructor(private contactReportService: ContactReportService, private permissionService: NgxPermissionsService) {}
  ngOnInit() {
    if (this.permissionService.hasPermission('contacts:can_get_contacts_reports_income_per_contact')) {
      this.getContactAverageIncome();
    }
  }

  ngOnChanges(changes: { [key: string]: SimpleChange }) {
    if (this.permissionService.hasPermission('contacts:can_get_contacts_reports_income_per_contact')) {
      if (
        (changes['relativeDate'] && !changes['relativeDate'].isFirstChange()) ||
        (changes['campaignData'] && !changes['campaignData'].isFirstChange()) ||
        (changes['criteriaArray'] && !changes['criteriaArray'].isFirstChange())
      ) {
        this.getContactAverageIncome();
      }
    }
  }

  getContactAverageIncome() {
    const criteriaArray = [
      {
        is_required: true,
        criterion: 'dateRange',
        value: {
          relative_date: this.relativeDate
        }
      },
      {
          'value': { c_id: this.campaignData },
          'criterion': 'campaign',
          'is_required': true
      }
    ];
    this.isCountLoaded = false;
    this.contactReportService.getContactAverageIncome(criteriaArray).subscribe((res: any) => {
      this.contactsAverageIncome = parseInt(res.data, 10).toString();
      Logger().info('getContactAverageIncome', 'res.data', parseInt(res.data, 10));
      this.isCountLoaded = true;
    });
  }
}
