<div>
  <nav
    class="sidebar"
    sidebar-anyclick-close=""
    [class.show-scrollbar]="settings.layout.asideScrollbar"
  >
    <ul class="nav">
      <li class="has-user-block"></li>
      <ng-template ngFor let-item [ngForOf]="menuItems" let-i="index">
        <ng-template [ngxPermissionsOnly]="[item.privilege]">
          <li
            [ngClass]="{ 'nav-heading': item.heading }"
            [routerLinkActive]="['active']"
            [routerLinkActiveOptions]="{ exact: true }"
          >
            <ng-template [ngIf]="item.heading">
              <span>{{ (item.translate | translate) || item.text }}</span>
            </ng-template>
          </li>
        </ng-template>
      </ng-template>
    </ul>
    <ng-template [ngIf]="parent_account_id == '1'">
      <ul class="nav navbar-fixed-bottom fixed-bottom">
        <li class="bg-default">
          <a (click)="showIntercom()">
            <em class="icon-support"></em>
            <span>Live Chat&nbsp;</span>
          </a>
        </li>
        <li class="bg-default">
          <a href="http://support.marketingoptimizer.com" target="_new">
            <em class="icon-docs"></em>
            <span>Documentation&nbsp;</span>
          </a>
        </li>
        <li class="bg-default">
          <a [routerLink]="['/get-started']">
            <em class="icon-question"></em>
            <span>Get Started&nbsp;</span>
          </a>
        </li>
      </ul>
    </ng-template>
  </nav>
</div>
