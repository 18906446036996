<ng-template [ngxPermissionsOnly]="['visitors:can_get_visitors_reports_average_conversion_rate']">
  <div class="box-row panel panel-default">
    <div class="panel-body text-center text-alpha">
      <div *ngIf="!isConversionLoaded" class="whirl standard spinnermargin"></div>
      <ng-template [ngIf]="isConversionLoaded">
        <div class="text-lg mo">{{websiteConversionRatePercentage}}%</div>
        <p>Website Conversion Rate</p>
        <div class="mb-lg"></div>
      </ng-template>
    </div>
  </div>
</ng-template>