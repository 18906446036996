// http://api.rubyonrails.org/classes/ActiveSupport/TimeZone.html
// https://en.wikipedia.org/wiki/List_of_tz_database_time_zones

export const timezones = [
    { 'name': '(UTC-11:00) International Date Line West', 'value': 'Pacific/Midway' },
    { 'name': '(UTC-11:00) Midway Island', 'value': 'Pacific/Midway' },
    { 'name': '(UTC-11:00) American Samoa', 'value': 'Pacific/Pago_Pago' },
    { 'name': '(UTC-10:00) Hawaii', 'value': 'Pacific/Honolulu' },
    { 'name': '(UTC-09:00) Alaska', 'value': 'America/Juneau' },
    { 'name': '(UTC-08:00) Pacific Time (US & Canada)', 'value': 'America/Los_Angeles' },
    { 'name': '(UTC-08:00) Tijuana', 'value': 'America/Tijuana' },
    { 'name': '(UTC-07:00) Mountain Time (US & Canada)', 'value': 'America/Denver' },
    { 'name': '(UTC-07:00) Arizona', 'value': 'America/Phoenix' },
    { 'name': '(UTC-07:00) Chihuahua', 'value': 'America/Chihuahua' },
    { 'name': '(UTC-07:00) Mazatlan', 'value': 'America/Mazatlan' },
    { 'name': '(UTC-06:00) Central Time (US & Canada)', 'value': 'America/Chicago' },
    { 'name': '(UTC-06:00) Saskatchewan', 'value': 'America/Regina' },
    { 'name': '(UTC-06:00) Guadalajara', 'value': 'America/Mexico_City' },
    { 'name': '(UTC-06:00) Mexico City', 'value': 'America/Mexico_City' },
    { 'name': '(UTC-06:00) Monterrey', 'value': 'America/Monterrey' },
    { 'name': '(UTC-06:00) Central America', 'value': 'America/Guatemala' },
    { 'name': '(UTC-05:00) Eastern Time (US & Canada)', 'value': 'America/New_York' },
    { 'name': '(UTC-05:00) Indiana (East)', 'value': 'America/Indiana/Indianapolis' },
    { 'name': '(UTC-05:00) Bogota', 'value': 'America/Bogota' },
    { 'name': '(UTC-05:00) Lima', 'value': 'America/Lima' },
    { 'name': '(UTC-05:00) Quito', 'value': 'America/Lima' },
    { 'name': '(UTC-04:00) Atlantic Time (Canada)', 'value': 'America/Halifax' },
    { 'name': '(UTC-04:00) Caracas', 'value': 'America/Caracas' },
    { 'name': '(UTC-04:00) La Paz', 'value': 'America/La_Paz' },
    { 'name': '(UTC-04:00) Santiago', 'value': 'America/Santiago' },
    { 'name': '(UTC-03:30) Newfoundland', 'value': 'America/St_Johns' },
    { 'name': '(UTC-03:00) Brasilia', 'value': 'America/Sao_Paulo' },
    { 'name': '(UTC-03:00) Buenos Aires', 'value': 'America/Argentina/Buenos_Aires' },
    { 'name': '(UTC-03:00) Montevideo', 'value': 'America/Montevideo' },
    { 'name': '(UTC-04:00) Georgetown', 'value': 'America/Guyana' },
    { 'name': '(UTC-03:00) Greenland', 'value': 'America/Godthab' },
    { 'name': '(UTC-02:00) Mid-Atlantic', 'value': 'Atlantic/South_Georgia' },
    { 'name': '(UTC-01:00) Azores', 'value': 'Atlantic/Azores' },
    { 'name': '(UTC-01:00) Cape Verde Is.', 'value': 'Atlantic/Cape_Verde' },
    { 'name': '(UTC+00:00) Dublin', 'value': 'Europe/Dublin' },
    { 'name': '(UTC+00:00) Edinburgh', 'value': 'Europe/London' },
    { 'name': '(UTC+00:00) Lisbon', 'value': 'Europe/Lisbon' },
    { 'name': '(UTC+00:00) London', 'value': 'Europe/London' },
    { 'name': '(UTC+00:00) Casablanca', 'value': 'Africa/Casablanca' },
    { 'name': '(UTC+00:00) Monrovia', 'value': 'Africa/Monrovia' },
    { 'name': '(UTC+00:00) UTC', 'value': 'Etc/UTC' },
    { 'name': '(UTC+01:00) Belgrade', 'value': 'Europe/Belgrade' },
    { 'name': '(UTC+01:00) Bratislava', 'value': 'Europe/Bratislava' },
    { 'name': '(UTC+01:00) Budapest', 'value': 'Europe/Budapest' },
    { 'name': '(UTC+01:00) Ljubljana', 'value': 'Europe/Ljubljana' },
    { 'name': '(UTC+01:00) Prague', 'value': 'Europe/Prague' },
    { 'name': '(UTC+01:00) Sarajevo', 'value': 'Europe/Sarajevo' },
    { 'name': '(UTC+01:00) Skopje', 'value': 'Europe/Skopje' },
    { 'name': '(UTC+01:00) Warsaw', 'value': 'Europe/Warsaw' },
    { 'name': '(UTC+01:00) Zagreb', 'value': 'Europe/Zagreb' },
    { 'name': '(UTC+01:00) Brussels', 'value': 'Europe/Brussels' },
    { 'name': '(UTC+01:00) Copenhagen', 'value': 'Europe/Copenhagen' },
    { 'name': '(UTC+01:00) Madrid', 'value': 'Europe/Madrid' },
    { 'name': '(UTC+01:00) Paris', 'value': 'Europe/Paris' },
    { 'name': '(UTC+01:00) Amsterdam', 'value': 'Europe/Amsterdam' },
    { 'name': '(UTC+01:00) Berlin', 'value': 'Europe/Berlin' },
    { 'name': '(UTC+01:00) Bern', 'value': 'Europe/Zurich' },
    { 'name': '(UTC+01:00) Zurich', 'value': 'Europe/Zurich' },
    { 'name': '(UTC+01:00) Rome', 'value': 'Europe/Rome' },
    { 'name': '(UTC+01:00) Stockholm', 'value': 'Europe/Stockholm' },
    { 'name': '(UTC+01:00) Vienna', 'value': 'Europe/Vienna' },
    { 'name': '(UTC+01:00) West Central Africa', 'value': 'Africa/Algiers' },
    { 'name': '(UTC+02:00) Bucharest', 'value': 'Europe/Bucharest' },
    { 'name': '(UTC+02:00) Cairo', 'value': 'Africa/Cairo' },
    { 'name': '(UTC+02:00) Helsinki', 'value': 'Europe/Helsinki' },
    { 'name': '(UTC+02:00) Kyiv', 'value': 'Europe/Kiev' },
    { 'name': '(UTC+02:00) Riga', 'value': 'Europe/Riga' },
    { 'name': '(UTC+02:00) Sofia', 'value': 'Europe/Sofia' },
    { 'name': '(UTC+02:00) Tallinn', 'value': 'Europe/Tallinn' },
    { 'name': '(UTC+02:00) Vilnius', 'value': 'Europe/Vilnius' },
    { 'name': '(UTC+02:00) Athens', 'value': 'Europe/Athens' },
    { 'name': '(UTC+03:00) Istanbul', 'value': 'Europe/Istanbul' },
    { 'name': '(UTC+03:00) Minsk', 'value': 'Europe/Minsk' },
    { 'name': '(UTC+02:00) Jerusalem', 'value': 'Asia/Jerusalem' },
    { 'name': '(UTC+02:00) Harare', 'value': 'Africa/Harare' },
    { 'name': '(UTC+02:00) Pretoria', 'value': 'Africa/Johannesburg' },
    { 'name': '(UTC+02:00) Kaliningrad', 'value': 'Europe/Kaliningrad' },
    { 'name': '(UTC+03:00) Moscow', 'value': 'Europe/Moscow' },
    { 'name': '(UTC+03:00)  St. Petersburg', 'value': 'Europe/Moscow' },
    { 'name': '(UTC+03:00) Volgograd', 'value': 'Europe/Volgograd' },
    { 'name': '(UTC+04:00) Samara', 'value': 'Europe/Samara' },
    { 'name': '(UTC+03:00) Kuwait', 'value': 'Asia/Kuwait' },
    { 'name': '(UTC+03:00) Riyadh', 'value': 'Asia/Riyadh' },
    { 'name': '(UTC+03:00) Nairobi', 'value': 'Africa/Nairobi' },
    { 'name': '(UTC+03:00) Baghdad', 'value': 'Asia/Baghdad' },
    { 'name': '(UTC+03:30) Tehran', 'value': 'Asia/Tehran' },
    { 'name': '(UTC+04:00) Abu Dhabi', 'value': 'Asia/Muscat' },
    { 'name': '(UTC+04:00) Muscat', 'value': 'Asia/Muscat' },
    { 'name': '(UTC+04:00) Baku', 'value': 'Asia/Baku' },
    { 'name': '(UTC+04:00) Tbilisi', 'value': 'Asia/Tbilisi' },
    { 'name': '(UTC+04:00) Yerevan', 'value': 'Asia/Yerevan' },
    { 'name': '(UTC+04:30) Kabul', 'value': 'Asia/Kabul' },
    { 'name': '(UTC+05:00) Ekaterinburg', 'value': 'Asia/Yekaterinburg' },
    { 'name': '(UTC+05:00) Islamabad', 'value': 'Asia/Karachi' },
    { 'name': '(UTC+05:00) Karachi', 'value': 'Asia/Karachi' },
    { 'name': '(UTC+05:00) Tashkent', 'value': 'Asia/Tashkent' },
    { 'name': '(UTC+05:30) Chennai', 'value': 'Asia/Kolkata' },
    { 'name': '(UTC+05:30) Kolkata', 'value': 'Asia/Kolkata' },
    { 'name': '(UTC+05:30) Mumbai', 'value': 'Asia/Kolkata' },
    { 'name': '(UTC+05:30) New Delhi', 'value': 'Asia/Kolkata' },
    { 'name': '(UTC+05:45) Kathmandu', 'value': 'Asia/Kathmandu' },
    { 'name': '(UTC+06:00) Astana', 'value': 'Asia/Dhaka' },
    { 'name': '(UTC+06:00) Dhaka', 'value': 'Asia/Dhaka' },
    { 'name': '(UTC+05:30) Sri Jayawardenepura', 'value': 'Asia/Colombo' },
    { 'name': '(UTC+06:00) Almaty', 'value': 'Asia/Almaty' },
    { 'name': '(UTC+07:00) Novosibirsk', 'value': 'Asia/Novosibirsk' },
    { 'name': '(UTC+06:30) Rangoon', 'value': 'Asia/Rangoon' },
    { 'name': '(UTC+07:00) Bangkok', 'value': 'Asia/Bangkok' },
    { 'name': '(UTC+07:00) Hanoi', 'value': 'Asia/Bangkok' },
    { 'name': '(UTC+07:00) Jakarta', 'value': 'Asia/Jakarta' },
    { 'name': '(UTC+07:00) Krasnoyarsk', 'value': 'Asia/Krasnoyarsk' },
    { 'name': '(UTC+08:00) Beijing', 'value': 'Asia/Shanghai' },
    { 'name': '(UTC+08:00) Chongqing', 'value': 'Asia/Chongqing' },
    { 'name': '(UTC+08:00) Hong Kong', 'value': 'Asia/Hong_Kong' },
    { 'name': '(UTC+06:00) Urumqi', 'value': 'Asia/Urumqi' },
    { 'name': '(UTC+08:00) Kuala Lumpur', 'value': 'Asia/Kuala_Lumpur' },
    { 'name': '(UTC+08:00) Singapore', 'value': 'Asia/Singapore' },
    { 'name': '(UTC+08:00) Taipei', 'value': 'Asia/Taipei' },
    { 'name': '(UTC+08:00) Perth', 'value': 'Australia/Perth' },
    { 'name': '(UTC+08:00) Irkutsk', 'value': 'Asia/Irkutsk' },
    { 'name': '(UTC+08:00) Ulaanbaatar', 'value': 'Asia/Ulaanbaatar' },
    { 'name': '(UTC+09:00) Seoul', 'value': 'Asia/Seoul' },
    { 'name': '(UTC+09:00) Osaka', 'value': 'Asia/Tokyo' },
    { 'name': '(UTC+09:00) Sapporo', 'value': 'Asia/Tokyo' },
    { 'name': '(UTC+09:00) Tokyo', 'value': 'Asia/Tokyo' },
    { 'name': '(UTC+09:00) Yakutsk', 'value': 'Asia/Yakutsk' },
    { 'name': '(UTC+09:30) Darwin', 'value': 'Australia/Darwin' },
    { 'name': '(UTC+09:30) Adelaide', 'value': 'Australia/Adelaide' },
    { 'name': '(UTC+10:00) Canberra', 'value': 'Australia/Melbourne' },
    { 'name': '(UTC+10:00) Melbourne', 'value': 'Australia/Melbourne' },
    { 'name': '(UTC+10:00) Sydney', 'value': 'Australia/Sydney' },
    { 'name': '(UTC+10:00) Brisbane', 'value': 'Australia/Brisbane' },
    { 'name': '(UTC+10:00) Hobart', 'value': 'Australia/Hobart' },
    { 'name': '(UTC+10:00) Vladivostok', 'value': 'Asia/Vladivostok' },
    { 'name': '(UTC+10:00) Guam', 'value': 'Pacific/Guam' },
    { 'name': '(UTC+10:00) Port Moresby', 'value': 'Pacific/Port_Moresby' },
    { 'name': '(UTC+11:00) Magadan', 'value': 'Asia/Magadan' },
    { 'name': '(UTC+11:00) Srednekolymsk', 'value': 'Asia/Srednekolymsk' },
    { 'name': '(UTC+11:00) Solomon Is.', 'value': 'Pacific/Guadalcanal' },
    { 'name': '(UTC+11:00) New Caledonia', 'value': 'Pacific/Noumea' },
    { 'name': '(UTC+12:00) Fiji', 'value': 'Pacific/Fiji' },
    { 'name': '(UTC+12:00) Kamchatka', 'value': 'Asia/Kamchatka' },
    { 'name': '(UTC+12:00) Marshall Is.', 'value': 'Pacific/Majuro' },
    { 'name': '(UTC+12:00) Auckland', 'value': 'Pacific/Auckland' },
    { 'name': '(UTC+12:00) Wellington', 'value': 'Pacific/Auckland' },
    { 'name': '(UTC+13:00) Nuku\'alofa', 'value': 'Pacific/Tongatapu' },
    { 'name': '(UTC+13:00) Tokelau Is.', 'value': 'Pacific/Fakaofo' },
    { 'name': '(UTC+12:45) Chatham Is.', 'value': 'Pacific/Chatham' },
    { 'name': '(UTC+13:00) Samoa', 'value': 'Pacific/Apia' }
];
