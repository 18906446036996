<ng-template [ngxPermissionsOnly]="['campaigns:can_get_campaigns_reports_sales_per_channel']">
  <div class="box-row panel panel-default">
    <div class="panel-heading">
      <h4>{{widgetTitle}}</h4>
    </div>
    <div class="panel-body text-center">
      <ng-template [ngIf]="!contactSoldByChannelLoaded">
        <div class="whirl standard spinnermargin"></div>
      </ng-template>
      <ng-template [ngIf]="contactSoldByChannelLoaded">
        <ng-container *ngIf="contactSoldByChannelData.datasets[0].data.length == 0; else displayChart">
          <p>No Leads in the last {{relativeDate}}</p>
        </ng-container>
        <ng-template #displayChart>
          <pie-chart *ngIf="chartType=='pieChart'" [pieData]=contactSoldByChannelData [showLegends]=true></pie-chart>
        </ng-template>
      </ng-template>
    </div>
  </div>
</ng-template>
