import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import 'rxjs/add/operator/map';
import { globalValues } from '@app/globals';
import { CustomHttp } from '@service/customhttp.service';
import { RequestLibraryService } from '@service/core/request-library.service';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class RefundReasonService {
    private customHttp: CustomHttp;
    constructor(
        private http: HttpClient,
        private router: Router,
        private requestLibrary: RequestLibraryService
    ) {
        this.customHttp = new CustomHttp(http, router);
    }

    getRefundReasons(page, page_size, sort, sort_dir) {
        return this.requestLibrary.GetAll('/refundreasons', {
            'options': { page: page, page_size: page_size, sort: sort, sort_dir: sort_dir }
        });
    }

    getAllRefundReasons() {
      return this.requestLibrary.GetAll('/refundreasons', {
          'options': { page_size: 'all' }
      });
  }
  getAllParentRefundReasons() {
      return this.requestLibrary.GetAll('/refundreasons/parent', {
          'options': { page_size: 'all' }
      });
  }
    addEditRefundReason(model) {
        return this.requestLibrary.Post('/refundreasons', model);
    }

    getReasonDetail(id) {
        return this.requestLibrary.Get('/refundreasons/' + id);
    }

    deleteRefundReason(id) {
        return this.requestLibrary.Delete('/refundreasons/' + id);
    }

    refundReasonsCount() {
        return this.requestLibrary.Get('/refundreasons');
    }

}
