import { Injectable } from "@angular/core"
import { environment } from "@env/environment"

@Injectable({
  providedIn: "root",
})
export class ConsoleToggleService {
  constructor() { }
  disableConsoleInProduction(): void {
    if (environment.production) {
      console.warn(`🚨 Console output is disabled on production!`)
      console.log = function (): void {}
      console.debug = function (): void {}
      console.warn = function (): void {}
      console.info = function (): void {}

      console.log('Console Log Has Been Disabled!')
      console.warn('Console Warn Has Been Disabled!')
      console.debug('Console Debug Has Been Disabled!')
      console.info('Console Info Has Been Disabled!') 
    }
  }
}
