import { Component, Input, OnChanges, OnInit, SimpleChange } from '@angular/core';
import { Privilege } from '@decorator/index';
import { ContactReportService, ToasterLibraryService, AppConfig } from '@service/index';
import { NgxPermissionsService } from 'ngx-permissions';
import { Logger } from '@service/global.funcs';

@Component({
  selector: 'app-contact-sold-captured-line-chart-widget',
  templateUrl: './contact-sold-captured-line-chart-widget.component.html',
  styleUrls: ['./contact-sold-captured-line-chart-widget.component.scss']
})

// @Privilege({
//   privilege: 'contacts:can_get_contacts_reports_captured'
// })
export class ContactSoldCapturedLineChartWidgetComponent implements OnInit, OnChanges {
  @Input() relativeDate: any;
  @Input() campaignData: any;
  @Input() formData: any;
  @Input() priceData: any;
  public criteriaArray: any;
  public isContactCapturedLoaded = false;
  public capturedCount = 0;
  public soldCount = 0;
  public leadsChartData = {
    labels: [],
    datasets: [{
      label: 'Leads Sold',
      backgroundColor: 'rgba(27,55,81,0.2)',
      borderColor: '#263746',
      data: []
    }, {
      label: 'Leads Captured',
      backgroundColor: 'rgba(192,135,66,0.2)',
      borderColor: '#e88b1b',
      pointBorderColor: '#fff',
      data: []
    }],
    legend: false
  };

  public appContactSoldOptions = AppConfig.contactSoldOptions;
  constructor(
    private contactReportService: ContactReportService,
    private toasterLibrary: ToasterLibraryService,
    private permissionService: NgxPermissionsService
  ) { }

  ngOnInit() {
    if (this.permissionService.hasPermission(['contacts:can_get_contacts_reports_sold_times', 'contacts:can_get_contacts_reports_captured'])) {
      this.requestContactSold();
    }
  }

  ngOnChanges(changes: { [key: string]: SimpleChange }) {
    if (this.permissionService.hasPermission(['contacts:can_get_contacts_reports_sold_times', 'contacts:can_get_contacts_reports_captured'])) {
      if (
        (changes['relativeDate'] && !changes['relativeDate'].isFirstChange()) ||
        (changes['campaignData'] && !changes['campaignData'].isFirstChange()) ||
        (changes['priceData'] && !changes['priceData'].isFirstChange()) ||
        (changes['formData'] && !changes['formData'].isFirstChange())
      ) {
        this.requestContactSold();
      }
    }

  }
  requestContactSold() {
    const criteriaArray: any = this.getCommonCriteria() || [];
    criteriaArray.push({
      id: '',
      is_required: true,
      criterion: 'soldOption',
      value: {
        sold_option: this.appContactSoldOptions.sold
      }
    });
    this.reInit();
    this.contactReportService.getSoldContacts(criteriaArray, 'ContactSoldCapturedLineChartWidgetComponent').subscribe(
      (res: any) => {
        if (res.success === 'true') {
          // console.log(this.leadsChartData.datasets);
          res.data.forEach(contact => {
            if (!this.leadsChartData.labels.includes(contact.date_month)) {
              this.leadsChartData.labels.push(contact.date_month);
            }
            if (this.leadsChartData.datasets[0].data === undefined) {
              this.leadsChartData.datasets[0].data = [];
            }
            this.leadsChartData.datasets[0].data.push(contact.number_of_occurrences);
            this.soldCount = this.soldCount + parseInt(contact.number_of_occurrences, 10);
          });
          // console.log(this.leadsChartData.datasets);

          this.requestContactCaptured();
        }
      },
      err => {
        this.toasterLibrary.error(null, err.error.message);
      }
    );
  }
  requestContactCaptured() {
    const criteriaArray: any = this.getCommonCriteria() || [];

    this.isContactCapturedLoaded = false;

    this.contactReportService.getContacts(criteriaArray, 'ContactSoldCapturedLineChartWidgetComponent').subscribe(
      (res: any) => {
        if (res.success === 'true') {
          if (res.data) {
            // console.log(this.leadsChartData.datasets);
            res.data.forEach(contact => {
              if (!this.leadsChartData.labels.includes(contact.date_month)) {
                this.leadsChartData.labels.push(contact.date_month);
              }
              if (this.leadsChartData.datasets[1].data === undefined) {
                this.leadsChartData.datasets[1].data = [];
              }
              this.leadsChartData.datasets[1].data.push(contact.number_of_occurrences);
              this.capturedCount = this.capturedCount + parseInt(contact.number_of_occurrences, 10);
            });
          }
        }
        this.isContactCapturedLoaded = true;
      },
      err => {
        this.toasterLibrary.error(null, err.error.message);
      }
    );
  }

  private getCommonCriteria() {
    const criteriaArray: any = [
      {
        is_required: true,
        criterion: 'dateRange',
        value: {
          relative_date: this.relativeDate
        }
      }
    ];

    if (this.campaignData && this.campaignData != "") {
      criteriaArray.push({
        'id': '',
        'value': { c_id: this.campaignData },
        'criterion': 'campaign',
        'is_required': true
      });
    }

    if (this.formData && this.formData != "") {
      criteriaArray.push({
        value: [this.formData],
        criterion: 'form',
        is_required: true,
        id: ''
      });
    }

    if (this.priceData && this.priceData != "") {
      criteriaArray.push({
        value: { rprice_id: this.priceData },
        criterion: 'leadPricing',
        is_required: true,
        id: 0
      });
    }
    return criteriaArray;
  }

  private reInit() {
    this.leadsChartData = {
      labels: [],
      datasets: [{
        label: 'Leads Sold',
        backgroundColor: 'rgba(27,55,81,0.2)',
        borderColor: '#263746',
        data: []
      }, {
        label: 'Leads Captured',
        backgroundColor: 'rgba(192,135,66,0.2)',
        borderColor: '#e88b1b',
        pointBorderColor: '#fff',
        data: []
      }],
      legend: false
    };
  }
}
