import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import 'rxjs/add/operator/map';
import { globalValues } from '@app/globals';
import { CustomHttp } from '@service/customhttp.service';
import { RequestLibraryService } from '@service/core/request-library.service';
import { HttpClient } from '@angular/common/http';
@Injectable()
export class FormService {
    private customHttp: CustomHttp;
    constructor(
        private http: HttpClient,
        private router: Router,
        private requestLibrary: RequestLibraryService
    ) {
        this.customHttp = new CustomHttp(http, router);
    }

    getForm(id): any {
        return this.requestLibrary.GetAll('/forms/' + id, {
            'options': { expand: ['fields'] }
        });
    }

    getFormsList() {
        return this.requestLibrary.GetAll('/forms', {
            'options': { page_size: 'all', is_compact: true }
        });
    }

    getPaginatedForms(body) {
        return this.requestLibrary.GetAll('/forms', {
            'options': body
        });
    }

    getExportForms(body) {
        return this.requestLibrary.GetCSV('/forms', {
            'options': body
        });
    }

    getAllForms() {
        return this.requestLibrary.GetAll('/forms', {
            'options': { page_size: 'all', is_compact: true }
        });
    }

    addEditForm(model) {
        return this.requestLibrary.Post('/forms', model);
    }

    deleteForm(id) {
        return this.requestLibrary.Delete('/forms/' + id);
    }
}
