import { environment } from '@env/environment';

interface Auth0Configuration {
  readonly clientID: string;
  readonly domain: string;
  readonly redirectUrl: string;
  readonly linkedRedirectUrl: string; 
  readonly forgotPasswordLink: string;
  readonly loginUrl: string;
  readonly extraParams: any;
}


export const auth0Config: Auth0Configuration = {
  clientID: environment.auth_lock_key,
  domain: environment.auth_domain,
  redirectUrl: environment.auth_redirect_url,
  linkedRedirectUrl: environment.auth_linkedRedirect_url,
  forgotPasswordLink: environment.forgot_password_url,
  loginUrl: environment.auth_login_url,
  extraParams: {
  }
};
// console.info('Auth0Configuration', window.location.protocol + "//" + window.location.hostname + ":" + window.location.port);
