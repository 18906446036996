import { Component, OnInit, Input } from '@angular/core';
import { globalValues } from '@app/globals';

@Component({
  selector: 'pie-chart',
  templateUrl: './pie-chart.component.html'
})
export class PieChartComponent implements OnInit {
  @Input() pieData: any;
  @Input() totalCount: any;
  @Input() showLegends = false;
  public emptyChart = false;

  pieColors = [
    {
      borderColor: globalValues.chart_colors,
      backgroundColor: globalValues.chart_colors
    }
  ];

  pieOptions = {
    responsive: true,
    legend: {
      position: 'right'
    }
  };

  emptyPieChartOptions = {
    responsive: true,
    legend: {
      position: 'right'
    },
    tooltips: {
      callbacks: {
        label: function(tooltipItem, data) {
          return 'No Data';
        }
      }
    }
  };

  emptyChartColor = [
    {
      borderColor: ['#edf1f2'],
      backgroundColor: ['#edf1f2']
    }
  ];

  constructor() {}

  ngOnInit() {
    if (this.pieData.datasets[0].data.length === 0) {
      this.pieData.labels[0] = 'No Data';
      this.pieData.datasets[0].data[0] = 1;
      this.emptyChart = true;
    }
  }
}
