import { Component, Input, OnChanges, OnInit, SimpleChange } from '@angular/core';
import { Privilege } from '@decorator/index';
import { AbTestReportService, ToasterLibraryService, VisitorService } from '@service/index';
import { NgxPermissionsService } from 'ngx-permissions';
@Component({
  selector: 'app-website-conversion-rate-widget',
  templateUrl: './website-conversion-rate-widget.component.html',
  styleUrls: ['./website-conversion-rate-widget.component.scss']
})

// @Privilege({
//   privilege: 'visitors:can_get_visitors_reports_average_conversion_rate'
// })
export class WebsiteConversionRateWidgetComponent implements OnInit, OnChanges {
  @Input() relativeDate: any;
  @Input() campaignData: any;
  public isConversionLoaded: boolean = false;
  public websiteConversionRatePercentage: any = 0.0;
  constructor(
    private testReportService: AbTestReportService,
    private visitorService: VisitorService,
    private toasterLibrary: ToasterLibraryService,
    private permissionService: NgxPermissionsService
  ) { }

  ngOnInit() {
    if (this.permissionService.hasPermission('visitors:can_get_visitors_reports_average_conversion_rate')) {
      this.getWebsiteConversionRatePercentage();
    }
  }

  ngOnChanges(changes: { [key: string]: SimpleChange }) {
    if (this.permissionService.hasPermission('visitors:can_get_visitors_reports_average_conversion_rate')) {
      if (
        (changes['relativeDate'] && !changes['relativeDate'].isFirstChange()) ||
        (changes['campaignData'] && !changes['campaignData'].isFirstChange()) ||
        (changes['criteriaArray'] && !changes['criteriaArray'].isFirstChange())
      ) {
        this.getWebsiteConversionRatePercentage();
      }
    }
  }

  getWebsiteConversionRatePercentage() {
    const criteriaArray: any = [
      {
        id: '',
        is_required: true,
        criterion: 'dateRange',
        value: {
          start_date: '',
          end_date: '',
          relative_date: this.relativeDate
        }
      }
    ];

    if (this.campaignData && this.campaignData != "") {
      criteriaArray.push({
        'id': '',
        'value': { c_id: this.campaignData },
        'criterion': 'campaign',
        'is_required': true
      });
    }

    this.isConversionLoaded = false;
    this.visitorService.getConversionRate(criteriaArray)
      .subscribe((res: any) => {
        this.websiteConversionRatePercentage = (res.data.average_conversion_rate * 100).toFixed(2);
        this.isConversionLoaded = true;
      });
  }
}
