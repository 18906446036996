import { Component, Input, ElementRef, OnChanges } from '@angular/core';
import * as _ from 'lodash';
import { ChangeTimezonePipe } from '@app/shared/pipes/change-timezone.pipe'
import * as moment from 'moment';

@Component({
    selector: 'read-more',
    template: `
        <div  [innerHTML]="currentText"></div>
        <a [class.hidden]="hideToggle" (click)="toggleView()">Read {{isCollapsed? 'more':'less'}}</a>
    `
})

export class ReadMoreComponent implements OnChanges {
    @Input() text: any;
    @Input() maxLength: number = 100;
    currentText: any;
    hideToggle: boolean = true;

    public isCollapsed: boolean = true;

    constructor(private elementRef: ElementRef) { }

    toggleView() {
        this.isCollapsed = !this.isCollapsed;
        this.determineView();
    }

    determineView() {
        this.text = this.prepText(this.text);
        if (this.text.length <= this.maxLength) {
            this.currentText = this.text;
            this.isCollapsed = false;
            this.hideToggle = true;
            return;
        }
        this.hideToggle = false;
        if (this.isCollapsed == true) {
            this.currentText = this.text.substring(0, this.maxLength) + "...";
        } else if (this.isCollapsed == false) {
            this.currentText = this.text;
        }

    }

    isDate(dateStr) {
        var dateType = /(\d{4})([\/-])(\d{1,2})\2(\d{1,2})/;
        return dateType.test(dateStr);
    }

    prepText(text) {
        if (_.isString(text)) {
            return text;
        }
        let tempText = '';
        if (_.isArray(text)) {
            _.forEach(text, (indexVal: any) => {
                tempText += indexVal.label + ' : ';
                if (this.isDate(indexVal.description)) {
                    tempText += new ChangeTimezonePipe().transform(indexVal.description, 'YYYY-MM-DD hh:mm A');
                } else {
                    tempText += indexVal.description;
                }
                tempText += '<br>';
            });
            return tempText;
        }
        return tempText;
    }

    ngOnChanges() {
        this.determineView();
    }
}