import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import 'rxjs/add/operator/map';
import { globalValues } from '@app/globals';
import { CustomHttp } from '@service/customhttp.service';
import { RequestLibraryService } from '@service/core/request-library.service';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class CallReportService {
    private customHttp: CustomHttp;
    constructor(
        private http: HttpClient,
        private router: Router,
        private requestLibrary: RequestLibraryService
    ) {
        this.customHttp = new CustomHttp(http, router);
    }

    getInboundCallCount(criteriaArray) {
        return this.requestLibrary.GetAll('/calls/reports/count', {
            'options': { criteria: criteriaArray }
        })
    }

    getChartData(criteriaArray) {
        return this.requestLibrary.GetAll('/calls/reports/count', {
            'options': { criteria: criteriaArray }
        });
    }

    getRevenue() {
        return this.requestLibrary.Get('/calls/reports/revenue')
            .map((res: any) => res.data);
    }

    getCallsAverageDuration(criteriaArray) {
        return this.requestLibrary.GetAll('/calls/reports/average-duration', {
            'options': { criteria: criteriaArray }
        });
    }
}
