<div *ngIf="!isLoaded" class="whirl standard spinnermargin"></div>
<div class="row" *ngIf="isLoaded">
  <div *ngIf="timelineHistory.length > 0" class="col-md-12 hyperlink-color">
    <ul [ngClass]="timelineAlt ? 'timeline-alt' : 'timeline'">
      <ng-template ngFor let-history [ngForOf]="timelineHistory" let-i="index" let-odd="odd" let-even="even">
        <li *ngIf="history && history.time && checkDateGroup(history.note.timestamp) == true" class="timeline-separator" [attr.data-datetime]="history.time | changeTimezone:'MM/DD/YYYY'"></li>
        <li *ngIf="i==0 || even">
          <div class="timeline-badge danger">
            <em class="fa fa-commenting"></em>
          </div>
          <div class="timeline-panel">
            <div class="popover left" [ngClass]="timelineAlt ? 'right' : 'left'">
              <h4 class="popover-title">
                <em>{{ history.note.user.name}}</em>
                <br>
                <small *ngIf="history.note.user" class="small-text"> by {{ history.note.user.name }}</small>
                <small class="small-text"> at {{ history.note.timestamp | changeTimezone:'MM/DD/YYYY hh:mm a' }}</small>
              </h4>
              <div class="arrow"></div>
              <div class="popover-content">
                <read-more *ngIf="history.note.body" [text]="history.note.body" [maxLength]="100"></read-more>
                <p *ngIf="!history.note.body"></p>
              </div>
            </div>
          </div>
        </li>
        <li *ngIf="odd" class="timeline-inverted">
          <div class="timeline-badge danger">
            <em class="fa fa-commenting"></em>
          </div>
          <div class="timeline-panel">
            <div class="popover right">
              <h4 class="popover-title">
                <em>{{ history.note.user.name}}</em>
                <br>
                <small *ngIf="history.note.user" class="small-text"> by {{ history.note.user.name }}</small>
                <small class="small-text"> at {{ history.note.timestamp | changeTimezone:'MM/DD/YYYY hh:mm a' }}</small>
              </h4>
              <div class="arrow"></div>
              <div class="popover-content">
                <read-more *ngIf="history.note.body" [text]="history.note.body" [maxLength]="100"></read-more>
                <p *ngIf="!history.note.body"></p>
              </div>
            </div>
          </div>
        </li>
      </ng-template>
    </ul>
  </div>
</div>
