<ng-template [ngxPermissionsOnly]="['campaigns:can_get_campaigns_reports_contact_per_channel']">
  <div class="box-row panel panel-default">
    <div class="panel-heading">
      <h4>{{widgetTitle}}</h4>
    </div>
    <div class="panel-body text-center">
      <div *ngIf="!leadsByChannelLoaded" class="whirl standard spinnermargin"></div>
      <ng-template [ngIf]="leadsByChannelLoaded">
        <pie-chart  *ngIf="chartType=='pieChart'"  [pieData]=leadsByChannelData [showLegends]=true></pie-chart>
        <line-chart *ngIf="chartType=='lineChart'" [chartData]="leadsByChannelData" [totalCount]="capturedCount"></line-chart>
      </ng-template>
    </div>
  </div>
</ng-template>