import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { AccountService, ToasterLibraryService } from '@service/index';
import { confirmationAlertSwalSettings } from '@service/app.config';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-account-change-status',
  templateUrl: './account-change-status.component.html',
  styleUrls: ['./account-change-status.component.scss']
})
export class AccountChangeStatusComponent implements OnInit {
  @Input() aConfig: any;
  @Input() nAccountID: number;
  @Input() sAccountStatus: number;
  @Input() sClass: string;
  @Output('statusUpdated') statusUpdated = new EventEmitter<any>();
  public currentAccountStatusLabel: string = 'Activate';
  constructor(private toasterLibrary: ToasterLibraryService, private accountService: AccountService) {}

  ngOnInit() {
    if (this.sAccountStatus == 1) {
      this.currentAccountStatusLabel = 'Deactivate';
    }
  }


  changeAccountStatus() {
    let swal_opts = Object.assign({},confirmationAlertSwalSettings);
        swal_opts['html'] = `<span class='confirm-alert-body'>Are you sure you want to ${this.sAccountStatus == 1 ? 'Deactivate' : 'Activate'} this client?</span>`;
        Swal.fire(swal_opts).then((result) => {
        if (result.isConfirmed) {
          this.changeStatus();
        } else if (result.dismiss === Swal.DismissReason.cancel) {
        }
    });
  }
  changeStatus() {
    this.accountService
      .changeStatus({
        id: this.nAccountID,
        is_crm_active: this.sAccountStatus == 1 ? 0 : 1,
        is_lead_buying_active: this.sAccountStatus == 1 ? 0 : 1
      })
      .subscribe(
        (res: any) => {
          if (res.success == 'true') {
            this.sAccountStatus = this.sAccountStatus == 1 ? 0 : 1;
            this.toasterLibrary.success('',this.currentAccountStatusLabel + ' Successful');
            this.currentAccountStatusLabel = this.sAccountStatus == 1 ? 'Deactivate' : 'Activate';
          } else {
            this.toasterLibrary.error('Activation Failed',res.message);
          }
          this.statusUpdated.emit();
        },
        err => {
          this.toasterLibrary.error('Activation Failed',err.error.message);
        }
      );
  }
}
