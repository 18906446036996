<h4>{{chartData.name}}
  <!--<span *ngIf="totalCount && totalCount!=''">({{totalCount}})</span>-->
</h4>
<div *ngIf="!totalCount || totalCount <= 10">
  <canvas baseChart [chartType]="'line'" [options]="lineOptionsForEmpty" [datasets]="chartData.datasets" [colors]="lineColors"
    [labels]="chartData.labels" [legend]="chartData.legend" height="120"></canvas>
</div>

<div *ngIf="totalCount && totalCount > 10">
  <canvas baseChart [chartType]="'line'" [options]="lineOptions" [datasets]="chartData.datasets" [colors]="lineColors"
    [labels]="chartData.labels" [legend]="chartData.legend" height="120"></canvas>
</div>
