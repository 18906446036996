import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { CustomHttp } from "@service/customhttp.service";
import "rxjs/add/operator/map";
import { toArray } from "rxjs/operators/toArray";
import { Logger } from "@service/global.funcs";
import { RequestLibraryService } from "@service/core/request-library.service";
import { HttpClient } from "@angular/common/http";

@Injectable()
export class UserService {
  private customHttp: CustomHttp;
  constructor(
    private http: HttpClient,
    private router: Router,
    private requestLibrary: RequestLibraryService
  ) {
    this.customHttp = new CustomHttp(http, router);
  }

  getPaginatedUsers(body) {
    return this.requestLibrary.GetAll("/users", {
      options: body,
    });
  }
  storePrivilege(user: number, privileges: any) {
    return this.requestLibrary.Post(`/users/${user}/privileges`, privileges);
  }

  getPrivilege(user: number) {
    return this.requestLibrary.Get(`/users/${user}/privileges`);
  }
  getMyPrivilege() {
    return this.requestLibrary.Get(`/users/my/privileges`);
  }

  getExportUsers(body) {
    return this.requestLibrary.GetCSV("/users", {
      options: body,
    });
  }

  getUser(id) {
    return this.requestLibrary.Get(`/users/${id}`);
  }
  getMyUser() {
    return this.requestLibrary.Get(`/users/my`);
  }
  getAllUser() {
    return this.requestLibrary.Get(`/users`);
  }

  getAllCompactUser() {
    return this.requestLibrary.GetAll('/users', {
      'options': { page_size: 'all', is_compact: true }
  });
  }

  changeUserStatus(model) {
    return this.requestLibrary.Post("/users", model);
  }
  
  inviteUser(model) {
    return this.requestLibrary.Post("/users/invite", model);
  }

  updateUser(model) {
    return this.requestLibrary.Post("/users", model);
  }
  updateMyUser(model) {
    return this.requestLibrary.Post("/users/my", model);
  }

  deleteUser(id) {
    return this.requestLibrary.Delete(`/users/${id}`);
  }

  changePassword(model) {
    return this.requestLibrary.Post("/users", model);
  }
  changeMyPassword(model) {
    return this.requestLibrary.Post("/users/my/changepassword", model);
  }

  getUserByProvider(provider){
    return this.requestLibrary.Get(`/users/${provider}`);
  }
}
