import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { environment } from '@env/environment';
import { Observable } from 'rxjs/Observable';
import * as _ from 'lodash';

@Injectable()
export class RequestLibraryService extends HttpClient {
    private baseUrl = environment.api_base_url;
    public GetAll<T>(endPoint: string, qArgs?: {}): Observable<T> {
        const options: IRequestOptions = {};
        if (qArgs) {
            options.params = this.toHttpParams(qArgs);
        }
        return super.get<T>(this.prepUrl(endPoint), options);
    }


    public BaseGet<T>(endPoint: string, options?: IRequestOptions): Observable<T> {
        return super.get<T>((endPoint), options);
    }

    public Get<T>(endPoint: string, options?: IRequestOptions): Observable<T> {
        return super.get<T>(this.prepUrl(endPoint), options);
    }

    public Post<T>(endPoint: string, params: Object, options?: IRequestOptions): Observable<T> {
        return super.post<T>(this.prepUrl(endPoint), params, options);
    }

    public Put<T>(endPoint: string, params: Object, options?: IRequestOptions): Observable<T> {
        return super.put<T>(this.prepUrl(endPoint), params, options);
    }

    public Delete<T>(endPoint: string) {
        return super.delete<T>(this.prepUrl(endPoint), { observe: 'response' })
            .map(this.toBooleanResponse)
    }

    public GetCSV(endPoint: string, qArgs?: {}) {
        const options: any = {};
        if (qArgs) {
            options.params = this.toHttpParams(qArgs);
            options.responseType = 'text';
            options.observe = 'response';
        }
        return super.get(this.prepUrl(endPoint), options)
            .map(this.toCSVResponse);
    }

    private prepUrl(url: string) {
        return `${this.baseUrl}${url}`;
    }
    private tojsonURI(json) { return (JSON.stringify(json)); }
    private toBooleanResponse(res: any) {
        if (res.status == 204) {
            return true;
        } else {
            return false;
        }
    }

    private toCSVResponse(res: any) {
        // console.log(res);
        let fileName = null;
        const headers = res.headers;
        const contentDisposition = headers.get('content-disposition') || '';
        if (contentDisposition) {
            const result = contentDisposition.split(';')[1].trim().split('=')[1];
            fileName = result.replace(/"/g, '')
        }

        if (res.status == 200 || res.status == 201 || res.status == 202) {
            const body = (<any>res).body;
            return { 'data': body, 'filename': fileName };
        } else {
            return [];
        }
    }

    private toHttpParams(params) {
        return Object.getOwnPropertyNames(params)
            .reduce((p, key) => p.set(key, this.tojsonURI(params[key])), new HttpParams());
    }
}


export interface IRequestOptions {
    headers?: HttpHeaders;
    observe?: 'body';
    params?: HttpParams;
    reportProgress?: boolean;
    responseType?: 'json';
    withCredentials?: boolean;
    body?: any;
}
