import 'rxjs/add/operator/map';

import { EventEmitter, Injectable } from '@angular/core';
import { Subject } from 'rxjs';

declare var Notification;
@Injectable()
export class AppService {
    public resetParentFilterEvent = new EventEmitter();
    public refreshParentListingEvent = new EventEmitter();
    // public changeContactFiltersFormEvent = new EventEmitter();

    private changeContactFiltersFormSource = new Subject<{ command: string, data: {} }>();
    public changeContactFiltersForm$ = this.changeContactFiltersFormSource.asObservable();

    private changeAutomationFiltersFormSource = new Subject<{ command: string, data: {} }>();
    public changeAutomationFiltersForm$ = this.changeAutomationFiltersFormSource.asObservable();

    private changeTasksFiltersFormSource = new Subject<{ command: string, data: {},isMarked }>();
    public changeTasksFiltersForm$ = this.changeTasksFiltersFormSource.asObservable();

    private inviteFormSource = new Subject<{ command: string, data: {}}>();
    public InviteForm$ = this.inviteFormSource.asObservable();

    private requestSource = new Subject<{ command: string, data: {} }>();
    public request$ = this.requestSource.asObservable();

    private changeAutomationScheduleSource = new Subject<{ command: string, data: {} }>();
    public changeAutomationScheduleForm$ = this.changeAutomationScheduleSource.asObservable();

    constructor() {
    }

    public resetParentFilter() {
        console.log('in:resetParentFilter');
        this.resetParentFilterEvent.emit();
    }

    public refreshParentListing() {
        console.log('in:refreshParentListing');
        this.refreshParentListingEvent.emit();
    }

    public changeContactFiltersForm(command: string, data: {}) {
        console.log('in:changeContactFiltersForm');
        this.changeContactFiltersFormSource.next({ command, data });
    }
    public changeTasksFiltersForm(command: string, data: {},isMarked: boolean) {
        console.log('in:changeTaskFiltersForm');
        this.changeTasksFiltersFormSource.next({ command, data,isMarked });
    }

    public inviteUserForm(command: string, data: {}) {
        console.log('in:InviteUserForm');
        this.inviteFormSource.next({ command, data});
    }

    public changeAutomationFiltersForm(command: string, data: {}) {
        console.log('in:changeAutomationFiltersForm');
        this.changeAutomationFiltersFormSource.next({ command, data });
    }

    public changeAutomationScheduleForm(command: string, data: {}) {
        console.log('in:changeAutomationScheduleForm');
        this.changeAutomationScheduleSource.next({ command, data });
    }

    public changeRequest(command: string, data: {}) {
        console.log('in:changeRequest');
        this.requestSource.next({ command, data });
    }


}
