import { NgModule } from '@angular/core';
import { RouterModule, PreloadAllModules } from '@angular/router';
import { APPCOMPONENTS } from './index';
import { AuthenticationGuard } from './authentication-guard/authentication-guard';
import { MenuService } from '../core/menu/menu.service';
import { TranslatorService } from '../core/translator/translator.service';
import appMenu from './menu'; //brings in statically declared menu components
import { apRroutes } from './routes';
import { SharedComponentModule } from './shared-components/shared-component.module';

@NgModule({
  imports: [
    RouterModule.forRoot(apRroutes, { preloadingStrategy: PreloadAllModules, relativeLinkResolution: 'legacy' }),
    // RouterModule.forRoot(apRroutes),
    SharedComponentModule
  ],
  declarations: [APPCOMPONENTS],
  providers: [AuthenticationGuard],
  exports: [RouterModule, APPCOMPONENTS]
})

/**
 * This class is mostly just imports - if you want the actual routing information, look at routes.ts.
 */
export class RoutesModule {
  /**
   * This is bascially only used to load the sidebar
   */
  constructor(private menu: MenuService, tr: TranslatorService) {
    menu.addMenu(appMenu);
  }
}
