// Import the core angular services.
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import 'rxjs/add/observable/of';
//Import Services,Components,Pipes
import { EmailTemplateService } from '@service/index';

@Component({
    selector: 'app-template-legend',
    templateUrl: './template-legend-modal.component.html',
    styleUrls: ['./template-legend-modal.component.scss']
})

export class TemplateLegendModalComponent implements OnInit {
    public activeTab: string = 'contact';
    public templateLegends: Array<any> = [];
    public selectedLegend: Array<any> = [];
    private isLoaded = false;
    @Output('templateLegendCloseEvt') templateLegendCloseEvt = new EventEmitter();
    constructor(private router: Router, private emailService: EmailTemplateService) {
        // this.dialog.context.dialogClass = 'template-legend-modal';
    }

    ngOnInit() {
        this.getTemplateLegends();
    }

    getTemplateLegends() {
        this.emailService
            .getTemplateLegend()
            .subscribe((res: any) => {
                if (res.success == 'true') {
                    this.templateLegends = res.data;
                    this.loadLegends(this.activeTab);
                } else {
                    this.router.navigate(['error']);
                }
            }, err => {
                this.router.navigate(['error']);
            })
    }

    loadLegends(type: string) {
        this.activeTab = type;
        this.selectedLegend = this.templateLegends[type];
    }

    closeDialog() {
        this.templateLegendCloseEvt.emit(true);
    }

    cancelDialog() {
        this.templateLegendCloseEvt.emit(false);
    }
}


