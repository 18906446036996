import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CustomHttp } from '@service/customhttp.service';
import { RequestLibraryService } from '@service/core/request-library.service';
import 'rxjs/add/operator/map';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class ContactActionService {
    private customHttp: CustomHttp;
    constructor(
        private http: HttpClient,
        private router: Router,
        private requestLibrary: RequestLibraryService
    ) {
        this.customHttp = new CustomHttp(http, router);
    }

    deleteMultipleLeads(model) {
        return this.requestLibrary.Post('/contactactions', model);
    }

    sellLeads(model) {
        return this.requestLibrary.Post('/contactactions', model);
    }

    postContactAction(model: any) {
        return this.requestLibrary.Post('/contactactions', model);
    }



    getSellActions() {
        // return this.customHttp.get('/contactactions');
        return this.http.get<any>('assets/leads/sell-actions.json').map(this.extractData);
    }

    sendEmail(model) {
        return this.requestLibrary.Post('/sendemail', model.vars);
    }
    

    private extractData(res: Response) {
        let body = res;
        // let body = res.json();
        return body;
    }

}
