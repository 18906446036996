import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import 'rxjs/add/operator/map';
import { globalValues } from '@app/globals';
import { CustomHttp } from '@service/customhttp.service';
import { RequestLibraryService } from '@service/core/request-library.service';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class CallService {
  private customHttp: CustomHttp;
  constructor(private http: HttpClient, private router: Router, private requestLibrary: RequestLibraryService) {
    this.customHttp = new CustomHttp(http, router);
  }
  getPaginatedCalls(page, page_size, sort, sort_dir, criteriaArray) {
    return this.requestLibrary.GetAll('/calls', {
      options: {
        page: page,
        page_size: page_size,
        sort: sort,
        sort_dir: sort_dir,
        criteria: criteriaArray
      }
    });
  }

  getExportedCalls(args) {
    return this.requestLibrary.GetCSV('/calls', {
      options: args
    });
  }
  getCall(id) {
    return this.requestLibrary.Get('/calls/' + id);
  }
  deleteCalls(id: string) {
    return this.requestLibrary.Delete('/calls/' + id);
  }

  getCallBuyingSettings(id, args = null) {
    if (args != null) {
      return this.requestLibrary.GetAll('/calls/leadbuyingsettings/' + id, {
        options: args
      });
    }
    return this.requestLibrary.Get('/calls/leadbuyingsettings/' + id);
  }

  updateCallBuyingSettings(model) {
    return this.requestLibrary.Post('/calls/leadbuyingsettings', model);
  }
}
