<div *ngIf="!isLoaded" class="whirl standard spinnermargin"></div>
<div class="row" *ngIf="isLoaded">
  <div *ngIf="timelineHistory.length > 0" class="col-md-12 hyperlink-color pre-scrollable">
    <ul [ngClass]="timelineAlt ? 'timeline-alt' : 'timeline'">
      <ng-template ngFor let-history [ngForOf]="timelineHistory" let-i="index" let-odd="odd" let-even="even">
        <li *ngIf="history && history.time && checkDateGroup(history.email.timestamp) == true" class="timeline-separator" [attr.data-datetime]="history.time | changeTimezone:'MM/DD/YYYY'"></li>
        <li *ngIf="i==0 || even">
          <div class="timeline-badge danger">
            <em class="fa fa-commenting"></em>
          </div>
          <div class="timeline-panel">
            <div class="popover right">
              <div class="popover-title">
                <span>From: {{history.email.sender}}</span>
                <br>
                <strong >{{ history.email.subject }}</strong>
                <!-- <small class="small-text">{{ history.email.body }}</small> -->
                <small class="small-text"> at {{ history.email.timestamp | changeTimezone:'MM/DD/YYYY hh:mm a' }}</small>
              </div>
              <div class="arrow"></div>
              <div class="popover-content">
                <read-more *ngIf="history.email.body" [text]="history.email.body" [maxLength]="450"></read-more>
                <p *ngIf="!history.email.body"></p>
              </div>
            </div>
          </div>
        </li>
        <li *ngIf="odd" class="timeline-inverted">
          <div class="timeline-badge danger">
            <em class="fa fa-commenting"></em>
          </div>
          <div class="timeline-panel" [ngClass]="timelineAlt ? 'right' : 'left'">
            <div class="popover right">
              <div class="popover-title">
                <span>From: {{history.email.sender}}</span>
                <br>
                <strong >{{ history.email.subject }}</strong>
                <!-- <small class="small-text">{{ history.email.body }}</small> -->
                <small class="small-text"> at {{ history.email.timestamp | changeTimezone:'MM/DD/YYYY hh:mm a' }}</small>
              </div>
              <div class="arrow"></div>
              <div class="popover-content">
                <read-more *ngIf="history.email.body" [text]="history.email.body" [maxLength]="450"></read-more>
                <p *ngIf="!history.email.body"></p>
              </div>
            </div>
          </div>
        </li>
      </ng-template>
    </ul>
  </div>
</div>
