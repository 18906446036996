import { Injectable, Inject } from '@angular/core';
import { Logger } from '@service/global.funcs';
import { Observable } from 'rxjs/Rx';
import 'rxjs/add/operator/map';
import { RefundReasonService } from './refund-reason/refund-reason.service';
import { UserService } from './user/user.service';
import { AppConfig } from './app.config';

@Injectable()
export class SetupAppService {
  private refundReasonArray: any[] = [];
  private defaultRefundReasonArray: any[] = AppConfig.defaultRefundReasonArray;

  constructor(
    private refundReasonService: RefundReasonService,
    private userService: UserService
  ) {
  }


  public setupApp() {
    // const installers = [
    //     SetupUserPrivilegeService,
    //     SetupRefundReasonService
    // ];
    // for (const installer of installers) {
    //     (new installer()).run();
    // }
    return Observable.create((observer) => {
      const user: number = +(localStorage.getItem('User'));
      Observable.forkJoin(
        this.refundReasonService.getAllRefundReasons()
      ).subscribe((results: any) => {
        const [
          refundReasons
        ] = results;

        if (refundReasons.success == 'true') {
          this.refundReasonArray = refundReasons.data;
        }
        // Observable
        //     .combineLatest(
        this.prepRefundReasons()
          .subscribe((response) => {
            Logger().info('in:prep variables complete');
          });
      }, err => {
        Logger().info(err);
      });
    }).toPromise();
  }

  private prepRefundReasons() {
    return Observable.create((observer) => {
      Logger().info('in:prepRefundReasons');
      return Observable
        .from(this.defaultRefundReasonArray)
        .flatMap((refundReason) => {
          return Observable.forkJoin([
            Observable
              .of(refundReason),
            this.getRefundReasonId(refundReason)
          ]);
        })
        .subscribe((result) => {
          observer.next(result);
        });
    });
  }

  private getRefundReasonId(strToFind) {
    Logger().info('in:getRefundReasonId');
    return Observable.create((observer) => {
      return Observable
        .of(strToFind)
        .flatMap((refundReason) => {
          return Observable.forkJoin([
            Observable
              .of(refundReason),
            Observable
              .from(this.refundReasonArray)
              .filter((reason: any) => reason.reason_text == refundReason)
              .defaultIfEmpty(false)
          ]);
        }).subscribe((results) => {
          const [refundReason, foundReason] = results;
          Logger().info(refundReason, foundReason);
          if (!foundReason) {
            this.refundReasonService
              .addEditRefundReason({
                reason_text: refundReason,
              })
              .subscribe((res: any) => {
                observer.next(res.data);
              });
          } else {
            observer.next(foundReason);
          }
        });
    });
  }

  public handleSmallLogo() {
    const small_log = localStorage.getItem('small_logo');
    const head = document.getElementsByTagName('head')[0];
    const faviconTag = document.createElement('link');
    faviconTag.type = 'image/*';
    faviconTag.id = 'globalRefFavicon';
    faviconTag.rel = 'icon';
    // faviconTag.href = 'assets/img/mo_small_logo.png';
    head.appendChild(faviconTag);


    if (small_log !== '') {
      faviconTag.href = small_log;
      return Promise.resolve();
    } else {
      faviconTag.href = 'assets/img/mo_small_logo.png';
      return Promise.resolve();
    }
  }
}
