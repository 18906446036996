import { Component, Input, OnChanges, OnInit, SimpleChange } from '@angular/core';
import { Privilege } from '@decorator/index';
import { AbTestReportService, ToasterLibraryService, VisitorService } from '@service/index';
import { NgxPermissionsService } from 'ngx-permissions';
@Component({
  selector: 'app-website-visitor-widget',
  templateUrl: './website-visitor-widget.component.html',
  styleUrls: ['./website-visitor-widget.component.scss'],
})

// @Privilege({
//   privilege: 'visitors:can_get_visitors_reports_count'
// })

export class WebsiteVisitorWidgetComponent implements OnInit, OnChanges {
  @Input() relativeDate: any;
  @Input() campaignData: any;
  public isCountLoaded = false;
  public websiteVisitorsCount = 0;
  constructor(
    private testReportService: AbTestReportService,
    private visitorService: VisitorService,
    private toasterLibrary: ToasterLibraryService,
    private permissionService: NgxPermissionsService
  ) {
  }

  ngOnInit() {
    if (this.permissionService.hasPermission('visitors:can_get_visitors_reports_count')) {
      this.getWebsiteVisitorsCount();
    }

  }

  ngOnChanges(changes: { [key: string]: SimpleChange }) {
    if (this.permissionService.hasPermission('visitors:can_get_visitors_reports_count')) {
      if (
        (changes['relativeDate'] && !changes['relativeDate'].isFirstChange()) ||
        (changes['campaignData'] && !changes['campaignData'].isFirstChange()) ||
        (changes['criteriaArray'] && !changes['criteriaArray'].isFirstChange())
      ) {
        this.getWebsiteVisitorsCount();
      }
    }
  }

  getWebsiteVisitorsCount() {
    const criteriaArray: any = [
      {
        'id': '',
        'is_required': true,
        'criterion': 'dateRange',
        'value': {
          'start_date': '',
          'end_date': '',
          'relative_date': this.relativeDate
        }
      }
    ];

    if (this.campaignData && this.campaignData != "") {
      criteriaArray.push({
        'id': '',
        'value': { c_id: this.campaignData },
        'criterion': 'campaign',
        'is_required': true
      });
    }

    this.isCountLoaded = false;
    this.visitorService
      .getVisitors(criteriaArray)
      .subscribe((res: any) => {
        this.websiteVisitorsCount = res.data.count;
        this.isCountLoaded = true;
      });
  }

}
