import { DatePipe } from '@angular/common';
import { Component, Input, OnInit, SimpleChange } from '@angular/core';
import { Logger } from '@service/global.funcs';
import { ContactService, ToasterLibraryService } from '@service/index';
import 'rxjs/add/observable/of';

@Component({
  selector: 'app-timeline-note',
  templateUrl: './timeline-note.component.html',
  providers: [DatePipe]
})
export class TimeLineNoteComponent implements OnInit {
  @Input()
  public contactId: any;
  @Input()
  private timelineRefresh;
  public timelineHistory: Array<any> = [];
  public isLoaded: Boolean = false;
  public tempDateContainer: string = '';
  constructor(
    private datePipe: DatePipe,
    private contactService: ContactService,
    private toasterLibrary: ToasterLibraryService
  ) {}

  ngOnInit() {
    this.getTimelineHistory();
  }
  ngOnChanges(changes: { [key: string]: SimpleChange }) {
    if (!changes['timelineRefresh'].isFirstChange()) {
      this.getTimelineHistory();
    }
  }
  getTimelineHistory() {
    this.contactService.getContactNoteTimeline(this.contactId).subscribe(
      (res: any) => {
        if (res.success == 'true') {
          this.isLoaded = true;
          this.timelineHistory = res.data;
        }
      },
      err => {
        this.toasterLibrary.error(null, err.error.message);
      }
    );
  }

  checkDateGroup(date: string) {
    date = this.datePipe.transform(date, 'yyyy-MM-dd');
    if (date != this.tempDateContainer) {
      this.tempDateContainer = date;
      return true;
    } else {
      return false;
    }
  }
}
