import { Injectable } from "@angular/core"
import { Router } from "@angular/router"
import { CustomHttp } from "@service/customhttp.service"
import "rxjs/add/operator/map"
import { toArray } from "rxjs/operators/toArray"
import { Logger } from "@service/global.funcs"
import { RequestLibraryService } from "@service/core/request-library.service"
import { HttpClient } from "@angular/common/http"

@Injectable({
  providedIn: "root",
})
export class TaskService {
  private customHttp: CustomHttp
  constructor(
    private http: HttpClient,
    private router: Router,
    private requestLibrary: RequestLibraryService
  ) {
    this.customHttp = new CustomHttp(http, router)
  }

  saveForm(model) {
    return this.requestLibrary.Post("/followups", model)
    // return this.customHttp.post('/contacts', model);
  }
  changeOwnerFollowups(model) {
    return this.requestLibrary.Post("/followups/change-owner", model)
    // return this.customHttp.post('/contacts', model);
  }
  markCompleteFollowups(model) {
    return this.requestLibrary.Post("/followups/status", model)
    // return this.customHttp.post('/contacts', model);
  }

  getFilteredFollowups(body) {
    return this.requestLibrary.GetAll("/followups", { options: body })
    //return this.requestLibrary.Get('/followups');
  }
  getPaginatedFollowups(page, page_size, sort, sort_dir,task_filter,task_type_id,search_term) {
    return this.requestLibrary.GetAll('/followups', {
      'options': { page: page, page_size: page_size, sort_dir: sort_dir,task_filter: task_filter,task_type_id: task_type_id,search_term:search_term}
    });
  }

  getCountFollowups(body) {
    return this.requestLibrary.GetAll('/followups/reports/count', { options: body });
  }

  deleteFollowup(model) {
    return this.requestLibrary.Post("/followups/delete", model)
    // return this.requestLibrary.Delete('/followups/' + id);
  }
  getFullTaskTemplates() {
    return this.requestLibrary.GetAll("/tasktemplates", {
      options: { page_size: "all" },
    })
  }

  getTaskTypes() {
    return this.requestLibrary.Get("/tasktypes")
  }

  getAllTaskTemplate() {
    return this.requestLibrary.Get("/tasktemplates")
  }

  getAllTaskFollowup() {
    return this.requestLibrary.Get("/followups")
  }
  getTaskFollowupDetail(id) {
    return this.requestLibrary.Get(`/followups/${id}`)
  }
}
