import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router
} from '@angular/router';
import { AuthService } from '@service/index';
import * as _ from 'lodash';
declare var window: any;
import { Logger } from '@service/global.funcs';
import { Intercom } from 'ng-intercom';
@Injectable()
export class AuthenticationGuard implements CanActivate {
  constructor(
    private authService: AuthService,
    private router: Router,
    public intercom: Intercom) {
  }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (!this.authService.isAuthenticated()) {
      this.router.navigate(['/login'], { queryParams: route.queryParams });
    }

    if (this.authService.isAuthenticated() && _.has(route.queryParams, '_as')) {
      const queryParam = route.queryParams;
      Logger().info(queryParam._as);
      this.router.navigate(['/act-as'], { queryParams: route.queryParams });
    }
    this.intercom.update();
    return true;
  }
}
