import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import 'rxjs/add/operator/map';
import { globalValues } from '@app/globals';
import { CustomHttp } from '@service/customhttp.service';
import { RequestLibraryService } from '@service/core/request-library.service';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class AccountInvoiceService {
  private customHttp: CustomHttp;
  constructor(private http: HttpClient, private router: Router, private requestLibrary: RequestLibraryService) {
    this.customHttp = new CustomHttp(http, router);
  }

  getInvoices(id, page, page_size, sort, sort_dir) {
    if (id !== '') {
      return this.requestLibrary.GetAll('/accountinvoices/' + id, {
        options: {
          page: page,
          page_size: page_size,
          sort: sort,
          sort_dir: sort_dir
        }
      });
    } else {
      return this.requestLibrary.GetAll('/accountinvoices/my', {
        options: {
          page: page,
          page_size: page_size,
          sort: sort,
          sort_dir: sort_dir
        }
      });
    }
  }

  getExportedInvoices(id, args) {
    if (id != '') {
      return this.customHttp.getDownloadableCsv('/accountinvoices/' + id + '?options=', args);
    } else {
      return this.customHttp.getDownloadableCsv('/accountinvoices/my?options=', args);
    }
  }

  getCategories(id) {
    if (id != '') {
      return this.requestLibrary.Get('/accountinvoices/' + id + '/categories');
    } else {
      return this.requestLibrary.Get('/accountinvoices/my/categories');
    }
  }

  addInvoice(model) {
    return this.requestLibrary.Post('/accountinvoices', model);
  }

  getInvoiceItems(invoice_id) {
    return this.requestLibrary.Get('/accountinvoices/' + invoice_id + '/items');
  }

  deleteInvoice(invoice_id) {
    return this.requestLibrary.Delete('/accountinvoices/' + invoice_id);
  }

  deleteInvoiceItem(item_id) {
    return this.requestLibrary.Delete('/accountinvoices/' + item_id + '/item');
  }

  billAccount(model) {
    return this.requestLibrary.Post('/accountinvoices', model);
  }
}
