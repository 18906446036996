import { Component, Input, OnChanges, OnInit, SimpleChange } from '@angular/core';
import { Privilege } from '@decorator/index';
import { AbTestReportService, ToasterLibraryService, VisitorService, ContactReportService } from '@service/index';
import { NgxPermissionsService } from 'ngx-permissions';
import { Logger } from '@service/global.funcs';
@Component({
  selector: 'app-lead-to-event-rate-widget',
  templateUrl: './lead-to-event-rate-widget.component.html',
  styleUrls: ['./lead-to-event-rate-widget.component.scss']
})

// @Privilege({
//   privilege: 'visitors:can_get_visitors_reports_average_conversion_rate'
// })
export class LeadToEventRateWidgetComponent implements OnInit, OnChanges {
  @Input() relativeDate: any;
  public filteredLeadsCount: number = 0;
  public filteredLeadsLoaded: boolean = false;
  public isVobLoaded: boolean = false;
  public isVobRateLoaded: boolean = false;
  public vobCount: number = 0;
  public vobRatePercentage: any = 0.0;
  public criteriaArray: Array<any> = [];
  public myAccountSettings: {
    settings_name: 'addictiontreatment';
    settings_value: {
      admissions_role_id: '';
      business_dev_rep_role_id: '';
      ref_out_event_id: '';
      ref_out_status_id: '';
      vob_event_id: '';
    };
  };

  constructor(
    private contactReportService: ContactReportService,
    private toasterLibrary: ToasterLibraryService,
    private permissionService: NgxPermissionsService
  ) {}

  ngOnInit() {
    if (this.permissionService.hasPermission('visitors:can_get_visitors_reports_average_conversion_rate')) {
      this.getAddictionTreatmentSettings();
      this.getVobRatePercentage();
    }
  }

  ngOnChanges(changes: { [key: string]: SimpleChange }) {
    if (this.permissionService.hasPermission('visitors:can_get_visitors_reports_average_conversion_rate')) {
      if (
        (changes['relativeDate'] && !changes['relativeDate'].isFirstChange()) ||
        (changes['campaignData'] && !changes['campaignData'].isFirstChange()) ||
        (changes['criteriaArray'] && !changes['criteriaArray'].isFirstChange())
      ) {
        this.getVobRatePercentage();
      }
    }
  }
  getAddictionTreatmentSettings() {
    this.myAccountSettings = JSON.parse(localStorage.getItem('addictiontreatment'));
  }

  getVobRatePercentage() {
    this.isVobLoaded = false;
    const criteriaArray = [
      {
        id: '',
        is_required: true,
        criterion: 'dateRange',
        value: {
          start_date: '',
          end_date: '',
          relative_date: this.relativeDate
        }
      }
    ];

    this.contactReportService
      .getVobCount(criteriaArray, this.myAccountSettings.settings_value.vob_event_id)
      .subscribe((res: any) => {
        this.vobCount = parseInt(res.data.count, 10);
        this.isVobLoaded = true;
        this.contactReportService.getFilteredLeadsCount(criteriaArray).subscribe((res: any) => {
          this.filteredLeadsCount = parseInt(res.data.count, 10);
          this.filteredLeadsLoaded = true;
          if (this.filteredLeadsCount <= 0) {
            this.vobRatePercentage = "0.00";
          } else {
            this.vobRatePercentage = ((this.vobCount / this.filteredLeadsCount) * 100).toFixed(2);
          }
          this.isVobRateLoaded = true;
        });
      });
  }
}
