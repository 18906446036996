import { Component, Input, OnChanges, OnInit, SimpleChange } from '@angular/core';
import { ContactReportService, ToasterLibraryService, AppConfig } from '@service/index';
import { NgxPermissionsService } from 'ngx-permissions';
import * as _ from 'lodash';
@Component({
  selector: 'app-contact-sold-count-widget-leads',
  templateUrl: './contact-sold-count-widget-leads.component.html',
  styleUrls: ['./contact-sold-count-widget-leads.component.scss']
})

// @Privilege({
//   privilege: 'emailtemplates:can_get_emailtemplates_reports_sent'
// })
export class ContactSoldCountWidgetLeadsComponent implements OnInit, OnChanges {
  @Input() relativeDate: any = 'Last 30 Days';
  @Input() criteriaArray: any;
  public isCountLoaded = false;
  public contactsSoldCount: any = 0;
  @Input() widgetTitle = 'Sold Leads';
  public appContactSoldOptions = AppConfig.contactSoldOptions;
  constructor(
    private contactReportService: ContactReportService,
    private toasterLibrary: ToasterLibraryService,
    private permissionService: NgxPermissionsService
  ) { }
  ngOnInit() {
    if (this.permissionService.hasPermission('contacts:can_get_contacts_reports_sold_times')) {
      const criteriaArray = JSON.parse(localStorage.getItem('leadsCriteria'));
      if (criteriaArray && criteriaArray.length) {
        this.criteriaArray = criteriaArray;
      }

      this.requestContactsSoldCount();
    }
  }

  ngOnChanges(changes: { [key: string]: SimpleChange }) {
    if (this.permissionService.hasPermission('contacts:can_get_contacts_reports_sold_times')) {
      if (
        (changes['relativeDate'] && !changes['relativeDate'].isFirstChange()) ||
        (changes['campaignData'] && !changes['campaignData'].isFirstChange()) ||
        (changes['criteriaArray'] && !changes['criteriaArray'].isFirstChange()) ||
        (changes['priceData'] && !changes['priceData'].isFirstChange()) ||
        (changes['formData'] && !changes['formData'].isFirstChange())
      ) {
        this.requestContactsSoldCount();
      }
    }
  }

  requestContactsSoldCount() {
    let criteriaArray: any = [];
    if (this.criteriaArray && this.criteriaArray.length) {
      criteriaArray = this.criteriaArray;
    }
    this.isCountLoaded = false;

    this.contactReportService.getSoldContacts(criteriaArray, 'ContactSoldCountWidgetLeadsComponent')
      .subscribe((res: any) => {
        this.contactsSoldCount = res.data.reduce((total, item) => total + Number(item.number_of_occurrences), 0);
        this.isCountLoaded = true;
      });
  }
}
