<div class="filters-div hyperlink-color" *ngFor="let field of model.fieldData; let i=index;">
    <a *ngIf="i != 0" class="remove-item" (click)="removeItem(i)">Remove</a>
    <fieldset class="field-values-group">
        <div class="form-group">
            <div class="col-lg-12  col-sm-12 settingsfields">
                <select class="form-control" #requireexcludefieldvalue name="requireexcludefieldvalue"
                    [(ngModel)]="model.fieldData[i].is_required">
                    <option value=true>Require Field Value</option>
                    <option value=false>Exclude Field Value</option>
                </select>
            </div>
        </div>
    </fieldset>
    <fieldset class="field-values-group">
        <div class="form-group">
            <div class="col-lg-12 col-sm-12 settingsfields">
                <select class="form-control" #field name="field" [(ngModel)]="model.fieldData[i].ff_id"
                    (ngModelChange)="selectFieldChange($event, i, model)">
                    <option *ngFor="let field of fieldsArray" [ngValue]="field.id">{{field.name}}</option>
                </select>
            </div>
        </div>
    </fieldset>
    <fieldset>
        <div class="form-group">
            <div class="col-lg-6 col-sm-12 settingsfields">
                <select class="form-control" #rangefield name="rangefield" [(ngModel)]="model.fieldData[i].operator"
                    (ngModelChange)="selectOperatorChange($event, i, model)">
                    <option value="EQUAL">is Equal To</option>
                    <option value="BEGINS">Begins with</option>
                    <option value="CONTAINS">Contains</option>
                    <!-- <option value="GREATER">is Greater Than</option> -->
                    <!-- <option value="LESS">is Less Than</option> -->
                    <!-- <option value="BETWEEN">Between</option> -->
                </select>
            </div>

            <div *ngIf="selectedField[i]" class="col-lg-6  col-sm-12 settingsfields"
                [ngSwitch]="selectedField[i].input_type">
                <!-- In case of text field -->
                <input class="form-control" *ngSwitchCase="'Text'" id="populated_{{i}}" type="text" #fieldname
                    name="fieldname_{{i}}" [(ngModel)]="model.fieldData[i].fd_data"
                    (ngModelChange)="selectDataChange($event, i, model)" />
                <!-- In case of textarea field -->
                <textarea class="form-control" *ngSwitchCase="'Textarea'" id="populated_{{i}}" #fieldname
                    name="fieldname_{{i}}" [(ngModel)]="model.fieldData[i].fd_data"
                    (ngModelChange)="selectDataChange($event, i, model)"></textarea>
                <!-- In case of select -->
                <select class="form-control" id="populated_{{i}}" *ngSwitchCase="'Select'" #fieldname
                    name="fieldname_{{i}}" [(ngModel)]="model.fieldData[i].fd_data"
                    (ngModelChange)="selectDataChange($event, i, model)">
                    <option *ngFor="let val of selectValues[i]; let i=index" [value]="val[0]">{{val[1]}}</option>
                </select>
                <!-- In case of checkbox -->
                <label *ngSwitchCase="'Checkbox'" class="checkbox-inline c-checkbox">
                    <input id="populated_{{i}}" #fieldname name="fieldname_{{i}}" [checked]="checked" type="checkbox"
                        [(ngModel)]="model.fieldData[i].fd_data" (ngModelChange)="selectDataChange($event, i, model)" />
                    <span class="fa fa-check"></span>
                </label>
                <!-- In case of file input -->
                <input *ngSwitchCase="'File'" id="populated_{{i}}" type="file" #fieldname name="fieldname_{{i}}"
                    [(ngModel)]="model.fieldData[i].fd_data" (ngModelChange)="selectDataChange($event, i, model)" />
                <!-- Default case -->
                <input class="form-control" *ngSwitchDefault id="populated_{{i}}" type="text" #fieldname
                    name="fieldname_{{i}}" [(ngModel)]="model.fieldData[i].fd_data"
                    (ngModelChange)="selectDataChange($event, i, model)" />
            </div>
            <div *ngIf="!selectedField[i]" class="col-lg-6  col-sm-12 settingsfields">
                <input class="form-control" id="populated_{{i}}" type="text" #fieldname name="fieldname_{{i}}"
                    [(ngModel)]="model.fieldData[i].fd_data" (ngModelChange)="selectDataChange($event, i, model)" />
            </div>
        </div>
    </fieldset>
</div>
