import { Deserializable } from '../deserializable.model';
export interface IAutomation {
    id: number;
    s_id: number
    description: string;
    name: string;
    is_active: boolean;
    is_repeating: boolean;
    is_realtime: boolean;
    readonly criteria: ReadonlyArray<{}>;
    readonly actions: ReadonlyArray<{}>;
}


export class MAutomation implements IAutomation, Deserializable {
    id: number;
    s_id: number;
    name: string;
    description: string;
    is_active: boolean;
    is_repeating: boolean;
    is_realtime: boolean;
    actions;
    criteria;
    constructor(
        id = 0,
        s_id = 0,
        name = '',
        description = '',
        is_active = true,
        is_repeating = true,
        is_realtime = false,
    ) {
        this.id = id,
            this.s_id = s_id,
            this.name = name,
            this.description = description,
            this.is_active = is_active,
            this.is_repeating = is_repeating,
            this.is_realtime = is_realtime;
    }

    deserialize(input: any): this {
        Object.assign(this, input);
        return this;
    }
}

