import { NgModule } from '@angular/core';

import { LatestVersionComponent } from './latest-version.component';
import { LatestVersionService } from './lastest-version.service';

import { SharedModule } from '../shared/shared.module';

@NgModule({
    imports: [
        SharedModule
    ],
    providers: [LatestVersionService],
    declarations: [
        LatestVersionComponent
    ],
    exports: [
        LatestVersionComponent
    ]
})
export class LatestVersionModule { }
