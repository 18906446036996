import { ErrorHandler, Injectable } from '@angular/core';
import * as Airbrake from '@airbrake/browser';
import { environment } from '@env/environment';

@Injectable()
export class AirbrakeErrorHandler implements ErrorHandler {
  private airbrake = new Airbrake.Notifier({
    projectId: environment.airbrake.projectId,
    projectKey: environment.airbrake.projectKey,
    environment: environment.production ? 'production' : 'development',
  });

  handleError(error: any): void {
    this.airbrake.notify({
      error: error,
      params: { angular: true },
    }).then((notice) => {
      console.log('Airbrake Error ID:', notice.id);
    }).catch((airbrakeError) => {
      console.error('Airbrake failed to notify:', airbrakeError);
    });
  
    if (!environment.production) {
      console.error('An error occurred:', error);
    } else {
      // console.error('An error occurred. Please try again later.');
    }

    throw error;
  }
}