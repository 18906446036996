import { Component } from "@angular/core"
import { AuthLoginService } from "@service/auth/auth-login.service"
import { Title } from "@angular/platform-browser"
import { AccountService, CustomHttp } from "@service/index"

@Component({
  selector: "app-signup-page",
  templateUrl: "./signup-page.component.html",
  styleUrls: ["./signup-page.component.scss"],
})
export class SignupPageComponent {
  constructor(private auth: AuthLoginService, private titleService: Title) {}

  ngOnInit(): void {
    this.auth.signup()
  }
}
