import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'bar-chart',
  templateUrl: './bar-chart.component.html'
})
export class BarChartComponent implements OnInit {
  @Input() barData: any;
  @Input() totalCount: any;
  @Input() chartFor: string = 'normalSize';
  @Input() chartType: string = 'bar';
  @Input() chartDisableAspectRatio: any = 'true';

  barDataTest = {
    labels: ['2015-01-01', '2015-01-02', '2015-01-03', '2015-01-04', '2015-01-05', '2015-01-06', '2015-01-07'],
    datasets: [
      {
        data: [0, 0, 5, 0, 3, 0, 0]
      }
    ]
  };

  barColors = [
    {
      backgroundColor: '#e88b1b',
      borderColor: '#e88b1b',
      pointHoverBackgroundColor: '#e88b1b',
      pointHoverBorderColor: '#e88b1b'
    }
  ];

  optionsForLessThanTen: any = {
    // scaleShowVerticalLines: false,
    responsive: true,
    maintainAspectRatio: false,
    elements: {
      point: {
        radius: 0
      }
    },
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
            max: 10
          }
        }
      ],
      xAxes: [
        {
          ticks: {
            // Create scientific notation labels
            callback: function(value, index, values) {
              if (typeof value === 'string') {
                return value;
              } else {
                return parseInt(value);
              }
            }
          }
        }
      ]
    },
    tooltips: {
      callbacks: {
        title: function(tooltipItem, data) {
          return data.labels[tooltipItem[0].index];
        }
      }
    }
  };

  optionsForGreaterThanTen: any = {
    // scaleShowVerticalLines: false,
    responsive: true,
    maintainAspectRatio: false,
    elements: {
      point: {
        radius: 0
      }
    },
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true
          }
        }
      ],
      xAxes: [
        {
          ticks: {
            // Create scientific notation labels
            
            // if (typeof value === 'string' && value.length >= 10) {
            //   return value.substr(0, 10) + '...';
            // } else 
            callback: function(value, index, values) {
              if (typeof value === 'string') {
                return value;
              } else {
                return parseInt(value);
              }
            }
          }
        }
      ]
    },
    tooltips: {
      callbacks: {
        title: function(tooltipItem, data) {
          return data.labels[tooltipItem[0].index];
        }
      }
    }
  };

  constructor() {}

  ngOnInit() {
    if (this.chartDisableAspectRatio == 'false') {
      delete this.optionsForGreaterThanTen['maintainAspectRatio'];
      delete this.optionsForLessThanTen['maintainAspectRatio'];
    }
  }
}
