<ng-template [ngxPermissionsOnly]="['calls:can_get_calls_reports_inbound']">
  <div class="box-row panel panel-default">
    <div class="panel-body text-center text-alpha">
      <div *ngIf="!isCountLoaded" class="whirl standard spinnermargin"></div>
      <ng-template [ngIf]="isCountLoaded">
        <div class="text-lg mo">{{inboundCallsCount}}</div>
        <p>Inbound Calls</p>
        <div class="mb-lg"></div>
      </ng-template>
    </div>
  </div>
</ng-template>