import { Component, Input, OnChanges, OnInit, SimpleChange } from '@angular/core';
import { Privilege } from '@decorator/index';
import { globalValues } from '@app/globals';
import { ContactReportService, ToasterLibraryService, AppConfig } from '@service/index';
import { NgxPermissionsService } from 'ngx-permissions';
import { Logger } from '@service/global.funcs';

@Component({
  selector: 'app-contact-refund-by-channel-pie-widget',
  templateUrl: './contact-refund-by-channel-pie-widget.component.html',
  styleUrls: ['./contact-refund-by-channel-pie-widget.component.scss']
})

// @Privilege({
//   privilege: 'campaigns:can_get_campaigns_reports_sales_per_channel'
// })
export class ContactRefundByChannelPieWidgetComponent implements OnInit, OnChanges {
  @Input() relativeDate: any;
  @Input() campaignData: any;
  @Input() criteriaArray: any;
  @Input() formData: any;
  @Input() priceData: any;
  @Input() widgetTitle = 'Leads Refunded by Channel';
  @Input('chartType') chartType = 'pieChart';
  @Input('useTestData') useTestData = false;

  public appContactSoldOptions = AppConfig.contactSoldOptions;

  public contactRefundByChannelData = {
    labels: [],
    datasets: [
      {
        data: []
      }
    ],
    totalCount: 0
  };
  testData = {
    labels: ['2015-01-01', '2015-01-02', '2015-01-03', '2015-01-04', '2015-01-05', '2015-01-06', '2015-01-07'],
    datasets: [
      {
        data: [0, 2, 5, 0, 6, 3, 4]
      }
    ],
    totalCount: 7,
  };
  public contactRefundByChannelLoaded = false;
  constructor(
    private contactReportService: ContactReportService,
    private permissionService: NgxPermissionsService,
    private toasterLibrary: ToasterLibraryService
  ) { }

  ngOnInit() {
    if (this.permissionService.hasPermission('campaigns:can_get_campaigns_reports_sales_per_channel')) {
      if (this.useTestData) {
        this.contactRefundByChannelData = this.testData;
        this.contactRefundByChannelLoaded = true;
      } else {
        this.getContactRefundByChannel();
      }
    }
  }
  ngOnChanges(changes: { [key: string]: SimpleChange }) {
    if (this.permissionService.hasPermission('campaigns:can_get_campaigns_reports_sales_per_channel')) {
      if (
        (changes['relativeDate'] && !changes['relativeDate'].isFirstChange()) ||
        (changes['campaignData'] && !changes['campaignData'].isFirstChange()) ||
        (changes['criteriaArray'] && !changes['criteriaArray'].isFirstChange()) ||
        (changes['priceData'] && !changes['priceData'].isFirstChange()) ||
        (changes['formData'] && !changes['formData'].isFirstChange())
      ) {
        if (this.useTestData) {
          this.contactRefundByChannelData = this.testData;
          this.contactRefundByChannelLoaded = true;
        } else {
          this.getContactRefundByChannel();
        }
      }
    }
  }

  getContactRefundByChannel() {
    const criteriaArray: any = this.getCommonCriteria() || [];
    criteriaArray.push({
      id: '',
      is_required: true,
      criterion: 'soldOption',
      value: {
        sold_option: this.appContactSoldOptions.refund
      }
    });

    this.contactRefundByChannelLoaded = false;
    this.reInit();
    this.contactReportService.getContactsbyChannel(criteriaArray, 'ContactRefundByChannelPieWidgetComponent').subscribe(
      (res: any) => {
        if (res.success === 'true') {
          let fifthOptionCount = 0;
          let aggregateIndex = 0;
          res.data.forEach((channel, index) => {
            let cat = channel.channel;

            if (index < globalValues.pie_chart_slices) {
              if (!this.contactRefundByChannelData.labels.includes(cat)) {
                this.contactRefundByChannelData.labels.push(cat);
                this.contactRefundByChannelData.datasets[0].data.push(channel.number_of_occurrences);
              } else {
                aggregateIndex = this.contactRefundByChannelData.labels.indexOf(cat);

                this.contactRefundByChannelData.datasets[0].data[aggregateIndex] =
                  parseInt(this.contactRefundByChannelData.datasets[0].data[aggregateIndex], 10) +
                  parseInt(channel.number_of_occurrences, 10);

                Logger().info(
                  'getSalesByChannel',
                  'pie chart data',
                  'new channel',
                  this.contactRefundByChannelData.datasets[0].data[aggregateIndex]
                );
              }
            } else {
              fifthOptionCount = fifthOptionCount + parseInt(channel.number_of_occurrences, 10);
            }
            this.contactRefundByChannelData.totalCount =
              this.contactRefundByChannelData.totalCount + parseInt(channel.number_of_occurrences, 10);
          });
          if (fifthOptionCount > 0) {
            this.contactRefundByChannelData.labels.push('All Other');
            this.contactRefundByChannelData.datasets[0].data.push(fifthOptionCount);
          }
        }
        this.contactRefundByChannelLoaded = true;
      },
      err => {
        this.toasterLibrary.error(null, err.error.message);
      }
    );
  }
  private reInit() {
    this.contactRefundByChannelData = {
      labels: [],
      datasets: [
        {
          data: []
        }
      ],
      totalCount: 0
    };
  }

  private getCommonCriteria() {
    const criteriaArray: any = [
      {
        is_required: true,
        criterion: 'dateRange',
        value: {
          relative_date: this.relativeDate
        }
      }
    ];

    if (this.campaignData && this.campaignData != "") {
      criteriaArray.push({
        'id': '',
        'value': { c_id: this.campaignData },
        'criterion': 'campaign',
        'is_required': true
      });
    }

    if (this.formData && this.formData != "") {
      criteriaArray.push({
        value: [this.formData],
        criterion: 'form',
        is_required: true,
        id: ''
      });
    }

    if (this.priceData && this.priceData != "") {
      criteriaArray.push({
        value: { rprice_id: this.priceData },
        criterion: 'leadPricing',
        is_required: true,
        id: 0
      });
    }
    return criteriaArray;
  }
}
