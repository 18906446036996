import { Component } from '@angular/core';
import { AuthLogoutService } from '@service/auth/auth-logout.service';

@Component({
    selector: 'app-logout',
    templateUrl: './logout.component.html',
    providers: [AuthLogoutService]
})
export class LogoutComponent {

    constructor(private auth: AuthLogoutService) {
        this.auth.logout();
    }
}
