import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import 'rxjs/add/operator/map';
import { globalValues } from '@app/globals';
import { CustomHttp } from '@service/customhttp.service';
import { RequestLibraryService } from '@service/core/request-library.service';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class CallScheduleService {
    private customHttp: CustomHttp;
    constructor(
        private http: HttpClient,
        private router: Router,
        private requestLibrary: RequestLibraryService
    ) {
        this.customHttp = new CustomHttp(http, router);
    }

    getPaginatedSchedules(page, page_size, sort, sort_dir) {
        return this.requestLibrary.GetAll('/callschedules', {
            'options': { page: page, page_size: page_size, sort: sort, sort_dir: sort_dir }
        });
    }

    getAllSchedules() {
        return this.requestLibrary.GetAll('/callschedules', {
            'options': { page_size: 'all' }
        });
    }

    /** SCHEDULES SERVICES */
    addSchedule(model) {
        return this.requestLibrary.Post('/callschedules', model);
    }

    getScheduleDetail(id) {
        return this.requestLibrary.Get('/callschedules/' + id);
    }

    deleteSchedule(id) {
        return this.requestLibrary.Delete('/callschedules/' + id);
    }

    getAllCallTrackingSchedule() {
        return this.requestLibrary.GetAll('/callschedules', {
            'options': { page_size: 'all' }
        });
    }

}
