import { Injectable } from '@angular/core';
import * as _ from 'lodash';

@Injectable()
export class PickListService {
    private _picklistValues = {
        'calls-filter__billing_option': [
            {
                'value': '0',
                'label': 'All',
                'selected': true,
            },
            {
                'value': '1',
                'label': 'Billed',
                'selected': false,
            },
            {
                'value': '3',
                'label': 'Refunded',
                'selected': false,
            },
            {
                'value': '2',
                'label': 'Refunded or Duplicate',
                'selected': false,
            },
        ],
        'call-tracking__field_type': [
            {
                'value': '410',
                'label': 'Home',
                'selected': true,
            },
            {
                'value': '411',
                'label': 'Business',
                'selected': false,
            },
            {
                'value': '412',
                'label': 'Mobile',
                'selected': false,
            },
        ],
        'call-tracking__address_fields_name': [
            {
                'value': 'business',
                'label': 'Business',
                'selected': false,
            },
            {
                'value': 'home',
                'label': 'Home',
                'selected': true,
            }
        ],
        'private-label__interface_url': [
            {
                'value': 'https://clientportal.marketingoptimizer.com',
                'label': 'Lead Buyer Portal',
                'selected': true,
            },
            {
                'value': 'https://addictiontreatment.marketingoptimizer.com',
                'label': 'Addiction Treatment',
                'selected': false,
            },
            {
                'value': 'https://aggregation.staging.marketingoptimizer.com',
                'label': 'Lead Aggregation',
                'selected': false,
            },
            {
                'value': '-1',
                'label': 'Custom',
                'selected': false,
            }
        ],
        'test__type': [
            {
                'value': 'website_page',
                'label': 'Website Page',
                'selected': true,
            },
            {
                'value': 'call-to-action',
                'label': 'Call-to-Action',
                'selected': false,
            },
            {
                'value': 'pop_up',
                'label': 'PopUp',
                'selected': false,
            },
            {
                'value': 'landing_page',
                'label': 'Landing Page',
                'selected': false,
            }
        ]
    };

    constructor() {
    }

    public get(picklist) {
        return this._picklistValues[picklist];
    }

    public getByValue(picklist, value, what = 'label') {
        return _.result(_.find(this.get(picklist), { 'value': value.toString() }), what);
    }
}
