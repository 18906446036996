import { SweetAlertOptions } from "sweetalert2";

enum AppVersions {
  AGGREGATION = 'aggregation',
  AUTOMATION = 'automation',
  ADDICTION = 'addiction',
  PORTAL = 'portal'
}
enum AppSoldOptions {
  sold = 1,
  unsold = 2,
  refund = 3,
  refundrequested = 4,
  refundrejected = 5,
}

export interface IAppConfig {
  exportLimit: number;
  toasterPositionClass: string;
  toasterShowCloseButton: boolean;
  defaultRefundReasonArray: any[];
  availableAppVersions: typeof AppVersions;
  contactCompletedFieldsOrder: any;
  contactSoldOptions: any;
  authCheckTimeInterval : number;
}
export const AppConfig: IAppConfig = {
  exportLimit: 500,
  toasterPositionClass: 'toast-top-right',
  toasterShowCloseButton: true,
  defaultRefundReasonArray: [
    'Call Did Not Meet Min Talk Time',
    'Other',
    'Wrong Number',
    'Did Not Meet Client Requirement',
    'Routing Error',
    'Internal Testing',
    'Fax/Modem',
    'Dead Air',
    'Not Qualified'
  ],
  availableAppVersions: AppVersions,
  contactCompletedFieldsOrder: {
    '88': 1,
    '89': 2,
    '91': 3,
    '92': 4,
    '87': 5,
    '93': 6,
    '55568': 7,
    '135': 8,
    '3272': 9,
    '136': 10,
    '137': 11,
    '138': 12,
  },
  contactSoldOptions: AppSoldOptions,
  authCheckTimeInterval: 5000,
  
};
export const confirmationAlertSwalSettings : SweetAlertOptions = {
  showCancelButton: true,
  confirmButtonText: 'Yes',
  cancelButtonText: 'No',
  reverseButtons: true,
  position:'top',
  allowOutsideClick : false,
  customClass : {
      confirmButton: 'btn btn-primary ',
      cancelButton: 'btn btn-default mr-sm'
  },
  buttonsStyling: false,
  title : "<span class='confirm-alert-title'>Confirm</span>"
}


