import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment-timezone';

@Pipe({
  name: 'changeTimezone'
})
export class ChangeTimezonePipe implements PipeTransform {
  transform(value: any, args?: any): any {
    const timezone = localStorage.getItem('account_timezone'),
      timestamp = moment(value).utc(),
      format = args || 'MM dd, YYYY';

    return moment(timestamp)
      .tz(timezone)
      .format(format);
  }
}
