<div class="container">
  <div class="page-header">
    <h1><small>Update your Password</small></h1>
  </div>
  <div class="row">
    <div class="col-md-12">
      <form
        name="registrationForm"
        class="form-validate"
        #registrationForm="ngForm"
        (ngSubmit)="onSubmit()"
        novalidate
      >
        <div class="panel panel-default">
          <div class="panel-heading">
            <!-- <div class="panel-heading">Company Details</div> -->
          </div>

          <div class="panel-body">
            <div class="form-group">
              <label for="name">Password</label>
              <input
                    class="form-control"
                    type="password"
                    name="password"
                    #password
                    placeholder="password"
                    [(ngModel)]="modelPass.password"
                    required
                  />
            </div>
          </div>
          <div class="panel-footer">
            <button
              class="btn btn-primary btn-lg"
              type="submit"
              [disabled]="!registrationForm.form.valid"
            >
              Submit
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>

