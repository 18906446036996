import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import 'rxjs/add/operator/map';
import { globalValues } from '@app/globals';
import { CustomHttp } from '@service/customhttp.service';
import { RequestLibraryService } from '@service/core/request-library.service';
import { map } from 'rxjs/operators';
import { MAutomation } from '@model/automation/automation.model'
import { HttpClient } from '@angular/common/http';

@Injectable()
export class AutomationService {
    private customHttp: CustomHttp;
    constructor(
        private http: HttpClient,
        private router: Router,
        private requestLibrary: RequestLibraryService
    ) {
        this.customHttp = new CustomHttp(http, router);
    }

    getFilteredAutomations(body) {
        return this.requestLibrary.GetAll('/automations', { 'options': body });
    }

    getExportAutomations(body) {
        return this.requestLibrary.GetCSV('/automations', {
            'options': body
        });
    }

    getStackedBarData() {
        return this.requestLibrary.Get('/automations/reports/actions');
    }

    getAutomation(id: number) {
        return this.requestLibrary.GetAll('/automations/' + id, {
            'options': {
                expand: ['actions']
            }
        }).pipe(
            map(data => new MAutomation().deserialize(data))
        );
    }

    addEditAutomation(model) {
        console.log("Automation Model Before Save : "+ JSON.stringify(model));
        
        return this.requestLibrary.Post('/automations', model);
    }

    deleteAutomation(id) {
        return this.requestLibrary.Delete('/automations/' + id);
    }
}
