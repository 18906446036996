import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import 'rxjs/add/operator/map';
import { globalValues } from '@app/globals';
import { CustomHttp } from '@service/customhttp.service';
import { RequestLibraryService } from '@service/core/request-library.service';
import { HttpClient } from '@angular/common/http';
@Injectable()
export class DomainService {
    private customHttp: CustomHttp;
    constructor(
        private http: HttpClient,
        private router: Router,
        private requestLibrary: RequestLibraryService
    ) {
        this.customHttp = new CustomHttp(http, router);
    }

    addDomain(model) {
        return this.requestLibrary.Post('/domains', model);
    }

    getAllDomains() {
        return this.requestLibrary.GetAll('/domains', { 'options': { page_size: 'all' } });
    }

    getTrackingCode() {
        return this.requestLibrary.Get('/domains/trackingcode');
    }

    getPaginatedDomains(page, page_size, sort, sort_dir) {
        return this.requestLibrary.GetAll('/domains', {
            'options': { page: page, page_size: page_size, sort: sort, sort_dir: sort_dir }
        });
    }

    getExportedDomains(args) {
        return this.requestLibrary.GetCSV('/domains', {
            'options': args
        });
    }

    deleteDomain(domainName) {
        return this.requestLibrary.Delete('/domains/' + domainName);
    }
}
