import { Injectable } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { filter, map, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PageTitleService {
  private default_title: string;

  constructor(
    private router: Router,
    private activeRoute: ActivatedRoute,
    private title: Title
  ) { }

  boot() {
    this.default_title = localStorage.getItem('account_name') ?? 'Marketing Optimizer';

    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map(() => {
        let child = this.activeRoute.firstChild;
        while (child.firstChild) {
          child = child.firstChild;
        }
        if (child.snapshot.data['title']) {
          return `${child.snapshot.data['title']} - ${this.default_title}`;
        }
        return this.default_title;
      }),
    ).subscribe((current_title) => this.title.setTitle(current_title));
  }
}