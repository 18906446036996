import { Component, ElementRef, Input, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Logger } from '@service/global.funcs';

@Component({
  selector: 'add-multiple-filters',
  templateUrl: './add-multiple-filters.component.html',
  styleUrls: ['./add-multiple-filters.component.scss']
})
export class AddMultipleFiltersComponent implements OnInit {
  @Input() model: any;
  @Input() fieldsArray: Array<any> = [];
  public fieldsMap: any;
  public selectedField: any = [];
  public selectValues = [];

  constructor(private elementRef: ElementRef, fb: FormBuilder) { }

  ngOnInit() {
    // console.log('in:add-multiple-filters::ngOnInit', 'model', this.model);
    // console.log('in:add-multiple-filters::ngOnInit', 'fieldsArray', this.fieldsArray);
    this.fieldsMap = [];
    /** Map field array with field id indexes */
    this.fieldsArray.forEach(field => {
      this.fieldsMap[field.id] = field;
    });
    // console.log(this.model.fieldData);
    /** check field type for already created field values */
    this.model.fieldData.forEach((fieldItem, index) => {
      // console.log('in:add-multiple-filters::ngOnInit', 'fieldItem', fieldItem);
      if (fieldItem.ff_id != 0) {
        this.checkField(fieldItem.ff_id, index);
        this.setCriterionName(fieldItem.ff_id, index);
      }
    });
  }

  /** Remove field value filter */
  removeItem(item: number) {
    this.model.fieldData.splice(item, 1);
  }
  selectFieldChange(id, i, model) {
    this.checkField(id, i);
    this.setCriterionName(id, i);

  }
  selectDataChange(id, i, model) {
    this.setCriterionName(this.model.fieldData[i].ff_id, i);
  }
  selectOperatorChange(id, i, model) {
    this.setCriterionName(this.model.fieldData[i].ff_id, i);
  }

  setCriterionName(id, i) {
    const selectedField: any = this.fieldsArray.filter(record => record['id'] == id).find(Boolean);
    // console.log(selectedField);
    if (selectedField) {
      this.model.fieldData[i].criterion_name = selectedField.name;
      if (this.model.fieldData[i].operator === 'BEGINS') {
        this.model.fieldData[i].criterion_name = selectedField.name + " [Begins with] '" + this.model.fieldData[i].fd_data + "'";
      } else if (this.model.fieldData[i].operator === 'CONTAINS') {
        this.model.fieldData[i].criterion_name = selectedField.name + " [Contains] '" + this.model.fieldData[i].fd_data + "'";
      } else {
        this.model.fieldData[i].criterion_name = selectedField.name + " [Equals] '" + this.model.fieldData[i].fd_data + "'";
      }
    } else {
      this.model.fieldData[i].criterion_name = 'unknown';
    }
  }
  /** check selected field type */
  checkField(id, indexKey, event = []) {
    this.selectedField[indexKey] = this.fieldsMap[id];
    // console.log(this.selectedField[indexKey]);
    if (this.selectedField[indexKey] !== undefined) {
      if (this.selectedField[indexKey].input_type && this.selectedField[indexKey].input_type == 'Select') {
        this.selectValues[indexKey] = [];
        this.selectValues[indexKey] = Object.keys(this.selectedField[indexKey].values).map(data => [
          data,
          this.selectedField[indexKey].values[data]
        ]);
      }
    }
  }
}
