import { Component, Input, OnChanges, OnInit, SimpleChange } from '@angular/core';
import { Privilege } from '@decorator/index';
import { ToasterLibraryService, CampaignReportService } from '@service/index';
import { NgxPermissionsService } from 'ngx-permissions';
import { Logger } from '@service/global.funcs';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import * as _ from 'lodash';
@Component({
  selector: 'app-conversion-rate-by-campaign-line-chart-widget',
  templateUrl: './conversion-rate-by-campaign-line-chart-widget.component.html',
  styleUrls: ['./conversion-rate-by-campaign-line-chart-widget.component.scss']
})

// @Privilege({
//   privilege: 'contacts:can_get_contacts_reports_captured'
// })
export class ConversionRateByCampaignLineChartWidgetComponent implements OnInit, OnChanges {
  @Input() relativeDate: any;
  @Input() campaignData: any;
  @Input('chartLabel') chartLabel = 'Conversion Rate by Campaign';
  public isContactCapturedLoaded = false;
  public capturedCount = 0;
  public leadsChartData = {
    labels: [],
    datasets: [],
    legend: false
  };

  public barChartOptions: ChartOptions = {
    scales: {
      yAxes: [{
        display: true,
        stacked: true,
        scaleLabel: {
          display: true,
          labelString: 'Total Visits'
        },
        ticks: {
          beginAtZero: true,
        }
      }, {
        id: 'conversion_rate',
        display: true,
        stacked: false,
        position: 'right',
        scaleLabel: {
          display: true,
          labelString: 'Converstion Rate'
        },
        ticks: {
          beginAtZero: true,
        }
      }]
    },
  };
  public barChartType: ChartType = 'bar';
  public barChartLegend = true;

  public barChartData: ChartDataSets[] = [{
    type: 'bar',
    label: 'Total Visits',
    data: [10, 20, 30, 40]
  }, {
    type: 'line',
    label: 'Conversion Rate',
    yAxisID: 'conversion_rate',
    data: [10, 98, 100, 0],
  }];
  public barChartLabels: string[] = ['January', 'February', 'March', 'April'];



  constructor(
    private campaignReportService: CampaignReportService,
    private toasterLibrary: ToasterLibraryService,
    private permissionService: NgxPermissionsService
  ) { }

  ngOnInit() {
    if (this.permissionService.hasPermission('contacts:can_get_contacts_reports_captured')) {
      this.requestContactCampaign();
    }
  }

  ngOnChanges(changes: { [key: string]: SimpleChange }) {
    if (this.permissionService.hasPermission('contacts:can_get_contacts_reports_captured')) {
      if (
        (changes['relativeDate'] && !changes['relativeDate'].isFirstChange()) ||
        (changes['campaignData'] && !changes['campaignData'].isFirstChange()) ||
        (changes['criteriaArray'] && !changes['criteriaArray'].isFirstChange())
      ) {
        this.requestContactCampaign();
      }
    }
  }


  requestContactCampaign() {
    const criteria = [
      {
        is_required: true,
        criterion: 'dateRange',
        value: {
          relative_date: this.relativeDate
        }
      }, {
        'value': { c_id: this.campaignData },
        'criterion': 'campaign',
        'is_required': true
      }, {
        id: '',
        is_required: true,
        criterion: 'statusSubstatus',
        value: {
          ostat_id: 2,
          ss_id: 0
        }
      }
    ];

    this.isContactCapturedLoaded = false;
    this.reInit();
    this.campaignReportService.getConversionRateByCampaign(this.relativeDate, criteria).subscribe(
      (res: any) => {
        if (res.success === 'true') {
          const barData = res.data.filter(function (item) {
            return item.conversion_rate !== 0;
          });
          const labels: string[] = _.map(barData, 'campaign');
          const conversionRateData = _.map(barData, 'conversion_rate');
          const totalVisitsData = _.map(barData, 'total_visits');
          this.barChartLabels = labels;

          this.barChartData = [{
            type: 'bar',
            label: 'Total Visits',
            data: totalVisitsData
          }, {
            type: 'line',
            label: 'Conversion Rate',
            yAxisID: 'conversion_rate',
            data: conversionRateData,
          }];
          // res.data.forEach(contact => {
          //   if (!this.barChartLabels.includes(contact.campaign)) {
          //     this.barChartLabels.push(contact.campaign);
          //   }
          //   // this.leadsChartData.datasets[0].data.push(contact.conversion_rate);
          //   // this.capturedCount = this.capturedCount + parseInt(contact.conversion_rate, 10);
          // });
        }

        this.isContactCapturedLoaded = true;
      },
      err => {
        this.toasterLibrary.error(null, err.error.message);
      }
    );
  }

  private reInit() {
    this.leadsChartData = {
      labels: [],
      datasets: [],
      legend: false
    };
  }
}
