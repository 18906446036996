import { Component, OnInit } from "@angular/core"
import { timezones } from "@asset/timezones/timezones"
import { currencies } from "@asset/currency/currency"
import { ActivatedRoute, Router } from "@angular/router"
import { auth0Config } from "@service/auth/auth0.config"
import * as jwt from "jsonwebtoken"
import * as _ from "lodash"
import {
  CustomerOnboardService,
  CustomerOnboardHelperService,
  CustomerOnboardRedirectService,
  ToasterLibraryService,
  AuthService,
} from "@service/index"
import { ToasterConfig } from "angular2-toaster"
import { AuthLogoutService } from "@service/auth/auth-logout.service"

@Component({
  selector: "app-company-info-page",
  templateUrl: "./company-info-page.component.html",
  styleUrls: ["./company-info-page.component.scss"],
})
export class CompanyInfoPageComponent implements OnInit {
  public auth0_session_token: string
  public auth0_state: string
  public auth0IncomingData
  public isSaving = false;
  public wrongSubmission: boolean = false;

  private _auth0_domain = auth0Config.domain
  private org_id = localStorage.getItem('account_id');
  private user_id = localStorage.getItem('User');
  private selectedAppVersion: string;

  public loaded = false;
  public loggedIn = false;
  public model: any = {
    name: "",
    username: "",
    first_name: "",
    last_name: "",
    email: "",
    id: "",
    user_id: "0",
    sub: "",
  }
  // TOASTER
  toasterConfig: any;
  toasterconfig: ToasterConfig = new ToasterConfig({
    positionClass: 'toast-bottom-right',
    showCloseButton: true
  });

  constructor(
    private _route: ActivatedRoute,
    private router: Router,
    private customerOnboardService: CustomerOnboardService,
    private customerOnboardHelperService: CustomerOnboardHelperService,
    private customerOnboardRedirectService: CustomerOnboardRedirectService,
    private toasterLibrary: ToasterLibraryService,
    private authService: AuthService,
    private authLogout: AuthLogoutService
  ) {
    this._route.queryParams.subscribe(params => {
      this.auth0_session_token = params["session_token"]
      this.auth0_state = params["state"]
    })
  }

  async ngOnInit() {
    this.auth0IncomingData =
      await this.customerOnboardHelperService.validateIncomingToken(
        this.auth0_session_token
      )
      
    if (
      this.auth0IncomingData.mo_req_payload &&
      _.has(this.auth0IncomingData.mo_req_payload, "email")
    ) {
      this.model.email = this.auth0IncomingData.mo_req_payload.email
      this.loaded = true;
    }
    if (
      this.auth0IncomingData.mo_req_payload &&
      _.has(this.auth0IncomingData.mo_req_payload, "firstname")
    ) {
      this.model.first_name = this.auth0IncomingData.mo_req_payload.firstname
    }
    if (
      this.auth0IncomingData.mo_req_payload &&
      _.has(this.auth0IncomingData.mo_req_payload, "lastname")
    ) {
      this.model.last_name = this.auth0IncomingData.mo_req_payload.lastname
    }
    if (
      this.auth0IncomingData.mo_req_payload &&
      _.has(this.auth0IncomingData.mo_req_payload, "username")
    ) {
      this.model.username = this.auth0IncomingData.mo_req_payload.username
    }
    if (this.auth0IncomingData && _.has(this.auth0IncomingData, "sub")) {
      this.model.sub = this.auth0IncomingData.sub
    }
    if (this.auth0IncomingData && _.has(this.auth0IncomingData, "exp")) {
      // this.auth0IncomingData.exp = this.auth0IncomingData.exp + 60
      let sessionExpTime = (this.auth0IncomingData.exp - this.auth0IncomingData.iat) * 1000;
      setTimeout(() => {
        this.toasterLibrary.error(null, "Your session has been expired")
        this.router.navigate(['/logout']);
      }, sessionExpTime);
    }
    
    if (this.org_id != null && this.user_id != null) {
      this.loggedIn = true;
      this.model.id = this.org_id;
      this.model.user_id = this.user_id;
      this.redirectToAuth0(this.model);
    } else {
      this.loggedIn = true;
     this.customerCheck();
    }

  }
  onSubmit() {
    if (this.model.name != '' && this.model.first_name != '' && this.model.last_name != '') {
      this.isSaving = true;
      this.wrongSubmission = false;
      this.customerOnboardService.createCustomer(this.model).subscribe(
        (res: any) => {
          if (res.success == "true") {
            this.model.id = res.data.id
            this.model.user_id = res.data.user_id
            this.authService.appendUserId(this.model.user_id, this.model.id, null);
            this.redirectToAuth0(this.model)
          } else {
            this.toasterLibrary.error(null, res.err)
          }
          this.isSaving = false;
        },
        err => {
          this.toasterLibrary.error(null, err.error.message)
          this.loaded = true;
          this.isSaving = false;
        }
      )
    } else {
      this.wrongSubmission = true;
    }
  }

  customerCheck() {
    if (this.model.email != '') {
      this.isSaving = true;
      this.wrongSubmission = false;
      this.customerOnboardService.checkCustomer(this.model).subscribe(
        (res: any) => {
          if (res.success == "true") {
            if (_.has(res, "data")) {
              this.model.id = res.data.id;
              this.model.user_id = res.data.user_id;
              this.authService.appendUserId(this.model.user_id, this.model.id, null);
              this.redirectToAuth0(this.model);
            } else {
              this.loggedIn = false;
            }
          } else {
            this.loggedIn = false;
            this.toasterLibrary.error(null, res.err);
          }
          this.isSaving = false;
        },
        err => {
          this.toasterLibrary.error(null, err.error.message)
          this.loaded = true;
          this.isSaving = false;
          this.loggedIn = false;
        }
      )
    } else {
      this.wrongSubmission = true;
    }
  }

  async redirectToPasswordPage(resOrgData) {
    const newToken = await this.customerOnboardHelperService.generateNewToken(
      this.auth0_state,
      this.auth0IncomingData,
      {
        mo_organization_id: resOrgData.id,
        mo_user_id: resOrgData.user_id,
        mo_user_name: resOrgData.name,
      }
    )

    this.router.navigate(["/onboard/customer/pwd"], {
      relativeTo: this._route,
      queryParams: {
        state: this.auth0_state,
        mo_session_token: newToken,
      },
      queryParamsHandling: "merge",
      skipLocationChange: false,
    });
  }

  async redirectToAuth0(resOrgData) {
    const newToken = await this.customerOnboardHelperService.generateNewToken(
      this.auth0_state,
      this.auth0IncomingData,
      {
        mo_organization_id: resOrgData.id,
        mo_user_id: resOrgData.user_id,
        mo_user_name: resOrgData.name,
      }
    )

    this.auth0IncomingData =
      await this.customerOnboardHelperService.validateIncomingToken(newToken)

    const url = `https://${this._auth0_domain}/continue?state=${this.auth0_state}&mo_token=${newToken}`
    window.location.href = url
  }

  // navigateTo(url, params) {
  //   this.router.navigate([url], {
  //     relativeTo: this._route,
  //     queryParams: params,
  //     queryParamsHandling: "merge",
  //     skipLocationChange: false,
  //   })
  // }

  setAppVersion(appVersion: string) {
    if (appVersion) {
      localStorage.setItem('appVersion', appVersion);
    }
  }
}
