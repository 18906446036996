// Import the core angular services.
import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import 'rxjs/add/observable/of';
//Import Services,Components,Pipes
import { ContactService, ToasterLibraryService } from '@service/index';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-add-note',
    templateUrl: './add-note-modal.component.html',
})

export class AddNoteModalComponent implements OnInit,OnDestroy {
    public contactId: any;
    public noteID: string = "";
    public showTimeline: any = true;
    public note: String = '';
    public isSaving = false;
    public refreshTimeline: Boolean;
    editorID="NewEditor";
    public loggedUserID: string = '';
    @Input('objModel') objModel : any = {};
    paramSubscription : Subscription;
    isEditHistory : boolean = false;

    constructor(
        private router: Router,
        private contactService: ContactService,
        private cdRef: ChangeDetectorRef,
        private toasterLibraryService : ToasterLibraryService,
        private route : ActivatedRoute
    ) {
    }

    ngOnInit() {
      if(this.objModel && this.objModel.id){
        this.contactId = this.objModel.id;
        for(let key in this.objModel){
          if(key == 'showTimeline'){
            this.showTimeline = this.objModel[key];
          }else if(key == 'history'){
            this.noteID = this.objModel[key] && this.objModel[key]['id']?this.objModel[key]['id']:'';
            this.note = this.objModel[key]?this.objModel[key]['description'] : '';
          }
        }
      }else{
        if(this.router.url.indexOf('/edit-history')>-1){
          this.paramSubscription = this.route.paramMap.subscribe(p=>{
            if(p.get('id')){
              this.isEditHistory = true;
              this.contactId = p.get('id');
              let history = localStorage.getItem('edit-history');
              if(history && JSON.parse(history)){
                history = JSON.parse(history);
                this.showTimeline = history['showTimeline'];
                this.noteID = history['id']? history['id'] : '';
                this.note = history['description']?history['description'] : '';
              }
            }
          });
        }
        
      }
        
    }
    ngOnDestroy(){
      if(this.paramSubscription){
        this.paramSubscription.unsubscribe();
      }
      localStorage.removeItem('edit-history');
    }
    addNoteRequest() {
        if (this.note) {
            this.isSaving = true;
            this.contactService.addNote({
                "id": this.contactId,
                "note": this.note
            }).subscribe((res: any) => {
                if (res.success == 'true') {
                    this.note = '';
                    this.isSaving = false;
                    this.refreshTimeline = true;
                    this.clearNoteFlag = true;
                    this.toasterLibraryService.success(null, 'Note Added Successfully');
                } else {
                    this.isSaving = false;
                }
            });
        }
    }
    clearNoteFlag : boolean = false;
    onTemplateChange(evt){
      if (!this.clearNoteFlag){
        this.note = evt;
      } else {
        this.clearNoteFlag = false;
      }
    }
    // helps remove the new angular 4 Expression has changed after it was checked error
    public ngDoCheck(): void {
      this.cdRef.detectChanges();
    }
    updateNoteRequest() {
      if (this.note && this.noteID) {
          this.isSaving = true;
          this.contactService.updateHistoryNote({
              "contact_id": this.contactId,
              "body": this.note,
              "user_id": localStorage.getItem('User'),
              "id": this.noteID
          }).subscribe((res: any) => {
              if (res.success == 'true') {
                  this.note = '';
                  this.isSaving = false;
                  this.refreshTimeline = true;
                  this.clearNoteFlag = true;
                  this.toasterLibraryService.success(null, 'Note Updated Successfully');
                  if(this.isEditHistory){
                    this.router.navigate(['leads',this.contactId,'detail']);
                  }
              } else {
                  this.isSaving = false;
              }
          },err=>{
            this.toasterLibraryService.error(err.error.message);
          });
      }
  }
  cancel(){
    this.router.navigate(['leads',this.contactId,'detail']);
  }
}


