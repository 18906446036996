import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CustomHttp } from '@service/customhttp.service';
import { RequestLibraryService } from '@service/core/request-library.service';
import 'rxjs/add/operator/map';
import { HttpClient } from '@angular/common/http';
@Injectable()
export class StatusReportService {
  private customHttp: CustomHttp;

  constructor(private http: HttpClient, private router: Router, private requestLibrary: RequestLibraryService) {
    this.customHttp = new CustomHttp(http, router);
  }
  getLeadsbyStatus(criteriaArr) {
    return this.requestLibrary.GetAll('/status/reports/status', {
      options: { criteria: criteriaArr }
    });
  }
  getSalesPipeline(criteriaArr) {
    return this.requestLibrary.GetAll('/status/reports/status', {
      options: { criteria: criteriaArr }
    });
  }
  getStatusesChart(criteriaArr) {
    return this.requestLibrary.GetAll('/status/reports/status', {
      options: { criteria: criteriaArr }
    });
  }
  getLeadsCount(criteriaArray) {
    let returnArr = [];
    if (criteriaArray != null) {
      returnArr = JSON.parse(JSON.stringify(criteriaArray));
    }
    return this.requestLibrary.GetAll('/contacts/reports/count', {
      options: { criteria: returnArr }
    });
  }

  getCloseLeadsCount(criteriaArray) {
    const statusSubstatus = {
      is_required: true,
      criterion: 'statusSubstatus',
      value: {
        ostat_id: 2,
        ss_id: 0
      }
    };
    let returnArr = [];
    if (criteriaArray != null) {
      returnArr = JSON.parse(JSON.stringify(criteriaArray));
    }
    returnArr.push(statusSubstatus);

    return this.requestLibrary.GetAll('/contacts/reports/count', {
      options: { criteria: returnArr }
    });
  }

  getOpenLeadsCount(criteriaArray) {
    const statusSubstatus = {
      is_required: true,
      criterion: 'statusSubstatus',
      value: {
        ostat_id: 1,
        ss_id: 0
      }
    };

    let returnArr = [];
    if (criteriaArray != null) {
      returnArr = JSON.parse(JSON.stringify(criteriaArray));
    }

    returnArr.push(statusSubstatus);
    return this.requestLibrary.GetAll('/contacts/reports/count', {
      options: { criteria: returnArr }
    });
  }
}
