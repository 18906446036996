import { Injectable } from '@angular/core';
import 'rxjs/add/operator/map';
import { RequestLibraryService } from '@service/core/request-library.service';

@Injectable()
export class ContactExportService {
  constructor(
    private requestLibrary: RequestLibraryService
  ) {
  }

  all(page, page_size, sort, sort_dir) {
    return this.requestLibrary.GetAll('/contacts/export', {
      options: {
        page: page,
        page_size: page_size,
        sort: sort,
        sort_dir: sort_dir
      }
    });
  }

  find(id: number) {
    return this.requestLibrary.Get('/contacts/export/' + id);
  }

  update(id: number, model: ContactExport) {
    const requestModel = model;
    requestModel.id = id;
    return this.requestLibrary.Post('/contacts/export', requestModel);
  }

  save(model: ContactExport) {
    return this.requestLibrary.Post('/contacts/export', model);
  }

  saveOrUpdate(id: number = null, model: ContactExport) {
    if (id == null) {
      return this.save(model);
    } else {
      return this.update(id, model);
    }
  }

  delete(id: number) {
    return this.requestLibrary.Delete('/contacts/export/' + id);
  }

  restart(id: number) {
    return this.requestLibrary.Post('/contacts/export/reset', id);
  }

  start(id: number) {
    const requestModel = {
      id: id
    };
    return this.requestLibrary.Post('/contacts/export', requestModel);
  }
}


interface ContactExport {
  id?: number,
  name?: string,
  is_recurring?: boolean,
  recurring_value?: string,
  recurring_type?: string,
  email_users?: Array<any>,
  criteria?: Array<any>
}
