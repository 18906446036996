<div class="wrapper">
    <!-- top navbar-->
    <app-header class="topnavbar-wrapper"></app-header>
    <!-- sidebar-->
    <app-sidebar-wrapper class="aside"></app-sidebar-wrapper>
    <!-- sidebar-->
    <!-- <app-sidebar class="aside"></app-sidebar> -->
    <!-- offsidebar-->
    <!-- <app-offsidebar class="offsidebar"></app-offsidebar> -->
    <!-- Main section-->
    <section (click)="collapseSidebarOnOutsideClick($event)">
        <!-- Page content-->
        <div class="content-wrapper">
            <router-outlet></router-outlet>
            <toaster-container [toasterconfig]="toasterConfig"></toaster-container>
        </div>
    </section>
    <!-- Page footer-->
    <footer class="footerStyle">
        <app-footer></app-footer>
    </footer>
</div>