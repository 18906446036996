<ng-template [ngxPermissionsOnly]="['campaigns:can_get_campaigns_reports_sales_per_channel']">
  <div class="box-row panel panel-default">
    <div class="panel-heading">
      <h4>{{widgetTitle}}</h4>
    </div>
    <div class="panel-body text-center">
      <ng-template [ngIf]="!contactUnsoldByChannelLoaded">
        <div class="whirl standard spinnermargin"></div>
      </ng-template>
      <ng-template [ngIf]="contactUnsoldByChannelLoaded">
        <ng-container *ngIf="!isChartLoaded; else displayChart">
          <p>No sales in the last {{relativeDate}}</p>
        </ng-container>
        <ng-template #displayChart>
          <pie-chart *ngIf="chartType=='pieChart'" [pieData]=contactUnsoldByChannelData [showLegends]=true></pie-chart>
          <!-- <bar-chart *ngIf="chartType=='barChart'" [chartFor]="'largeSize'" [chartType]="'horizontalBar'" [barData]="contactUnsoldByChannelData" [totalCount]="'7'"></bar-chart> -->
        </ng-template>
      </ng-template>
    </div>
  </div>
</ng-template>
