import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from '@env/environment';

import { BootController } from './boot-control';

if (environment.production) {
    enableProdMode();
}

// let p = platformBrowserDynamic().bootstrapModule(AppModule);
// p.then(() => { (<any>window).appBootstrap && (<any>window).appBootstrap(); })
// .catch(err => console.error(err));

const init = () => {
    platformBrowserDynamic()
        .bootstrapModule(AppModule)
        .then(() => (<any>window).appBootstrap && (<any>window).appBootstrap())
        .catch(err => {
            let error = err.error ? err.error.message : err;
            console.error('NG Bootstrap Error =>', error)
        });
}

// Init on first load
init();

// Init on reboot request
const boot = BootController.getbootControl().watchReboot().subscribe(() => init());
