import { Component, Input, OnChanges, OnInit, SimpleChange } from '@angular/core';
import { Privilege } from '@decorator/index';
import { LocalFilterStorageService, StatusReportService, ToasterLibraryService } from '@service/index';
import { NgxPermissionsService } from 'ngx-permissions';
import { Router, ActivatedRoute } from '@angular/router';
// Add the observable and combineLatest
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/combineLatest';
@Component({
  selector: 'app-close-leads-widget',
  templateUrl: './close-leads-widget.component.html',
  styleUrls: ['./close-leads-widget.component.scss']
})

// @Privilege({
//   privilege: 'contacts:can_get_contacts_reports_count'
// })
export class CloseLeadsWidgetComponent implements OnInit, OnChanges {
  @Input() criteriaArray: any;
  @Input() relativeDate: any;
  @Input() campaignData;
  @Input() widgetRefresh;
  public isCountLoaded = false;
  public closeLeadsCount = 0;
  constructor(
    private statusReportService: StatusReportService,
    private toasterLibrary: ToasterLibraryService,
    private permissionService: NgxPermissionsService,
    private localStorageLibrary: LocalFilterStorageService,
    private _route: ActivatedRoute
  ) {
    const urlParams = Observable.combineLatest(
      this._route.queryParams,
      (queryParams) => ({ ...queryParams })
    );

    urlParams.subscribe(routeParams => {
      this.criteriaArray = [
        {
          id: '',
          is_required: true,
          criterion: 'dateRange',
          value: {
            start_date: '',
            end_date: '',
            relative_date: this.relativeDate
          }
        }, {
          value: { c_id: this.campaignData },
          criterion: 'campaign',
          is_required: true
        }
      ];

      // this.getCloseLeadsCount();
    });
  }

  ngOnInit() {
    if (this.permissionService.hasPermission('contacts:can_get_contacts_reports_count')) {
      if (this.criteriaArray === undefined && this.relativeDate !== undefined) {
        this.criteriaArray = [
          {
            is_required: true,
            criterion: 'dateRange',
            value: {
              relative_date: this.relativeDate
            }
          }
        ];
      } else {
        // this.criteriaArray = JSON.parse(localStorage.getItem('leadsCriteria'));
        let filter_data = JSON.parse(this.localStorageLibrary.getItem());
        this.criteriaArray = filter_data.leadsCriteria;
      }
      this.getCloseLeadsCount();
    }
  }

  /**
   *
   * @TODO Fix these widgets on update
   */

  ngOnChanges(changes: { [key: string]: SimpleChange }) {

    if (this.permissionService.hasPermission('contacts:can_get_contacts_reports_count')) {
      if (
        (changes['criteriaArray'] && !changes['criteriaArray'].isFirstChange()) ||
        (changes['widgetRefresh'] && !changes['widgetRefresh'].isFirstChange())
      ) {
        this.getCloseLeadsCount();
      }
      if ((changes['relativeDate'] && !changes['relativeDate'].isFirstChange()) ||
        (changes['campaignData'] && !changes['campaignData'].isFirstChange())) {
        this.criteriaArray = [
          {
            id: '',
            is_required: true,
            criterion: 'dateRange',
            value: {
              start_date: '',
              end_date: '',
              relative_date: this.relativeDate
            }
          }, {
            value: { c_id: this.campaignData },
            criterion: 'campaign',
            is_required: true
          }
        ];

        this.getCloseLeadsCount();
      }




    }




    // if (
    //   this.permissionService.hasPermission('contacts:can_get_contacts_reports_count') &&
    //   changes['criteriaArray'] &&
    //   !changes['criteriaArray'].isFirstChange()
    // ) {
    //   this.getCloseLeadsCount();
    // }

    // if (this.permissionService.hasPermission('contacts:can_get_contacts_reports_count')) {
    //   if (changes['campaignData'] && !changes['campaignData'].isFirstChange()) {
    //     this.getCloseLeadsCount();
    //   }
    // }
    // if (
    //   this.permissionService.hasPermission('contacts:can_get_contacts_reports_count') &&
    //   changes['widgetRefresh'] &&
    //   !changes['widgetRefresh'].isFirstChange()
    // ) {
    //   this.getCloseLeadsCount();
    // }
    // if (
    //   this.permissionService.hasPermission('contacts:can_get_contacts_reports_count') &&
    //   changes['relativeDate'] &&
    //   !changes['relativeDate'].isFirstChange()
    // ) {
    //   this.criteriaArray = [
    //     {
    //       is_required: true,
    //       criterion: 'dateRange',
    //       value: {
    //         relative_date: this.relativeDate
    //       }
    //     }
    //   ];
    //   this.getCloseLeadsCount();
    // }
  }

  getCloseLeadsCount() {
    this.isCountLoaded = false;
    this.statusReportService.getCloseLeadsCount(this.criteriaArray).subscribe((res: any) => {
      this.closeLeadsCount = res.data.count;
      this.isCountLoaded = true;
    });
  }
}
