<ng-template [ngxPermissionsOnly]="['contacts:can_get_contacts_reports_count']">
  <div class="box-row panel panel-default">
    <div class="panel-body text-center text-alpha">
      <div *ngIf="!isCountLoaded" class="whirl standard spinnermargin"></div>
      <ng-template [ngIf]="isCountLoaded">
        <div class="text-lg mo">{{newLeadsCount}}</div>
        <p>{{widgetTitle}}</p>
        <div class="mb-lg"></div>
      </ng-template>
    </div>
  </div>
</ng-template>
