import { Location } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-back-button',
    template: `<a (click)="goBack()" [class]="classes" ><i class="fa fa-arrow-left"></i>&nbsp;Go Back</a>`
})

export class BackButtonComponent {
    @Input() classes = 'btn btn-link text-inverse text-bold';

    constructor(private location: Location) { }

    goBack() {
        this.location.back();
    }
}
