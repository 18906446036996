import { Component, Input, OnChanges, OnInit, SimpleChange } from '@angular/core';
import { Privilege } from '@decorator/index';
import { ToasterLibraryService, CampaignReportService } from '@service/index';
import { NgxPermissionsService } from 'ngx-permissions';
import { Logger } from '@service/global.funcs';
import * as _ from 'lodash';

@Component({
  selector: 'app-channel-new-contacts-bar-chart-widget',
  templateUrl: './channel-new-contacts-bar-chart-widget.component.html',
  styleUrls: ['./channel-new-contacts-bar-chart-widget.component.scss']
})

// @Privilege({
//   privilege: 'users:can_get_users_reports_sales'
// })
export class ChannelNewContactsBarChartWidgetComponent implements OnInit, OnChanges {
  @Input() relativeDate: any;
  @Input() widgetTitle = 'Leads by Campaign';
  @Input() channel = 'BUSDEV';
  @Input() chartAspectRatio: any;

  public stackedBarChartData = [
    {
      data: [],
      label: this.widgetTitle,
      color: '#1fafff'
    }
  ];
  public barChartData = {
    labels: [],
    datasets: [
      {
        label: this.widgetTitle,
        data: []
      }
    ],
    name: this.widgetTitle,
    totalVisits: 0
  };
  public number_of_occurrences;
  public channelNewContactsLoaded = false;
  constructor(
    private campaignReportService: CampaignReportService,
    private toasterLibrary: ToasterLibraryService,
    private permissionService: NgxPermissionsService
  ) { }

  ngOnInit() {
    if (this.permissionService.hasPermission('users:can_get_users_reports_sales')) {
      this.getByChannel();
    }
  }

  ngOnChanges(changes: { [key: string]: SimpleChange }) {
    if (this.permissionService.hasPermission('users:can_get_users_reports_sales')) {
      if (
        (changes['relativeDate'] && !changes['relativeDate'].isFirstChange()) ||
        (changes['campaignData'] && !changes['campaignData'].isFirstChange()) ||
        (changes['criteriaArray'] && !changes['criteriaArray'].isFirstChange())
      ) {
        this.getByChannel();
      }
    }
  }
  getByChannel() {
    const criteriaArray = [
      {
        id: '',
        is_required: true,
        criterion: 'dateRange',
        value: {
          start_date: '',
          end_date: '',
          relative_date: this.relativeDate
        }
      },
      {
        id: '',
        is_required: true,
        criterion: 'channel',
        value: {
          c_channel: this.channel
        }
      }
    ];
    this.channelNewContactsLoaded = false;
    this.reInit();
    this.campaignReportService.getLeadsByCampaign(criteriaArray).subscribe(
      (res: any) => {
        Logger().info('getByChannel', 'res.data', res.data);
        if (res.success === 'true') {
          const forAggregation = res.data.slice(0);
          const afterAggregation: any = [];
          // Aggregate the data so we don't have duplicate bars for each category.
          forAggregation.forEach((record, index, theArray) => {
            if (undefined !== afterAggregation && afterAggregation.length > 0) {
              const idx = afterAggregation.findIndex(item => item.category === record.category);
              if (idx !== -1) {
                afterAggregation[idx].number_of_occurrences =
                  parseInt(afterAggregation[idx].number_of_occurrences, 10) +
                  parseInt(record.number_of_occurrences, 10);
              } else {
                afterAggregation.push(record);
              }
            } else {
              afterAggregation.push(record);
            }
          });
          // Sort by total visits
          const byTotalVisits = afterAggregation.slice(0);
          byTotalVisits.sort(function (a, b) {
            return b.number_of_occurrences - a.number_of_occurrences;
          });

          byTotalVisits.forEach(record => {
            if (record.category) {
              this.stackedBarChartData[0].data.push([record.category, parseInt(record.number_of_occurrences, 10)]);
            }
          });
        }
        this.channelNewContactsLoaded = true;
      },
      err => {
        this.toasterLibrary.error(null, err.error.message);
      }
    );
  }
  private reInit() {
    this.stackedBarChartData = [
      {
        data: [],
        label: this.widgetTitle,
        color: '#1fafff'
      }
    ];
  }
}
