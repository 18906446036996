<div class="content-heading" *ngIf="this.isEditHistory">
  <h4>Add Note</h4>
</div>
<div class="container-fluid">
  <div class="row">
    <div class="panel panel-default">
      <div class="panel-body">
        <app-editor [htmlTemplate]="note" [clearNote]="clearNoteFlag" (templateChanged)="onTemplateChange($event)" [editorID]="editorID" ></app-editor>
      </div>
      <div *ngIf="isSaving" class="whirl standard spinnermargin"></div>
      <div *ngIf="!isSaving" class="panel-footer text-center action-button">
        <button *ngIf="!this.noteID" class="btn btn-info" (click)="addNoteRequest()">Add Note</button>
        <button *ngIf="this.noteID" class="btn btn-info" (click)="updateNoteRequest()">Update Note</button>
        <button *ngIf="this.isEditHistory" class="btn btn-default" (click)="cancel()">Cancel</button>
      </div>
    </div>
  </div>
</div>
<div class="container-fluid" *ngIf="showTimeline">
  <app-timeline-note [contactId]="contactId" [timelineRefresh]='refreshTimeline'></app-timeline-note>
</div>
