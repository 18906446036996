import { Injectable } from "@angular/core"
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpHeaders,
  HttpResponse,
  HttpErrorResponse,
} from "@angular/common/http"
import { Observable } from "rxjs/Rx"
import { tap } from "rxjs/operators"
import { ToasterLibraryService } from "@service/index"
import { AuthLogoutService } from "@service/auth/auth-logout.service"
import { Router } from "@angular/router"

@Injectable()
export class HttpHeadersInterceptor implements HttpInterceptor {
  constructor(
    private toasterLibrary: ToasterLibraryService,
    private authLogout: AuthLogoutService,
    private router: Router
  ) {}
  sessionExpired: boolean = false
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const authHeader = localStorage.getItem("id_token")
    let headers = new HttpHeaders()
    headers = headers.append("Authorization", `Bearer ${authHeader}`)

    if (localStorage.getItem("User")) {
      headers = headers.set("User", localStorage.getItem("User"))
    }

    // .set('Access-Control-Allow-Headers', "Content-Type")
    // .set('Access-Control-Allow-Origin', "*")
    // .set('Access-Control-Allow-Methods', "GET, POST, OPTIONS, PUT, DELETE")
    // .set('Access-Control-Allow-Headers', "Access-Control-Allow-Methods, Authorization, User, Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers");
    const authReq = req.clone({ headers: headers })
    let reqRes = next.handle(authReq).pipe(
      tap(
        event => {
          return event
        },
        err => {
          if (err && err.status == 401) {
            if (!this.sessionExpired) {
              this.toasterLibrary.error(
                "Session Expired",
                "Your current session is expired, Please login."
              )
              this.sessionExpired = true
              setTimeout(() => {
                this.forceLogout()
                return
              }, 2500)
            }
          }
        }
      )
    )
    return reqRes
  }
  forceLogout() {
    this.authLogout.logout()
    this.router.navigate(["/login"])
  }
}
