<!-- <ng-template [ngxPermissionsOnly]="['tests:can_get_tests_reports_visits_to_conversion_rate']"> -->
<div class="box-row panel panel-default">
  <div class="panel-body text-center">
    <div *ngIf="!isTestConversionRateChartLoaded">
      <div class="whirl standard spinnermargin"></div>
    </div>
    <ng-template [ngIf]="isTestConversionRateChartLoaded">
      <stacked-bar-chart [stackedBarData]="testConversionRateChartData" [stackedBarHeight]="220"></stacked-bar-chart>
    </ng-template>
  </div>
</div>
<!-- </ng-template> -->