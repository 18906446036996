import { Component, Input, OnChanges, OnInit, SimpleChange } from '@angular/core';
import { Privilege } from '@decorator/index';
import { EmailTemplateReportService, ToasterLibraryService } from '@service/index';
import { NgxPermissionsService } from 'ngx-permissions';

@Component({
  selector: 'app-email-opened-widget',
  templateUrl: './email-opened-widget.component.html',
  styleUrls: ['./email-opened-widget.component.scss'],
})

// @Privilege({
//   privilege: 'emailtemplates:can_get_emailtemplates_reports_open'
// })
export class EmailOpenedWidgetComponent implements OnInit, OnChanges {
  @Input() relativeDate: any = '';
  @Input() campaignData: any;
  public isCountLoaded = false;
  public emailOpenedCount = 0;
  constructor(
    private emailTemplateReportService: EmailTemplateReportService,
    private toasterLibrary: ToasterLibraryService,
    private permissionService: NgxPermissionsService

  ) { }
  ngOnInit() {
    if (this.permissionService.hasPermission('emailtemplates:can_get_emailtemplates_reports_open')) {
      this.getFormSubmissionCount();
    }
  }

  ngOnChanges(changes: { [key: string]: SimpleChange }) {

    if (this.permissionService.hasPermission('emailtemplates:can_get_emailtemplates_reports_open')) {
      if (
        (changes['relativeDate'] && !changes['relativeDate'].isFirstChange()) ||
        (changes['campaignData'] && !changes['campaignData'].isFirstChange()) ||
        (changes['criteriaArray'] && !changes['criteriaArray'].isFirstChange())
      ) {
        this.getFormSubmissionCount();
      }
    }

  }

  getFormSubmissionCount() {
    const criteriaArray: any = [
      {
        is_required: true,
        criterion: 'dateRange',
        value: {
          relative_date: this.relativeDate
        }
      }
    ];
    
    if (this.campaignData && this.campaignData != "") {
      criteriaArray.push({
        'id': '',
        'value': { c_id: this.campaignData },
        'criterion': 'campaign',
        'is_required': true
      });
    }
    this.isCountLoaded = false;
    this.emailTemplateReportService
      .getEmailOpenedCount(criteriaArray)
      .subscribe((res) => {
        this.emailOpenedCount = res;
        this.isCountLoaded = true;
      });
  }
}
