import { Input, OnInit, Component } from '@angular/core';
import { Privilege } from '@decorator/index';
import { Logger } from '@service/global.funcs';
import { GroupService, PrivilegeService } from '@service/index';
import * as _ from 'lodash';
import { NgxPermissionsService } from 'ngx-permissions';

@Component({
    selector: 'app-show-privilege-list',
    templateUrl: './show-privilege-list.component.html',
})

// @Privilege({
//     privilege: 'privileges:can_get_privileges'
// })

export class ShowPrivilegeListComponent implements OnInit {
    @Input() modelPrivilege: Array<any> = [];
    public togglePrivilegeLabel = 'Select All';
    public privilegesArray: Array<any> = [];
    public groupsArray: Array<any> = [];
    public privilegesDisplyableArray: Array<any> = [];
    public isPrivilegesLoaded = false;
    public isGroupsLoaded = false;

    constructor(
        private privilegeService: PrivilegeService,
        private groupService: GroupService,
        private permissionService: NgxPermissionsService
    ) {
    }

    async ngOnInit() {
        if (this.permissionService.hasPermission('privileges:can_get_privileges')) {
            Logger().info('privileges exist:', this.modelPrivilege);
            await this.requestGroups();
            await this.requestAllPrivileges();
            this.defaultPrivilegeLabel();
        }

    }
    toggleAllPrivileges() {
        Logger().info('in: toggleAllPrivileges');
        this.togglePrivilegeLabel = (this.togglePrivilegeLabel === 'Select All') ?
            'Deselect All' : 'Select All';
        if (this.privilegesArray && this.privilegesArray.length > 0) {
            this.privilegesArray.forEach((value) => {
                const id = value.key;
                this.selectPrivilege(id);
            });
        }
    }
    private defaultPrivilegeLabel() {
        this.togglePrivilegeLabel = (this.togglePrivilegeLabel === 'Select All' && this.modelPrivilege.length > 0) ?
            'Deselect All' : 'Select All';
    }

    requestAllPrivileges() {
        return new Promise((resolve, reject) => {
            this.isPrivilegesLoaded = false;
            this.privilegeService
                .getAllPrivileges()
                .subscribe((res: any) => {
                    if (res.success == 'true') {
                        this.privilegesArray = (res.data);
                        this.privilegesDisplyableArray = this.transform(res.data);
                        this.isPrivilegesLoaded = true;
                        resolve(true);
                    }
                });
        });
    }
    transform(privileges): any {
        return _.chain(privileges)
            .groupBy('resource')
            .toPairs()
            .map(function (currentItem) {
                return _.zipObject(['resource', 'privileges'], currentItem);
            })
            .value();
    }
    trackByKey(index: number, privilege: any): string { return privilege.key; }
    trackByResource(index: number, resource: any): string { return resource.resource; }
    addRemovePrivilege(id: string) {
        if (this.checkPrivilege(id)) {
            _.remove(this.modelPrivilege, function (n) {
                return n == id;
            });
        } else {
            this.modelPrivilege.push(id);
        }
    }
    selectPrivilege(id: string) {
        if (this.togglePrivilegeLabel == 'Deselect All' && !this.checkPrivilege(id)) {
            this.modelPrivilege.push(id);
        }
        if (this.togglePrivilegeLabel == 'Select All' && this.checkPrivilege(id)) {
            _.remove(this.modelPrivilege, function (n) {
                return n == id;
            });
        }
    }

    checkPrivilege(id: string) {
        // Logger().info(this.modelPrivilege);
        return this.modelPrivilege && (this.modelPrivilege.indexOf(id) > -1);
    }
    requestGroups() {
        return new Promise((resolve, reject) => {
            this.isGroupsLoaded = false;
            return this.groupService
                .getGroups()
                .subscribe((res: any) => {
                    if (res.success == 'true') {
                        // this.groupsArray = (res.data);
                        this.groupsArray = res.data.filter((data) => data.type != 'RESELLER');
                        this.isGroupsLoaded = true;
                        resolve(true);
                    }
                });
        });
    }
    filterPrivilegesByGroup(group: number): void {
        Logger().info(group);
        Logger().info('in:filterPrivilegesByGroup');
        Logger().info(this.privilegesArray);
        const privileges = (_.filter(this.privilegesArray, (item) => {
            return _.some(item.groups, { 'id': group });
        }));
        Logger().info(privileges);
        this.modelPrivilege = [];
        if (privileges && privileges.length > 0) {
            privileges.forEach((value) => {
                const id = value.key;
                this.addRemovePrivilege(id);
            });
        }
    }
}

