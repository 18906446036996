import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Rx';
import 'rxjs/Rx';
import { AuthService } from '@service/index'
import { HttpClient } from '@angular/common/http';

@Injectable()
export class AuthFailedRedirectService {
    private authService;
    constructor(
        private http: HttpClient,
        authService: AuthService
    ) { }

    get() {

        const now = Date.now();
        const expires_at = JSON.parse(localStorage.getItem('expires_at'));
        // console.log({ expires_at, expires_at_format: new Date(expires_at), 'now': new Date() });
        return Observable.create((observer: any) => {
            if (expires_at) {
                observer.next(expires_at && (now < expires_at));
            } else {
                observer.next(false);
            }
        });
    }

}
