<!-- <div> -->
  <nav
    class="sidebar"
    sidebar-anyclick-close=""
    [class.show-scrollbar]="settings.layout.asideScrollbar"
  >
    <ul class="nav top-section-links">
      <li class="has-user-block"></li>
      <ng-template ngFor let-item [ngForOf]="menuItems" let-i="index">
        <ng-template [ngxPermissionsOnly]="[item.privilege]">
          <li
            [ngClass]="{ 'nav-heading': item.heading }"
            [routerLinkActive]="['active']"
            [routerLinkActiveOptions]="{ exact: false }"
          >
            <ng-template [ngIf]="item.heading">
              <span>{{ (item.translate | translate) || item.text }}</span>
            </ng-template>
            <ng-template [ngIf]="!item.heading && !item.submenu">
              <a
                [routerLink]="item.link"
                [attr.route]="item.link"
                title="{{ item.text }}"
              >
                <span
                  class="pull-right"
                  *ngIf="item.alert"
                  [ngClass]="item.label || 'label label-success'"
                  >{{ item.alert }}</span
                >
                <em class="{{ item.icon }}" *ngIf="item.icon"></em>
                <span>{{ (item.translate | translate) || item.text }}</span>
              </a>
            </ng-template>
          </li>
        </ng-template>
      </ng-template>
    </ul>
    <ng-template [ngIf]="parent_account_id == '1'">
      <ul class="nav align-content-end">
        <li class="bg-default">
          <a (click)="showIntercom()">
            <em class="icon-support"></em>
            <span>Live Chat&nbsp;</span>
          </a>
        </li>
        <li class="bg-default">
          <a href="http://support.marketingoptimizer.com" target="_new">
            <em class="icon-docs"></em>
            <span>Documentation&nbsp;</span>
          </a>
        </li>
        <li class="bg-default">
          <a [routerLink]="['/get-started']">
            <em class="icon-question"></em>
            <span>Get Started&nbsp;</span>
          </a>
        </li>
      </ul>
    </ng-template>
  </nav>
<!-- </div> -->
