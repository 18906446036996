import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  OnChanges
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { FormFieldBase } from './form-field/form-field-base';
import { ContactService, FormService } from '@service/index';
import { DropdownField } from './form-field/dropdown-field';
import { CheckboxField } from './form-field/checkbox-field';
import { TextAreaField } from './form-field/textarea-field';
import { TextboxField } from './form-field/textbox-field';
import { Observable } from 'rxjs/Rx';
import * as _ from 'lodash';
import { Logger } from '@service/global.funcs';
import { MultiCheckboxField } from './form-field/multi-checkbox-field';
import { ToasterConfig, ToasterService } from 'angular2-toaster';
import { HtmlAreaField } from './form-field/html-field';

@Component({
  selector: 'app-lead-dynamic-form',
  templateUrl: 'lead-dynamic-form.component.html'
})
export class LeadDynamicFormComponent implements OnInit, OnChanges {
  @Input() contactId: string;
  @Input() formId: string;
  @Input() contactFields: any[];
  @Output() formSaved: EventEmitter<any> = new EventEmitter();

  private oQuestions: FormFieldBase<any>[];
  public isLoaded: Boolean = false;

  leadDynamicForm: FormGroup;
  payLoad = '';
  public model = {
    id: '',
    form_id: '',
    fields: []
  };
  ifFieldExist = false;
  // TOASTER

  toasterConfig: any;
  toasterconfig: ToasterConfig = new ToasterConfig({
    positionClass: 'toast-top-right',
    showCloseButton: true
  });

  constructor(
    private toasterService: ToasterService,
    private contactService: ContactService,
    private formService: FormService
  ) { }

  ngOnInit() {
    if (this.formId == '-1') {
      // get the forms ids from fields
      this.loadCompletedForm();
    } else {
      this.getForm(this.formId);
    }
  }

  ngOnChanges(changes) {
    if (!changes['formId'].isFirstChange()) {
      this.getForm(this.formId);
    }
  }

  getForm(id) {
    this.isLoaded = false;
    this.formService.getForm(id).subscribe(res => {
      if ((res.success = 'true')) {
        const fields = res.data.fields;
        if (fields.length > 0) {
          this.ifFieldExist = true;
          this.generateQuestions(fields).subscribe(questions => {
            this.leadDynamicForm = this.toFormGroup(questions);
            // console.log(this.oQuestions)
            this.isLoaded = true;
          });
        } else {
          this.isLoaded = true;
        }
      }
    });
  }
  loadCompletedForm() {
    this.isLoaded = false;
    // console.log(this.contactFields);
    if (this.contactFields.length > 0) {
      this.ifFieldExist = true;
      this.generateQuestions(this.contactFields).subscribe(questions => {
        this.leadDynamicForm = this.toFormGroup(questions);
        this.isLoaded = true;
        // console.log(this.oQuestions)
      });
    } else {
      this.isLoaded = true;
    }
  }

  toFormGroup(questions: FormFieldBase<any>[]): any {
    const group: any = {};
    if (questions && questions.length > 0) {
      questions.forEach(question => {
        group[question.key] = question.required
          ? new FormControl(question.value || '', Validators.required)
          : new FormControl(question.value || '');
      });

      return new FormGroup(group);
    }
  }

  generateQuestions(fields) {
    return Observable.create(observer => {
      this.oQuestions = [];
      for (let i = 0; i < fields.length; i++) {
        // console.log(_.lowerCase(fields[i]['field']['input_type']));
       // console.log("Field : "+JSON.stringify(fields[i]));
        
        if (_.lowerCase(fields[i]['field']['input_type']) === 'text') {
          this.oQuestions.push(
            new TextboxField({
              key: fields[i].field.id,
              label: fields[i].field.name,
              value: this.checkFieldValue(fields[i].field.id),
              required: fields[i].field.is_required,
              order: i
            })
          );

        } else if (_.lowerCase(fields[i]['field']['input_type']) === 'select') {
          const fieldsValues = (fields[i]['field']['values']) ? fields[i]['field']['values'] : [];
          this.oQuestions.push(
            new DropdownField({
              key: fields[i].field.id,
              label: fields[i].field.name,
              value: this.checkFieldValue(fields[i].field.id),
              options: this.setDropDownOptions(fieldsValues), // key => value
              order: i
            })
          );
        } else if (
          _.lowerCase(fields[i]['field']['input_type']) === 'checkbox'
        ) {
          this.oQuestions.push(
            new CheckboxField({
              key: fields[i].field.id,
              label: fields[i].field.name,
              value: this.checkFieldCheckBoxValue(fields[i].field.id),
              required: false,
              order: i
            })
          );
        } else if (
          _.lowerCase(fields[i]['field']['input_type']) === 'checkbox multiple'
        ) {
          this.oQuestions.push(
            new MultiCheckboxField({
              key: fields[i].field.id,
              label: fields[i].field.name,
              value: this.checkFieldCheckBoxValue(fields[i].field.id),
              required: false,
              order: i,
              multipleValues: fields[i].field.display_values ? fields[i].field.display_values.split(',') : []
            })
          );
        } else if (
          _.lowerCase(fields[i]['field']['input_type']) === 'textarea'
        ) {
          this.oQuestions.push(
            new TextAreaField({
              key: fields[i].field.id,
              label: fields[i].field.name,
              value: this.checkFieldValue(fields[i].field.id),
              required: fields[i].field.is_required,
              order: i
            })
          );
        }
        else if (
          _.lowerCase(fields[i]['field']['input_type']) === 'html'
        ) {
          this.oQuestions.push(
            new HtmlAreaField({
              key: fields[i].field.id,
              label: fields[i].field.name,
              value: fields[i].field.display_values,
              display_values: this.checkFieldValue(fields[i].field.display_values),
              required: fields[i].field.is_required,
              order: i
            })
          );
        }
      }
      observer.next(this.oQuestions.sort((a, b) => a.order - b.order));
    });
  }

  setDropDownOptions(valuesObject) {
    const dropdownValues = Object.keys(valuesObject).map(data => [
      data,
      valuesObject[data]
    ]);

    return dropdownValues.map(function (l, n) {
      return {
        key: l[0],
        value: l[1]
      }
    })
  }

  checkFieldCheckBoxValue(id) {
    if (this.contactId && this.contactId != '') {
      for (var i = 0; i < this.contactFields.length; i++) {
        if (this.contactFields[i]['id'] == id) {
          if (this.contactFields[i]['value'] == 'Yes') {
            // Getting Yes/No from API instead of boolean
            return true;
          } else {
            return false;
          }
        }
      }
      return false;
    } else {
      return false;
    }
  }

  checkFieldValue(id) {
   // console.log(JSON.stringify(this.contactFields), id);
    if (this.contactId && this.contactId != '') {
      for (var i = 0; i < this.contactFields.length; i++) {
        if (this.contactFields[i]['id'] == id) {
          return this.contactFields[i]['value'];
        }
      }
      return '';
    } else {
      return '';
    }
  }

  onSubmit() {
    this.isLoaded = false;
    this.generateFieldsArray(this.leadDynamicForm.value);
    if (this.contactId != '') {
      this.model.id = this.contactId;
    } else {
      delete this.model.id;
    }
    // this.model.form_id = this.formId;
    this.model.form_id = ''; // Had to empty this variable because it was storing internal form fills as form submissions.
    this.contactService.saveForm(this.model).subscribe((res: any) => {
      if (res.success == 'true') {
        this.isLoaded = true;
        this.pop('success', '', 'Contact Successfully Submited');
        this.formSaved.emit(res.data.id);
      } else {
        this.pop('error', '', 'Form Submission Failed');
      }
    });
  }

  generateFieldsArray(formValue) {
    for (var i in formValue) {
      if (formValue[i] === true || formValue[i] === false) {
        if (formValue[i] === true) {
          this.model.fields.push({
            id: i,
            value: 'Yes'
          });
        } else if (formValue[i] === false) {
          this.model.fields.push({
            id: i,
            value: 'No'
          });
        }
      } else {
        this.model.fields.push({
          id: i,
          value: formValue[i]
        });
      }
    }
  }

  pop(type, title, text) {
    this.toasterService.pop(type, title, text);
  }
}
