import { FormFieldBase } from './form-field-base';

export class DropdownField extends FormFieldBase<string> {
  override controlType = 'dropdown';
  options: { key: string, value: string }[] = [];

  constructor(options: {} = {}) {
    super(options);
    this.options = options['options'] || [];
  }
}
