import { Injectable } from '@angular/core';
import 'rxjs/add/operator/map';
import { Router } from '@angular/router';
import { globalValues } from '@app/globals';
import { CustomHttp } from '@service/customhttp.service';
import { RequestLibraryService } from '@service/core/request-library.service';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class EmailTemplateReportService {
  // public headers: Headers;
  private customHttp: CustomHttp;
  constructor(private http: HttpClient, private router: Router, private requestLibrary: RequestLibraryService) {
    this.customHttp = new CustomHttp(http, router);
  }

  getSent(criteriaArray) {
    return this.requestLibrary.GetAll('/emailtemplates/reports/sent', { options: { criteria: criteriaArray } });
  }

  getEmailSentCount(criteriaArray) {
    return this.requestLibrary
      .GetAll('/emailtemplates/reports/sent', { options: { criteria: criteriaArray } })
      .map((res: any) => res.data)
      .map(arr => arr.reduce((a, b) => a + Number(b.number_of_occurrences), 0));
  }

  getEmailOpenedCount(criteriaArray) {
    return this.requestLibrary
      .GetAll('/emailtemplates/reports/open', { options: { criteria: criteriaArray } })
      .map((res: any) => res.data)
      .map(arr => arr.reduce((a, b) => a + Number(b.number_of_occurrences), 0));
  }
}
