<ng-template [ngxPermissionsOnly]="['contacts:can_get_contacts_reports_captured']">
  <div class="box-row panel panel-default">
    <div class="panel-heading">
      <h4>{{chartLabel}}</h4>
    </div>
    <div class="panel-body text-center text-alpha">
      <div *ngIf="!isContactCapturedLoaded" class="whirl standard spinnermargin"></div>
      <div *ngIf="isContactCapturedLoaded">
        <div class="col-lg-12 col-sm-12">
          <!-- <line-chart [chartData]="leadsChartData" [totalCount]="capturedCount"></line-chart> -->
          <!-- <div *ngIf="!totalCount || totalCount <= 10">
            <canvas baseChart [chartType]="'line'" [options]="lineOptionsForEmpty" [datasets]="leadsChartData.datasets"
              [labels]="chartData.labels" [legend]="chartData.legend" height="120"></canvas>
          </div> -->

          <!-- <div *ngIf="totalCount && totalCount > 10"> -->
          <!-- <canvas baseChart [chartType]="'line'" [options]="lineOptions" [datasets]="leadsChartData.datasets"
              [labels]="chartData.labels" [legend]="chartData.legend" height="120"></canvas> -->
          <canvas baseChart [datasets]="barChartData" [labels]="barChartLabels" [options]="barChartOptions"
            [legend]="barChartLegend" [chartType]="barChartType"></canvas>
          <!-- </div> -->
        </div>
      </div>
    </div>
  </div>
</ng-template>
