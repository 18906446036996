<div class="row">
    <div class="col-md-12">
        <ng-template [ngIf]="!isGroupsLoaded">
            <div class="whirl standard spinnermargin"></div>
        </ng-template>
        <ng-template [ngIf]="isGroupsLoaded">
            <div class="well well-small">
                <div class="form-group">
                    <label class="col-sm-4 control-label">Select Privileges by Group</label>
                    <div class="col-sm-8">
                        <select class="form-control" name="emailtemplateselect" (change)="filterPrivilegesByGroup($event.target.value)">
                            <ng-container *ngFor="let group of groupsArray">
                                <option [value]="group.id">{{group.name}}</option>
                            </ng-container>
                        </select>
                    </div>
                </div>
            </div>
        </ng-template>
    </div>
</div>
<div class="row">
    <div class="col-lg-6 col-md-12 col-sm-12">
        <a class="hyperlink-color pull-left" [class.disabled]="!isPrivilegesLoaded" (click)="toggleAllPrivileges();">{{togglePrivilegeLabel}}</a>
    </div>
</div>
<div class="row campaigns-checkboxes-list">
    <ng-template [ngIf]="!isPrivilegesLoaded">
        <div class="whirl standard spinnermargin"></div>
    </ng-template>
    <ng-template [ngIf]="isPrivilegesLoaded">
        <ng-template ngFor let-resource [ngForOf]="privilegesDisplyableArray" let-i="index" [ngForTrackBy]="trackByResource">
            <div class="panel">
                <div class="panel-heading">
                    <h4 class="panel-title">{{resource.resource | titlecase}}</h4>
                </div>
                <div class="panel-body">
                    <ng-template ngFor let-privilege [ngForOf]="resource.privileges" let-i="index" [ngForTrackBy]="trackByKey">
                        <div class="col-lg-4 col-md-4 col-sm-12" [ngClass]="{greyout: privilege.is_system == true}">
                            <label class="checkbox-inline c-checkbox">
                                <input name="campaign_name" [disabled]="privilege.is_system == true" type="checkbox" (click)="addRemovePrivilege(privilege.key);"
                                    [checked]="checkPrivilege(privilege.key)">
                                <span class="fa fa-check"></span>
                                {{privilege.label}}
                            </label>
                        </div>
                    </ng-template>
                </div>
            </div>
        </ng-template>
    </ng-template>
</div>