import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { globalValues } from '@app/globals';
import { AccountService } from '@service/account/account.service';
import { FieldService } from '@service/field/field.service';
import { PrivateLabelService } from '@service/private-label/private-label.service';
import * as _ from 'lodash';
import { Logger } from '../global.funcs';

@Injectable()
export class AddictionTreatmentSuiteService {


  constructor(
    public router: Router,
    public privateLabelService: PrivateLabelService,
    public accountService: AccountService,
    private fieldService: FieldService,
  ) { }

  public setup() {
  }
  /* public setup() {
    console.log('you are here..');
    return Observable.create((observer) => {
      const appVersion: string = localStorage.getItem('appVersion');
      if (appVersion == AppConfig.availableAppVersions.ADDICTION) {
        console.log('you are in here....');
        Observable.forkJoin(
          this.accountService.getMyAccountSettings('addictiontreatment'),
          this.fieldService.getAllFields()
        ).subscribe((results: any) => {
          const [
            accountSetting,
            fields
          ] = results;

          if (accountSetting.success == 'true') {
            localStorage.setItem('addictiontreatment', JSON.stringify(accountSetting.data));
          }

          if (fields.success == 'true') {
            const fieldsArray = fields.data;
            const insuranceFieldDetail = _.find(fieldsArray, { type: globalValues.insurance_field_type });
            const referredToFieldDetail = _.find(fieldsArray, { type: globalValues.referred_to_field_type });
            if (insuranceFieldDetail) {
              localStorage.setItem('addictiontreatment_insurance_field', JSON.stringify(insuranceFieldDetail));
            }
            if (referredToFieldDetail) {
              localStorage.setItem('addictiontreatment_referred_to_field', JSON.stringify(referredToFieldDetail));
            }
          }

        }, err => {
          Logger().info(err);
        });
      }

    }).toPromise();
  } */

  // public handle(): Promise<{} | string> {
  //   const appVersion = localStorage.getItem('appVersion');
  //   if (appVersion == AppConfig.availableAppVersions.ADDICTION) {
  //     Logger().info("in:" + appVersion);
  //     return this.getAccountInformation()
  //       .then(this.getInsuranceField.bind(this));
  //     // return this.getInsuranceField();

  //   } else {
  //     return Promise.resolve(true);
  //   }
  // }

  private getAccountInformation() {
    return new Promise((resolve, reject) => {
      return this.accountService
        .getMyAccountSettings('addictiontreatment')
        .subscribe((res: any) => {
          if (res.success === 'true') {
            localStorage.setItem('addictiontreatment', JSON.stringify(res.data));
            return resolve(true);
          }
          else {
            return reject('Something went wrong getting your account settings!');
          }
        });
    });
  }

  private getInsuranceField() {
    return new Promise((resolve, reject) => {
      return this.fieldService
        .getAllFields()
        .subscribe((res: any) => {
          if (res.success === 'true') {
            const fieldsArray = res.data;
            const insuranceFieldDetail = _.find(fieldsArray, { type: globalValues.insurance_field_type });
            const referredToFieldDetail = _.find(fieldsArray, { type: globalValues.referred_to_field_type });
            if (insuranceFieldDetail) {
              localStorage.setItem('addictiontreatment_insurance_field', JSON.stringify(insuranceFieldDetail));
            }
            if (referredToFieldDetail) {
              localStorage.setItem('addictiontreatment_referred_to_field', JSON.stringify(referredToFieldDetail));
            }
            return resolve(true);
          } else {
            return reject('Something went wrong getting your account settings!');
          }
        });
    });
  }
}
