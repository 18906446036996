<ng-template [ngxPermissionsOnly]="['automations:can_get_automations_reports_actions']">
  <div class="box-row panel panel-default">
    <div class="panel-body text-center">
      <div *ngIf="!isAutomationActionRunChartLoaded" class="child">
        <div class="whirl standard spinnermargin"></div>
      </div>
      <ng-template [ngIf]="isAutomationActionRunChartLoaded" class="child">
        <stacked-bar-chart [stackedBarData]="automationActionRunChartData" [stackedBarHeight]="170"></stacked-bar-chart>
        <!-- <app-bar-chart-compact [barData]="automationActionRunChartData" [totalCount]="totalChartData" chartFor="mediumSize"></app-bar-chart-compact> -->
      </ng-template>
    </div>
  </div>
</ng-template>