<ng-template [ngxPermissionsOnly]="['users:can_get_users_reports_sales']">
  <div class="box-row panel panel-default">
    <div class="panel-heading">
      <h4>{{widgetTitle}}</h4>
    </div>
    <div class="panel-body text-center">
      <div *ngIf="!chartDataLoaded" class="whirl standard spinnermargin"></div>
      <ng-template [ngIf]="chartDataLoaded">
        <stacked-bar-chart [stackedBarData]="stackedBarChartData" [stackedBarHeight]="250"></stacked-bar-chart>
      </ng-template>
    </div>
  </div>
</ng-template>