import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

@Injectable()
export class HelpersService {
    constructor() {
    }

    public csvDownload(reqData: any, csvName: string = '') {
        let csvContent = 'data:text/csv;charset=utf-8,';
        const extName = '.csv';
        let name = (Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 15)) + extName;
        if (csvName != '') {
            name = csvName + extName;
        }
        if (reqData.filename && reqData.filename != null) {
            name = reqData.filename;
        }

        csvContent += reqData.data;
        const encodedUri = encodeURI(csvContent);
        const link = document.createElement('a');
        link.setAttribute('href', encodedUri);
        link.setAttribute('download', name);
        document.body.appendChild(link);
        link.click();
    }

    public appendQueryString(url, queryVars) {
        const firstSeperator = (url.indexOf('?') == -1 ? '?' : '&');
        const queryStringParts = new Array();
        if (queryVars) {
            for (const key in queryVars) {
                if (queryVars.hasOwnProperty(key)) {
                    queryStringParts.push(key + '=' + queryVars[key]);
                }
            }
        }

        const queryString = queryStringParts.join('&');
        return url + firstSeperator + queryString;
    }

    public genRange(N, seq) {
        return Array.from(new Array(N), (val, index) => seq(index));
    }

}
