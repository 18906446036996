import { Component, ElementRef, Input, OnInit, OnChanges } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { LeadPriceService, SettingsService } from '@service/index';
import * as _ from 'lodash';

@Component({
    selector: 'app-show-pricing-list',
    templateUrl: './show-pricing-list.component.html',
})

export class ShowPricingListComponent implements OnInit, OnChanges {
    @Input() model: any;
    @Input() priceFor: string = 'valueObj';
    @Input() withSwitchFilter: string = '';
    @Input() view: string;

    public pricesArray: Array<any> = [];
    public pricesLoaded: Boolean = false;
    public togglePriceLabel = 'Select All';
    public chunkPricesArray: {
        chunkSize: number,
        chunkData: Array<any>
    } = { chunkSize: 0, chunkData: [] };

    constructor(
        private elementRef: ElementRef,
        fb: FormBuilder,
        private settingsService: SettingsService,
        private router: Router,
        private route: ActivatedRoute,
        private priceService: LeadPriceService
    ) {
    }

    ngOnInit() {
        this.getPrices();
    }

    ngOnChanges(changes) {
        this.view = this.view || 'lg';
    }

    getPrices() {
        this.priceService
            .getAllLeadsPrices()
            .subscribe((res: any) => {
                if (res.success == 'true') {
                    this.pricesArray = _.sortBy(res.data, [ 'name']);
                    this.makeChunks();
                }
                this.pricesLoaded = true;
            }, err => {
                this.router.navigate(['error']);
            });
    }

    addRemovePrice(id) {

      // console.log('addRemovePrice this.priceFor', this.priceFor);
        if (this.priceFor == 'dataObj') {
            if (this.checkPrice(id)) {
                let index = this.model.data.indexOf(id);
                this.model.data.splice(index, 1);
            } else {
                this.model.data.push(id);
            }
        } else {
            if (this.checkPrice(id)) {
                delete this.model.value[id];
            } else {
                this.model.value[id] = 'on';
                this.model.names.push(this.getCriterionName(id));
            }
        }

    }

    checkPrice(id) {
        if (this.priceFor == 'dataObj') {
          // console.log(this.model.data);
            if (this.model.data && this.model.data.length > 0) {
                if (this.model.data.indexOf(id) > -1) {
                    return true;
                }
            }
            return false;
        } else {

            for (var price in this.model.value) {
                if (price == id) {
                    return true;
                }
            }
            return false;
        }
    }
    toggleAllPrices() {
        this.togglePriceLabel = (this.togglePriceLabel === 'Select All') ? 'Deselect All' : 'Select All';
        if (this.pricesArray && this.pricesArray.length > 0) {
            this.pricesArray.forEach((value) => {
                const id = value.id;
                if (this.priceFor == 'dataObj') {
                    if (this.checkPrice(id)) {
                        const index = this.model.data.indexOf(id);
                        this.model.data.splice(index, 1);
                    } else {
                        this.model.data.push(id);
                    }
                } else {
                    if (this.checkPrice(id)) {
                        delete this.model.value[id];
                    } else {
                        this.model.value[id] = 'on';
                    }
                }
            });
        }
    }

    getCriterionName(id: number) {
        let criterion_name = '';
        const found = _.find(this.pricesArray, { id: id });

        if (found != undefined) {
            criterion_name += found['name'];
        }

        return criterion_name;
    }

    makeChunks() {
        // console.log(this.view);
        let chunk = 0;
        if (this.view === 'sm') {
            chunk = 12;
        }
        if (this.view === 'md') {
            chunk = 2;
        }
        if (this.view === 'lg') {
            chunk = 4;
        }

        this.chunkPricesArray = { chunkSize: chunk, chunkData: _.chunk(this.pricesArray, chunk) };
    }
}
