import { Injectable } from "@angular/core"
import { Router } from "@angular/router"
import { AuthLogoutService } from "@service/auth/auth-logout.service"
import { auth0Config } from "@service/auth/auth0.config"
import * as jwt from "jsonwebtoken"
import * as _ from "lodash"

@Injectable({
  providedIn: "root",
})
export class CustomerOnboardHelperService {
  public _auth0_rollias_secret = "x2f1"

  constructor(
    private auth: AuthLogoutService,
    public router: Router
  ) {}

  async validateIncomingToken(session_token) {
    if (!session_token) {
      throw "No session_token found"
    }

    try {
      const decoded = jwt.verify(session_token, this._auth0_rollias_secret)
      return decoded
    } catch (e) {
      console.log(e)
      this.router.navigate(['/logout']);
      throw "Invalid session_token"
    }
  }

  async generateNewToken(state, data, nData) {
    const payload = _.merge({}, data)
    const nPayload = {
      state,
      mo_data: nData
    }
    // const nPayload = {
    //   state, // Mandatory, validated by Auth0
    //   mo_data: {
    //     mo_organization_id: resOrgData.id,
    //     mo_user_id: resOrgData.user_id,
    //     mo_user_name: resOrgData.name,
    //   }, // Optional custom parameters to be used in Actions
    // }
    // todo: extend exp: token expiry time with + 60s.
    const result = _.merge(payload, nPayload)
    const secret = jwt.sign(result, this._auth0_rollias_secret)

    return secret
  }
}
