import { Pipe, PipeTransform } from '@angular/core';
import { timezones } from '@asset/timezones/timezones';
import * as _ from 'lodash';

@Pipe({
  name: 'labelTimezone'
})
export class LabelTimezonePipe implements PipeTransform {
  transform(value: any, args?: any): any {
    const foundObject = _.find(timezones, function (obj: any) {
      return obj.value === value;
    });
    if (foundObject) {
      return foundObject.name;
    } else {
      return value;
    }
  }

}
