import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { CustomHttp } from "@service/customhttp.service";
import "rxjs/add/operator/map";
import { toArray } from "rxjs/operators/toArray";
import { Logger } from "@service/global.funcs";
import { RequestLibraryService } from "@service/core/request-library.service";
import { HttpClient } from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class TaskTemplateService {

  private customHttp: CustomHttp;
  constructor(
    private http: HttpClient,
    private router: Router,
    private requestLibrary: RequestLibraryService
  ) {
    this.customHttp = new CustomHttp(http, router);
  }

  getTaskTemplates() {
    return this.requestLibrary.Get('/tasktemplates');
  }

  getTaskTemplateDetail(id) {
    return this.requestLibrary.Get(`/tasktemplates/${id}`);
  }

  getPaginatedTaskTemplates(page, page_size, sort, sort_dir) {
    return this.requestLibrary.GetAll('/tasktemplates', {
      'options': { page: page, page_size: page_size, sort: sort, sort_dir: sort_dir }
    });
  }

  saveTaskTemplate(model) {
    return this.requestLibrary.Post('/tasktemplates', model);
  }

  deleteTaskTemplate(id) {
    return this.requestLibrary.Delete(`/tasktemplates/${id}`);
    // return this.requestLibrary.Delete('/followups/' + id);
  }
}
