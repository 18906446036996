import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import 'rxjs/add/operator/map';
import { globalValues } from '@app/globals';
import { CustomHttp } from '@service/customhttp.service';
import { RequestLibraryService } from '@service/core/request-library.service';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class AutomationReportService {
  private customHttp: CustomHttp;
  constructor(private http: HttpClient, private router: Router, private requestLibrary: RequestLibraryService) {
    this.customHttp = new CustomHttp(http, router);
  }
  getStackedBarData() {
    /**
     * we hard coded the date range because it's the only one we want here
     */
    const relative_date = 'Last 7 Days';
    return this.requestLibrary.GetAll('/automations/reports/actions', { options: { date_interval: relative_date } });
  }

  getActionRunCount() {
    const relative_date = 'Last 7 Days';
    return this.requestLibrary
      .GetAll('/automations/reports/actions', { options: { date_interval: relative_date } })
      .map((res: any) => res.data)
      .map(arr => arr.reduce((total, record) => total + Number(record.actions_count), 0));
  }
}
