import { Injectable } from '@angular/core';
import 'rxjs/add/operator/map';
import { Router } from '@angular/router';
import { CustomHttp } from '@service/customhttp.service';
import { RequestLibraryService } from '@service/core/request-library.service';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class GroupService {
    private customHttp: CustomHttp;
    constructor(
        private http: HttpClient,
        private router: Router,
        private requestLibrary: RequestLibraryService
    ) {
        this.customHttp = new CustomHttp(http, router);
    }
    
    storePrivilege(group: number, privileges: any) {
        return this.requestLibrary.Post(`/groups/${group}/privileges`, privileges);
    }

    getPrivilege(group: number) {
        return this.requestLibrary.Get(`/groups/${group}/privileges`);
    }

    getGroups() {
        return this.requestLibrary.GetAll('/groups', {
            'options': { page_size: 'all', is_compact: true }
        });
    }

    getFullGroups() {
        return this.requestLibrary.GetAll('/groups', {
            'options': { page_size: 'all', 'expand': ['users'] }
        });
    }

    getPaginatedGroups(page, page_size, sort, sort_dir) {
        return this.requestLibrary.GetAll('/groups', {
            'options': { page_size: 'all', sort: sort, sort_dir: sort_dir }
        });
    }

    getExportedGroups(args) {
        return this.requestLibrary.GetCSV('/groups', {
            'options': args
        });
    }

    getGroupDetail(id) {
        return this.requestLibrary.Get('/groups/' + id);
    }

    deleteUserGroup(id) {
        return this.requestLibrary.Delete('/groups/' + id);
    }

    addEditGroups(model) {
        return this.requestLibrary.Post('/groups', model);
    }
}
