import { Component, OnInit } from '@angular/core';
import {
  AppConfig
} from '@service/index';

@Component({
  selector: 'app-sidebar-wrapper',
  templateUrl: './sidebar-wrapper.component.html'
})
export class SidebarWrapperComponent implements OnInit {

  public appVersion = localStorage.getItem('appVersion') || 'default';
  public appVersions = AppConfig.availableAppVersions;
  constructor(
  ) {


  }

  ngOnInit() {
  }
}
