import { Injectable } from '@angular/core';
import { RequestLibraryService } from '@service/core/request-library.service';
import { Observable } from 'rxjs';

@Injectable()
export class OnBoardReportService {
    constructor(
        private requestLibrary: RequestLibraryService
    ) {
    }
    getTasks(): Observable<any> {
        return this.requestLibrary.BaseGet(`/assets/onboard-task.json`);
    }



}
