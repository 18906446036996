<ng-template [ngxPermissionsOnly]="['privileges:can_get_privileges']">
  <div class="row mb-sm">
    <div class="col-lg-6 col-md-12 col-sm-12 align-content-center">
      <a class="hyperlink-color pull-left pdt" [class.disabled]="!isPrivilegesLoaded"
        (click)="toggleAllPrivileges();">{{togglePrivilegeLabel}}</a>
    </div>
    <div class="col-lg-6 col-md-6 col-sm-12">
      <div class="form-group mt-sm">
        <label class="col-sm-4 control-label">Replace with</label>
        <div class="col-sm-8">
          <select class="form-control w-100" name="privilege_template_id" (change)="onPrivilegeTemplateChange($event)"
            [(ngModel)]="privilege_template_id">
            <option selected value>Privilege Template</option>
            <option *ngFor="let template of privilegesTemplateArray" [value]="template.id">{{template.name}}</option>
          </select>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-12 col-md-12 col-sm-12">
      <ng-template [ngIf]="!isPrivilegesLoaded">
        <div class="whirl standard spinnermargin"></div>
      </ng-template>
      <ng-template [ngIf]="isPrivilegesLoaded">
        <ng-container>
          <accordion closeOthers="true">
            <ng-template ngFor let-resource [ngForOf]="privilegesDisplyableArray" let-i="index"
              [ngForTrackBy]="trackByResource">
              <accordion-group>
                <div accordion-heading class="clearfix">
                  <div class="table-grid">
                    <div class="col">
                      {{resource.resource_label | titlecase}}
                    </div>
                    <ng-template ngFor let-method [ngForOf]="resource.methods" let-indexMethod="index">
                      <div class="col">
                        <ng-template [ngIf]="resource.privileges[method].length">
                          <label class="checkbox-inline c-checkbox">
                            <input name="resource_{{method}}" type="checkbox"
                              (change)="_addRemoveBulkPrivilege(method, resource.resource, $event.target.checked);"
                              [checked]="_checkBulkPrivilege(method, resource.resource)">
                            <span class="fa fa-check"></span>
                            {{actionLabel[method]}}
                          </label>
                        </ng-template>
                      </div>
                    </ng-template>
                  </div>
                </div>
                <ng-container>
                  <div class="table-grid">
                    <div class="col">
                      &nbsp;
                    </div>
                    <ng-template ngFor let-methodBody [ngForOf]="resource.methods" let-indexMethod="index">
                      <div class="col">
                        <ng-template ngFor let-methodPrivileges [ngForOf]="resource.privileges[methodBody]"
                          let-i="indexMethodPrivileges">
                          <ng-template ngFor let-methodPrivilegeItem [ngForOf]="[methodPrivileges]"
                            let-indexMethodPrivilegeItem="index">
                            <div [ngClass]="{greyout: methodPrivilegeItem.is_system == true}">
                              <label class="checkbox-inline c-checkbox">
                                <input name="campaign_name" [disabled]="methodPrivilegeItem.is_system == true"
                                  type="checkbox" (click)="addRemovePrivilege(methodPrivilegeItem.key);"
                                  [checked]="isPrivilegeExist(methodPrivilegeItem.key)">
                                <span class="fa fa-check"></span>
                                {{methodPrivilegeItem.label}}
                              </label>
                            </div>
                          </ng-template>
                        </ng-template>
                      </div>
                    </ng-template>
                  </div>
                </ng-container>
              </accordion-group>
            </ng-template>
          </accordion>
        </ng-container>
      </ng-template>
    </div>
  </div>
</ng-template>
