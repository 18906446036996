import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CustomHttp } from '@service/customhttp.service';
import { RequestLibraryService } from '@service/core/request-library.service';
import 'rxjs/add/operator/map';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class UserReportService {
  private customHttp: CustomHttp;
  constructor(private http: HttpClient, private router: Router, private requestLibrary: RequestLibraryService) {
    this.customHttp = new CustomHttp(http, router);
  }

  getSalesByRepStat(since, role_id) {
    return this.requestLibrary.GetAll('/users/reports/sales', {
      options: { date_interval: since, role_id: role_id }
    });
  }

  getContactsByUser(criteriaArray) {
    return this.requestLibrary.GetAll('/users/reports/users', {
      options: { criteria: criteriaArray }
    });
  }
}
