import { Injectable } from '@angular/core';
import 'rxjs/add/operator/map';
import { Router } from '@angular/router';
import { CustomHttp } from '@service/customhttp.service';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class DashboardService {
    private customHttp: CustomHttp;
    constructor(private http: HttpClient, private router: Router) {
        this.customHttp = new CustomHttp(http, router);
    }

    getStats(filter) {
        return this.customHttp.getAll('/tests/reports/stats?options=', { date_interval: filter });
    }

    

}
