import { Component, Input, OnChanges, OnInit, SimpleChange } from '@angular/core';
import { Privilege } from '@decorator/index';
import { StatusReportService, ToasterLibraryService } from '@service/index';
import { NgxPermissionsService } from 'ngx-permissions';

@Component({
  selector: 'app-addiction-admissions-widget',
  templateUrl: './addiction-admissions-widget.component.html',
  styleUrls: ['./addiction-admissions-widget.component.scss']
})

export class AddictionAdmissionsWidgetComponent implements OnInit, OnChanges {

  @Input() relativeDate: any;
  @Input() campaignData: any;
  @Input() criteriaArray: any;
  @Input() private widgetRefresh;
  public isCountLoaded: Boolean = false;
  public closeLeadsCount: Number = 0;
  constructor(
    private statusReportService: StatusReportService,
    private toasterLibrary: ToasterLibraryService,
    private permissionService: NgxPermissionsService

  ) { }

  ngOnInit() {
    if (this.permissionService.hasPermission('contacts:can_get_contacts_reports_count')) {
      this.criteriaArray = JSON.parse(localStorage.getItem('leadsCriteria'));
      this.getCloseLeadsCount();
    }
  }

  /**
   * 
   * @TODO Fix these widgets on update
   */

  ngOnChanges(changes: { [key: string]: SimpleChange }) {

    if (this.permissionService.hasPermission('contacts:can_get_contacts_reports_count')) {
      if (
        (changes['relativeDate'] && !changes['relativeDate'].isFirstChange()) ||
        (changes['campaignData'] && !changes['campaignData'].isFirstChange()) ||
        (changes['criteriaArray'] && !changes['criteriaArray'].isFirstChange())
      ) {
        this.getCloseLeadsCount();
      }
    }

  }

  getCloseLeadsCount() {
    this.isCountLoaded = false;
    this.statusReportService
      .getCloseLeadsCount(this.criteriaArray)
      .subscribe((res: any) => {
        this.closeLeadsCount = res.data.count;
        this.isCountLoaded = true;
      });
  }

}
