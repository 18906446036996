import { Input, OnInit, Component, AfterViewInit, Output, EventEmitter } from '@angular/core';
import { PrivilegesRoles } from '@asset/privileges-roles/privileges-roles';
import { PrivilegesTemplates } from '@asset/privileges-roles/privileges-templates';
import { Privilege } from '@decorator/index';
import { Logger } from '@service/global.funcs';
import { GroupService, PrivilegeService } from '@service/index';
import * as _ from 'lodash';
import { Observable } from 'rxjs/Observable';
import { NgxPermissionsService } from 'ngx-permissions';
@Component({
  selector: 'app-assign-privilege-container',
  templateUrl: './assign-privilege-container.component.html',
  styles: [
    `
      .inner-links {
        font-size: 14px;
        text-decoration: none;
      }
      .custom-dp {
        width: 300px;
        display: inline;
        margin-left: 25px
      }
    `
  ],
})

// @Privilege({
//     privilege: 'privileges:can_get_privileges'
// })


export class AssignPrivilegeContainerComponent implements OnInit {
  @Input() modelPrivilege: Array<any> = [];
  @Output() modelPrivilegeChange = new EventEmitter<any[]>();
  public defaultModelPrivilege: Array<any> = [];
  @Input() only: any = [];
  public togglePrivilegeLabel = 'Select All';
  public privilegesArray: Array<any> = [];
  public systemPrivilegesArray: Array<any> = [];
  public groupsArray: Array<any> = [];
  public privilegesDisplyableArray: Array<any> = [];
  public privilegesTemplateArray: Array<any> = [];
  public isPrivilegesLoaded = false;
  public isGroupsLoaded = false;
  public privilege_template_id = '';
  public actionLabel = {
    'get': 'View',
    'delete': 'Delete',
    'post': 'Modify',
    'run': 'Execute'
  }
  public actionValueDefault = {
    'get': [],
    'delete': [],
    'post': [],
    'run': []
  }
  public actionOrder = {
    'get': 1,
    'post': 2,
    'delete': 3,
    'run': 4
  }

  constructor(
    private privilegeService: PrivilegeService,
    private groupService: GroupService,
    private permissionService: NgxPermissionsService
  ) {
  }

  async ngOnInit() {
    if (this.permissionService.hasPermission('privileges:can_get_privileges')) {
      this.defaultModelPrivilege = _.cloneDeep(this.modelPrivilege);
      await this.requestAllPrivileges();
      this.defaultPrivilegeLabel();
      this.requestPrivilegesTemplate();
      if (this.only.length) {
        this.filterPrivileges(this.only);
      }
    }
  }

  toggleAllPrivileges() {
    this.togglePrivilegeLabel = (this.togglePrivilegeLabel === 'Select All') ?
      'Deselect All' : 'Select All';
    this.privilege_template_id = '';
    if (this.privilegesArray && this.privilegesArray.length > 0) {
      this.privilegesArray.forEach((value) => {
        const id = value.key;
        this.selectPrivilege(id);
      });
    }
  }
  private defaultPrivilegeLabel() {
    this.togglePrivilegeLabel = (this.togglePrivilegeLabel === 'Select All' && this.modelPrivilege.length > 0) ?
      'Deselect All' : 'Select All';
  }

  requestAllPrivileges() {
    return new Promise((resolve, reject) => {
      this.isPrivilegesLoaded = false;
      this.privilegeService
        .getAllPrivileges()
        .subscribe((res: any) => {
          if (res.success == 'true') {
            this.privilegesArray = (res.data);
            this.privilegesDisplyableArray = this.transform(res.data);
            // console.log(this.privilegesDisplyableArray);
            this.isPrivilegesLoaded = true;
            this.systemPrivilegesArray = this.privilegesArray.filter((r) => r.is_system === true).map((p) => p.key);
            // Logger().info(this.systemPrivilegesArray);
            resolve(true);
          }
        });
    });
  }
  transform(privileges): any {
    // output : {
    //   resource: 'string',
    //   resource_label: 'string',
    //   methods: [],
    //   privileges: [],
    // }


    let list = _.chain(privileges)
      .groupBy('resource')
      .toPairs()
      .map(function (currentItem) {
        return _.zipObject(['resource', 'privileges'], currentItem);
      })
      .value();

    // get all method based on resource
    const list_method = _.map(list, (item: any) => {
      const rank = this.actionOrder;

      // const methods = _.uniq(_.filter(item.privileges, (privilege: any) => privilege.resource === item.resource)
      //   .map(pri => pri.method));
      const methods = ['get', 'delete', 'post'];

      const sortedMethods = _.sortBy(methods, function (element) {
        return rank[element];
      });

      const privileges: any = _.mapValues(_.groupBy(item.privileges, 'method'),
        clist => clist.map(privilege => _.omit(privilege, 'method')));

      const defaultedPrivileges = (Object.assign({}, this.actionValueDefault, privileges));
      const found_resource_label = _.first(_.values(_.filter(item.privileges, { 'resource': item.resource }).map((v: any) => v.resource_label)));

      return { resource: item.resource, resource_label: found_resource_label, privileges: defaultedPrivileges, methods: sortedMethods };
    });

    return _.orderBy(list_method, ['resource'], ['asc']);
  }

  trackByKey(index: number, privilege: any): string { return privilege.key; }
  trackByResource(index: number, resource: any): string { return resource.resource; }
  addRemovePrivilege(id: string) {
    if (this.isPrivilegeExist(id)) {
      _.remove(this.modelPrivilege, function (n) {
        return n == id;
      });
    } else {
      this.modelPrivilege.push(id);
    }
    this.modelPrivilegeChange.emit(this.modelPrivilege);
  }
  selectPrivilege(id: string) {
    if (this.togglePrivilegeLabel === 'Deselect All' && !this.isPrivilegeExist(id)) {
      this.modelPrivilege.push(id);
    }
    if (
      this.togglePrivilegeLabel === 'Select All' &&
      this.isPrivilegeExist(id) &&
      this.isSystemPrivilege(id)
    ) {
      _.remove(this.modelPrivilege, (n) => n === id);
    }
  }

  isSystemPrivilege(id) {
    return this.systemPrivilegesArray && !(this.systemPrivilegesArray.indexOf(id) > -1)
  }

  isPrivilegeExist(id: string) {
    return this.modelPrivilege && (this.modelPrivilege.indexOf(id) > -1);
  }
  _addRemoveBulkPrivilege(method: string, resource: string, isChecked): void {
    Logger().info('Trigger: click ', method, resource, isChecked);
    let privileges = this.getPrivilegesResourceMethod(method, resource);
    if (method === 'get' && !isChecked) {
      privileges = this.getPrivilegesResourceMethod('get', resource);
    }
    if (method === 'post' && !isChecked) {
      privileges = this.getPrivilegesResourceMethod('post', resource);
    }

    if (method === 'delete' && !isChecked) {
      privileges = this.getPrivilegesResourceMethod('delete', resource);
    }
    Logger().info(privileges);
    if (privileges && privileges.length > 0) {
      privileges.forEach((id) => {
        if (isChecked) {
          this.addPrivilege(id);
        } else {
          this.removePrivilege(id);
        }
      });
    }
    this.modelPrivilegeChange.emit(this.modelPrivilege);
  }

  addPrivilege(id: string) {
    return this.modelPrivilege.push(id);
  }
  removePrivilege(id: string) {
    return _.remove(this.modelPrivilege, function (n) {
      return n == id;
    });
  }

  _checkBulkPrivilege(method: string, resource: string): boolean {
    // Logger().info('Trigger: checked ', method, resource);
    const privileges = this.getPrivilegesResourceMethod(method, resource);
    // console.log(this.getPrivilegesResourceMethod('can', resource));
    const hasAll = privileges.filter(r => !this.modelPrivilege.includes(r));
    return hasAll.length === 0;
  }

  getPrivilegesResourceMethod(method: string, resource: string): string[] {
    if (!this.privilegesArray) {
      return [];
    }

    if (method === 'get') {
      return this.privilegesArray
        .filter((value) => value.resource === resource && value.method === 'get')
        .map((privilege) => privilege.key);
    }

    if (method === 'delete') {
      return this.privilegesArray
        .filter((value) => value.resource === resource && value.method === 'delete')
        .map((privilege) => privilege.key);
    }

    if (method === 'post') {
      return this.privilegesArray
        .filter((value) => value.resource === resource && value.method === 'post')
        .map((privilege) => privilege.key);
    }

    return this.privilegesArray
      .filter((value) => value.resource === resource && value.method === method)
      .map((privilege) => privilege.key);
  }

  filterPrivileges(privileges: string[]) {
    Logger().info(privileges);
    this.privilegesDisplyableArray = this.transform(privileges);
  }

  getPrivilegesTemplate() {
    return Observable
      .from(PrivilegesTemplates)
      .mergeMap((obj: any) => {
        Logger().info(obj);
        const name$ = Observable.of(obj).map((rec) => rec.name);
        const id$ = Observable.of(obj).map((rec) => rec.id);
        return Observable.combineLatest(name$, id$, (name, id) => {
          return { id, name };
        });
      })
      .toArray()
  }
  requestPrivilegesTemplate() {
    this
      .getPrivilegesTemplate()
      .subscribe((templates: any) => {
        Logger().info(templates);
        console.log('asdf', templates);
        if (templates && templates.length > 0) {
          this.privilegesTemplateArray = templates;
        }
      });
  }

  onPrivilegeTemplateChange(event$) {

    const privileges = PrivilegesTemplates.filter((template) => { return template.id === this.privilege_template_id })
      .map((found) => found.privileges);
    if (_.includes(privileges, "*")) {
      // this.togglePrivilegeLabel = 'Select All';
      this.toggleAllPrivileges();
    }

    if (!_.includes(privileges, "*") && privileges && privileges.length > 0) {
      this.resetSelectedPrivileges().then(() => {
        _.flatten(privileges).forEach((id) => {
          if (!this.isPrivilegeExist(id)) {
            this.addPrivilege(id);
          }
        });
        // console.log(this.modelPrivilege);
        this.modelPrivilegeChange.emit(this.modelPrivilege);
      });
    }

    this.defaultPrivilegeLabel();
  }
  resetSelectedPrivileges() {
    return new Promise((resolve, reject) => {
      this.modelPrivilege = _.cloneDeep(this.systemPrivilegesArray);
      // console.log(this.modelPrivilege);
      return resolve(true);
    });
  }
}


