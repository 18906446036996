let environmentStaging = {
  production: true,
  env_key: "staging",
  auth_redirect_url: location.protocol + "//" + location.host + "/callback",
  auth_linkedRedirect_url:
    location.protocol + "//" + location.host + "/users/callback",
  auth_login_url: location.protocol + "//" + location.host + "/login",
  forgot_password_url:
    location.protocol + "//" + location.host + "/forgot-password",
  auth_lock_key: "ibnlo0SrT74hYp6GDlRNS61EVhRptHhE", // Auth0 Lock Key
  auth_domain: "marketingoptimizerdev.us.auth0.com",
  export_leads_url: "https://api.staging.marketingoptimizer.com/api/v1/export/", // Download export leads URL
  import_leads_url:
    "https://api.staging.marketingoptimizer.com/api/v1/contacts/import", // Import leads URL
  api_base_url: "https://api.staging.marketingoptimizer.com/api/v1", // API Base URL
  stripe_api_key: "pk_test_SNo6rXREyd217gQR6CrlrMa400WrgCZQEx",
  intercom_id: "oeuwkbw1",
  airbrake_project_id: 389094,
  airbrake_project_key: "030155334015609780fb471fc81c4389",
  ga_measurement_id: "G-YGQXM3R5QZ",
}

if (location.hostname.indexOf("figdaconsulting") !== -1) {
  environmentStaging.auth_lock_key = "4i0UXD3JiKRi1tZgv7CoQy8pNXLX8Fvh"
  environmentStaging.auth_domain = "figdaconsultingprod.us.auth0.com"
  console.log("figdaconsulting", location.hostname)
}

if (location.hostname.indexOf("activemarketing") !== -1) {
  environmentStaging.auth_lock_key = "pQgwCJqP1JW0bM2KyKTmYlueTOXLMGvd"
  environmentStaging.auth_domain = "activemarketingprod.us.auth0.com"
  console.log("activemarketing", location.hostname)
}
if (location.hostname.indexOf("progrowth") !== -1) {
  environmentStaging.auth_lock_key = "CIRTH0m94SdV5QaJEJSnK3OT20ePmUmD"
  environmentStaging.auth_domain = "progrowthprod.us.auth0.com"
  console.log("progrowth", location.hostname, environmentStaging)
}
export const environment = environmentStaging
