import { Component, Input, OnChanges, OnInit, SimpleChange } from '@angular/core';
import { Privilege } from '@decorator/index';
import { ToasterLibraryService, AutomationReportService } from '@service/index';
import { NgxPermissionsService } from 'ngx-permissions';
import { Logger } from '@service/global.funcs';

@Component({
  selector: 'app-automations-action-run-count-widget',
  templateUrl: './automations-action-run-count-widget.component.html',
  styleUrls: ['./automations-action-run-count-widget.component.scss'],
})

// @Privilege({
//   privilege: 'visitors:can_get_visitors_reports_count'
// })

export class AutomationsActionRunCountWidgetComponent implements OnInit {
  public isCountLoaded = false;
  public websiteVisitorsCount = 0;
  constructor(
    private toasterLibrary: ToasterLibraryService,
    private permissionService: NgxPermissionsService,
    private automationReportService: AutomationReportService
  ) {
  }

  ngOnInit() {
    if (this.permissionService.hasPermission('automations:can_get_automations_reports_actions')) {
      this.getActionRunCount();
    }

  }

  getActionRunCount() {
    this.isCountLoaded = false;
    this.automationReportService
      .getActionRunCount()
      .subscribe((res: any) => {
        Logger().info(res.data);
        this.websiteVisitorsCount = res.data || 0;
        this.isCountLoaded = true;
      });
  }

}
