import { Component, Input, OnChanges, OnInit, SimpleChange } from '@angular/core';
import { Privilege } from '@decorator/index';
import { FormReportService, ToasterLibraryService } from '@service/index';
import { NgxPermissionsService } from 'ngx-permissions';

@Component({
  selector: 'app-form-submission-widget',
  templateUrl: './form-submission-widget.component.html',
  styleUrls: ['./form-submission-widget.component.scss'],
})

// @Privilege({
//   privilege: 'forms:can_get_forms_reports_contacts_submissions'
// })

export class FormSubmissionWidgetComponent implements OnInit, OnChanges {

  @Input() relativeDate: any;
  @Input() campaignData: any;
  public isCountLoaded = false;
  public formSubmissionCount = 0;
  constructor(
    private formReportService: FormReportService,
    private toasterLibrary: ToasterLibraryService,
    private permissionService: NgxPermissionsService

  ) { }

  ngOnInit() {
    if (this.permissionService.hasPermission('forms:can_get_forms_reports_forms')) {
      this.getFormSubmissionCount();
    }

  }

  ngOnChanges(changes: { [key: string]: SimpleChange }) {

    if (this.permissionService.hasPermission('forms:can_get_forms_reports_forms')) {
      if (
        (changes['relativeDate'] && !changes['relativeDate'].isFirstChange()) ||
        (changes['campaignData'] && !changes['campaignData'].isFirstChange()) ||
        (changes['criteriaArray'] && !changes['criteriaArray'].isFirstChange())
      ) {
        this.getFormSubmissionCount();
      }
    }
  }

  getFormSubmissionCount() {

    const criteriaArray: any = [
      {
        id: '',
        is_required: true,
        criterion: 'dateRange',
        value: {
          start_date: '',
          end_date: '',
          relative_date: this.relativeDate
        }
      }
    ];
    
    if (this.campaignData && this.campaignData != "") {
      criteriaArray.push({
        'id': '',
        'value': { c_id: this.campaignData },
        'criterion': 'campaign',
        'is_required': true
      });
    }

    this.isCountLoaded = false;
    this.formReportService
      .getFormSubmissionCount(criteriaArray)
      .subscribe((res: any) => {
        this.formSubmissionCount = res.data.reduce((total, item) => total + Number(item.number_of_occurrences), 0);
        this.isCountLoaded = true;
      });
  }

}
