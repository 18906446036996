import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import 'rxjs/add/operator/map';
import { globalValues } from '@app/globals';
import { CustomHttp } from '@service/customhttp.service';
import { RequestLibraryService } from '@service/core/request-library.service';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class ContactRefundModerateService {
  private customHttp: CustomHttp;
  constructor(
    private http: HttpClient,
    private router: Router,
    private requestLibrary: RequestLibraryService
  ) {
    this.customHttp = new CustomHttp(http, router);
  }
  getPaginatedLeads(page, page_size, sort, sort_dir, criteriaArray) {
    return this.requestLibrary.GetAll('/refundmoderate', {
      options: {
        page: page,
        page_size: page_size,
        sort: sort,
        sort_dir: sort_dir,
        criteria: criteriaArray
      }
    });
  }



  refundApprove(contact_id, account_id) {
    return this.requestLibrary.Post('/refundmoderate/approve', { 'contact_id': contact_id, 'account_id': account_id });
  }
  refundDeny(contact_id, account_id, description) {
    return this.requestLibrary.Post('/refundmoderate/deny',
      { 'contact_id': contact_id, 'account_id': account_id, 'description': description });
  }

  getExportContact(args) {
    return this.customHttp.getDownloadableCsv('/refundmoderate?options=', args);
  }
}
