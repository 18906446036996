import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewContainerRef } from '@angular/core';
import { Router } from '@angular/router';
import { SettingsService } from '@service/index';

@Component({
    selector: 'filter-button',
    templateUrl: './filters.component.html',
})

export class FiltersComponent implements OnInit {

    @Input() filters: any;
    @Output() filterApplied: EventEmitter<any> = new EventEmitter();

    constructor(
        public vcRef: ViewContainerRef) {
    }

    ngOnInit() {
    }

    /** Apply filters */
    applyFilters() {
        
    }
}