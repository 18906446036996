import { Injectable } from '@angular/core';
import { Privilege } from '@model/privilege/privilege.model';
import { UserService } from './user.service';
import { NgxPermissionsService } from 'ngx-permissions';
import * as _ from 'lodash';
import { Logger } from '@service/global.funcs';


@Injectable()
export class UserPrivilegeContextService {
    constructor(
        private userService: UserService,
        private permissionsService: NgxPermissionsService,
    ) {

    }

    configurePrivileges() {
        const loadedPrivileges = this.permissionsService.getPermissions();
        return this.requestPrivileges()
            .then((privileges: any) => {
                this.permissionsService.loadPermissions(privileges);
            });
    }
    requestPrivileges() {
        // return this.getPrivilegesStorage()
        //     .then(this.getPrivilegesServer.bind(this))
        return this.getMergePrivileges()
    }
    getPrivilegesStorage() {
        Logger().info('in:getPrivilegesStorage');
        return new Promise((resolve, reject) => {
            return resolve(JSON.parse(localStorage.getItem('userPrivileges')) || []);
        });
    }

    storePrivilegesStorage(privileges: any = []) {
        return new Promise((resolve, reject) => {
            localStorage.setItem('userPrivileges', JSON.stringify(privileges));
            return resolve(privileges);
        });
    }

    getPrivilegesServer() {
        return new Promise((resolve, reject) => {
            return this.userService
                .getMyPrivilege()
                .subscribe((res: any) => {
                    const objPrivileges = res.data.privileges;
                    const modelPrivilege = objPrivileges.map(privilege => privilege.key);
                    return resolve(modelPrivilege);
                }, err => {
                    return reject(err);
                });
        });
    }
    getMergePrivileges() {
        return new Promise((resolve, reject) => {
            return Promise.all([
                this.getPrivilegesStorage(),
                this.getPrivilegesServer()
            ]).then((results: any) => {
                return resolve(_.uniq([].concat.apply([], results)));
            });
        }).then(this.storePrivilegesStorage.bind(this));
    }

}
