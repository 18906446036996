<ng-template [ngxPermissionsOnly]="['campaigns:can_get_campaigns_reports_contact_per_campaign']">
  <div class="box-row panel panel-default">
    <div class="panel-heading">
      <h4>{{widgetTitle}}</h4>
    </div>
    <div class="panel-body text-center">
      <div *ngIf="!channelSalesLoaded" class="whirl standard spinnermargin"></div>
      <ng-template [ngIf]="channelSalesLoaded">
        <stacked-bar-chart [stackedBarData]="stackedBarChartData"></stacked-bar-chart>
      </ng-template>
    </div>
  </div>
</ng-template>
