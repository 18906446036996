import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import 'rxjs/add/operator/filter';
import { AuthLoginService } from '@service/auth/auth-login.service';
@Component({
    selector: 'app-callback',
    templateUrl: './callback.component.html',
    styleUrls: []
})
export class CallbackComponent implements OnInit {
    _as = null;
    constructor(
        private authloginservice: AuthLoginService,
        private route: ActivatedRoute
    ) {
        this.route.queryParams
            .filter(params => params._as)
            .subscribe(params => {
                this._as = params._as;
            });
    }

    ngOnInit() {
        this.authloginservice.handleAuthentication(this._as);
    }
}
