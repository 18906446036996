import { Injectable } from '@angular/core';

@Injectable()
export class ContactDropdownStorageService {
    public currentUser: string;
    private key = 'lead_dropdown_';
    constructor() {
        this.currentUser = localStorage.getItem('User');
    }

    public isEmptyItem() {
        return this.getItem() === null || this.getItem() === '';
    }

    public getItem() {
        return localStorage.getItem(this.key + this.currentUser);
    }

    public getItemParse() {
        return JSON.parse(this.getItem());
    }

    public setItem(obj) {
        let existingItem = this.getItemParse();
        if(existingItem){
            let itemAlreadyExist : boolean = false;
            for(let i=0;i<existingItem.length;i++){
                if(existingItem[i]['contact_detail_id'] == obj['contact_detail_id']){
                    existingItem[i]['formID'] = obj['formID'];
                    itemAlreadyExist = true;
                    break;
                }
            }
            if(!itemAlreadyExist){
                existingItem.push(obj);
            }
        }else{
            existingItem = [];
            existingItem.push(obj);
        }
        localStorage.setItem(this.key + this.currentUser, JSON.stringify(existingItem))
    }
    getContactDetailDropdownItem(contact_detail_id){
        let existingItem = this.getItemParse();
        if(existingItem){
            for(let i=0;i<existingItem.length;i++){
                if(existingItem[i]['contact_detail_id'] == contact_detail_id){
                    return existingItem[i]['formID'];
                }
            }
        }else{
            return '';
        }
    }
}
