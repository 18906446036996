import { Injectable } from '@angular/core';
import 'rxjs/add/operator/map';
import { Router } from '@angular/router';
import { globalValues } from '@app/globals';
import { CustomHttp } from '@service/customhttp.service';
import { RequestLibraryService } from '@service/core/request-library.service';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class EmailTemplateService {
  private customHttp: CustomHttp;
  constructor(private http: HttpClient, private router: Router, private requestLibrary: RequestLibraryService) {
    this.customHttp = new CustomHttp(http, router);
  }

  getPaginatedEmails(body) {
    return this.requestLibrary.GetAll('/emailtemplates', {
      options: body
    });
  }

  getExportEmails(body) {
    return this.requestLibrary.GetCSV('/emailtemplates', {
      options: body
    });
  }

  getAllEmails() {
    return this.requestLibrary.Get('/emailtemplates');
  }
  getAllCompactEmails() {
    return this.requestLibrary.GetAll('/emailtemplates', {
      options: { page_size: 'all', is_compact: true }
    });
  }

  getEmail(id) {
    return this.requestLibrary.Get('/emailtemplates/' + id);
  }


  // getBarData() {
  //     const relative_date = 'Last 7 Days';
  //     return this.requestLibrary.GetAll('/emailtemplates/reports/sent', {
  //         'options': { date_interval: relative_date }
  //     });
  // }

  deleteEmail(id) {
    return this.requestLibrary.Delete('/emailtemplates/' + id);
  }

  addEmail(model) {
    return this.requestLibrary.Post('/emailtemplates', model);
  }

  editEmail(model) {
    return this.requestLibrary.Post('/emailtemplates', model);
  }

  getTemplateLegend() {
    return this.requestLibrary.Get('/templatelegends');
  }

  private extractData(res: Response) {
    let body = res.json();
    return body;
  }
}
