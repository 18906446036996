 <div class="panel panel-default" id="panelChart2" *ngIf="chartFor == 'normalSize' && chartType == 'bar'">
    <div class="panel-wrapper">
      <div class="panel-body" *ngIf="!totalCount || totalCount <= 10">
        <canvas baseChart [chartType]="'bar'" [options]="optionsForLessThanTen" [datasets]="barData.datasets" [colors]="barColors" [labels]="barData.labels" [legend]="false" height="40"></canvas>
      </div>
      <div class="panel-body" *ngIf="totalCount && totalCount > 10">
        <canvas baseChart [chartType]="'bar'" [options]="optionsForGreaterThanTen" [datasets]="barData.datasets" [colors]="barColors" [labels]="barData.labels" [legend]="false" height="40"></canvas>
      </div>
    </div>
  </div>
  <div class="panel panel-default" id="panelChart2" *ngIf="chartFor == 'mediumSize' && chartType == 'bar'">
    <div class="panel-wrapper">
      <div class="panel-body" *ngIf="!totalCount || totalCount <= 10">
        <canvas baseChart [chartType]="'bar'" [options]="optionsForLessThanTen" [datasets]="barData.datasets" [colors]="barColors" [labels]="barData.labels" [legend]="false" height="60"></canvas>
      </div>
      <div class="panel-body" *ngIf="totalCount && totalCount > 10">
        <canvas baseChart [chartType]="'bar'" [options]="optionsForGreaterThanTen" [datasets]="barData.datasets" [colors]="barColors" [labels]="barData.labels" [legend]="false" height="60"></canvas>
      </div>
    </div>
  </div>
  <div id="panelChart2" *ngIf="chartFor == 'normalSize' && chartType == 'horizontalBar'">
      <div *ngIf="!totalCount || totalCount <= 10">
        <canvas baseChart [chartType]="'horizontalBar'" [options]="optionsForLessThanTen" [datasets]="barData.datasets" [colors]="barColors" [labels]="barData.labels" [legend]="false" height="130"></canvas>
      </div>
      <div *ngIf="totalCount && totalCount > 10">
        <canvas baseChart [chartType]="'horizontalBar'" [options]="optionsForGreaterThanTen" [datasets]="barData.datasets" [colors]="barColors" [labels]="barData.labels" [legend]="false" height="130"></canvas>
      </div>
  </div>

  <div id="panelChart2" *ngIf="chartFor == 'largeSize' && chartType == 'horizontalBar'">
    <div *ngIf="!totalCount || totalCount <= 10">
      <canvas baseChart [chartType]="'horizontalBar'" [options]="optionsForLessThanTen" [datasets]="barData.datasets" [colors]="barColors" [labels]="barData.labels" [legend]="false" height="245"></canvas>
    </div>
    <div *ngIf="totalCount && totalCount > 10">
      <canvas baseChart [chartType]="'horizontalBar'" [options]="optionsForGreaterThanTen" [datasets]="barData.datasets" [colors]="barColors" [labels]="barData.labels" [legend]="false" height="245"></canvas>
    </div>
</div>

  <div id="panelChart2" *ngIf="chartFor == 'normalSize' && chartType == 'verticalBar'">
      <div *ngIf="!totalCount || totalCount <= 10">
        <canvas baseChart [chartType]="'bar'" [options]="optionsForLessThanTen" [datasets]="barData.datasets" [colors]="barColors" [labels]="barData.labels" [legend]="false"></canvas>
      </div>
      <div *ngIf="totalCount && totalCount > 10">
        <canvas baseChart [chartType]="'bar'" [options]="optionsForGreaterThanTen" [datasets]="barData.datasets" [colors]="barColors" [labels]="barData.labels" [legend]="false"></canvas>
      </div>
  </div>