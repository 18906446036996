import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import 'rxjs/add/operator/map';
import { globalValues } from '@app/globals';
import { CustomHttp } from '@service/customhttp.service';
import { RequestLibraryService } from '@service/core/request-library.service';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class AbTestReportService {
    private customHttp: CustomHttp;
    constructor(
        private http: HttpClient,
        private router: Router,
        private requestLibrary: RequestLibraryService
    ) {
        this.customHttp = new CustomHttp(http, router);
    }

    getStatVisit(since) {
        return this.requestLibrary.GetAll('/tests/reports/visits', { 'options': { date_interval: since } });
    }

    getStatConversionRate(since) {
        return this.requestLibrary.GetAll('/tests/reports/visits-to-conversion-rate', { 'options': { date_interval: since } });
    }

    getVisitorCount(since) {
        return this.requestLibrary.GetAll('/tests/reports/visits', { 'options': { date_interval: since } })
            .map((res: any) => res.data)
            .map(arr => arr.reduce((total, record) => total + Number(record.total_visits), 0));
    }
    getConversionRateCount(since) {
        return this.requestLibrary.GetAll('/tests/reports/conversion-rate', { 'options': { date_interval: since } })
            .map((res: any) => res.data)
            .map(arr => arr.reduce((total, record) => total + Number(record.conversion_rate), 0));
    }
    getTotalConversionRateCount(since) {
        return this.requestLibrary.GetAll('/tests/reports/total-conversion-rate', { 'options': { date_interval: since } });
    }

}
