<ng-template [ngxPermissionsOnly]="['campaigns:can_get_campaigns_reports_sales_per_campaign']">
  <div class="box-row panel panel-default">
    <div class="panel-heading">
      <h4>{{widgetTitle}}</h4>
    </div>
    <div class="panel-body text-center">
      <ng-template [ngIf]="!salesByCampaignLoaded">
        <div class="whirl standard spinnermargin"></div>
      </ng-template>
      <ng-template [ngIf]="salesByCampaignLoaded">
        <ng-container *ngIf="costByCampaignData.datasets[0].data.length == 0; else displayChart">
          <p>None in the last {{relativeDate}}</p>
        </ng-container>
        <ng-template #displayChart>
          <pie-chart [pieData]=costByCampaignData [showLegends]=true></pie-chart>
        </ng-template>
      </ng-template>
    </div>
  </div>
</ng-template>
