import { Injectable } from '@angular/core';
import 'rxjs/add/operator/map';
import { RequestLibraryService } from '@service/core/request-library.service';
import { } from '@model/automation/action/status.model'

@Injectable()
export class AutomationActionService {
  constructor(private requestLibrary: RequestLibraryService) {
  }

  find(automationId: number, actionId: number) {
    return this.requestLibrary.Get('/automations/' + automationId + '/actions/' + actionId);
  }

  update(automationId: number, actionId: number, data) {
    return this.requestLibrary.Post('/automations/' + automationId + '/actions/' + actionId, data);
  }

  create(automationId: number, data) {
    return this.requestLibrary.Post('/automations/' + automationId + '/actions', data);
  }

  delete(automationId: number, actionId: number) {
    return this.requestLibrary.Delete('/automations/' + automationId + '/actions/' + actionId);
  }



}
