import { NgModule } from '@angular/core';

import { LayoutComponent } from './layout.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { AuthFailedRedirectComponent } from './auth-failed-redirect/auth-failed-redirect.component';
import { AuthFailedRedirectService } from './auth-failed-redirect/auth-failed-redirect.service';
import { AuthLogoutService } from '@service/auth/auth-logout.service';
import { SharedModule } from '../shared/shared.module';

import {
  SidebarWrapperComponent,
  SidebarComponent,
  SidebarPortalComponent
} from './sidebar-section/index';


@NgModule({
  imports: [
    SharedModule
  ],
  providers: [AuthFailedRedirectService, AuthLogoutService],
  declarations: [
    LayoutComponent,
    SidebarWrapperComponent,
    SidebarComponent,
    SidebarPortalComponent,
    HeaderComponent,
    FooterComponent,
    AuthFailedRedirectComponent
  ],
  exports: [
    LayoutComponent,
    SidebarWrapperComponent,
    SidebarComponent,
    SidebarPortalComponent,
    HeaderComponent,
    FooterComponent,
    AuthFailedRedirectComponent
  ]
})
export class LayoutModule { }
