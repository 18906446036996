import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import 'rxjs/add/operator/map';
import { globalValues } from '@app/globals';
import { CustomHttp } from '@service/customhttp.service';
import { RequestLibraryService } from '@service/core/request-library.service';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class CampaignService {
    private customHttp: CustomHttp;
    constructor(
        private http: HttpClient,
        private router: Router,
        private requestLibrary: RequestLibraryService
    ) {
        this.customHttp = new CustomHttp(http, router);
    }

    getPaginatedCampaigns(body) {
        return this.requestLibrary.GetAll('/campaigns', {
            'options': body
        });
    }

    getExportCampaigns(body) {
        return this.requestLibrary.GetCSV('/campaigns', {
            'options': body
        });
    }

    deleteCampaign(id) {
        return this.requestLibrary.Delete('/campaigns/' + id);
    }

    addEditCampaign(model) {
        return this.requestLibrary.Post('/campaigns', model);
    }

    getCampaign(id, args = null) {
        if (args != null) {
            return this.requestLibrary.GetAll('/campaigns/' + id, {
                'options': args
            });
        }
        return this.requestLibrary.Get('/campaigns/' + id);
    }

    getCampaigns() {
        return this.requestLibrary.GetAll('/campaigns', {
            'options': { page_size: 'all', sort: 'name', sort_dir: 'ASC', is_archived: false }
        });
    }

    getCompactCampaigns() {
        return this.requestLibrary.GetAll('/campaigns', {
            'options': { is_compact: 'true', page_size: 'all', sort: 'name', sort_dir: 'ASC', is_archived: false }
        });
    }

}
