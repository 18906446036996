import { Component, Input, OnChanges, OnInit, SimpleChange } from '@angular/core';
import { Privilege } from '@decorator/index';
import { Logger } from '@service/global.funcs';
import { CallReportService, ToasterLibraryService } from '@service/index';
import { NgxPermissionsService } from 'ngx-permissions';


@Component({
  selector: 'app-inbound-call-widget',
  templateUrl: './inbound-call-widget.component.html',
  styleUrls: ['./inbound-call-widget.component.scss'],
})

// @Privilege({
//   privilege: 'calls:can_get_calls_reports_inbound'
// })
export class InboundCallWidgetComponent implements OnInit, OnChanges {

  @Input() relativeDate: any;
  @Input() campaignData: any;
  public isCountLoaded = false;
  public inboundCallsCount = 0;
  constructor(
    private callReportService: CallReportService,
    private toasterLibrary: ToasterLibraryService,
    private permissionService: NgxPermissionsService

  ) { }

  ngOnInit() {
    if (this.permissionService.hasPermission('calls:can_get_calls_reports_inbound')) {
      this.getInboundCallsCount();
    }

  }

  ngOnChanges(changes: { [key: string]: SimpleChange }) {

    if (this.permissionService.hasPermission('calls:can_get_calls_reports_inbound')) {
      if (
        (changes['relativeDate'] && !changes['relativeDate'].isFirstChange()) ||
        (changes['campaignData'] && !changes['campaignData'].isFirstChange()) ||
        (changes['criteriaArray'] && !changes['criteriaArray'].isFirstChange())
      ) {
        this.getInboundCallsCount();
      }
    }
  }

  getInboundCallsCount() {
    this.isCountLoaded = false;

    const criteriaArray: any = [
      {
        id: '',
        'is_required': true,
        'criterion': 'dateRange',
        'value': {
          'start_date': '',
          'end_date': '',
          'relative_date': this.relativeDate
        }
      }
    ];

    if (this.campaignData && this.campaignData != "") {
      criteriaArray.push({
        'id': '',
        'value': { c_id: this.campaignData },
        'criterion': 'campaign',
        'is_required': true
      });
    }

    this.callReportService
      .getInboundCallCount(criteriaArray)
      .subscribe((res: any) => {
        Logger().info('getInboundCallsCount', res);
        this.inboundCallsCount = res.data.reduce((total, item) => total + Number(item.number_of_occurrences), 0);
        // this.inboundCallsCount = res.total;
        this.isCountLoaded = true;
      });
  }

}
