import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Intercom } from 'ng-intercom';
declare var window: any;
import Auth0Lock from 'auth0-lock';
import * as auth0js from 'auth0-js';
import * as _ from 'lodash';
import { auth0Config } from './auth0.config';

@Injectable()
export class AuthLogoutService {
  private _clientId = auth0Config.clientID;
  private _domain = auth0Config.domain;

  _auth0WebAuth = new auth0js.WebAuth({
    domain: this._domain,
    clientID: this._clientId,
    responseType: 'token id_token',
    // audience: auth0Config.audience,
    scope: 'openid',
    redirectUri: auth0Config.redirectUrl,
  });

  constructor(
    public router: Router,
    public intercom: Intercom
  ) {
  }


  public logout(): void {
    // Remove tokens and expiry time from localStorage
    localStorage.clear();

    this.intercom.shutdown();

    this._auth0WebAuth.logout({
      returnTo: auth0Config.loginUrl,
      client_id: this._clientId
    });

    // Go back to the home route
    //this.router.navigate(['/login']);
  }

}
