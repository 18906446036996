import { Injectable } from "@angular/core"
import { auth0Config } from "@service/auth/auth0.config"
import * as jwt from "jsonwebtoken"
import * as _ from "lodash"

@Injectable({
  providedIn: "root",
})
export class CustomerOnboardRedirectService {
  public auth0IncomingData
  public auth0_session_token: string
  public auth0_state: string
  public _auth0_rollias_secret = "x2f1" // collect this from environment file
  private _auth0_domain = auth0Config.domain

  constructor() {}

  redirectToAuth0(comingData: any, state: any) {
    this.auth0IncomingData = comingData
    this.auth0_state = state
    this.redirect()
  }

  async redirect() {
    const newToken = await this.generateNewToken(
      this.auth0IncomingData,
      this.auth0_state
    )
    const url = `https://${this._auth0_domain}/continue?state=${this.auth0_state}&mo_token=${newToken}`
    window.location.href = url
  }
  async generateNewToken(data, state) {
    const payload = _.merge({}, data)
    const nPayload = {
      state,
      mo_data: {
        mo_user_id: data.user_id,
      },
    }
    // todo: extend exp: token expiry time with + 60s.
    const result = _.merge(payload, nPayload)
    const secret = jwt.sign(result, this._auth0_rollias_secret)

    return secret
  }
}
