// Import the core angular services.
import {
  Component,
  ComponentFactoryResolver,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewContainerRef,
} from "@angular/core";
import {
  FormGroup,
  Validators,
  FormBuilder,
} from "@angular/forms";
import { Logger } from "@service/global.funcs";
import { ActivatedRoute, Router } from "@angular/router";
import "rxjs/add/observable/of";
//Import Services,Components,Pipes
import {
  EmailService,
  EmailTemplateService,
  ToasterLibraryService,
  UserService,
} from "@service/index";

import { TemplateLegendModalComponent } from "../../shared-components/template-legend-modal/template-legend-modal.component";
import { HttpClient } from "@angular/common/http";
import { ToasterConfig, ToasterService } from "angular2-toaster";
import { Subscription } from "rxjs";
declare var BeePlugin: any; // beefree plugin object
import * as _ from 'lodash';
import { Title } from '@angular/platform-browser';

@Component({
  selector: "app-contact-email-modal",
  templateUrl: "./contact-email-modal.component.html",
  styleUrls: ["./contact-email-modal.component.scss"],
})
export class ContactEmailModalComponent implements OnInit, OnDestroy {
  public isSaving = false;
  public usersArr: Array<any> = [];
  public isUsersLoaded: Boolean = false;
  public isEmailTemplatesLoaded: Boolean = false;
  public emailTemplatesArray: Array<any> = [];
  public emailText = ""; //for summer note editor
  public refreshTimeline: Boolean;
  public sendEmailToContactForm: FormGroup; //Form
  public formatSelected: Boolean = false; //beefree or summernote
  public headers;
  public isHTML: Boolean = false;
  public isTemplateChanged = true;
  public templateId: string = ""; //in case of editable
  public beTemplateId: string = ""; //in case of editable
  public account_id = localStorage.getItem("account_id");
  public user_id = localStorage.getItem("User");
  public template;
  public isLoaded: Boolean = false; //in case if editable, first load email
  public editorID = "contactEmailModalEditor";

  // Bee Free Configuration
  public beeConfig = {
    uid: "ap" + this.account_id,
    container: "bee-plugin-container",
    autosave: false,
    language: "en-US",
    specialLinks: [],
    mergeTags: [],
    mergeContents: [],
    // onSave: function (jsonFile, htmlFile) {
    //   // this.isSaving = true;
    //   localStorage.setItem("newsletter.html", htmlFile);
    //   this.sendEmailToContactForm.patchValue({
    //     raw_json: jsonFile,
    //   });
    //   this.submitForm();
    // }.bind(this),
    onSaveAsTemplate: function (jsonFile) {
      localStorage.setItem("newsletter-template.json", jsonFile);
    },
    onAutoSave: function (jsonFile) {
      localStorage.setItem("newsletter.autosave", jsonFile);
    },
    onSend: function (htmlFile) { },
    onError: function (errorMessage) { },
  };
  toasterconfig: ToasterConfig = new ToasterConfig({
    positionClass: "toast-bottom-right",
    showCloseButton: true,
  });
  queryParamSubscription: Subscription;

  public actionResolverArray = {
    templateLegend: {
      component: TemplateLegendModalComponent,
      label: 'Template Legend',
    },
  };
  @ViewChild('actionDetailContainter', { read: ViewContainerRef })
  entry: ViewContainerRef;
  componentRef: any;
  public selectedAction: any = {};

  constructor(
    private http: HttpClient,
    fb: FormBuilder,
    private router: Router,
    private emailService: EmailService,
    private emailTemplateService: EmailTemplateService,
    private userService: UserService,
    private toasterService: ToasterService,
    private elementRef: ElementRef,
    private route: ActivatedRoute,
    private toasterLibrary: ToasterLibraryService,
    private resolver: ComponentFactoryResolver,
    private titleService: Title
  ) {
    this.titleService.setTitle("Send an Email");
    this.headers = new Headers();
    this.headers.append("Content-Type", "application/x-www-form-urlencoded");
    this.sendEmailToContactForm = fb.group({
      cc: [null],
      body: [null],
      subject: [null, Validators.required],
      contact_id: [''],
      email_template_id: [0],
      sender: [this.user_id],
    });

  }
  contactID = '';
  ngOnInit() {
    this.isTemplateChanged = true;
    this.queryParamSubscription = this.route.queryParams.subscribe(qParam => {
      if (qParam && qParam['contactID']) {
        this.contactID = qParam['contactID'];
        this.chooseEditor("summernote");
        this.getEmailTemplates();
        this.getUsers();
        this.sendEmailToContactForm.patchValue({
          contact_id: this.contactID,
          sender: this.user_id,
        });
        if (qParam && qParam['subject'].length > 0) {
          this.sendEmailToContactForm.patchValue({
            subject: "RE: " + qParam['subject'],
          });
        }
        this.onChanges();

      } else {
        this.pop("error", "", "Contact ID is required.");
        if (this.router.url.indexOf('/leads') > -1) {
          this.router.navigate(['/leads']);
        } else if (this.router.url.indexOf('/email') > -1) {
          this.router.navigate(['/email']);
        }
        return;
      }
    });

  }
  ngOnDestroy() {
    if (this.queryParamSubscription) {
      this.queryParamSubscription.unsubscribe();
    }
  }
  ngAfterViewInit() {
    const s = document.createElement("script");
    s.type = "text/javascript";
    s.src = "https://app-rsrc.getbee.io/plugin/BeePlugin.js";
    this.elementRef.nativeElement.appendChild(s);
  }
  onChanges(): void {
    const templateControl = this.sendEmailToContactForm.get(
      "email_template_id"
    );
    templateControl.valueChanges.forEach((value: string) => this.getEmail());
  }

  getEmailTemplates() {
    this.emailTemplateService.getAllEmails().subscribe(
      (res: any) => {
        if (res.success == "true") {
          // this.emailTemplatesArray = res.data;
          // For now, we only want html emails
          this.emailTemplatesArray = res.data.filter(
            (data) => data.raw_json == ""
          );
        }
        this.isEmailTemplatesLoaded = true;
      },
      (err) => {
        this.pop("error", "", err.error.message);
      }
    );
  }
  getEmail() {
    this.emailTemplateService
      .getEmail(this.sendEmailToContactForm.get("email_template_id").value)
      .subscribe(
        (res: any) => {
          if (res.success == "true") {
            this.sendEmailToContactForm.patchValue({
              subject: res.data.subject,
              body: res.data.raw_html,
              raw_json: res.data.raw_json,
            });
            if(res.data.raw_html !="" && res.data.raw_html != " "){
              this.emailText = res.data.raw_html;
            }
            
            // $("#"+this.editorID).summernote("code", res.data.raw_html); // to Send to summer note

            if (res.data.raw_json != "" && res.data.raw_json != " ") {
              // email in json
              this.getEditToken(res.data.raw_json);
              this.isHTML = false;
            } else {
              // email in html
              this.isHTML = true;
              this.isLoaded = true;
            }
          }
        },
        (err) => {
          Logger().error(
            this.sendEmailToContactForm.get("email_template_id").value
          );
        }
      );
  }

  chooseEditor(option) {
    this.formatSelected = true;
    if (option == "summernote") {
      this.isHTML = true;
    } else {
      this.getToken();
    }
  }

  // Get Bee Free Token for Add Email
  getToken() {
    this.sendEmailToContactForm.patchValue({
      id: "0",
    });
    if (!this.templateId) {
      this.http
        .post(
          "https://auth.getbee.io/apiauth",
          "grant_type=password&client_id=fd7fb8a4-becf-4fd0-8025-0c02b39309f3&client_secret=Xj7IRMTDW7kzy33eupe22Q3hfMa0tXecqGYpJAk9iZZOOVD8lk3",
          { headers: this.headers }
        )
        .subscribe((res: any) => {
          BeePlugin.create(res.json(), this.beeConfig, (beePluginInstance) => {
            const bee = beePluginInstance;

            if (this.beTemplateId) {
              this.http
                .get(
                  "/assets/emailtemplates/template" +
                  this.beTemplateId +
                  ".json"
                )
                .subscribe((bRes: any) => {
                  this.template = bRes.json();
                  bee.start(this.template);
                });
            } else {
              this.http
                .get("https://rsrc.getbee.io/api/templates/m-bee")
                .subscribe((res: any) => {
                  const response = res.json();
                  bee.start(response);
                });
            }
          });
        });
    }
  }

  // Get Bee Free Token for Add Email
  getEditToken(htmlTemplate) {
    this.sendEmailToContactForm.patchValue({
      id: this.templateId,
    });
    this.isLoaded = true;
    this.http
      .post(
        "https://auth.getbee.io/apiauth",
        "grant_type=password&client_id=fd7fb8a4-becf-4fd0-8025-0c02b39309f3&client_secret=Xj7IRMTDW7kzy33eupe22Q3hfMa0tXecqGYpJAk9iZZOOVD8lk3",
        { headers: this.headers }
      )
      .subscribe((res: any) => {
        let response = res.json();
        BeePlugin.create(response, this.beeConfig, (beePluginInstance) => {
          let bee = beePluginInstance;
          bee.load(JSON.parse(htmlTemplate));
          bee.start(JSON.parse(htmlTemplate));
        });
      });
  }
  submitForm() {
    for (let c in this.sendEmailToContactForm.controls) {
      this.sendEmailToContactForm.controls[c].markAsTouched();
    }
    if (this.sendEmailToContactForm.valid) {
      this.isSaving = true;
      this.refreshTimeline = false;
      this.emailService
        .sendEmailToContact(this.sendEmailToContactForm.value)
        .subscribe(
          (res: any) => {
            if (res.success == "true") {
              this.isSaving = false;
              this.refreshTimeline = true;
              this.toasterLibrary.success(null, 'Email Sent Successfully');
            }
          },
          (err) => {
            this.pop("error", "", "API ERROR");
          }
        );
    } else {
      this.pop("error", "", "Must fill required fields");
    }
  }
  getUsers() {
    this.isUsersLoaded = false;
    this.usersArr = [];
    this.userService
      .getPaginatedUsers({
        page: 0,
        page_size: "all",
        sort: "name",
        sort_dir: "ASC",
        is_active: true,
      })
      .subscribe(
        (res: any) => {
          if (res.success == "true") {
            this.usersArr = res.data;
            this.isUsersLoaded = true;
          }
        },
        (err) => {
          this.pop("error", "", err.error.message);
        }
      );
  }
  onTemplateChange(email) {
    this.sendEmailToContactForm.patchValue({
      body: email,
    });
  }
  pop(type, title, text) {
    this.toasterService.pop(type, title, text);
  }
  navigate() {
    this.router.navigate(["/email-templates"]);
  }
  createComponent(actionType: any, actionData: any) {
    this.entry.clear();
    const factory = this.resolver.resolveComponentFactory(
      this.resolveActionComponent(actionType)
    );
    this.componentRef = this.entry.createComponent(factory);
    this.componentRef.instance.objModel = actionData;
  }

  resolveActionComponent(actionType: string | number) {
    if (this.actionResolverArray[actionType]) {
      return this.actionResolverArray[actionType]['component'];
    } else {
      return null;
    }
  }

  destroyComponent() {
    if (this.componentRef) {
      this.componentRef.destroy();
    }
    this.selectedAction = {};
  }
  isSelected() {
    return _.isEmpty(this.selectedAction);
  }
  getActionLabel(action) {
    return this.actionResolverArray[action.type]
      ? this.actionResolverArray[action.type]['label']
      : '';
  }
  addTemplateLegendAction() {
    let actionType = "templateLegend";
    this.selectedAction = { type: actionType };
    const objModel = {
      type: actionType,
    };
    this.createComponent(actionType, objModel);
  }
}

export class ContactEmailAdditionalData {
  // public userid: any;
  public contact_id: any;
  public subject: any;
  // public body: any;
  // public email_template_id: any;
  // public bcc: any;
  // public cc: any;
}
