import { OnInit, Component } from '@angular/core';
import { Privilege } from '@decorator/index';
import { AbTestReportService, LoggerService, ToasterLibraryService } from '@service/index';
import * as _ from 'lodash';
import { NgxPermissionsService } from 'ngx-permissions';
@Component({
  selector: 'app-tests-conversion-rate-widget',
  templateUrl: './tests-conversion-rate-widget.component.html',
  styleUrls: ['./tests-conversion-rate-widget.component.scss'],
})

// @Privilege({
//   privilege: 'tests:can_get_tests_reports_visits_to_conversion_rate'
// })

export class TestsConversionRateWidgetComponent implements OnInit {
  /*
  * Format
  *
  * [
	*  { label: record.name, data: [[record.[name].date_month, record.[name].conversion_rate]] },
  *  ]
  */
  public testConversionRateChartData = [{
    'label': '',
    'color': '#e88b1b',
    'data': []
  }];
  public isTestConversionRateChartLoaded: boolean = false;
  public colorsArray: Array<any> = [
    '#e88b1b',
    '#263746',
    '#ECCA2A',
    '#2F80E7'
  ];
  constructor(
    private toasterLibrary: ToasterLibraryService,
    private testReportService: AbTestReportService,
    private logger: LoggerService,
    private permissionService: NgxPermissionsService

  ) {
  }

  ngOnInit() {
    if (this.permissionService.hasPermission('tests:can_get_tests_reports_visits_to_conversion_rate')) {
      this.getTestStatConversionRate();
    }
  }
  getTestStatConversionRate() {
    this.isTestConversionRateChartLoaded = false;
    this.testReportService.getStatConversionRate('all')
      .subscribe((res: any) => {
        if (res.success == 'true') {
          if (res.data.length) {
            this.testConversionRateChartData = this.transform(res.data, 'name', 'date_month', 'conversion_rate');
          }
        }
        this.isTestConversionRateChartLoaded = true;
      }, err => {
        this.toasterLibrary.error(null, err.error.message);
      });
  }
  private transform(records, group_by, xAtr, yAtr) {
    return _.chain(records)
      .groupBy(group_by)
      .toPairs()
      .map(function (currentItem) {
        return _.zipObject(['label', 'data'], currentItem);
      })
      .map((currentItemT) => {
        const currentItemData = currentItemT['data'].map((item) => {
          return [item[xAtr], item[yAtr]];
        });
        return {
          'label': currentItemT['label'],
          'color': this.colorsArray[Math.floor(Math.random() * this.colorsArray.length)],
          'data': currentItemData
        };
      })
      .value();
  }
}
