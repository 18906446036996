import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import 'rxjs/add/operator/map';
import { globalValues } from '@app/globals';
import { CustomHttp } from '@service/customhttp.service';
import { RequestLibraryService } from '@service/core/request-library.service';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class SmsTemplateService {
    private customHttp: CustomHttp;
    constructor(
        private http: HttpClient,
        private router: Router,
        private requestLibrary: RequestLibraryService
    ) {
        this.customHttp = new CustomHttp(http, router);
    }

    getPaginatedSMSTemplates(page, page_size, sort, sort_dir) {
        return this.requestLibrary.GetAll('/smstemplates', {
            'options': { page: page, page_size: page_size, sort: sort, sort_dir: sort_dir }
        });
    }

    getExportedSMSTemplates(args) {
        return this.requestLibrary.GetCSV('/smstemplates', {
            'options': args
        });
    }

    getSMSTemplateDetail(id) {
        return this.requestLibrary.Get('/smstemplates/' + id);
    }

    addEditSMSTemplate(model) {
        return this.requestLibrary.Post('/smstemplates', model);
    }

    deleteSMSTemplate(id) {
        return this.requestLibrary.Delete('/smstemplates/' + id);
    }

    getAllTemplates() {
        return this.requestLibrary.GetAll('/smstemplates', {
            'options': { page_size: 'all' }
        });
    }
}
