import { Component, Injector, OnInit } from "@angular/core"
import { Router } from "@angular/router"
import { MenuService } from "@app/core/menu/menu.service"
import { SettingsService } from "@app/core/settings/settings.service"
declare var $: any
import * as _ from "lodash"
import {
  AccountInvoiceService,
  AccountService,
  AppConfig,
  HelpersService,
  ToasterLibraryService,
} from "@service/index"

@Component({
  selector: "app-sidebar-portal",
  templateUrl: "./sidebar-portal.component.html",
})
export class SidebarPortalComponent implements OnInit {
  public myAccount: any
  menuItems: Array<any> = [
    {
      text: "Balance",
      link: "/account/balance",
      icon: "icon-credit-card",
      privilege: "accounts:can_post_accounts_invoices",
      order: 1,
    },
    {
      text: "Leads",
      link: "/leads",
      icon: "icon-layers",
      privilege: "contacts:can_get_contacts",
      order: 2,
    },
    {
      text: "Billing",
      link: "/account/invoices",
      icon: "icon-book-open",
      privilege: "accounts:can_get_accounts_invoices",
      order: 3,
    },
    {
      text: "My Account",
      link: "/account",
      icon: "icon-user",
      privilege: "accounts:can_get_accounts_my",
      order: 4,
    },
  ]
  router: Router
  appCurrentVersion: any
  public parent_account_id: string
  constructor(
    private menu: MenuService,
    public settings: SettingsService,
    private injector: Injector,
    private accountService: AccountService
  ) {
    // this.menuItems = menu.getMenu();
    if (localStorage.getItem("appVersion") == "portal") {
      _.remove(this.menuItems, {
        link: "/dashboard",
      })
    }
  }

  ngOnInit() {
    const account = JSON.parse(localStorage.getItem("account"))
    if (account) {
      this.parent_account_id = account.parent_account_id
    }

    this.router = this.injector.get(Router)
    this.appCurrentVersion = localStorage.getItem("appVersion")
    this.router.events.subscribe(val => {
      // scroll view to top
      window.scrollTo(0, 0)
    })
    this.getClientDetail()
  }
  getClientDetail() {
    this.accountService.getMyAccount().subscribe(
      (res: any) => {
        if (res.success == "true") {
          this.myAccount = res.data
        }
      },
      err => {
        console.log(err)
      }
    )
  }

  removeFloatingNav() {
    $(".nav-floating").remove()
  }

  isSidebarCollapsed() {
    return this.settings.layout.isCollapsed
  }
  isSidebarCollapsedText() {
    return this.settings.layout.isCollapsedText
  }
  isEnabledHover() {
    return this.settings.layout.asideHover
  }
}
