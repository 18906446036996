import { Component, Input, OnChanges, OnInit, SimpleChange } from '@angular/core';
import { Privilege } from '@decorator/index';
import { AccountReportService, ToasterLibraryService } from '@service/index';
import { NgxPermissionsService } from 'ngx-permissions';

@Component({
  selector: 'app-account-receivable-widget',
  templateUrl: './account-receivable-widget.component.html',
  styleUrls: ['./account-receivable-widget.component.scss']
})

// @Privilege({
//   privilege: 'emailtemplates:can_get_emailtemplates_reports_sent'
// })

export class AccountReceivablesWidgetComponent implements OnInit {
  public isCountLoaded = false;
  public accountsReceiveable = 0;
  constructor(
    private accountReportService: AccountReportService,
    private toasterLibrary: ToasterLibraryService,
    private permissionService: NgxPermissionsService

  ) { }
  ngOnInit() {
    if (this.permissionService.hasPermission('accounts:can_get_accounts_reports_receivables')) {
      this.requestAccountReceiveable();
    }

  }

  requestAccountReceiveable() {
    this.isCountLoaded = false;
    this.accountReportService
      .getAccountReceiveable()
      .subscribe((res: any) => {
        this.accountsReceiveable = res;
        this.isCountLoaded = true;
      });
  }

}
