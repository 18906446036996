import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'Sorting'})
export class SortingPipe implements PipeTransform {
    transform(list, path: string[], order: number) {
        // Check if is not null
        if (!list || !path || !order) return list;
        // if (!list || !path || !order) return list;
        return list.sort((a, b) => {
          // We go for each property followed by path
          path.forEach(property => {
            a = a[property];
            b = b[property];
          })

          // Order * (-1): We change our order
          return a > b ? order : order * (- 1);
        })
      }

}
