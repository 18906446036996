import { Component, OnDestroy, OnInit } from '@angular/core';
import { environment } from '@env/environment';
import { Logger } from '@service/global.funcs';
import { ToasterLibraryService } from '@service/index';
import * as _ from 'lodash';
import { TimerObservable } from 'rxjs/observable/TimerObservable';
import { AuthFailedRedirectService } from './auth-failed-redirect.service';
import { AuthLogoutService } from '@service/auth/auth-logout.service';

@Component({
  selector: 'app-auth-failed-redirect',
  templateUrl: './auth-failed-redirect.component.html',
  styleUrls: []
})
export class AuthFailedRedirectComponent implements OnInit, OnDestroy {
  private alive: boolean;
  private interval: number;
  private display: boolean;
  constructor(
    private authFailedRedirectService: AuthFailedRedirectService,
    private authLogout: AuthLogoutService,
  ) {
    this.display = false;
    this.alive = true;
    this.interval = 600000;
  }

  ngOnInit() {

    TimerObservable.create(0, this.interval)
      .takeWhile(() => (environment.production) && this.alive)
      // .takeWhile(() => (true) && this.alive)
      .subscribe(() => {
        this.authFailedRedirectService.get()
          .subscribe((data: any) => {
            if (data == false || data == null) {
              this.authLogout.logout();
              window.location.href = '/login';
              return;
            }
          });
      });
  }

  ngOnDestroy() {
    this.alive = false;
  }
}
