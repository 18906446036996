<div *ngIf="!isLoaded" class="whirl standard spinnermargin"></div>
<div *ngIf="isLoaded">
  <toaster-container [toasterconfig]="toasterconfig"></toaster-container>
  <form (ngSubmit)="onSubmit()" [formGroup]="leadDynamicForm" *ngIf="leadDynamicForm">
    <div class="panel panel-default">
      <div class="panel-body">
        <div *ngFor="let question of oQuestions" class="form-row">
          <app-lead-df-field [question]="question" [form]="leadDynamicForm"></app-lead-df-field>
        </div>
      </div>
      <div class="panel-footer text-center">
        <button class="btn btn-info" type="submit">Save Changes</button>
      </div>
    </div>
  </form>
  <div *ngIf="payLoad" class="form-row">
    <strong>Saved the following values</strong>
    <br>{{payLoad}}
  </div>
</div>