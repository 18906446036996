<div *ngIf="!pricesLoaded" class="whirl standard settingsspinnermargin"></div>
<div *ngIf="pricesLoaded">
    <div class="row">
        <div class="col-sm-12">
            <a [routerLink]="[ '/prices' ]" class="hyperlink-color pull-right">Manage Prices</a>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12">
            <select class="form-control" #price name="price" [(ngModel)]="model.is_required">
                <option value=true>Require Any Checked Prices</option>
                <option value=false>Exclude all Checked Prices</option>
            </select>
        </div>
    </div>
    <div class="row mt-sm">
        <div class="col-md-12">
            <a class="hyperlink-color pull-left" (click)="toggleAllPrices();">{{togglePriceLabel}}</a>
        </div>
    </div>
    <div class="prices-checkboxes-list">
        <ng-template ngFor let-chunkPrices [ngForOf]="chunkPricesArray['chunkData']" let-i="chunkIndex">
            <div class="row">
                <ng-template ngFor let-iPrice [ngForOf]="chunkPrices" let-i="index">
                    <div class="col-{{view}}-{{chunkPricesArray['chunkSize']}}">
                        <label class="checkbox-inline c-checkbox">
                            <input name="price_name" (click)="addRemovePrice(iPrice.id);"
                                [checked]="checkPrice(iPrice.id)" type="checkbox" />
                            <span class="fa fa-check"></span>
                            [{{iPrice.id}}] {{iPrice.price}} - {{iPrice.name}}
                        </label>
                    </div>
                </ng-template>
            </div>
        </ng-template>

    </div>
</div>
