import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CustomHttp } from '@service/customhttp.service';
import { RequestLibraryService } from '@service/core/request-library.service';
import 'rxjs/add/operator/map';
import { Observable } from 'rxjs/Observable';
import { IAccountsGetResponse } from '@model/account/accounts-get.response';
import { Logger } from '@service/global.funcs';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class AccountService {
  private customHttp: CustomHttp;
  constructor(private http: HttpClient, private router: Router, private requestLibrary: RequestLibraryService) {
    this.customHttp = new CustomHttp(http, router);
  }

  getMyAccountSettings(setting) {
    return this.requestLibrary.GetAll('/accounts/my/settings?options=', { options: { settings_name: setting } });
  }

  updateMyAccountSettings(model) {
    return this.requestLibrary.Post('/accounts/my/settings', model);
  }

  getMyAccount() {
    return this.requestLibrary.Get('/accounts/my');
  }

  getCreditCard(id) {
    if (id !== '') {
      return this.requestLibrary.Get('/accounts/' + id + '/creditcard');
    } else {
      return this.requestLibrary.Get('/accounts/my/creditcard');
    }
  }
  getMyCreditCard() {
    return this.requestLibrary.Get('/accounts/my/creditcard');
  }

  addCreditCard(model) {
    return this.requestLibrary.Post('/accounts/creditcard', model);
  }

  getRefundRequests() {
    return this.requestLibrary.Get('/refundmoderate');
  }

  addNote(model) {
    return this.requestLibrary.Post('/notes', model);
  }
  getAccountNoteTimeline(accountId) {
    return this.requestLibrary.GetAll('/accounts/' + accountId + '/notes', {
      options: {
        expand: ['notes']
      }
    });
  }

  getStripe() {
    return this.requestLibrary.Get('/accounts/stripe');
  }
  getStripePublishableKey() {
    return this.requestLibrary.Get('/accounts/stripe/publishable_key');
  }

  getParentStripePublishableKey() {
    return this.requestLibrary.Get('/accounts/stripe/publishable_key/parent');
  }
  setStripe(model) {
    return this.requestLibrary.Post('/accounts/stripe', model);
  }

  getPaginatedClients(page, page_size, sort, sort_dir): Observable<IAccountsGetResponse> {
    return this.requestLibrary.GetAll('/accounts', {
      options: {
        page: page,
        page_size: page_size,
        sort: sort,
        sort_dir: sort_dir
      }
    });
  }

  getExportedClients(args) {
    return this.requestLibrary.GetCSV('/accounts', {
      options: args
    });
  }

  getFilteredClients(body) {
    return this.requestLibrary.GetAll('/accounts', { options: body });
  }

  getCompactAccountByGroup(group) {
    return this.getFilteredClients({
      plan_id: group,
      is_compact: true,
      page_size: 'all',
      is_active : 1
    });
  }

  changeStatus(model) {
    return this.requestLibrary.Post('/accounts', model);
  }

  updateClient(model) {
    return this.requestLibrary.Post('/accounts', model);
  }

  getClient(id, filterExpand = false, args = null) {
    if (args != null) {
      return this.requestLibrary.GetAll('/accounts/' + id, {
        options: args
      });
    }
    if (filterExpand == true) {
      return this.requestLibrary.GetAll('/accounts/' + id, {
        options: { expand: ['filter'] }
      });
    }

    return this.requestLibrary.Get('/accounts/' + id);
  }

  getAccounts() {
    return this.requestLibrary.GetAll('/accounts', {
      options: { page_size: 'all' }
    });
  }
  getAccountDetail(id) {
    return this.requestLibrary.GetAll('/accounts/' +id);
  }

  loginClient(model) {
    return this.requestLibrary.Post('/accounts/clientlogin', model);
  }

  appendUserId(id, account_id, account_name) {
    return this.customHttp.appendUserId(id, account_id, account_name);
  }

  cancelAccount(model) {
    return this.requestLibrary.Post('/accounts/cancel', model);
  }

  restoreAccount(model) {
    return this.requestLibrary.Post('/accounts/restore', model);
  }
  getAllCompactsAccounts() {
    return this.requestLibrary.GetAll('/accounts', {
      options: {
        is_compact: true,
        page_size: 'all',
        status: 1
      }
    });
  }

  deleteAccount(id) {
    return this.requestLibrary.Delete('/accounts/' + id);
  }

  deleteAccountNote(accountId, noteId) {
    return this.requestLibrary.Delete('/accounts/' + accountId + '/notes/' + noteId);
  }

  storePrivilege(user: number, privileges: any) {
    return this.requestLibrary.Post(`/accounts/${user}/privileges`, privileges);
  }
  getPrivilege(user: number) {
    return this.requestLibrary.Get(`/accounts/${user}/privileges`);
  }

  getStripeCustomer(account: number) {
    return this.requestLibrary.Get(`/accounts/${account}/stripe-customer`);
  }
  storeStripeCustomer(account: number, token: string) {
    return this.requestLibrary.Post(`/accounts/${account}/stripe-customer`, { card_token: token });
  }
}
