import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CustomHttp } from '@service/customhttp.service';
import { RequestLibraryService } from '@service/core/request-library.service';
import 'rxjs/add/operator/map';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class StatusService {
    private customHttp: CustomHttp;
    constructor(
        private http: HttpClient,
        private router: Router,
        private requestLibrary: RequestLibraryService
    ) {
        this.customHttp = new CustomHttp(http, router);
    }

    getAllStatuses(sort, sort_dir) {
        return this.requestLibrary.GetAll('/status', {
            'options': { page_size: 'all', sort: sort, sort_dir: sort_dir }
        });
    }

    getStatusDetail(id) {
        return this.requestLibrary.Get('/status/' + id);
    }

    addEditStatus(model) {
        return this.requestLibrary.Post('/status', model);
    }

    deleteStatus(id) {
        return this.requestLibrary.Delete('/status/' + id);
    }

    UpDownStatusOrder(id, model) {
        return this.requestLibrary.Post('/status/' + id + '/pipeline', model);
    }

    getAllStatus() {
        return this.requestLibrary.GetAll('/status', {
            'options': { page_size: 'all' }
        });
    }

    getFilterStatus(parent_status) {
        return this.requestLibrary.GetAll('/status', {
            'options': { page_size: 'all', 'parent_status': parent_status, sort: 'stage', sort_dir: 'ASC' }
        });
    }
}
