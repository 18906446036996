import { Injectable, OnInit } from '@angular/core';
import { environment } from '@env/environment';
import { AccountService } from '@service/index';
import { ScriptLoaderService } from '@service/scriptloader.service';

declare var Stripe: any;
@Injectable()
export class StripeService {
  stripe;
  constructor(private scriptLoaderService: ScriptLoaderService) { }
  getStripeElement() {
    this.stripe = Stripe(localStorage.getItem('stripe_publishable_key'));
    return this.stripe;
  }
  loadScripts() {
    const dynamicScripts = [
      'https://js.stripe.com/v3/',
    ];
    for (let i = 0; i < dynamicScripts.length; i++) {
      const node = document.createElement('script');
      node.setAttribute('id', 'stripeJS');
      node.src = dynamicScripts[i];
      node.type = 'text/javascript';
      node.async = false;
      node.charset = 'utf-8';
      document.getElementsByTagName('head')[0].appendChild(node);
    }
  }
}
