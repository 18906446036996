<button class="btn btn-info btn-xs mr-sm status" *ngIf="showFiltersObj.status && showFiltersObj.status != 2">{{ (showFiltersObj.status == '1') ? '"Active"' : '"Deactivated"' }}</button>
<button class="btn btn-info btn-xs mr-sm is_active" *ngIf="showFiltersObj.is_active && showFiltersObj.is_active != 2">{{ (showFiltersObj.is_active == '1') ? '"Active"' : '"Deactivated"' }}</button>

<button class="btn btn-info btn-xs mr-sm call_tracking_status" *ngIf="showFiltersObj.call_tracking_status">{{ showFiltersObj.call_tracking_status }}</button>

<button class="btn btn-info btn-xs mr-sm call_tracking_fallback" *ngIf="showFiltersObj.call_tracking_fallback">{{ showFiltersObj.call_tracking_fallback }}</button>
<button class="btn btn-info btn-xs mr-sm call_tracking_schedule" *ngIf="showFiltersObj.call_tracking_schedule && showFiltersObj.call_tracking_schedule.name !=''">{{ '"' + showFiltersObj.call_tracking_schedule.name + '"' }}</button>

<button class="btn btn-info btn-xs mr-sm is_archived_0" *ngIf="showFiltersObj.is_archived && showFiltersObj.is_archived == 0">Unarchived</button>
<button class="btn btn-info btn-xs mr-sm is_archived_1" *ngIf="showFiltersObj.is_archived && showFiltersObj.is_archived == 1">Archived</button>
<button class="btn btn-info btn-xs mr-sm is_archived_2" *ngIf="showFiltersObj.is_archived && showFiltersObj.is_archived == 2">Both</button>
<button class="btn btn-info btn-xs mr-sm search" *ngIf="showFiltersObj.search && showFiltersObj.search != ''">{{ '"' + showFiltersObj.search + '"' }}</button>
<button class="btn btn-info btn-xs mr-sm lead_type" *ngIf="showFiltersObj.lead_type && showFiltersObj.lead_type != ''">{{ (showFiltersObj.lead_type == 'call') ? 'Call' : 'Web Form' }}</button>
<button class="btn btn-info btn-xs mr-sm role_name" *ngIf="showFiltersObj.role && showFiltersObj.role.name != ''">{{ showFiltersObj.role.name }}</button>
<button class="btn btn-info btn-xs mr-sm plan_name" *ngIf="showFiltersObj.plan && showFiltersObj.plan.name != ''">{{ showFiltersObj.plan.name }}</button>
<button class="btn btn-info btn-xs mr-sm group_name" *ngIf="showFiltersObj.group && showFiltersObj.group.name != ''">{{ showFiltersObj.group.name }}</button>
<button class="btn btn-info btn-xs mr-sm channel_name" *ngIf="showFiltersObj.channel && showFiltersObj.channel.name != ''">{{ '"' + showFiltersObj.channel.name + '"'}}</button>

<button class="btn btn-default btn-xs" *ngIf="(showFiltersObj.status && showFiltersObj.status != 2) ||
        (showFiltersObj.is_active && showFiltersObj.is_active != 2)
            || (showFiltersObj.call_tracking_status && showFiltersObj.call_tracking_status !='') 
            || (showFiltersObj.call_tracking_fallback && showFiltersObj.call_tracking_fallback!='') 
            || (showFiltersObj.call_tracking_schedule && showFiltersObj.call_tracking_schedule.name !='') 

            || (showFiltersObj.search && showFiltersObj.search != '') 
            || (showFiltersObj.is_archived && showFiltersObj.is_archived != '') 
            || (showFiltersObj.lead_type && showFiltersObj.lead_type != '') 
            || (showFiltersObj.role && showFiltersObj.role.name != '') 
            || (showFiltersObj.plan && showFiltersObj.plan.name != '')
            || (showFiltersObj.group && showFiltersObj.group.name != '') 
            || (showFiltersObj.channel && showFiltersObj.channel.name != '')" (click)="deleteAppliedFilters('all', 'Are you sure to clear all filters?')">Clear All Filters</button>