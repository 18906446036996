import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormFieldBase } from '../form-field/form-field-base';
@Component({
  selector: 'app-lead-df-field',
  templateUrl: 'lead-df-field.component.html'
})
export class LeadDfFieldComponent {
  @Input() question: FormFieldBase<any>;
  @Input() form: FormGroup;
  constructor() {
  }
  get isValid() { return this.form.controls[this.question.key].valid; }
}
