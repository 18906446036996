import { Component, OnInit, Input } from '@angular/core';


@Component({
    selector: 'stacked-bar-chart',
    templateUrl: './stacked-bar-chart.component.html'
})
export class StackedBarChartComponent implements OnInit {
    @Input() stackedBarData: any;
    @Input() stackedBarHeight: any = 120;

    //Stacked Bar Chart Options
    barStackedOptions = {
        series: {
            stack: true,
            bars: {
                align: 'center',
                lineWidth: 0,
                show: true,
                barWidth: 0.6,
                fill: 0.9
            }
        },
        grid: {
            borderColor: '#eee',
            borderWidth: 1,
            hoverable: true,
            backgroundColor: '#fcfcfc'
        },
        tooltip: true,
        tooltipOpts: {
            content: function (label, x, y) { return label + ' : ' + y; }
        },
        xaxis: {
            tickColor: '#fcfcfc',
            mode: 'categories',
        },
        yaxis: {
            min: 0,
            //max: 200, // optional: use it for a clear represetation
            // position: ($scope.app.layout.isRTL ? 'right' : 'left'),
            tickColor: '#eee',
        },
        shadowSize: 0,
        legend: false,
        maintainAspectRatio: false,
    };


    constructor() {

    }

    ngOnInit() {
    }

}
